<div class="dashboard-container">
  <div
    id="dashboard-project-search"
    fxLayout="column"
    fxLayoutAlign="center center"
    class="dashboard-form-container"
  >
    <mat-icon
      svgIcon="dashboard-project-search"
      class="dashboard-logo"
    ></mat-icon>
    <form
      fxLayout
      class="search-form"
      (ngSubmit)="openSearchPage()"
    >
      <input
        id="search-input"
        matInput
        [(ngModel)]="searchValue"
        name="searchValue"
        type="text"
        class="search-input"
        placeholder="{{'DASHBOARD_PROJECT_SEARCH.SEARCH_PLACEHOLDER' | translate}}"
      />
      <app-raised-button
        type="submit"
        id="search-button"
      >
        {{'DASHBOARD_PROJECT_SEARCH.SEARCH_BUTTON' | translate}}
      </app-raised-button>
    </form>
  </div>
</div>
