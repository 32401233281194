import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EulaAcceptRequest, GetEulaMeResponse, GetEulaResponse } from '@core/models/eula/eula.model';
import { HttpClient } from '@angular/common/http';
import { ApiUrlProvider } from '@core/utilities/apiUrl/apiUrl.provider';

@Injectable({
  providedIn: 'root'
})
export class EulaService {
  constructor(private http: HttpClient, private apiUrlProvider: ApiUrlProvider) {}

  respondToEula(accepted: boolean, eulaNrn: string): Observable<{}> {
    const body: EulaAcceptRequest = {
      isAccepted: accepted
    };

    return this.http.post(`${this.apiUrlProvider.getUrl()}/eula/${eulaNrn}/accept`, body);
  }

  getEula(): Observable<GetEulaResponse> {
    return this.http.get<GetEulaResponse>(`${this.apiUrlProvider.getUrl()}/eula`);
  }

  getEulaMe(): Observable<GetEulaMeResponse> {
    return this.http.get<GetEulaMeResponse>(`${this.apiUrlProvider.getUrl()}/eula/me`);
  }
}
