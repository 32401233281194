export enum ApiErrorType {
  EmailInvalidLeadingOrTrailingCharacters,
  EmailRepeatingCharacters,
  TooLong,
  TooShort,
  InvalidCharacters,
  NotSpecified,
  OverwriteNotAllowed,
  DuplicateEmailFilingAddress,
  Unhandled
}

export enum ApiErrorMessageType {
  TnCNotAccepted = 'http://issues.newforma.cloud/common/forbidden#TnC_not_accepted',
  ProjectNotConnectedToFolder = 'http://issues.newforma.cloud/common/not_found#project_not_connected_to_folder'
}
