import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { AvatarState } from '@shared/models/avatarState/avatar-state';

@Component({
  selector: 'app-avatar-manager',
  templateUrl: './avatar-manager.component.html',
  styleUrls: ['./avatar-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarManagerComponent implements OnChanges {
  @Input() isMultipleUser: boolean;
  @Input() isAnyUserInactive: boolean;

  avatarManagerState: AvatarState;
  avatarState = AvatarState;

  ngOnChanges(): void {
    this.avatarManagerState = this.isMultipleUser
      ? AvatarState.MultipleUsers
      : this.isAnyUserInactive
      ? AvatarState.SingleInactiveUser
      : AvatarState.SingleActiveUser;
  }
}
