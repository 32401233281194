<div
  class="search-selector-container"
  [class.label-text-disabled]="shouldDisplayTooltip"
>
  <form
    [formGroup]="form"
  >
    <mat-form-field
      fxFlex=" 1 1 100"
      class="search-input"
      [class.disabled]="shouldDisplayTooltip"
      [class.custom-hint]="shouldDisplayCustomMessage"
      [class.ff-additional-padding-for-error-msg]="searchControl?.errors"
      [id]="elementId"
      [class.display-required-asterisk]="isRequired"
      [class.placeholder-padding]="!isFocused && !searchInput.value.length"
      [class.placeholder-no-padding]="isFocused"
    >
      <mat-icon
        svgIcon="search"
        class="search-icon"
        matPrefix
      ></mat-icon>
      <app-tooltip
        [title]="tooltipValue"
        [showHoverCard]="showHoverCard"
      >
      </app-tooltip>
      <input
        #searchInput
        matInput
        class="search-selector-input"
        placeholder="{{ label | translate }}"
        matAutocompletePosition="below"
        [attr.disabled]="shouldDisplayTooltip"
        [formControl]="searchControl"
        [matAutocomplete]="keywordAutocomplete.auto"
        (focus)="setIsInputFocused(true)"
        (blur)="setIsInputFocused(false)"
        (click)="openAutoCompletePanel()"
        (mouseenter)="mouseOver()"
        (mouseleave)="mouseOut()"
        />
        <span
          *ngIf="shouldDisplaySuffixCustomMessage"
          matSuffix
          class="input-suffix"
        >
          {{ customMessage | translate }}
        </span>
      <mat-error
        class="search-selector-error"
        *ngIf="searchControl?.errors"
      >
        {{ validationErrorMessage | translate }}
      </mat-error>
      <mat-hint
        *ngIf="shouldDisplayCustomMessage"
      >
        {{ 'APP.KEYWORDS.SPEC_SECTION_VALIDATION_INFORMATION' | translate}}
      </mat-hint>
      <app-search-autocomplete
        id="keyword-autocomplete"
        #keywordAutocomplete
        [items]="(searchControl.value && isClientSideFiltering) ? filteredItems : items"
        [hasError]="hasError"
        [isLoading]="isLoading"
        [hasMoreItemsToLoad]="hasMoreItemsToLoad"
        [shouldShowDescription]="shouldShowDescription"
        [loadingErrorMessage]="loadingErrorMessage"
        [noResultErrorMessage]="noResultErrorMessage"
        (onSelectItem)="selectItem($event)"
        (onPanelOpened)="panelOpened()"
        (onPanelClosed)="panelClosed()"
        (onClickMessage)="focusOnClick()"
      ></app-search-autocomplete>
      <span
        class="tooltip"
        *ngIf="shouldDisplayTooltip && tooltipErrorMessage"
      >
        {{ tooltipErrorMessage | translate }}
      </span>
    </mat-form-field>
  </form>
</div>
