import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipComponent {
  @Input() showHoverCard: boolean;
  @Input() title: string;
  @Input() subtitle: string;
}
