import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from '@core/guards/authentication/loggedIn.guard';
import { BrowserCompatibilityGuard } from '@core/guards/compatibility/browser-compatibility.guard';
import { BrowserCompatibilityWarningComponent } from '@shared/components/browser-compatibility-warning/browser-compatibility-warning.component';
import { EmailsGuard } from '@core/guards/emails/emails.guard';
import { ActionItemsGuard } from '@core/guards/action-items/action-items.guard';
import { FeatureGuard } from '@core/guards/feature/feature.guard';
import { ServerAccessGuard } from '@app/core/guards/server-access/server-access.guard';
import { DashboardSearchComponent } from '@shared/components/dashboard-search/dashboard-search.component';
import { LoggedOutGuard } from '@core/guards/authentication/loggedOut.guard';
import { UserAdministrationGuard } from '@core/guards/user-administration/user-administration.guard';
import { SubmittalsGuard } from '@core/guards/submittals/submittals.guard';
import { ProjectTeamGuard } from '@core/guards/project-team/project-team.guard';
import { RfisGuard } from '@core/guards/rfis/rfis.guard';

const routes: Routes = [
  { path: 'unsupported-browser', component: BrowserCompatibilityWarningComponent },
  {
    path: '',
    canActivate: [FeatureGuard],
    canActivateChild: [BrowserCompatibilityGuard],
    children: [
      // external routes
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      {
        path: 'login',
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule),
        canActivate: [LoggedOutGuard]
      },
      {
        path: 'redirect',
        loadChildren: () => import('./redirect/redirect.module').then(m => m.RedirectModule)
      },
      { path: 'dashboard/project-search/:projectNrn', component: DashboardSearchComponent },
      {
        path: 'shareditem',
        loadChildren: () => import('./shared-item/shared-item.module').then(m => m.SharedItemModule)
      },
      // internal routes (needs canLoad property to prevent unauthenticated navigation)
      {
        path: 'projects/:projectNrn/changelog',
        loadChildren: () => import('./project-log/project-log.module').then(m => m.ProjectLogModule),
        canLoad: [LoggedInGuard]
      },
      {
        path: 'projects',
        loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
        canLoad: [LoggedInGuard]
      },
      {
        path: 'projects/:projectNrn/project-team',
        loadChildren: () => import('./project-team/project-team.module').then(m => m.ProjectTeamModule),
        canLoad: [LoggedInGuard],
        canActivate: [ProjectTeamGuard]
      },
      {
        path: 'actionitems',
        loadChildren: () => import('./action-items/action-items.module').then(m => m.ActionItemsModule),
        canLoad: [LoggedInGuard],
        canActivate: [ActionItemsGuard]
      },
      {
        path: 'actionitems/:projectNrn',
        loadChildren: () => import('./action-items/action-items.module').then(m => m.ActionItemsModule),
        canLoad: [LoggedInGuard],
        canActivate: [ActionItemsGuard]
      },
      {
        path: 'emails',
        loadChildren: () => import('./file-email/file-email.module').then(m => m.FileEmailModule),
        canLoad: [LoggedInGuard],
        canActivate: [EmailsGuard]
      },
      {
        path: 'serveraccess',
        loadChildren: () => import('./server-access/server-access.module').then(m => m.ServerAccessModule),
        canLoad: [LoggedInGuard],
        canActivate: [ServerAccessGuard]
      },
      {
        path: 'submittals',
        loadChildren: () => import('./submittals/submittals.module').then(m => m.SubmittalsModule),
        canLoad: [LoggedInGuard],
        canActivate: [SubmittalsGuard]
      },
      {
        path: 'rfis',
        loadChildren: () => import('./rfis/rfis.module').then(m => m.RfisModule),
        canLoad: [LoggedInGuard],
        canActivate: [RfisGuard]
      },
      {
        path: 'projects/:projectNrn',
        loadChildren: () => import('./project-view/project-view.module').then(m => m.ProjectViewModule),
        canLoad: [LoggedInGuard]
      },
      {
        path: 'user-administration',
        loadChildren: () =>
          import('./user-administration/user-administration.module').then(m => m.UserAdministrationModule),
        canLoad: [LoggedInGuard],
        canActivate: [UserAdministrationGuard]
      },
      // default route
      { path: '**', redirectTo: 'login' } // TODO: add PageNotFoundComponent
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
