import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EventUserListItem } from '@shared/models/eventUsersListItem/event-user-list-item';
import { LicenseUserViewModel } from '@user-administration/models/user-administration/license-user';

@Component({
  selector: 'app-user-display-list-with-delete',
  templateUrl: './user-display-list-with-delete.component.html',
  styleUrls: ['./user-display-list-with-delete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDisplayListWithDeleteComponent {
  @Input() eventUserList: EventUserListItem<LicenseUserViewModel>[];
  @Output() onUnselectUser = new EventEmitter<LicenseUserViewModel>();

  trackByEntry = (index: number, entry: LicenseUserViewModel) => entry.userNrn;

  unselectUser(user: LicenseUserViewModel): void {
    this.onUnselectUser.emit(user);
  }
}
