import { HttpErrorResponse } from '@angular/common/http';

export class ErrorUtils {
  static isLicensingAccessDenied(error: HttpErrorResponse): boolean {
    return (
      ErrorUtils.isRfc(error) && error.status === 403 && error.error.type.includes('/forbidden/licensing#access_denied')
    );
  }

  static isTimeout(error: HttpErrorResponse): boolean {
    return error.status === 504;
  }

  static isLicensingUserNotLicensed(error: HttpErrorResponse): boolean {
    return (
      ErrorUtils.isRfc(error) &&
      error.status === 403 &&
      error.error.type.includes('/forbidden/licensing#user_not_licensed')
    );
  }

  static isBadRequestMicrosoftProfile(error: HttpErrorResponse): boolean {
    return (
      ErrorUtils.isRfc(error) && error.status === 400 && error.error.type.includes('/bad_request#microsoft_profile')
    );
  }

  private static isRfc(error: HttpErrorResponse): boolean {
    return !!(error && error.error && error.error.type);
  }
}
