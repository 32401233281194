import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { WindowService } from '@core/services/window/window.service';
import { ComponentCanDeactivateDirective } from '@core/guards/can-deactivate/can-deactivate';
import { RfisState } from '@rfis/reducers';
import {
  selectIsLogFormDirty,
  selectIsUploadInProgress
} from '@rfis/selectors/rfi-workflow-action-form/rfi-workflow-action-form.selectors';

@Injectable()
export class CanDeactivateLogRfiFormGuard implements CanDeactivate<ComponentCanDeactivateDirective> {
  constructor(
    private store: Store<RfisState>,
    private translateService: TranslateService,
    private windowService: WindowService
  ) {}

  canDeactivate(): Observable<boolean> {
    return combineLatest([
      this.store.select(selectIsLogFormDirty),
      this.store.select(selectIsUploadInProgress),
      this.translateService.get('RFIS.LOG_RFI.ERRORS.LOG_CONFIRMATION_ERROR')
    ]).pipe(
      map(([isDirty, isUploadInProgress, confirmationMessage]) =>
        isDirty || isUploadInProgress ? this.windowService.confirm(confirmationMessage) : true
      )
    );
  }
}
