<div
  id="{{type}}-snackbar"
  fxLayout
  class="{{type}}-snackbar-container snackbar-defaults"
  fxLayoutAlign="center center"
>
  <mat-icon
    *ngIf="type | snackbaricon"
    class="snackbar-icons"
  >{{type | snackbaricon}}</mat-icon>
  <span
    id="{{type}}-snackbar-message"
    fxLayoutAlign="center center"
  >
    {{message}}
  </span>
</div>
