import { Injectable } from '@angular/core';
import { Project } from '@core/models/project/project.model';
import { RecentProject } from '@core/models/project/recent-project';
import { AuthenticationCredentialProvider } from '@core/services/authentication/authentication.credential.provider';

@Injectable()
export class RecentProjectsService {
  private readonly recentProjectsKeyInLocalStorage = 'recentProjects';
  private readonly maxRecentProjectsAmount = 12;

  constructor(private authenticationCredentialProvider: AuthenticationCredentialProvider) {}

  loadRecentProjects(projects: Project[]): string[] {
    const recentProjects = localStorage.getItem(this.recentProjectsKeyInLocalStorage);
    const userEmail = this.authenticationCredentialProvider.getEmail();

    if (!recentProjects || !userEmail) {
      return [];
    }

    const parsedRecentProjects: RecentProject[] = JSON.parse(recentProjects);
    const projectsForSpecificUser = parsedRecentProjects.find(recentProject => recentProject.email === userEmail);

    if (!projectsForSpecificUser) {
      return [];
    }

    const currentProjects = [...projects];
    const newRecentProjectNrns: string[] = [];
    const recentProjectsNrnsToDelete: string[] = [];

    projectsForSpecificUser.projects.forEach(projectNrn => {
      const project = currentProjects.find(currentProject => currentProject.nrn === projectNrn);

      if (project) {
        newRecentProjectNrns.push(project.nrn);
      } else {
        recentProjectsNrnsToDelete.push(projectNrn);
      }
    });

    if (recentProjectsNrnsToDelete.length > 0) {
      projectsForSpecificUser.projects = projectsForSpecificUser.projects.filter(
        projectNrn => !recentProjectsNrnsToDelete.includes(projectNrn)
      );

      localStorage.setItem(this.recentProjectsKeyInLocalStorage, JSON.stringify(parsedRecentProjects));
    }

    return newRecentProjectNrns;
  }

  updateRecentProjects(newRecentProjectNrn: string, recentProjects: string[]): string[] {
    const existingRecentProjectNrn = recentProjects.find(projectNrn => projectNrn === newRecentProjectNrn);

    const updateFunction = existingRecentProjectNrn
      ? this.moveRecentProjectAtTop
      : recentProjects.length < this.maxRecentProjectsAmount
      ? this.addNewRecentProject
      : this.replaceRecentProject;

    const newRecentProjectNrns = updateFunction.call(this, recentProjects, newRecentProjectNrn);
    this.updateRecentProjectsInLocalStorage(newRecentProjectNrns);

    return newRecentProjectNrns;
  }

  private moveRecentProjectAtTop(currentRecentProjectNrns: string[], recentProjectNrn: string): string[] {
    const newRecentProjectNrns = currentRecentProjectNrns.filter(projectNrn => projectNrn !== recentProjectNrn);
    newRecentProjectNrns.unshift(recentProjectNrn);

    return newRecentProjectNrns;
  }

  private addNewRecentProject(currentRecentProjectNrns: string[], newRecentProjectNrn: string): string[] {
    const newRecentProjectNrns = [newRecentProjectNrn, ...currentRecentProjectNrns];

    while (newRecentProjectNrns.length > this.maxRecentProjectsAmount) {
      newRecentProjectNrns.pop();
    }

    return newRecentProjectNrns;
  }

  private replaceRecentProject(currentRecentProjectNrns: string[], newRecentProjectNrn: string): string[] {
    const newRecentProjectNrns = [...currentRecentProjectNrns];
    newRecentProjectNrns.pop();
    newRecentProjectNrns.unshift(newRecentProjectNrn);

    return newRecentProjectNrns;
  }

  private updateRecentProjectsInLocalStorage(recentProjectsNrns: string[]): void {
    const recentProjectsFromLocalStorage = localStorage.getItem(this.recentProjectsKeyInLocalStorage);
    const userEmail = this.authenticationCredentialProvider.getEmail();

    if (!userEmail) {
      return;
    }

    const newRecentProjects = !recentProjectsFromLocalStorage
      ? [{ email: userEmail, projects: recentProjectsNrns }]
      : this.getNewRecentProjectsForLocalStorage(userEmail, recentProjectsFromLocalStorage, recentProjectsNrns);

    localStorage.setItem(this.recentProjectsKeyInLocalStorage, JSON.stringify(newRecentProjects));
  }

  private getNewRecentProjectsForLocalStorage(
    userEmail: string,
    recentProjectsFromLocalStorage: string,
    recentProjectsNrns: string[]
  ): RecentProject[] {
    const parsedRecentProjects: RecentProject[] = JSON.parse(recentProjectsFromLocalStorage);
    const projectsForSpecificUser = parsedRecentProjects.find(recentProject => recentProject.email === userEmail);

    if (projectsForSpecificUser) {
      projectsForSpecificUser.projects = recentProjectsNrns;
    } else {
      const newRecentProjectsForUser = {
        email: userEmail,
        projects: recentProjectsNrns
      };
      parsedRecentProjects.push(newRecentProjectsForUser);
    }

    return parsedRecentProjects;
  }
}
