import {
  getTermsAndConditionsContent,
  getTermsAndConditionsContentFailed,
  getTermsAndConditionsContentSuccess,
  createTermsAndConditions,
  createTermsAndConditionsFailed,
  createTermsAndConditionsSuccess,
  closeTermsAndConditionsDialog,
  loadTermsAndConditionsUponProjectSelect,
  loadTermsAndConditionsDetailsSuccess,
  acceptTermsAndConditions,
  acceptTermsAndConditionsFailed,
  acceptTermsAndConditionsSuccess,
  loadTermsAndConditionsDetailsFailed
} from '@terms-and-conditions/actions/terms-and-conditions-dialog/terms-and-conditions-dialog.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { updateObject } from '@app/architecture';

export interface TermsAndConditionsDialogState {
  isCreatingTermsAndConditions: boolean;
  isGettingTermsAndConditionsContent: boolean;
  isLoadingTermsAndConditions: boolean;
  isAcceptingTermsAndConditions: boolean;
  showLoadingError: boolean;
  termsAndConditionsContent: string | null;
  termsAndConditionsAccepted: boolean | null;
  termsAndConditionsNrn: string | null;
}

export const initialState: TermsAndConditionsDialogState = {
  isCreatingTermsAndConditions: false,
  isGettingTermsAndConditionsContent: false,
  isLoadingTermsAndConditions: false,
  isAcceptingTermsAndConditions: false,
  showLoadingError: false,
  termsAndConditionsContent: null,
  termsAndConditionsAccepted: null,
  termsAndConditionsNrn: null
};

const reducer = createReducer(
  initialState,
  on(getTermsAndConditionsContentFailed, handleGetTermsAndConditionsContentFailed),
  on(createTermsAndConditions, handleCreateTermsAndConditions),
  on(getTermsAndConditionsContent, handleGetTermsAndConditionsContent),
  on(getTermsAndConditionsContentSuccess, handleGetTermsAndConditionsContentSuccess),
  on(loadTermsAndConditionsUponProjectSelect, handleLoadTermsAndConditionsUponProjectSelect),
  on(loadTermsAndConditionsDetailsSuccess, handleLoadTermsAndConditionsDetailsSuccess),
  on(acceptTermsAndConditionsSuccess, handleAcceptTermsAndConditionsSuccess),
  on(acceptTermsAndConditions, handleAcceptTermsAndConditions),
  on(
    createTermsAndConditionsSuccess,
    acceptTermsAndConditionsFailed,
    createTermsAndConditionsFailed,
    closeTermsAndConditionsDialog,
    loadTermsAndConditionsDetailsFailed,
    () => initialState
  )
);

function handleGetTermsAndConditionsContentFailed(state: TermsAndConditionsDialogState) {
  return updateObject(state, { showLoadingError: true });
}

function handleCreateTermsAndConditions(state: TermsAndConditionsDialogState) {
  return updateObject(state, { isCreatingTermsAndConditions: true });
}

function handleGetTermsAndConditionsContent(state: TermsAndConditionsDialogState) {
  return updateObject(state, { isGettingTermsAndConditionsContent: true });
}

function handleGetTermsAndConditionsContentSuccess(
  state: TermsAndConditionsDialogState,
  action: { content: string | null }
): TermsAndConditionsDialogState {
  return updateObject(state, { termsAndConditionsContent: action.content, isGettingTermsAndConditionsContent: false });
}

function handleLoadTermsAndConditionsUponProjectSelect(state: TermsAndConditionsDialogState) {
  return updateObject(state, { isLoadingTermsAndConditions: true });
}

function handleLoadTermsAndConditionsDetailsSuccess(
  state: TermsAndConditionsDialogState,
  action: {
    nrn: string | null;
    content: string | null;
    accepted: boolean | null;
  }
): TermsAndConditionsDialogState {
  return updateObject(state, {
    termsAndConditionsNrn: action.nrn,
    termsAndConditionsContent: action.content,
    termsAndConditionsAccepted: action.accepted,
    isLoadingTermsAndConditions: false
  });
}

function handleAcceptTermsAndConditionsSuccess(
  state: TermsAndConditionsDialogState,
  action: {
    content: string | null;
    accepted: boolean | null;
  }
): TermsAndConditionsDialogState {
  return updateObject(state, {
    termsAndConditionsContent: action.content,
    termsAndConditionsAccepted: action.accepted,
    isAcceptingTermsAndConditions: false
  });
}

function handleAcceptTermsAndConditions(state: TermsAndConditionsDialogState): TermsAndConditionsDialogState {
  return updateObject(state, { isAcceptingTermsAndConditions: true });
}

export function termsAndConditionsDialogReducer(state = initialState, action: Action): TermsAndConditionsDialogState {
  return reducer(state, action);
}
