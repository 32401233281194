import { NgModule } from '@angular/core';
import { ProjectsService } from '@core/services/project/projects.service';
import { TermsAndConditionsService } from '@core/services/terms-and-conditions/terms-and-conditions.service';
import { ToastService } from '@core/services/toast/toast.service';
import { ConfigurationService } from '@core/services/configuration/configuration.service';
import { BrowserCompatibilityService } from '@core/services/browser-compatibility/browser-compatibility.service';
import { AuthenticationCredentialProvider } from '@core/services/authentication/authentication.credential.provider';
import { FeatureResolver } from '@core/services/feature/feature-resolver.service';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { WindowService } from '@core/services/window/window.service';
import { DomService } from '@core/services/dom/dom.service';
import { DownloadService } from '@core/services/download/download.service';
import { FeatureService } from '@core/services/feature/feature.service';
import { LoggedInGuard } from '@core/guards/authentication/loggedIn.guard';
import { ActionItemsGuard } from '@core/guards/action-items/action-items.guard';
import { LoggedOutGuard } from '@core/guards/authentication/loggedOut.guard';
import { FeatureGuard } from '@core/guards/feature/feature.guard';
import { BrowserCompatibilityGuard } from '@core/guards/compatibility/browser-compatibility.guard';
import { EmailsGuard } from '@core/guards/emails/emails.guard';
import { SharedItemService } from '@core/services/shared-item/shared-item.service';
import { QueryParamsInterceptor } from '@core/services/interceptors/query-params/query-params.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AwsInterceptor } from '@core/services/interceptors/aws/aws.interceptor';
import { UserDisplayService } from '@core/services/user-display/user-display.service';
import { ApiUrlProvider } from '@core/utilities/apiUrl/apiUrl.provider';
import { NrnService } from '@core/services/nrn/nrn.service';
import { ActivityService } from '@core/services/activity/activity.service';
import { ServerAccessService } from '@app/server-access/services/server-access.service';
import { ServerAccessGuard } from '@core/guards/server-access/server-access.guard';
import { UploadService } from '@core/services/upload/upload.service';
import { RecentProjectsService } from '@projects/services/recent-projects/recent-projects.service';
import { SharedItemMapperService } from '@core/services/shared-item-mapper/shared-item-mapper.service';
import { SubmittalsGuard } from '@core/guards/submittals/submittals.guard';
import { AnalyticsService } from '@core/services/analytics/analytics.service';
import { CanDeactivateCreateActionItemFormGuard } from '@core/guards/can-deactivate/can-deactivate-create-action-item-form.guard';
import { ApiErrorService } from '@core/services/api/api-error.service';
import { LocalStorageService } from '@core/services/storage/local-storage.service';
import { InternationalizationService } from '@core/services/internationalization/internationalization.service';
import { FileUploadModule } from 'ng2-file-upload';
import { CanDeactivateLogFormGuard } from '@core/guards/can-deactivate/can-deactivate-log-form.guard';
import { EulaService } from '@core/services/eula/eula.service';
import { UserAdministrationGuard } from '@core/guards/user-administration/user-administration.guard';
import { CanDeactivateSubmittalWorkflowProcessGuard } from '@core/guards/can-deactivate/can-deactivate-submittal-workflow-process.guard';
import { CanDeactivateLogRfiFormGuard } from '@core/guards/can-deactivate/can-deactivate-log-rfi-form.guard';
import { ProjectTeamGuard } from '@core/guards/project-team/project-team.guard';
import { RfisGuard } from '@core/guards/rfis/rfis.guard';

@NgModule({
  imports: [HttpClientModule, FileUploadModule],
  providers: [
    ApiUrlProvider,
    AuthenticationCredentialProvider,
    AuthenticationService,
    BrowserCompatibilityService,
    ConfigurationService,
    DomService,
    DownloadService,
    FeatureResolver,
    FeatureService,
    ProjectsService,
    TermsAndConditionsService,
    ServerAccessService,
    SharedItemService,
    SharedItemMapperService,
    ToastService,
    UserDisplayService,
    WindowService,
    NrnService,
    UploadService,
    RecentProjectsService,
    AnalyticsService,
    LocalStorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AwsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: QueryParamsInterceptor,
      multi: true
    },
    LoggedOutGuard,
    LoggedInGuard,
    BrowserCompatibilityGuard,
    EmailsGuard,
    ActionItemsGuard,
    FeatureGuard,
    ActivityService,
    ServerAccessGuard,
    SubmittalsGuard,
    RfisGuard,
    CanDeactivateLogFormGuard,
    CanDeactivateCreateActionItemFormGuard,
    ApiErrorService,
    InternationalizationService,
    EulaService,
    UserAdministrationGuard,
    CanDeactivateSubmittalWorkflowProcessGuard,
    CanDeactivateLogRfiFormGuard,
    ProjectTeamGuard
  ]
})
export class CoreModule {}
