import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TeamResourceViewModelType } from '@shared/models/team-resource/team-resource-view-model.type';
import { InitialsTheme } from '@shared/models/initials/initialsTheme.enum';

@Component({
  selector: 'app-team-members-chip',
  templateUrl: './team-members-chip.component.html',
  styleUrls: ['./team-members-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamMembersChipComponent {
  @Input() removable = true;
  @Input() selectable = false;
  @Input() name: string;
  @Input() email: string;
  @Input() type: TeamResourceViewModelType;
  @Input() discipline: string;
  @Input() initials: string;
  @Output() onRemove = new EventEmitter();
  @Output() onClick = new EventEmitter();

  teamResourceType = TeamResourceViewModelType;
  initialsTheme = InitialsTheme;

  remove(): void {
    this.onRemove.emit();
  }

  click(): void {
    this.onClick.emit();
  }
}
