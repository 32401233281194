import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
  OnDestroy,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { KeywordModel } from '@core/models/project/keyword.model';
import { debounceTime, distinctUntilChanged, filter, skip } from 'rxjs/operators';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';
import { KeywordsDetails } from '@shared/models/keywords/keywords-details';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-multiple-keywords-selector',
  templateUrl: './multiple-keywords-selector.component.html',
  styleUrls: ['./multiple-keywords-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultipleKeywordsSelectorComponent implements OnInit, OnDestroy, OnChanges {
  private filterSubscription: Subscription;
  @ViewChild('chipList', { static: false }) chipList;
  @ViewChild('keywordInput', { read: MatAutocompleteTrigger, static: false })
  autoCompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('keywordInput', { static: false })
  keywordInput: ElementRef<HTMLInputElement>;

  @Input() set keywordsList(value: KeywordsDetails) {
    this.hasError = value.hasError || false;
    this.keywords = value.items || [];
    this.isLoading = value.isLoading || false;
    this.hasMoreKeywordsToLoad = !!value.paging;
  }
  @Input() set selectedKeywords(keywords: KeywordModel[]) {
    this.keywordChips = keywords;
  }
  @Input() loadingErrorMessage: string;
  @Input() label: string;
  @Input() elementId: string;
  @Input() shouldShowDescription: boolean;
  @Input() displayTooltip: boolean;
  @Input() tooltipErrorMessage: string;
  @Input() noResultErrorMessage: string;
  @Input() allowAddingCustomKeywords: boolean;
  @Input() separatorKeysCodes: number[];
  @Input() additionalNoResultErrorMessage: string;
  @Input() projectNrn: string | null;
  @Output() onPanelOpened = new EventEmitter();
  @Output() onPanelClosed = new EventEmitter();
  @Output() onKeywordListChanged = new EventEmitter<KeywordModel[]>();
  @Output() onKeywordListQuery = new EventEmitter<string>();

  hasError: boolean;
  isLoading: boolean;
  isFocused: boolean;
  query: string;
  isEmptyQuery = true;
  hasMoreKeywordsToLoad: boolean;
  keywords: KeywordModel[] = [];
  keywordChips: KeywordModel[] = [];
  private readonly keywordsControlName = 'keywordsControl';

  form = new FormGroup({
    [this.keywordsControlName]: new FormControl('')
  });

  ngOnInit(): void {
    this.subcribeToFormChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.projectNrn?.previousValue !== changes.projectNrn?.currentValue && !changes.projectNrn?.firstChange) {
      this.keywordsControl.patchValue('');
      this.keywordInput.nativeElement.value = '';
      this.keywordChips = [];
      this.onKeywordListChanged.emit([]);
    }
  }

  ngOnDestroy(): void {
    this.filterSubscription.unsubscribe();
  }

  get keywordsControl(): AbstractControl {
    return <AbstractControl>this.form.get(this.keywordsControlName);
  }

  removeKeyword(keyword: KeywordModel): void {
    const filteredValues = this.keywordChips.filter(value => value.name !== keyword.name);
    this.onKeywordListChanged.emit(filteredValues);

    this.setIsInputFocused(false);
  }

  selectKeyword(keyword: KeywordModel): void {
    const isUnique = !this.keywordChips.find(
      value => value.name.toLocaleLowerCase() === keyword.name.toLocaleLowerCase()
    );

    if (isUnique) {
      this.onKeywordListChanged.emit([...this.keywordChips, keyword]);
      this.setIsInputFocused(true);
    }

    this.autoCompleteTrigger.closePanel();
    this.keywordsControl.patchValue('');
    this.keywordInput.nativeElement.value = '';
    this.setIsInputFocused(false);
  }

  panelOpened(): void {
    this.onPanelOpened.emit();
  }

  panelClosed(): void {
    this.onPanelClosed.emit();
  }

  focus(isFocused: boolean): void {
    this.setIsInputFocused(isFocused);
    const keywordValue = this.keywordInput.nativeElement.value.trim();
    if (keywordValue) {
      this.add({ value: keywordValue } as MatChipInputEvent);
    }
  }

  private setIsInputFocused(isFocused: boolean): void {
    this.isFocused = isFocused;
  }

  openAutoCompletePanel(): void {
    this.autoCompleteTrigger._onChange(this.keywordsControl.value || '');
    this.autoCompleteTrigger.openPanel();
  }

  add(event: MatChipInputEvent): void {
    if (!this.allowAddingCustomKeywords) {
      return;
    }

    const keywordValue = event.value.trim();
    if (keywordValue) {
      this.selectKeyword({ name: keywordValue, type: 'generic' } as KeywordModel);
    }
  }

  private subcribeToFormChanges(): void {
    this.filterSubscription = this.keywordsControl.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        filter(value => !this.keywords.find(keyword => keyword.name === value)),
        skip(1)
      )
      .subscribe(value => {
        this.onKeywordListQuery.emit(value);
      });
  }
}
