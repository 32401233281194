import { KeywordModel } from '@core/models/project/keyword.model';
import { Action, createReducer, on } from '@ngrx/store';
import {
  loadDisciplines,
  loadDisciplinesCompleted,
  loadDisciplinesFailed
} from '@root/actions/keywords/keywords.actions';
import { updateObject } from '@app/architecture';
import { KeywordsDetails } from '@shared/models/keywords/keywords-details';

export interface KeywordsState {
  disciplines: KeywordsDetails;
}

export const initialKeywordsState: KeywordsState = {
  disciplines: { items: [], hasError: false, isLoading: false }
};

const reducer = createReducer(
  initialKeywordsState,
  on(loadDisciplines, handleLoadDisciplines),
  on(loadDisciplinesCompleted, handleLoadDisciplinesCompleted),
  on(loadDisciplinesFailed, handleLoadDisciplinesFailed)
);

function handleLoadDisciplines(state: KeywordsState): KeywordsState {
  return updateObject(state, {
    disciplines: {
      items: [],
      hasError: false,
      isLoading: true
    }
  });
}

function handleLoadDisciplinesCompleted(state: KeywordsState, action: { disciplines: KeywordModel[] }): KeywordsState {
  return updateObject(state, {
    disciplines: {
      items: action.disciplines,
      hasError: false,
      isLoading: false
    }
  });
}

function handleLoadDisciplinesFailed(state: KeywordsState): KeywordsState {
  return updateObject(state, {
    disciplines: {
      items: [],
      hasError: true,
      isLoading: false
    }
  });
}

export function keywordsReducer(state: KeywordsState = initialKeywordsState, action: Action): KeywordsState {
  return reducer(state, action);
}
