import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class TranslateCacheService {
  private readonly translatedEvents = new Map<string, string>();

  constructor(private translateService: TranslateService) {}

  getCachedTranslation(key: string): string {
    if (this.translatedEvents.has(key)) {
      return <string>this.translatedEvents.get(key);
    }

    const translatedType = this.translateService.instant(key);

    this.translatedEvents.set(key, translatedType);

    return translatedType;
  }
}
