export function updateObject<T>(state: T, newState: Partial<T>): T {
  return {
    ...state,
    ...newState
  };
}

export function updateArray<T>(array: T[], updateFunction: (el: T) => T) {
  return array.map(updateFunction);
}
