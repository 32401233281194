import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  loadDisciplines,
  loadDisciplinesCompleted,
  loadDisciplinesFailed
} from '@root/actions/keywords/keywords.actions';
import { ProjectsService } from '@core/services/project/projects.service';

@Injectable()
export class KeywordsEffects {
  constructor(private actions: Actions, private projectsService: ProjectsService) {}

  loadDisciplines = createEffect(() =>
    this.actions.pipe(
      ofType(loadDisciplines),
      mergeMap(() =>
        this.projectsService.getKeywords(null, 'discipline').pipe(
          map(response => loadDisciplinesCompleted({ disciplines: response.items })),
          catchError(() => of(loadDisciplinesFailed()))
        )
      )
    )
  );
}
