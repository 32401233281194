import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  closeProjectFoldersDialog,
  createProjectFolder,
  loadExternalFolders,
  loadExternalFoldersNextPage,
  loadNavigation,
  selectExternalFolder,
  updateProjectFolder
} from '@root/actions/project-folders/project-folders.actions';
import { of, Subject } from 'rxjs';
import {
  selectIsSharePointSiteProviderSupported,
  selectSearchQuery,
  selectIsLoading,
  selectExternalFolders,
  selectIsLoadingNextPage,
  selectOffsetToken,
  selectCurrentExternalFolder,
  selectLoadingError,
  selectIsConnecting,
  selectIsEditMode,
  selectHasFolderConnected,
  selectNavigation
} from '@root/selectors/project-folders/project-folders.selectors';
import { CloudProjectFolderType } from '@shared/models/project-folders/project-folder.type.enum';
import { first, map, mergeMap, takeUntil } from 'rxjs/operators';
import { ExternalFolder } from '@core/models/external-folder/external-folder.model';
import { addRecentProject, clearProject, loadProjects } from '@root/actions/project-list/project-list.actions';
import { scrollToProject } from '@projects/actions/projects-grid/projects-grid.actions';
import { selectCurrentProjectNrn } from '@root/selectors/project-list/project-list.selectors';
import { Router } from '@angular/router';
import { selectProjectDialogMode } from '@projects/selectors/projects-dialog/projects-dialog.selectors';
import { AppRootState } from '@root/reducers';
import { Breadcrumbs } from '@shared/models/breadcrumbs/breadcrumbs.model';

@Component({
  selector: 'app-project-folders',
  templateUrl: './project-folders.container.html',
  styleUrls: ['./project-folders.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectFoldersContainer implements OnInit, OnDestroy {
  private destroyComponent = new Subject();
  private offsetToken: string | null;
  private projectNrn: string;
  selectedItem: ExternalFolder | null;
  isEditMode: boolean;
  isFilesView: boolean;
  isDefaultView: boolean;
  breadcrumbs: Breadcrumbs[];

  isSharePointProviderSupported$ = this.store.select(selectIsSharePointSiteProviderSupported);
  searchQuery$ = this.store.select(selectSearchQuery);
  isLoading$ = this.store.select(selectIsLoading);
  isLoadingNextPage$ = this.store.select(selectIsLoadingNextPage);
  externalFolders$ = this.store.select(selectExternalFolders);
  navigation$ = this.store.select(selectNavigation);
  currentExternalFolder$ = this.store.select(selectCurrentExternalFolder);
  loadingError$ = this.store.select(selectLoadingError);
  isConnecting$ = this.store.select(selectIsConnecting);
  hasFolderConnected$ = this.store.select(selectHasFolderConnected);
  constructor(private store: Store<AppRootState>, private router: Router) {}

  ngOnInit() {
    this.isDefaultView = true;
    this.isFilesView = this.router.url.includes('/files');
    this.connectToStore();
  }

  ngOnDestroy() {
    this.destroyComponent.next();
    this.destroyComponent.complete();
  }

  selectExternalFolder(item: ExternalFolder, navigation: ExternalFolder[]): void {
    this.updateExternalFolder(item);
    if (!this.isDefaultView) {
      this.nextPage([...navigation], item);
    } else {
      this.currentPage(item);
    }
  }

  selectExternalFolderFromBreadcrumbs(item: ExternalFolder, navigation: ExternalFolder[]): void {
    const nav = [...navigation];
    const lastIndex = this.lastIndexOfArrayElementByNrn(item.nrn, nav);
    if (lastIndex === -1) {
      this.goToDefaultView();
      return;
    }
    nav.length = lastIndex;
    this.selectExternalFolder(item, nav);
  }

  searchSharePointSites(query: string): void {
    this.store.dispatch(loadExternalFolders({ query }));
  }

  loadNextPage(item: ExternalFolder | null): void {
    if (!this.offsetToken) {
      return;
    }
    if (this.isDefaultView) {
      this.store.dispatch(loadExternalFoldersNextPage({}));
    } else if (item) {
      this.store.dispatch(loadExternalFoldersNextPage({ parentNrn: item.nrn }));
    }
  }

  closeDialog(): void {
    if (!this.isEditMode && !this.isFilesView) {
      this.store.dispatch(
        loadProjects({
          actionsToDispatch: [
            scrollToProject({
              projectNrn: this.projectNrn
            }),
            addRecentProject({ recentProjectNrn: this.projectNrn }),
            clearProject()
          ],
          forceRefresh: true
        })
      );
    }

    this.store.dispatch(closeProjectFoldersDialog());
  }

  createProjectFolder(): void {
    if (!this.selectedItem) {
      return;
    }
    this.store.dispatch(selectExternalFolder({ nrn: this.selectedItem.nrn }));

    of(this.isFilesView)
      .pipe(
        mergeMap(isFilesView => (isFilesView ? of(null) : this.store.select(selectProjectDialogMode))),
        map(mode => createProjectFolder(mode)),
        first()
      )
      .subscribe(action => this.store.dispatch(action));
  }

  updateProjectFolder(): void {
    if (!this.selectedItem) {
      return;
    }

    this.store.dispatch(
      updateProjectFolder({
        projectFolder: { ...this.selectedItem, type: CloudProjectFolderType.SharePoint }
      })
    );

    this.closeDialog();
  }

  nextProjectFolder(nrn?: string): void {
    if (!this.selectedItem) {
      return;
    }

    this.searchSharePointSiteFolders(nrn || this.selectedItem.nrn);
    this.isDefaultView = false;
  }

  goToDefaultView(): void {
    this.isDefaultView = true;
    this.selectedItem = null;
    this.store.dispatch(loadExternalFolders({}));
    this.updateNavigation([]);
    this.updateBreadcrumbs([]);
  }

  private nextPage(navigation: ExternalFolder[], next: ExternalFolder): void {
    this.nextProjectFolder();
    this.updateNavigation(navigation, next);
    this.updateBreadcrumbs([...navigation, next]);
  }

  private currentPage(next: ExternalFolder): void {
    this.updateNavigation([], next);
    this.updateBreadcrumbs([next]);
  }

  private connectToStore(): void {
    this.store
      .select(selectOffsetToken)
      .pipe(takeUntil(this.destroyComponent))
      .subscribe(offsetToken => {
        this.offsetToken = offsetToken;
      });

    this.store
      .select(selectIsEditMode)
      .pipe(takeUntil(this.destroyComponent))
      .subscribe(isEditMode => {
        this.isEditMode = isEditMode;
      });

    this.store
      .select(selectCurrentProjectNrn)
      .pipe(takeUntil(this.destroyComponent))
      .subscribe(projectNrn => {
        this.projectNrn = projectNrn;
      });
  }

  private searchSharePointSiteFolders(parentNrn: string): void {
    this.store.dispatch(loadExternalFolders({ parentNrn }));
  }

  private updateNavigation(navigation: ExternalFolder[], newNavigation?: ExternalFolder): void {
    const navigationArray =
      newNavigation?.name && newNavigation?.nrn ? [...navigation, newNavigation] : [...navigation];
    this.store.dispatch(loadNavigation({ navigation: navigationArray }));
  }

  private updateExternalFolder(item: ExternalFolder): void {
    this.store.dispatch(selectExternalFolder({ nrn: item.nrn }));
    this.selectedItem = item;
  }
  private lastIndexOfArrayElementByNrn(nrn: string, navigation: ExternalFolder[]): number {
    return navigation.lastIndexOf(navigation.find((nav: ExternalFolder) => nav.nrn === nrn) as ExternalFolder);
  }
  private updateBreadcrumbs(navigation: ExternalFolder[]): void {
    this.breadcrumbs = navigation.map((nav: ExternalFolder) => ({ name: nav.name.trim(), link: nav.nrn }));
  }
}
