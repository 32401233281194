import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FilesRemaining } from '@shared/models/files/files.interfaces';

@Component({
  selector: 'app-upload-progress',
  templateUrl: './upload-progress.component.html',
  styleUrls: ['./upload-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadProgressComponent {
  @Input() fileName: string;
  @Input() uploadProgress: number;
  @Input() uploadError: boolean;
  @Input() filesTotal: number;
  @Input() filesRemaining: FilesRemaining;
  @Input() destinationResourceName: string | null;
}
