import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, retryWhen, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of, Subscription, throwError as observableThrowError, timer } from 'rxjs';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { ApiUrlProvider } from '@core/utilities/apiUrl/apiUrl.provider';
import { ToastService } from '@app/core/services/toast/toast.service';
import { WindowService } from '@core/services/window/window.service';
import { DomService } from '@core/services/dom/dom.service';
import {
  FileDownloadParams,
  GetBulkDownloadLocationResponse,
  GetEmailLocationResponse,
  GetFileLocationOptions,
  GetFileLocationResponse
} from '@core/services/download/download.models';
import { AnalyticsService } from '@core/services/analytics/analytics.service';
import { AnalyticEventAction } from '@shared/models/analytics/analytic-event-action.model';
import { AnalyticEventCategory } from '@shared/models/analytics/analytic-event-category.model';

@Injectable()
export class DownloadService {
  private readonly emlMimeType = 'message/rfc822';

  constructor(
    private http: HttpClient,
    private apiUrlProvider: ApiUrlProvider,
    private windowService: WindowService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private domService: DomService,
    private analyticsService: AnalyticsService
  ) {}

  bulkDownload(resources: string[]): Observable<{}> {
    this.toastService.displayInfo('DOWNLOAD.START');

    return this.getBulkFiles(resources).pipe(
      tap(location => {
        this.domService.createAndOpenAnchor(location.url);
      }),
      catchError(error => {
        this.toastService.displayError('FILE.BULK_DOWNLOAD_ERROR_GENERIC');
        return of(error);
      })
    );
  }

  private getBulkFiles(resources: string[]): Observable<GetBulkDownloadLocationResponse> {
    const requestUrl = `${this.getUrl()}/files/downloadSessions`;
    const payload = { resources };

    return this.http.post<GetBulkDownloadLocationResponse>(requestUrl, payload);
  }

  download(file: FileDownloadParams): Observable<{}> {
    this.toastService.displayInfo('DOWNLOAD.START');
    const mimeType = file.contentType;

    const isIE11 = this.windowService.isIE11();
    return this.getFileLocation(file.nrn, file.source).pipe(
      mergeMap(location => (isIE11 ? of(location) : this.checkFileStatus(location))),
      mergeMap((location: GetFileLocationResponse) => {
        if (!isIE11 && !this.windowService.isEdge()) {
          return this.nativeDownloadFile(location);
        } else {
          const processResponse = isIE11 ? this.retryWhenEdmsNotReady : () => map(response => response);
          return this.downloadFile(location).pipe(
            processResponse(),
            tap((response: HttpResponse<Object>) => this.saveFile(response.body, mimeType, file.name))
          );
        }

        return of({});
      }),
      catchError(error => {
        this.handleDownloadError(file.name, error?.status);
        return of(error);
      })
    );
  }

  downloadEmail(nrn: string, name: string): Subscription {
    const infoToastRef = this.toastService.displayInfo('DOWNLOAD.START');
    return this.getEmailLocation(nrn)
      .pipe(
        mergeMap((location: GetEmailLocationResponse) =>
          this.http.get(location.url, { responseType: 'blob' }).pipe(
            tap(response => {
              this.saveFile(response, this.emlMimeType, `${name}.eml`);
            })
          )
        ),
        // todo: the analytic service logic should be moved to effect when the email details component is refactored to ngrx
        tap(() => this.analyticsService.recordEvent(AnalyticEventAction.DownloadEmail, AnalyticEventCategory.Email)),
        catchError(error => {
          this.handleDownloadError(name);
          return of(error);
        })
      )
      .subscribe(
        () => infoToastRef.dismiss(),
        () => this.toastService.displayError('DOWNLOAD.ERROR')
      );
  }

  nativeDownloadFile = (location: GetFileLocationResponse) => {
    const finalUrl = this.getFinalUrl(location);
    this.domService.createAndOpenAnchor(finalUrl, location.url);

    return of(finalUrl);
  };

  downloadStringAsFile(fileName: string, body: string): void {
    if (!fileName || !body) {
      this.toastService.displayError('DOWNLOAD.ERROR');
      return;
    }
    this.toastService.displayInfo('DOWNLOAD.START');
    this.domService.createAndOpenAnchor(`data:text/plain;charset=utf-8,${encodeURIComponent(body)}`, `/${fileName}`);
  }

  private checkFileStatus(
    location: GetFileLocationResponse
  ): Observable<GetFileLocationResponse | HttpResponse<Object>> {
    return of(location).pipe(
      mergeMap(loc => this.http.request('HEAD', this.getFinalUrl(loc), { observe: 'response' })),
      this.retryWhenEdmsNotReady(location)
    );
  }

  private retryWhenEdmsNotReady = (location?: GetFileLocationResponse) => (
    source: Observable<HttpResponse<Object>>
  ) => {
    let retryCount = 0;
    const maxRetries = 10;

    return source.pipe(
      mergeMap((response: HttpResponse<Object>) => {
        if (response.status !== 200 && response.status !== 202 && response.status !== 206) {
          return observableThrowError(response.body);
        }

        if (response.status === 202) {
          return observableThrowError('EDMS file not ready');
        }

        return of(location || response);
      }),
      retryWhen(error =>
        error.pipe(
          tap(() => retryCount++),
          mergeMap(err => {
            if (retryCount++ > maxRetries) {
              return observableThrowError(err);
            }

            return timer(retryCount * 1000);
          })
        )
      )
    );
  };

  private getFinalUrl({ url, queryParameters }: GetFileLocationResponse): string {
    const queryString = queryParameters.map(({ key, value }) => `${key}=${encodeURIComponent(value)}`).join('&');
    return queryString ? `${url}?${queryString}` : url;
  }

  private downloadFile = ({
    httpMethod,
    url,
    ...options
  }: GetFileLocationResponse): Observable<{ status: number; body: any }> =>
    this.http.request('POST', url, this.mapDownloadRequestOptions(options));

  private mapDownloadRequestOptions = ({ queryParameters, contentType }: GetFileLocationOptions) => {
    const body = queryParameters
      .map(bodyParameter => `${encodeURIComponent(bodyParameter.key)}=${encodeURIComponent(bodyParameter.value)}`)
      .join('&');

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', contentType);

    return {
      headers,
      body,
      observe: 'response' as 'response',
      responseType: 'blob' as 'blob'
    };
  };

  private handleDownloadError(fileName?: string, errorCode?: number) {
    (errorCode === 403
      ? this.translateService.get('FILE.DOWNLOAD_ERROR_NO_PERMISSIONS')
      : fileName
      ? this.translateService.get('FILE.DOWNLOAD_ERROR', { fileName })
      : this.translateService.get('FILE.DOWNLOAD_ERROR_GENERIC')
    ).subscribe((errorText: string) => {
      this.toastService.displayError(errorText);
    });
  }

  saveFile(response: any, mimeType: string, fileName: string = '') {
    const blob = new Blob([response], { type: mimeType });
    saveAs(blob, fileName);
  }

  private getFileLocation(fileNrn: string, source?: string): Observable<GetFileLocationResponse> {
    let params = new HttpParams();
    if (source) {
      params = params.set('source', source);
    }

    const url = `${this.getUrl()}/files/${encodeURIComponent(fileNrn)}/location`;

    return this.http.get<GetFileLocationResponse>(url, { params });
  }

  getEmailLocation(nrn: string): Observable<GetEmailLocationResponse> {
    return this.http.get<GetEmailLocationResponse>(`${this.apiUrlProvider.getUrl()}/email/${nrn}/location`);
  }

  private getUrl(): string {
    return this.apiUrlProvider.getUrl();
  }
}
