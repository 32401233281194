import { Injectable } from '@angular/core';
import { CanLoad, Route } from '@angular/router';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { Observable } from 'rxjs';
import { WindowService } from '@core/services/window/window.service';

@Injectable()
export class LoggedInGuard implements CanLoad {
  constructor(private authService: AuthenticationService, private windowService: WindowService) {}

  canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
    if (this.authService.isUserLogged()) {
      return true;
    }

    // not logged in so redirect to login page
    const window = this.windowService.getNativeWindow();
    const separator = '?';
    const [, queryParams] = window.location.href.split(separator);
    const nextUrl = `${window.location.pathname}${separator}${queryParams}`;

    this.authService.nextUrl = nextUrl;
    this.authService.logOut(true);
    return false;
  }
}
