export enum IconType {
  Zip = 'zip',
  Pdf = 'pdf',
  Email = 'email',
  Image = 'image',
  Dwg = 'dwg',
  Dwf = 'dwf',
  Dgn = 'dgn',
  Excel = 'excel',
  Powerpoint = 'powerpoint',
  Word = 'word',
  Text = 'text'
}

export class FileTypes {
  private static readonly fileTypes: { iconType: IconType; mimeTypes: string[] }[] = [
    {
      iconType: IconType.Zip,
      mimeTypes: [
        'application/zip',
        'application/x-zip',
        'application/x-zip-compressed',
        'application/x-compress',
        'application/x-compressed',
        'multipart/x-zip'
      ]
    },
    {
      iconType: IconType.Pdf,
      mimeTypes: [
        'application/pdf',
        'application/x-pdf',
        'application/acrobat',
        'applications/vnd.pdf',
        'text/pdf',
        'text/x-pdf'
      ]
    },
    {
      iconType: IconType.Email,
      mimeTypes: ['application/vnd.ms-outlook', 'application/x-msg', 'message/rfc822']
    },
    {
      iconType: IconType.Image,
      mimeTypes: [
        'image/bmp',
        'image/x-bmp',
        'image/x-bitmap',
        'image/x-xbitmap',
        'image/x-win-bitmap',
        'image/x-windows-bmp',
        'image/ms-bmp',
        'image/x-ms-bmp',
        'application/bmp',
        'application/x-bmp',
        'application/x-win-bitmap',
        'image/gif',
        'image/gi_',
        'image/jpeg',
        'image/jpg',
        'image/jp_',
        'application/jpg',
        'application/x-jpg',
        'image/pipeg',
        'image/jpe_',
        'image/vnd.swiftview-jpeg',
        'image/pjpeg',
        'image/png',
        'application/png',
        'application/x-png',
        'image/tiff',
        'image/svg+xml',
        'image/DNG',
        'image/x-dcraw',
        'image/x-sony-arw',
        'image/x-canon-cr2',
        'image/x-canon-crw',
        'image/x-kodak-dcr',
        'image/x-adobe-dng',
        'image/x-epson-erf',
        'image/x-kodak-k25',
        'image/x-kodak-kdc',
        'image/x-minolta-mrw',
        'image/x-nikon-nef',
        'image/x-olympus-orf',
        'image/x-pentax-pef',
        'image/x-fuji-raf',
        'image/x-panasonic-raw',
        'image/x-sony-sr2',
        'image/x-sony-srf',
        'image/x-sigma-x3f'
      ]
    },
    {
      iconType: IconType.Dwg,
      mimeTypes: [
        'drawing/dwg',
        'image/x-dwg',
        'image/vnd.dwg',
        'application/dwg',
        'application/x-dwg',
        'application/autocad_dwg',
        'application/x-autocad',
        'application/acad',
        'application/x-acad'
      ]
    },
    {
      iconType: IconType.Dwf,
      mimeTypes: [
        'application/dwf',
        'application/x-dwf',
        'image/vnd.dwf',
        'drawing/x-dwf',
        'image/x-dwf',
        'model/vnd.dwf'
      ]
    },
    {
      iconType: IconType.Dgn,
      mimeTypes: ['image/vnd.dgn', 'ver=8']
    },
    {
      iconType: IconType.Excel,
      mimeTypes: [
        'application/excel',
        'application/x-excel',
        'application/x-msexcel',
        'application/vnd.ms-excel',
        'application/vnd.ms-excel.sheet.macroEnabled.12',
        'application/vnd.ms-excel.template.macroEnabled.12',
        'application/vnd.ms-excel.addin.macroEnabled.12',
        'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
        'text/comma-separated-values',
        'text/csv',
        'application/csv',
        'application/vnd.msexcel',
        'text/anytext'
      ]
    },
    {
      iconType: IconType.Powerpoint,
      mimeTypes: [
        'application/powerpoint',
        'application/mspowerpoint',
        'application/x-mspowerpoint',
        'application/vnd.ms-powerpoint',
        'application/vnd.ms-powerpoint.addin.macroEnabled.12',
        'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
        'application/vnd.ms-powerpoint.template.macroEnabled.12',
        'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.presentationml.template',
        'application/vnd.openxmlformats-officedocument.presentationml.slideshow'
      ]
    },
    {
      iconType: IconType.Word,
      mimeTypes: [
        'application/msword',
        'application/vnd.ms-word.document.macroEnabled.12',
        'application/vnd.ms-word.template.macroEnabled.12',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.template'
      ]
    },
    {
      iconType: IconType.Text,
      mimeTypes: ['text/plain']
    }
  ];

  static getFileTypes(): { iconType: IconType; mimeTypes: string[] }[] {
    return this.fileTypes;
  }
}
