import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { InputErrorStateMatcher } from '@shared/validators/input-error-state-matcher/input-error-state-matcher';

@Component({
  selector: 'app-inline-editable-input',
  templateUrl: './inline-editable-input.component.html',
  styleUrls: ['./inline-editable-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineEditableInputComponent implements OnInit {
  @Input() formTheme: 'dark' | 'lightblue' = 'lightblue';
  @Input() isReadonly = false;
  @Input() readonlyFieldBolded = false;
  @Input() errorMessage: string;
  @Input() readonlyFieldId: string;
  @Input() editableFieldId: string;
  @Input() isRequired = true;
  @Input()
  set inputValue(value: string) {
    this.handleInputValueChange(value);
  }
  @Input()
  set hasRequestError(hasError: boolean) {
    this.handleRequestErrorChange(hasError);
  }
  @Output() onSubmit = new EventEmitter<string>();
  @Output() onChangeMode = new EventEmitter<boolean>();

  private previousInputValue: string;
  readonly inlineEditableControlName = 'inlineEditableControl';
  form = new FormGroup({
    [this.inlineEditableControlName]: new FormControl('')
  });
  isEditMode = false;
  inputErrorMatcher = new InputErrorStateMatcher();

  @ViewChild('inlineEditableInput', { static: false })
  inlineEditableInput: ElementRef;

  ngOnInit(): void {
    if (this.isRequired) {
      this.inlineEditableControl.setValidators(Validators.required);
    }
  }

  discardChanges(): void {
    this.updateEditMode(false);
    this.inlineEditableControl.patchValue(this.previousInputValue);
  }

  openEditMode(): void {
    if (this.isReadonly) {
      return;
    }

    this.updateEditMode(true);
  }

  leaveEditMode(): void {
    this.inlineEditableInput.nativeElement.blur();
  }

  submit(): void {
    if (!this.isEditMode || this.form.invalid) {
      return;
    }

    this.updateEditMode(false);
    const currentInputValue = this.inlineEditableControl.value;

    if (currentInputValue.trim() !== this.previousInputValue) {
      this.onSubmit.emit(currentInputValue);
    } else {
      this.inlineEditableControl.patchValue(this.previousInputValue);
    }
  }

  private get inlineEditableControl(): AbstractControl {
    return <AbstractControl>this.form.get(this.inlineEditableControlName);
  }

  private handleInputValueChange(value: string): void {
    this.inlineEditableControl.patchValue(value);
    this.previousInputValue = value;
    this.updateEditMode(false);
  }

  private handleRequestErrorChange(hasError: boolean): void {
    if (!hasError) {
      return;
    }

    this.isEditMode = this.inlineEditableControl.value !== this.previousInputValue;

    if (this.isEditMode) {
      this.onChangeMode.emit(true);
    }
  }

  private updateEditMode(isEditMode: boolean): void {
    this.isEditMode = isEditMode;
    this.onChangeMode.emit(isEditMode);
  }
}
