export class StringUtils {
  static splitStringAndRemoveEmptyStrings(value: string, splitter: string): string[] {
    return value.split(splitter).filter(el => !!el);
  }

  static validateEmailString(email: string): boolean {
    const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailPattern.test(email);
  }
}
