import { NavigationEnd } from '@angular/router';
import { AuthenticationCredentialProvider } from '@core/services/authentication/authentication.credential.provider';
import { GoogleAnalyticsTrackingId } from '@shared/models/analytics/google-tracking-id.model';
import { AnalyticEventAction } from '@shared/models/analytics/analytic-event-action.model';
import { AnalyticEventCategory } from '@shared/models/analytics/analytic-event-category.model';
import { ConfigurationService } from '@core/services/configuration/configuration.service';
import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable()
export class AnalyticsService {
  private trackingId: GoogleAnalyticsTrackingId;
  private isProd: boolean;
  private isE2E = false;

  private readonly devSpecificDomains = ['@newforming', '@newforma'];

  constructor(
    private authenticationCredentialProvider: AuthenticationCredentialProvider,
    private configurationService: ConfigurationService
  ) {
    this.isE2E = (localStorage && localStorage.getItem('PROTRACTOR_ENV') === 'true') || (window as any).Cypress;
    this.configurationService.configuration.subscribe(config => {
      this.isProd = !!config.production && config.urlPrefix === '';
    });
    this.authenticationCredentialProvider.loggedInUserEmail.subscribe(email => {
      this.trackingId = this.getTrackingId(email);
      if (!this.isE2E) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        gtag('config', this.trackingId, { send_page_view: false });
      }
    });
  }

  recordPageViewEvent(event: NavigationEnd): void {
    if (this.isE2E) {
      return;
    }

    const routeBase = this.getRouteBase(event.urlAfterRedirects);

    if (event.urlAfterRedirects.includes('/redirect')) {
      return;
    }

    gtag('config', this.trackingId, {
      /* eslint-disable @typescript-eslint/naming-convention */
      page_path: routeBase,
      page_location: event.url
      // eslint-enable @typescript-eslint/naming-convention
    });
  }

  // https://developers.google.com/analytics/devguides/collection/gtagjs/events for more detail
  recordEvent(
    action: AnalyticEventAction,
    category: AnalyticEventCategory,
    label: string | null = null,
    value: number | null = null
  ): void {
    if (this.isE2E) {
      return;
    }

    const eventDetails: { [key: string]: any } = {};

    if (category) {
      eventDetails.event_category = category;
    }

    if (label) {
      eventDetails.eventLabel = label;
    }

    if (value) {
      eventDetails.value = value;
    }

    gtag('event', action, eventDetails);
  }

  private getRouteBase(urlAfterRedirects: string): string {
    const routeSeparator = '/';
    const querySeparator = '?';
    const routeEnd = urlAfterRedirects.split(routeSeparator).pop();

    if (!routeEnd) {
      return routeSeparator;
    }

    const indexOfQuerySeparator = routeEnd.indexOf(querySeparator);

    if (indexOfQuerySeparator === -1) {
      return routeSeparator + routeEnd;
    }

    return routeSeparator + routeEnd.substring(0, indexOfQuerySeparator);
  }

  private getTrackingId(email: string | null): GoogleAnalyticsTrackingId {
    if (email && this.devSpecificDomains.some(domainName => email.includes(domainName))) {
      return GoogleAnalyticsTrackingId.DEV;
    }

    if (this.isProd) {
      return GoogleAnalyticsTrackingId.PROD;
    }

    return GoogleAnalyticsTrackingId.DEV;
  }
}
