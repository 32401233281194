import { Injectable } from '@angular/core';
import { ApiErrorType } from '@core/models/api/error-type.model';

@Injectable()
export class ApiErrorService {
  getApiErrorType(errorType: string): ApiErrorType {
    const errorTypes = {
      /* eslint-disable @typescript-eslint/naming-convention */
      '#too_long': ApiErrorType.TooLong,
      '#too_short': ApiErrorType.TooShort,
      '#invalid_characters': ApiErrorType.InvalidCharacters,
      '#overwrite_not_allowed': ApiErrorType.OverwriteNotAllowed,
      '#email_invalid_leading_or_trailing_characters': ApiErrorType.EmailInvalidLeadingOrTrailingCharacters,
      '#email_repeating_characters': ApiErrorType.EmailRepeatingCharacters,
      '#duplicate_email_filing_address': ApiErrorType.DuplicateEmailFilingAddress
      /* eslint-enable @typescript-eslint/naming-convention */
    };

    const hashIndex: number = errorType.indexOf('#');

    if (hashIndex < 0) {
      return ApiErrorType.NotSpecified;
    }

    const errorReason: string = errorType.substring(hashIndex, errorType.length);

    return errorTypes[errorReason] !== undefined ? errorTypes[errorReason] : ApiErrorType.Unhandled;
  }
}
