<app-chip-item
  [removable]="removable"
  [selectable]="true"
  [keyword]="email"
  [subtitle]="discipline"
  (onRemove)="remove()"
  (onClick)="click()"
>
  <mat-icon
    *ngIf="type === teamResourceType.Group"
    svgIcon="group-chip"
    class="group-chip-icon"
  >
  </mat-icon>
  <span class="resource-text">{{ name || email }}</span>
</app-chip-item>
