import { BaseEvent, EventModel, EventPerson, SimpleChange } from '@shared/models/events/events-model';
import { KeywordModel } from '@core/models/project/keyword.model';

export type RfiReceivedEvent = BaseEvent<
  'RFI_RECEIVED',
  {
    nrn: string;
    projectNrn: string;
    subject: string;
    number: string;
    specSection: KeywordModel | null;
    receivedDate: string;
    dueDate?: string | null;
    urgent?: boolean | null;
    type?: KeywordModel | null;
    senderNumber?: string | null;
    from: EventPerson;
    to: EventPerson[];
    question: string;
    suggestion?: string | null;
    internalNotes?: string | null;
    reasons: KeywordModel[];
    impactSchedule?: KeywordModel | null;
    impactCost?: KeywordModel | null;
    disciplines: KeywordModel[];
    keywords: KeywordModel[];
    attachments: string[];
    path: string;
  }
>;
export function isRfiReceivedEvent(event: EventModel): event is RfiReceivedEvent {
  return event.type === 'RFI_RECEIVED';
}

export type RfiForwardedEvent = BaseEvent<
  'RFI_FORWARDED',
  {
    nrn: string;
    projectNrn: string;
    subject: string;
    number: string;
    reviewDueDate: string;
    to: EventPerson[];
    cc: EventPerson[];
    remarks?: string | null;
    attachments: string[];
    path: string;
  }
>;
export function isRfiForwardedEvent(event: EventModel): event is RfiForwardedEvent {
  return event.type === 'RFI_FORWARDED';
}

export type RfiReviewResponseEvent = BaseEvent<
  'RFI_REVIEW_RESPONSE',
  {
    nrn: string;
    projectNrn: string;
    subject: string;
    number: string;
    action: KeywordModel;
    from: EventPerson;
    to: EventPerson[];
    cc: EventPerson[];
    answer?: string | null;
    attachments: string[];
    path: string;
  }
>;
export function isRfiReviewResponseEvent(event: EventModel): event is RfiReviewResponseEvent {
  return event.type === 'RFI_REVIEW_RESPONSE';
}

export type RfiRespondedEvent = BaseEvent<
  'RFI_RESPONDED',
  {
    nrn: string;
    projectNrn: string;
    subject: string;
    number: string;
    action: KeywordModel;
    from: EventPerson;
    to: EventPerson[];
    cc: EventPerson[];
    answer?: string | null;
    attachments: string[];
    path: string;
  }
>;
export function isRfiRespondedEvent(event: EventModel): event is RfiRespondedEvent {
  return event.type === 'RFI_RESPONDED';
}

export type RfiClosedEvent = BaseEvent<
  'RFI_CLOSED',
  {
    nrn: string;
    projectNrn: string;
    subject: string;
    number: string;
    action: KeywordModel;
    remarks?: string | null;
    attachments: string[];
    path: string;
  }
>;
export function isRfiClosedEvent(event: EventModel): event is RfiClosedEvent {
  return event.type === 'RFI_CLOSED';
}

export type RfiDeletedEvent = BaseEvent<
  'RFI_DELETED',
  {
    nrn: string;
    projectNrn: string;
    subject: string;
    number: string;
    path: string | null;
    deletedFiles: string[];
  }
>;
export function isRfiDeletedEvent(event: EventModel): event is RfiDeletedEvent {
  return event.type === 'RFI_DELETED';
}

export type RfiModifiedEvent = BaseEvent<
  'RFI_MODIFIED',
  {
    nrn: string;
    projectNrn: string;
    subject: string;
    number: string;
    changes: SimpleChange[];
  }
>;
export function isRfiModifiedEvent(event: EventModel): event is RfiModifiedEvent {
  return event.type === 'RFI_MODIFIED';
}

export type RfiFileTransferFailedEvent = BaseEvent<
  'RFI_FILE_TRANSFER_FAILED',
  {
    nrn: string;
    projectNrn: string;
    subject: string;
    number: string;
    fileName: string;
    workflowAction: KeywordModel;
  }
>;
export function isRfiFileTransferFailedEvent(event: EventModel): event is RfiFileTransferFailedEvent {
  return event.type === 'RFI_FILE_TRANSFER_FAILED';
}

export type RfiFileDownloadedEvent = BaseEvent<
  'RFI_FILE_DOWNLOADED',
  {
    nrn: string;
    projectNrn: string;
    subject: string;
    number: string;
    fileName: string;
    path: string;
  }
>;
export function isRfiFileDownloadedEvent(event: EventModel): event is RfiFileDownloadedEvent {
  return event.type === 'RFI_FILE_DOWNLOADED';
}
