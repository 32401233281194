import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Project } from '@core/models/project/project.model';

@Component({
  selector: 'app-content-header-sub-header',
  templateUrl: './content-header-sub-header.component.html',
  styleUrls: ['./content-header-sub-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentHeaderSubHeaderComponent {
  @Input() project: Project | null;
}
