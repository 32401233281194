import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-indeterminate-progress-bar',
  templateUrl: './indeterminate-progress-bar.component.html',
  styleUrls: ['./indeterminate-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndeterminateProgressBarComponent {
  @Input() isProcessing: boolean;
  @Input() processSuccess: boolean;
  @Input() processError: boolean;
  @Input() progressLabel: string;
  @Input() successLabel: string;
  @Input() errorLabel: string;
}
