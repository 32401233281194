<div
  *ngIf="!isEditMode"
  class="due-date-container-read-mode"
  (mousedown)="openEditMode()"
>
  <span class="due-date-text">
    {{ 'APP.KANBAN.DUE_DATE' | translate }}
    {{ dueDateControl.value | date: 'shortDate' | emptyLabel }}
  </span>
  <mat-icon
    *ngIf="!isReadonly"
    class="action-icon edit-indicator-icon"
  >
    mode_edit
  </mat-icon>
</div>
<form *ngIf="isEditMode" [formGroup]="form">
  <div
    appClickOutside
    (clickOutside)="tryToSubmit()"
    fxLayout
    class="due-date-container-edit-mode"
  >
    <span class="due-date-text">
      {{ 'APP.KANBAN.DUE_DATE' | translate }}
    </span>
    <app-date-picker
      class="date-picker"
      [parentForm]="form"
      [displayEditIndicatorButtons]="true"
      [controlName]="dueDateControlName"
      (onPickerOpened)="disableClickOutside()"
      (onPickerClosed)="enableClickOutside()"
      (onDiscardChanges)="discardChanges()"
      (onAcceptChanges)="tryToSubmit()"
      [isRequired]="isRequired"
      [errorMessage]="requiredErrorMessage"
    ></app-date-picker>
  </div>
</form>
