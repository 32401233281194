import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserLicenseResponseModel } from '@core/models/user/user-license-response.model';
import { Store } from '@ngrx/store';
import { AppRootState } from '@root/reducers';
import { updateUser, updateUserComplete } from '@root/actions/user/user.actions';
import { UpdateUserModel, UserProfileModel } from '@core/models/user/user.model';
import { selectUserDetails } from '@root/selectors/user/user.selectors';
import { selectDisciplines } from '@root/selectors/keywords/keywords.selectors';
import { loadDisciplines } from '@root/actions/keywords/keywords.actions';
import { SearchableItemViewModel } from '@core/models/project/keyword.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.container.html',
  styleUrls: ['./view-profile.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewProfileContainer implements OnInit, OnDestroy {
  readonly emailControlName = 'emailControl';
  readonly firstNameControlName = 'firstNameControl';
  readonly lastNameControlName = 'lastNameControl';
  readonly phoneControlName = 'phoneControl';
  readonly disciplineControlName = 'disciplineControl';

  viewProfileForm = new FormGroup({
    [this.emailControlName]: new FormControl(),
    [this.firstNameControlName]: new FormControl(),
    [this.lastNameControlName]: new FormControl(),
    [this.phoneControlName]: new FormControl(),
    [this.disciplineControlName]: new FormControl()
  });
  @Output() onUserProfileSaved = new EventEmitter<UserLicenseResponseModel>();

  disciplines$ = this.store.select(selectDisciplines);
  userNrn: string;

  private destroyComponent = new Subject();

  constructor(
    private dialogRef: MatDialogRef<ViewProfileContainer>,
    private store: Store<AppRootState>,
    private actions: Actions
  ) {}

  get disciplineControl(): AbstractControl {
    return <AbstractControl>this.viewProfileForm.get('disciplineControl');
  }

  get emailControl(): AbstractControl {
    return <AbstractControl>this.viewProfileForm.get('emailControl');
  }

  private connectToStore(): void {
    this.store
      .select(selectUserDetails)
      .pipe(takeUntil(this.destroyComponent))
      .subscribe(result => {
        if (result) {
          this.setCurrentValues(result);
        }
      });
  }

  ngOnInit(): void {
    this.emailControl.disable();
    this.connectToStore();
    this.subscribeToActions();
    this.store.dispatch(loadDisciplines());
  }

  setCurrentValues(user: UserProfileModel): void {
    this.viewProfileForm.patchValue({
      [this.emailControlName]: user.email,
      [this.firstNameControlName]: user.firstName,
      [this.lastNameControlName]: user.lastName,
      [this.phoneControlName]: user.phoneNumber,
      [this.disciplineControlName]: user.discipline
    });
    this.userNrn = user.nrn;
  }

  ngOnDestroy(): void {
    this.destroyComponent.next();
    this.destroyComponent.complete();
  }

  changeDiscipline(keyword: SearchableItemViewModel | null): void {
    this.disciplineControl.patchValue(keyword);
    this.disciplineControl.setErrors(null);
  }

  setDisciplineError(): void {
    this.disciplineControl.setErrors({ invalid: true });
  }

  closeForm(): void {
    this.dialogRef.close();
  }

  save(): void {
    if (this.viewProfileForm.invalid) {
      return;
    }
    const discipline = this.disciplineControl.value
      ? {
          name: this.disciplineControl.value.name,
          type: this.disciplineControl.value.type
        }
      : null;

    const userToUpdate: UpdateUserModel = {
      nrn: this.userNrn,
      firstName: this.viewProfileForm.value.firstNameControl,
      lastName: this.viewProfileForm.value.lastNameControl,
      phoneNumber: this.viewProfileForm.value.phoneControl,
      discipline
    };

    this.store.dispatch(updateUser({ updateUserModel: userToUpdate }));
  }

  private subscribeToActions(): void {
    this.actions.pipe(ofType(updateUserComplete), takeUntil(this.destroyComponent)).subscribe(() => this.closeForm());
  }
}
