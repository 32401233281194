import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { DialogError } from '@shared/models/project-folders/dialog-error.type';
import { IconTypeParams } from '@core/models/files/icon-type.params';
import { ExternalFolder } from '@core/models/external-folder/external-folder.model';

export type ProjectItemType = 'file' | 'folder';

export interface ProjectItem {
  name: string;
  nrn: string;
  isChecked: boolean;
  type: ProjectItemType;
  iconTypeParams: IconTypeParams;
  creationDate?: string;
  sizeInBytes?: number;
}

type BrowserItems = ExternalFolder[] | ProjectItem[];
type BrowserItem = ExternalFolder | ProjectItem;

@Component({
  selector: 'app-files-browser',
  templateUrl: './files-browser.component.html',
  styleUrls: ['./files-browser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilesBrowserComponent implements OnChanges {
  @Input() items: BrowserItems;
  @Input() navigationItems: BrowserItems = [];
  @Input() isSelectable: boolean;
  @Input() isLoading: boolean;
  @Input() isLoadingNextPage: boolean;
  @Input() rootLabelName: string;
  @Input() error: DialogError | null;
  @Input() showBorder: boolean;
  @Input() lowerHeight: boolean;

  @Output() onItemSelected = new EventEmitter<BrowserItem>();
  @Output() onRootFolderSelected = new EventEmitter();
  @Output() onParentFolderSelected = new EventEmitter<BrowserItem>();
  @Output() onEndOfListReached = new EventEmitter();

  showSeparator: boolean;
  currentDirectory: BrowserItem | null = null;
  parentDirectory: BrowserItem | null = null;
  isErrorVisible: boolean;

  readonly navigationSeparator = '...';

  private readonly maxElementsInNavigation = 2;

  ngOnChanges(changes: SimpleChanges): void {
    this.updateNavigationDirectories();
    this.updateErrorVisibility();
  }

  itemSelected(item: BrowserItem): void {
    this.onItemSelected.emit(item);
  }

  rootFolderSelected(): void {
    this.onRootFolderSelected.emit();
  }

  parentFolderSelected(): void {
    this.onParentFolderSelected.emit(<BrowserItem>this.parentDirectory);
  }

  endOfListReached(): void {
    if (!this.isLoadingNextPage) {
      this.onEndOfListReached.emit();
    }
  }

  private updateNavigationDirectories(): void {
    const pathLength = this.navigationItems.length;

    this.currentDirectory = null;
    this.parentDirectory = null;

    this.showSeparator = pathLength > this.maxElementsInNavigation;
    this.currentDirectory = this.navigationItems[pathLength - 1];

    if (pathLength >= this.maxElementsInNavigation) {
      this.parentDirectory = this.navigationItems[pathLength - this.maxElementsInNavigation];
    }
  }

  private updateErrorVisibility(): void {
    this.isErrorVisible = !this.isLoading && !!this.error;
  }
}
