import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUrlProvider } from '@core/utilities/apiUrl/apiUrl.provider';
import { Observable } from 'rxjs';
import { ServerAccessFields } from '@app/server-access/models/server-access-fields.model';

@Injectable()
export class ServerAccessService {
  constructor(private http: HttpClient, private apiUrlProvider: ApiUrlProvider) {}

  create(): Observable<ServerAccessFields> {
    return this.http.post<ServerAccessFields>(this.getAccessKeyUrl(), null);
  }

  private getAccessKeyUrl(): string {
    return `${this.apiUrlProvider.getUrl()}/accesskeys`;
  }
}
