import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, tap } from 'rxjs/operators';
import { AnalyticEventAction } from '@shared/models/analytics/analytic-event-action.model';
import { AnalyticEventCategory } from '@shared/models/analytics/analytic-event-category.model';
import { DownloadService } from '@core/services/download/download.service';
import { AnalyticsService } from '@core/services/analytics/analytics.service';
import { downloadFile } from '@root/actions/download/download.actions';

@Injectable({ providedIn: 'root' })
export class DownloadEffects {
  constructor(
    private actions: Actions,
    private downloadService: DownloadService,
    private analyticsService: AnalyticsService
  ) {}

  downloadFile = createEffect(
    () =>
      this.actions.pipe(
        ofType(downloadFile),
        mergeMap(({ params }) => this.downloadService.download(params)),
        tap(() => this.analyticsService.recordEvent(AnalyticEventAction.DownloadFile, AnalyticEventCategory.Files))
      ),
    { dispatch: false }
  );
}
