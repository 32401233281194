<div
  fxLayout="column"
  class="files-browser-container"
  [class.with-border]="showBorder"
  [class.with-lower-height]="lowerHeight"
>
  <div
    class="user-drives-header"
    fxLayoutAlign="left center"
    fxFlex="none"
  >
    <span
      class="selected-items-history-step"
      [class.last-history-step]="navigationItems.length === 0"
      (click)="rootFolderSelected()"
    >
      {{rootLabelName | translate}}
    </span>
    <span
      *ngIf="navigationItems.length > 0"
      class="items-divider"
    >
      /
    </span>
    <span
      *ngIf="showSeparator"
      class="selected-items-history-step"
    >
      <span class="item-name items-separator">
        {{navigationSeparator}}
      </span>
    </span>
    <span
      *ngIf="showSeparator"
      class="items-divider"
    >
      /
    </span>
    <span
      *ngIf="parentDirectory"
      class="selected-items-history-step item-name-wrapper parent-name-container"
      fxFlex="0 3 auto"
      (click)="parentFolderSelected()"
    >
      <span class="item-name">
        {{parentDirectory.name}}
      </span>
    </span>
    <span
      *ngIf="parentDirectory"
      class="items-divider"
    >
      /
    </span>
    <span
      *ngIf="currentDirectory"
      class="item-name selected-items-history-step last-history-step item-name-wrapper"
      fxFlex="1 1 auto"
    >
      {{currentDirectory.name}}
    </span>
  </div>
  <div
    *ngIf="!isLoading && !error"
    class="browser-content"
    infiniteScroll
    (scrolled)="endOfListReached()"
    [scrollWindow]="false"
  >
    <mat-list>
      <mat-list-item
        *ngFor="let item of items"
        class="list-item"
        [class.selected]="item.isChecked"
        (click)="itemSelected(item)"
      >
        <div
          *ngIf="isSelectable && item.type === 'file'"
          class="select-checkbox"
        >
          <mat-checkbox [checked]="item.isChecked"></mat-checkbox>
        </div>
        <mat-icon
          *ngIf="!item.iconTypeParams"
          class="type-icon"
          svgIcon="folder-filled-miniature"
        ></mat-icon>
        <mat-icon
          *ngIf="item.iconTypeParams"
          class="type-icon"
          [class.folder-icon]="item.type === 'folder'"
          svgIcon="{{item.iconTypeParams | iconTypeParser:'folder-filled'}}-miniature"
        ></mat-icon>
        <div class="item-name">{{item.name}}</div>
      </mat-list-item>
      <mat-list-item
        id="files-browser-loading-more-container"
        *ngIf="isLoadingNextPage"
        fxLayout
        fxLayoutAlign="center center"
      >
        <app-spinner></app-spinner>
      </mat-list-item>
    </mat-list>
  </div>
  <div
    *ngIf="isLoading"
    id="files-browser-loading-container"
    class="browser-content"
    fxLayout
    fxLayoutAlign="center center"
  >
    <app-spinner></app-spinner>
  </div>
  <app-empty-state
    *ngIf="isErrorVisible"
    id="files-browser-error"
    class="error-container"
    [class.smallerIcons]="lowerHeight"
    [secondaryLabel]="error?.message"
    [icon]="error?.icon"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxFlex="1 1 100%"
  ></app-empty-state>
</div>
