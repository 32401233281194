import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TermsAndConditionsState } from '@terms-and-conditions/reducers';

export const selectTermsAndConditionsStateModule = createFeatureSelector<TermsAndConditionsState>('terms');

export const selectTermsAndConditionsStateDialogState = createSelector(
  selectTermsAndConditionsStateModule,
  state => state?.dialog
);
export const selectIsCreatingTermsAndConditions = createSelector(
  selectTermsAndConditionsStateDialogState,
  state => state.isCreatingTermsAndConditions
);

export const selectIsGettingTermsAndConditionsContent = createSelector(
  selectTermsAndConditionsStateDialogState,
  state => state.isGettingTermsAndConditionsContent
);

export const selectShowLoadingError = createSelector(
  selectTermsAndConditionsStateDialogState,
  state => state.showLoadingError
);

export const selectTermsAndConditionsContent = createSelector(
  selectTermsAndConditionsStateDialogState,
  state => state.termsAndConditionsContent
);

export const selectTermsAndConditionsAccepted = createSelector(
  selectTermsAndConditionsStateDialogState,
  state => state?.termsAndConditionsAccepted
);

export const selectTermsAndConditionsNrn = createSelector(
  selectTermsAndConditionsStateDialogState,
  state => state.termsAndConditionsNrn
);

export const selectIsLoadingTermsAndConditions = createSelector(
  selectTermsAndConditionsStateDialogState,
  state => state.isLoadingTermsAndConditions
);

export const selectShowSpinner = createSelector(
  selectTermsAndConditionsStateDialogState,
  state => state.isGettingTermsAndConditionsContent && !state.showLoadingError
);

export const selectShowButtonSpinner = createSelector(
  selectTermsAndConditionsStateDialogState,
  state => state.isCreatingTermsAndConditions || state.isAcceptingTermsAndConditions
);

export const selectShowDisabledButton = createSelector(
  selectTermsAndConditionsStateDialogState,
  state => state.isCreatingTermsAndConditions || state.isGettingTermsAndConditionsContent
);
