import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogService } from '@shared/services/confirmation-dialog/confirmation.dialog.service';
import { ConfirmationDialogData } from '@shared/services/confirmation-dialog/confirmation.dialog.model';
import { catchError, filter, map, takeUntil, tap } from 'rxjs/operators';
import { EulaService } from '@core/services/eula/eula.service';
import { forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { EulaDialogData } from '@shared/models/eula/eula-dialog-data.model';
import { AnalyticsService } from '@core/services/analytics/analytics.service';
import { AnalyticEventAction } from '@shared/models/analytics/analytic-event-action.model';
import { AnalyticEventCategory } from '@shared/models/analytics/analytic-event-category.model';

@Component({
  selector: 'app-eula',
  templateUrl: './eula.container.html',
  styleUrls: ['./eula.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EulaContainer implements OnInit, OnDestroy {
  isLoading = true;
  isEulaRead = false;
  isEulaPreviouslyAccepted: boolean;
  eulaContents: string;
  isEulaVersionAccepted: boolean;
  eulaVersion: string;
  eulaNrn: string;
  isErrorLoadingEula = false;

  destroyComponent = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EulaDialogData,
    private dialogRef: MatDialogRef<EulaContainer>,
    private confirmationDialogService: ConfirmationDialogService,
    private eulaService: EulaService,
    private analyticsService: AnalyticsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.isEulaPreviouslyAccepted = !!this.data.isEulaPreviouslyAccepted;
  }

  ngOnInit() {
    this.getEula().subscribe(
      eulaContentRtf => {
        this.eulaContents = eulaContentRtf;
        this.isLoading = false;
        this.changeDetectorRef.markForCheck();
      },
      () => {
        this.isLoading = false;
        this.isErrorLoadingEula = true;
        this.changeDetectorRef.markForCheck();
      }
    );
  }

  ngOnDestroy() {
    this.destroyComponent.next();
    this.destroyComponent.complete();
  }

  onConfirm(): void {
    this.analyticsService.recordEvent(AnalyticEventAction.EulaAccepted, AnalyticEventCategory.Authentication);
    this.dialogRef.close({ nrn: this.eulaNrn, isAccepted: true });
  }

  onDecline(): void {
    const dialogOptions: ConfirmationDialogData = {
      headerText: 'AUTHENTICATION.EULA_DIALOG.DECLINE_DIALOG.HEADER',
      dialogText: 'AUTHENTICATION.EULA_DIALOG.DECLINE_DIALOG.TEXT',
      confirmationText: 'AUTHENTICATION.EULA_DIALOG.DECLINE_DIALOG.CONFIRM',
      declineText: 'AUTHENTICATION.EULA_DIALOG.DECLINE_DIALOG.DECLINE'
    };
    this.confirmationDialogService
      .openConfirmationDialog(dialogOptions)
      .pipe(
        filter(result => result.isConfirmed),
        tap(() => {
          this.analyticsService.recordEvent(AnalyticEventAction.EulaRejected, AnalyticEventCategory.Authentication);
          this.dialogRef.close({ nrn: this.eulaNrn, isAccepted: false });
        })
      )
      .subscribe();
  }

  markedAsReadChanged(): void {
    this.isEulaRead = !this.isEulaRead;
  }

  onClose(): void {
    this.dialogRef.close(undefined);
  }

  private getEula(): Observable<string> {
    if (this.data.isUserAuthenticated) {
      return this.getEulaAuthenticated();
    }

    return this.getEulaUnauthenticated();
  }

  private getEulaUnauthenticated(): Observable<string> {
    return this.eulaService.getEula().pipe(
      map(eula => {
        this.eulaVersion = eula.version;
        this.isEulaVersionAccepted = false;
        this.eulaNrn = eula.nrn;
        return eula.content;
      }),
      catchError(error => throwError(error)),
      takeUntil(this.destroyComponent)
    );
  }

  private getEulaAuthenticated(): Observable<string> {
    const eulaMeCall = this.eulaService.getEulaMe().pipe(
      catchError(error => {
        if (error.status && error.status === 404) {
          this.isEulaPreviouslyAccepted = false;
          return of(undefined);
        }
        return throwError(error);
      })
    );

    return forkJoin([this.eulaService.getEula(), eulaMeCall]).pipe(
      map(([eula, eulaStatus]) => {
        this.isEulaPreviouslyAccepted = !!eulaStatus;
        this.isEulaVersionAccepted = eula.nrn === eulaStatus?.nrn;
        this.eulaNrn = eula.nrn;
        this.eulaVersion = eula.version;
        return eula.content;
      }),
      catchError(error => throwError(error)),
      takeUntil(this.destroyComponent)
    );
  }
}
