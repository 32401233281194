import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { DialogError } from '@shared/models/project-folders/dialog-error.type';
import { ExternalFolder } from '@core/models/external-folder/external-folder.model';

@Component({
  selector: 'app-sites-search-results',
  templateUrl: './sites-search-results.component.html',
  styleUrls: ['./sites-search-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SitesSearchResultsComponent {
  @Input() externalFolders: ExternalFolder[];
  @Input() isLoading: boolean;
  @Input() isLoadingNextPage: boolean;
  @Input() error: DialogError | null;
  @Input() selectedExternalFolder: string | null;
  @Input() isDefaultView: boolean;
  @Output() onEndOfListReached = new EventEmitter();
  @Output() onExternalFolderSelected = new EventEmitter<ExternalFolder>();

  trackBySharePointSiteNrn = (index: number, externalFolder: ExternalFolder) => externalFolder.nrn;

  endOfListReached(): void {
    if (!this.isLoadingNextPage) {
      this.onEndOfListReached.emit();
    }
  }

  externalFolderSelected(externalFolder: ExternalFolder): void {
    this.onExternalFolderSelected.emit(externalFolder);
  }
}
