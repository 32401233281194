<div
  class="user-display"
  fxLayout
  fxLayoutAlign="space-between center"
>
  <div fxLayout fxLayoutAlign="start center">
    <app-initials [initials]="userDetails.initials" [theme]="initialsTheme.Light"></app-initials>
    <div class="primary-label">
      {{userDetails.displayName}}
    </div>
  </div>
</div>
