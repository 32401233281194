import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterViewChecked,
  Output,
  EventEmitter
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

const ANIMATION_DELAY = 750;

@Component({
  selector: 'app-inline-editable-textarea',
  templateUrl: './inline-editable-textarea.component.html',
  styleUrls: ['./inline-editable-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineEditableTextareaComponent implements AfterViewChecked {
  @ViewChild('textareaContainer', { static: false })
  textareaContainer: ElementRef;

  @Input() headerText: string;
  @Input() emptyValueMessage: string;
  @Input() editableFieldId: string;
  @Input() readonlyFieldId: string;
  @Input() isReadonly: boolean;
  @Input() isRequired: boolean;
  @Input() errorMessage: string;

  @Input() set fieldValue(value: string) {
    this.initialValue = value;
    this.handleInputValueChange(value);
  }
  @Input() set hasRequestError(hasError: boolean) {
    this.handleRequestErrorChange(hasError);
  }
  @Input() isProcessing: boolean;
  @Output() onSubmit = new EventEmitter<string | null>();
  @Output() onChangeMode = new EventEmitter<boolean>();

  initialValue: string | null;
  minimalHeight = '126px';
  isTogglingInProgress = false;
  isExpanded = false;
  isEditMode = false;
  readMore = false;

  readonly inlineEditableTextareaControlName = 'inlineEditableTextareaControl';
  form = new FormGroup({
    [this.inlineEditableTextareaControlName]: new FormControl('')
  });

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewChecked(): void {
    if (!this.textareaContainer || this.isEditMode) {
      return;
    }

    this.readMore = this.textareaContainer.nativeElement.scrollHeight > parseInt(this.minimalHeight, 10);
    this.changeDetectorRef.detectChanges();
  }

  get textareaControl(): AbstractControl {
    return <AbstractControl>this.form.get(this.inlineEditableTextareaControlName);
  }

  toggleHeight(): void {
    if (this.isTogglingInProgress) {
      return;
    }

    const container = this.textareaContainer.nativeElement;

    container.style.maxHeight = this.isExpanded ? this.minimalHeight : `${container.scrollHeight}px`;
    this.isTogglingInProgress = true;
    this.changeDetectorRef.detectChanges();

    setTimeout(() => {
      this.isExpanded = !this.isExpanded;
      this.isTogglingInProgress = false;
      this.changeDetectorRef.detectChanges();
    }, ANIMATION_DELAY);
  }

  openEditMode(event: MouseEvent): void {
    event.preventDefault();

    if (this.isEditMode || this.isReadonly) {
      return;
    }

    this.updateEditMode(true);
  }

  discardChanges(): void {
    this.updateEditMode(false);
    this.textareaControl.patchValue(this.initialValue);
  }

  submit(): void {
    if (!this.textareaControl.value && this.isRequired) {
      return;
    }

    const currentTrimmedInputValue =
      this.textareaControl.value === null ? this.textareaControl.value : this.textareaControl.value.trim();
    this.updateEditMode(false);
    this.textareaControl.patchValue(currentTrimmedInputValue);

    if (currentTrimmedInputValue !== this.initialValue) {
      this.onSubmit.emit(currentTrimmedInputValue.length ? currentTrimmedInputValue : null);
    }
  }

  private handleRequestErrorChange(hasError: boolean): void {
    if (!hasError) {
      return;
    }

    this.isEditMode = this.textareaControl.value !== this.initialValue;

    if (this.isEditMode) {
      this.onChangeMode.emit(true);
    }
  }

  private handleInputValueChange(value: string): void {
    this.textareaControl.patchValue(value);
  }

  private updateEditMode(isEditMode: boolean): void {
    this.isEditMode = isEditMode;
    this.onChangeMode.emit(isEditMode);
  }
}
