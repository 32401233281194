import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogData } from '@shared/services/confirmation-dialog/confirmation.dialog.model';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { of, Observable } from 'rxjs';
import { ConfirmationDialogLocalStorageService } from '@shared/components/confirmation-dialog/local-storage-service/confirmation-dialog.local-storage.service';
import { ConfirmationDialogResponse } from '@shared/models/ConfirmationDialogResponse';

@Injectable()
export class ConfirmationDialogService {
  constructor(private dialog: MatDialog, private localStorageService: ConfirmationDialogLocalStorageService) {}

  openConfirmationDialog(options: ConfirmationDialogData): Observable<ConfirmationDialogResponse> {
    if (this.localStorageService.shouldDisplayDialog(options.localStorageKey)) {
      return this.dialog
        .open(ConfirmationDialogComponent, {
          width: '535px',
          data: options,
          disableClose: true
        })
        .afterClosed();
    }
    return of({ isConfirmed: true });
  }
}
