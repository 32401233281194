import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '@shared/utilities/date/date.utils';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'timeOrDate'
})
export class TimeOrDatePipe implements PipeTransform {
  private readonly today = DateUtils.Today;

  constructor(private datePipe: DatePipe) {}

  transform(date: string, dateFormat: 'short' | 'shortDate'): string | null {
    const format = DateUtils.isSame(new Date(date), this.today) ? 'shortTime' : dateFormat;
    return this.datePipe.transform(date, format);
  }
}
