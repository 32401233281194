<div
  class="accordion-container"
  [style.max-height]="expandedHeight || collapsedHeight"
  #accordionContainer
>
  <div fxLayout fxLayoutAlign="space-between start">
    <div class="accordion-header">
      <span class="accordion-title font-uppercase" [ngClass]="headerStyle">
        {{header | translate }}
      </span>
      <span *ngIf="!!value" class="accordion-title-value">
        {{value}}
      </span>
    </div>
    <div fxLayout>
      <mat-icon
        *ngIf="isEditPossible"
        class="action-icon edit-indicator-icon"
      >
        mode_edit
      </mat-icon>
      <mat-icon
        *ngIf="isExpandPossible"
        svgIcon="drop-down"
        [id]="toggleIconId"
        class="toggle-icon"
        [ngClass]="{'rotate-down': isExpanded, 'rotate-up': !isExpanded}"
        (mousedown)="toggleHeight($event)"
      ></mat-icon>
    </div>
  </div>
  <ng-content></ng-content>
</div>
