<div
  [class.opened]="isSearchBarOpen"
  [class.closed]="!isSearchBarOpen"
  class="searchbar-container"
>
  <div *ngIf="!isSearchBarOpen">
    <ng-content select="[buttons-collapsed]"></ng-content>
  </div>
  <div class="searchbar-content">
    <div
      class="searchbar-body"
      fxLayout="column"
    >
      <ng-content select="[buttons]"></ng-content>
      <div
        *ngIf="displaySorting"
        id="searchbar-sorting-header"
        class="searchbar-header">
        <mat-icon
          class="searchbar-icon"
          svgIcon="search-sorting"
        ></mat-icon>
        <span class="searchbar-header-text">
          {{ 'APP.SORTING' | translate }}
        </span>
      </div>
      <ng-content select="[sorting]"></ng-content>
      <div class="searchbar-delimiter"></div>
      <div class="searchbar-header" fxLayoutAlign="space-between center">
        <div>
          <mat-icon
            class="searchbar-icon"
            svgIcon="search-filters"
          ></mat-icon>
          <span class="searchbar-header-text">
          {{ 'APP.FILTERS' | translate }}
        </span>
        </div>
        <span
          class="searchbar-clear-filters"
          (click)="clearFilters()"
        >
          {{ 'APP.CLEAR_FILTERS' | translate }}
        </span>
      </div>
      <ng-content select="[filters]"></ng-content>
    </div>
  </div>
  <div
    *ngIf="allowToggle"
    class="searchbar-menu-item-close-wrapper"
    [class.collapsed-searchbar-menu-item-close-wrapper]="!isSearchBarOpen"
    (click)="toggleSidebar()"
  >
    <mat-icon
      class="searchbar-menu-item-close"
      svgIcon="menu-hide-searchbar"
    ></mat-icon>
    <mat-icon
      *ngIf="displaySorting"
      class="searchbar-icon"
      svgIcon="search-sorting"
    ></mat-icon>
    <mat-icon
      class="searchbar-icon search-filters"
      svgIcon="search-filters"
    ></mat-icon>
  </div>
</div>
