import { Observable, forkJoin, of } from 'rxjs';
import { ProjectResponseModel } from '@core/models/project/project.response.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiUrlProvider } from '@core/utilities/apiUrl/apiUrl.provider';
import { map, tap } from 'rxjs/operators';
import { ProjectDetails } from '@shared/models/project/project-details.model';
import { CreateProjectResponse } from '@shared/models/project/create-project.response.model';
import { UpdateProjectDetailsModel } from '@shared/models/project/update-project-details.model';
import { ProjectFoldersResponseModel } from '@shared/models/project-folders/project-folders.response.model';
import { KeywordsResponseModel } from '@core/models/project/keywords.response.model';
import { GetProjectsParams } from '@core/services/project/get-projects.params';
import { ProjectDetailsResponseModel } from '@shared/models/project/project-details.response.model';
import { ProjectFolderService } from '@core/services/project-folder/project-folder.service';
import { compareKeywords } from '@shared/utilities/compare-keywords/compare-keywords';
import {
  RfisProjectsFilterType,
  SubmittalsProjectsFilterType
} from '@core/models/project/workflow-items-projects-filter-type';

export enum ProjectType {
  MyProjects,
  SharedFolders,
  RecentProjects
}

type KeywordResponsePageCache = Map<string, KeywordsResponseModel>;

@Injectable()
export class ProjectsService {
  private readonly projectsEndpointName = 'projects';
  private keywordsCache: KeywordResponsePageCache = new Map<string, KeywordsResponseModel>();
  private projectsWithSubmittalsSupportCache: ProjectResponseModel | null = null;
  private projectsWithRfisSupportCache: ProjectResponseModel | null = null;
  private projectDetailsCache = new Map<string, ProjectDetailsResponseModel>();

  constructor(
    private http: HttpClient,
    private apiUrlProvider: ApiUrlProvider,
    private projectFolderService: ProjectFolderService
  ) {}

  getProjects({ disableCache = false }: GetProjectsParams): Observable<ProjectResponseModel> {
    let params = new HttpParams();

    if (disableCache) {
      params = params.set('disableCache', 'true');
    }

    return this.fetchProjectsList(params);
  }

  getProjectsWithActionItems(): Observable<ProjectResponseModel> {
    return this.fetchProjectsList(new HttpParams().set('filter', 'actionitems'));
  }

  getProjectsWithSubmittals(filterType: SubmittalsProjectsFilterType): Observable<ProjectResponseModel> {
    return this.fetchProjectsList(new HttpParams().set('filter', filterType));
  }

  getProjectsWithRfis(filterType: RfisProjectsFilterType): Observable<ProjectResponseModel> {
    return this.fetchProjectsList(new HttpParams().set('filter', filterType));
  }

  getProjectsWithSubmittalsSupport(): Observable<ProjectResponseModel> {
    if (this.projectsWithSubmittalsSupportCache !== null) {
      return of(this.projectsWithSubmittalsSupportCache);
    }

    return this.fetchProjectsList(new HttpParams().set('filter', 'submittals')).pipe(
      tap(response => {
        this.projectsWithSubmittalsSupportCache = response;
      })
    );
  }

  getProjectsWithRfisSupport(): Observable<ProjectResponseModel> {
    if (this.projectsWithRfisSupportCache !== null) {
      return of(this.projectsWithRfisSupportCache);
    }

    return this.fetchProjectsList(new HttpParams().set('filter', 'rfis')).pipe(
      tap(response => {
        this.projectsWithRfisSupportCache = response;
      })
    );
  }

  clearSubmittalsProjectsCache(): void {
    this.projectsWithSubmittalsSupportCache = null;
  }

  clearRfisProjectsCache(): void {
    this.projectsWithRfisSupportCache = null;
  }

  createProject(createProjectRequest: ProjectDetails): Observable<CreateProjectResponse> {
    return this.http.post<CreateProjectResponse>(`${this.getUrl()}/${this.projectsEndpointName}`, createProjectRequest);
  }

  deleteProject(projectNrn: string): Observable<string> {
    return this.http
      .delete(`${this.getUrl()}/${this.projectsEndpointName}/${encodeURIComponent(projectNrn)}`)
      .pipe(map(() => projectNrn));
  }

  loadProjectDetails(projectNrn: string): Observable<[ProjectDetailsResponseModel, ProjectFoldersResponseModel]> {
    return forkJoin([
      this.getProjectDetails(projectNrn, true),
      this.projectFolderService.getProjectFolders(projectNrn)
    ]);
  }

  private fetchProjectsList(params: HttpParams): Observable<ProjectResponseModel> {
    return this.http.get<ProjectResponseModel>(`${this.getUrl()}/${this.projectsEndpointName}`, {
      params
    });
  }

  getProjectDetails(projectNrn: string, disableCache?: boolean): Observable<ProjectDetailsResponseModel> {
    if (this.projectDetailsCache.has(projectNrn) && !disableCache) {
      return of(<ProjectDetailsResponseModel>this.projectDetailsCache.get(projectNrn));
    }

    if (this.projectDetailsCache.has(projectNrn) && disableCache) {
      this.projectDetailsCache.delete(projectNrn);
    }

    return this.http
      .get<ProjectDetailsResponseModel>(
        `${this.getUrl()}/${this.projectsEndpointName}/${encodeURIComponent(projectNrn)}`
      )
      .pipe(
        tap(response => {
          this.projectDetailsCache.set(projectNrn, response);
        })
      );
  }

  updateProjectDetails(updateProjectDetails: UpdateProjectDetailsModel, projectNrn: string): Observable<{}> {
    return this.http.put(
      `${this.getUrl()}/${this.projectsEndpointName}/${encodeURIComponent(projectNrn)}`,
      updateProjectDetails
    );
  }

  getKeywords(
    nrn: string | null = null,
    listType: string,
    offsetToken: string | null = null,
    maxSize: number | null = null,
    query: string | null = null
  ): Observable<KeywordsResponseModel> {
    let url;
    if (nrn) {
      url = `${this.getUrl()}/projects/${encodeURIComponent(nrn)}/keywords`;
    } else {
      url = `${this.getUrl()}/keywords`;
    }
    const cacheUrl = `${url}/${listType}/${offsetToken}/${maxSize}/?query=${query ?? ''}`;
    const cache = this.keywordsCache.get(cacheUrl);

    if (cache) {
      return of(cache);
    }

    return this.http
      .get<KeywordsResponseModel>(url, {
        params: this.getParamsForKeywordsRequest(listType, offsetToken, maxSize, query)
      })
      .pipe(
        map(response => ({
          ...response,
          items: response.items.sort(compareKeywords)
        })),
        tap(response => {
          this.keywordsCache.set(cacheUrl, response);
        })
      );
  }

  private getParamsForKeywordsRequest(
    listType: string,
    offsetToken: string | null = null,
    maxSize: number | null = null,
    query: string | null = null
  ): HttpParams {
    let params = new HttpParams();

    params = params.set('listType', listType);

    if (!!offsetToken) {
      params = params.set('offsetToken', offsetToken);
    }
    if (!!maxSize) {
      params = params.set('maxSize', maxSize.toString());
    }
    if (!!query) {
      params = params.set('query', query);
    }

    return params;
  }

  private getUrl(): string {
    return `${this.apiUrlProvider.getUrl()}`;
  }
}
