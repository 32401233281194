import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class InternationalizationService {
  constructor(private translateService: TranslateService) {}

  private readonly supportedLangs = ['en', 'en-GB', 'de'];
  private readonly defaultLang = 'en';

  getApplicationLanguage(): string {
    const browserCultureLang = this.translateService.getBrowserCultureLang();
    const isCultureLangSupported = this.supportedLangs.includes(browserCultureLang);

    return isCultureLangSupported ? browserCultureLang : this.getLang();
  }

  private getLang(): string {
    const browserLang = this.translateService.getBrowserLang();
    const isSupported = this.supportedLangs.includes(browserLang);

    return isSupported ? browserLang : this.defaultLang;
  }
}
