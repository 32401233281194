import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';
import { blacklistZone } from './blacklist';

if (environment.production) {
  enableProdMode();
}

blacklistZone.run(() => {
  platformBrowserDynamic().bootstrapModule(AppModule);
});
