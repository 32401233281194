import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@core/services/authentication/authentication.service';

@Injectable()
export class LoggedOutGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.authService.isUserLogged()) {
      return true;
    }

    // logged in so redirect to projects page
    this.router.navigate(['/projects']);
    return false;
  }
}
