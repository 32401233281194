<div
  id="empty-state-component"
  fxLayout="column"
  fxLayoutAlign="center center"
  class="empty-state-component"
>
  <mat-icon svgIcon="{{icon}}" class="icon-large"></mat-icon>
  <div class="primary-label">
    {{primaryLabel | translate}}
  </div>
  <div class="secondary-label">
    {{secondaryLabel | translate}}
  </div>
  <ng-content class="extra-content"></ng-content>
</div>
