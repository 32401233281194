<form
  [formGroup]="form"
  (ngSubmit)="leaveEditMode()"
  [ngClass]="formTheme + '-theme'"
>
  <mat-form-field
    *ngIf="!isEditMode"
    class="form-field form-field-readonly"
  >
    <textarea
      [id]="readonlyFieldId"
      matInput
      matTextareaAutosize
      readonly="true"
      class="input input-readonly"
      [class.input-readonly-bold]="readonlyFieldBolded"
      [class.form-field-view]="!isReadonly"
      [formControlName]="inlineEditableControlName"
      (click)="openEditMode()"
    ></textarea>
    <mat-icon
      *ngIf="!isReadonly"
      matSuffix
      class="action-icon edit-icon"
      (click)="openEditMode()"
    >
      mode_edit
    </mat-icon>
  </mat-form-field>
  <mat-form-field
    *ngIf="isEditMode"
    class="form-field"
  >
    <input
      appAutoFocus
      [id]="editableFieldId"
      #inlineEditableInput
      matInput
      class="input"
      [formControlName]="inlineEditableControlName"
      (blur)="submit()"
      (keydown.escape)="discardChanges()"
      [errorStateMatcher]="isRequired ? inputErrorMatcher : undefined"
    />
    <mat-icon
      matSuffix
      class="action-icon"
      (mousedown)="discardChanges()"
    >
      clear
    </mat-icon>
    <mat-icon
      id="confirm-inline-edit-button"
      matSuffix
      class="action-icon"
    >
      done
    </mat-icon>
    <mat-error>
      {{errorMessage | translate}}
    </mat-error>
  </mat-form-field>
</form>
