<div
  class="sidebar-submenu"
  [class.hidden-submenu]="!isSidebarOpen"
>
  <div class="sidebar-submenu-item">
    <span
      class="sidebar-submenu-item-text pointer-cursor"
      (click)="navigate('URL.PRIVACY_POLICY', analyticEventAction.ViewPrivacyPolicy)"
    >
      {{ 'PROJECT.PRIVACY_POLICY' | translate }}
    </span>
    <mat-icon
      class="sidebar-submenu-icon"
      svgIcon="menu-external-link"
    ></mat-icon>
  </div>
  <div class="sidebar-submenu-item">
    <span
      class="sidebar-submenu-item-text pointer-cursor"
      (click)="navigate('URL.TERMS_AND_CONDITIONS', analyticEventAction.ViewTermsOfUse)"
    >
      {{ 'PROJECT.TERMS_OF_USE' | translate }}
    </span>
    <mat-icon
      class="sidebar-submenu-icon"
      svgIcon="menu-external-link"
    ></mat-icon>
  </div>
  <div
    class="sidebar-submenu-item"
    *ngIf="isEulaSupported"
  >
    <span
      id="eula-button"
      class="sidebar-submenu-item-text pointer-cursor"
      (click)="displayEula()"
    >
      {{ 'PROJECT.EULA' | translate }}
    </span>
  </div>
  <span class="sidebar-submenu-item">
    {{ 'APP.VERSION' | translate: { value: version } }}
  </span>
</div>
