import { Injectable } from '@angular/core';
import { Router, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BrowserCompatibilityService } from '@core/services/browser-compatibility/browser-compatibility.service';

@Injectable()
export class BrowserCompatibilityGuard implements CanActivateChild {
  constructor(private router: Router, private browserCompatibilityService: BrowserCompatibilityService) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isBrowserSupported = this.browserCompatibilityService.isBrowserSupported();
    const hasUserAcknowledgedSupport = this.browserCompatibilityService.hasUserAcknowledgedSupport();

    if (isBrowserSupported || hasUserAcknowledgedSupport) {
      return true;
    } else {
      this.router.navigate(['/unsupported-browser']);
      return false;
    }
  }
}
