import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { KeywordModel, SearchableItemViewModel } from '@core/models/project/keyword.model';

@Component({
  selector: 'app-search-autocomplete',
  templateUrl: './search-autocomplete.component.html',
  styleUrls: ['./search-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchAutocompleteComponent {
  @ViewChild('auto', { static: true }) auto;
  @Input() shouldShowDescription: boolean;
  @Input() items: SearchableItemViewModel[];
  @Input() hasError: boolean;
  @Input() loadingErrorMessage: string;
  @Input() isLoading: boolean;
  @Input() hasMoreItemsToLoad: boolean;
  @Input() noResultErrorMessage: string;

  @Output() onPanelOpened = new EventEmitter();
  @Output() onPanelClosed = new EventEmitter();
  @Output() onSelectItem = new EventEmitter<SearchableItemViewModel | KeywordModel>();
  @Output() onClickMessage = new EventEmitter();

  trackByItemName = (_, item: SearchableItemViewModel) => item.name;

  panelOpened(): void {
    this.onPanelOpened.emit();
  }

  panelClosed(): void {
    this.onPanelClosed.emit();
  }

  selectItem(item: SearchableItemViewModel | KeywordModel): void {
    this.onSelectItem.emit(item);
  }

  clickMessage(): void {
    this.onClickMessage.emit();
  }
}
