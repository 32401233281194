<mat-form-field
  class="select-field"
  [class.select-error]="hasLoadingKeywordsError"
  [class.disabled]="isDisabled"
>
  <mat-select
    #keywordSelect
    [placeholder]="placeholder | translate"
    disableOptionCentering
    [required]="isRequired"
    [disabled]="isDisabled"
    [compareWith]="compareKeywords"
    [value]="selectedKeyword"
    (selectionChange)="keywordSelected($event)"
    (openedChange)="panelOpened($event)"
    (focus)="selectionFocused()"
    (blur)="updateFieldValidity()"
  >
  <mat-option
    *ngIf="isClearable"
    class="clear-option"
    [value]="null"
  >
    {{'APP.NONE' | translate }}
  </mat-option>
  <mat-option
    *ngFor="let keyword of keywords"
    [value]="{ name: keyword.name, type: keyword.type }"
    class="select-option"
  >
    {{ keyword.name }}
  </mat-option>
  </mat-select>
  <mat-error
    *ngIf="keywordSelect.errorState && emptyFieldErrorMessage"
    id="empty-field-error"
  >
    {{ emptyFieldErrorMessage | translate }}
  </mat-error>
  <app-spinner
    *ngIf="isLoadingKeywords"
    class="select-loading-spinner"
    diameter="20"
    strokeWidth="1.5"
  ></app-spinner>
  <div
    *ngIf="hasLoadingKeywordsError && loadErrorMessage"
    class="load-select-error"
  >
    {{ loadErrorMessage | translate }}
  </div>
  <span class="tooltip" *ngIf="tooltipMessage">
    {{ tooltipMessage | translate }}
  </span>
</mat-form-field>
