import { Pipe, PipeTransform } from '@angular/core';
import { BytesParser } from '@shared/utilities/bytes/bytes.parser';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'byteParser'
})
export class ByteParserPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(val: number): string {
    const parsed = BytesParser.parse(val);
    let fullParsedValue = '';
    this.translateService.get(`FILE.UNITS.${parsed.unit}`).subscribe(translatedUnit => {
      fullParsedValue = `${parsed.value} ${translatedUnit}`;
    });
    return fullParsedValue;
  }
}
