import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastService } from '@core/services/toast/toast.service';
import {
  getTermsAndConditionsContent,
  getTermsAndConditionsContentFailed,
  getTermsAndConditionsContentSuccess,
  loadTermsAndConditionsUponProjectSelect,
  loadTermsAndConditionsDetailsSuccess,
  loadTermsAndConditionsDetailsFailed,
  createTermsAndConditions,
  createTermsAndConditionsFailed,
  createTermsAndConditionsSuccess,
  closeTermsAndConditionsDialog,
  acceptTermsAndConditions,
  acceptTermsAndConditionsSuccess,
  openTermsAndConditionsDialog,
  loadTermsAndConditions
} from '@terms-and-conditions/actions/terms-and-conditions-dialog/terms-and-conditions-dialog.actions';
import { catchError, map, tap, switchMap, mergeMap, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { LoadTermsAndConditionsResponse } from '@terms-and-conditions/models/load-terms-and-conditions.response.model';
import { CreateTermsAndConditionsResponse } from '@terms-and-conditions/models/create-terms-and-conditions.response.model';
import { TermsAndConditionsService } from '@core/services/terms-and-conditions/terms-and-conditions.service';
import { TermsAndConditionsDialogService } from '@core/services/terms-and-conditions/terms-and-conditions-dialog.service';
import { AnalyticsService } from '@core/services/analytics/analytics.service';
import { AnalyticEventAction } from '@shared/models/analytics/analytic-event-action.model';
import { AnalyticEventCategory } from '@shared/models/analytics/analytic-event-category.model';
import { selectProject } from '@root/actions/project-list/project-list.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Action, Store } from '@ngrx/store';
import { TermsAndConditionsDialogState } from '@terms-and-conditions/reducers/terms-and-conditions-dialog/terms-and-conditions-dialog.reducer';
import { selectTermsAndConditionsContent } from '@terms-and-conditions/selectors/terms-and-conditions-dialog/terms-and-conditions-dialog.selectors';

@Injectable()
export class TermsAndConditionsDialogEffects {
  constructor(
    private actions: Actions,
    private store: Store<TermsAndConditionsDialogState>,
    private termsAndConditionsService: TermsAndConditionsService,
    private termsAndConditionsDialogService: TermsAndConditionsDialogService,
    private toastService: ToastService,
    private analyticsService: AnalyticsService
  ) {}

  openTermsAndConditionsDialog = createEffect(
    () =>
      this.actions.pipe(
        ofType(openTermsAndConditionsDialog),
        tap(({ projectNrn, viewTermsAndconditions, actionOnSuccess }) =>
          this.termsAndConditionsDialogService.open(projectNrn, viewTermsAndconditions, actionOnSuccess)
        )
      ),
    { dispatch: false }
  );

  closeTermsAndConditionsDialog = createEffect(
    () =>
      this.actions.pipe(
        ofType(closeTermsAndConditionsDialog),
        tap(() => this.termsAndConditionsDialogService.close())
      ),
    {
      dispatch: false
    }
  );

  getTermsAndConditionsContent = createEffect(() =>
    this.actions.pipe(
      ofType(getTermsAndConditionsContent),
      switchMap(() =>
        this.termsAndConditionsService.getTermsAndConditionsContent().pipe(
          map((getTermsAndConditionsContentResponse: LoadTermsAndConditionsResponse) =>
            getTermsAndConditionsContentSuccess({
              content: getTermsAndConditionsContentResponse ? getTermsAndConditionsContentResponse.content : null
            })
          ),
          catchError(() => of(getTermsAndConditionsContentFailed()))
        )
      )
    )
  );

  loadTermsAndConditions = createEffect(() =>
    this.actions.pipe(
      ofType(loadTermsAndConditions),
      switchMap(({ payload }) =>
        this.termsAndConditionsService.loadTermsAndConditionsDetails(payload).pipe(
          map((loadTermsAndConditionsResponse: LoadTermsAndConditionsResponse) =>
            loadTermsAndConditionsDetailsSuccess({
              nrn: loadTermsAndConditionsResponse.nrn || null,
              content: loadTermsAndConditionsResponse.content || null,
              accepted: loadTermsAndConditionsResponse.accepted || null
            })
          ),
          catchError(() => of(loadTermsAndConditionsDetailsFailed()))
        )
      )
    )
  );

  loadTermsAndConditionsUponProjectSelect = createEffect(() =>
    this.actions.pipe(
      ofType(loadTermsAndConditionsUponProjectSelect),
      switchMap(({ payload }) =>
        this.termsAndConditionsService.loadTermsAndConditionsDetails(payload).pipe(
          mergeMap((loadTermsAndConditionsResponse: LoadTermsAndConditionsResponse) =>
            loadTermsAndConditionsResponse.accepted
              ? [
                  selectProject({ projectNrn: payload.referenceNrn }),
                  loadTermsAndConditionsDetailsSuccess({
                    nrn: loadTermsAndConditionsResponse.nrn || null,
                    content: loadTermsAndConditionsResponse.content || null,
                    accepted: loadTermsAndConditionsResponse.accepted || null
                  })
                ]
              : [
                  openTermsAndConditionsDialog({ projectNrn: payload.referenceNrn }),
                  loadTermsAndConditionsDetailsSuccess({
                    nrn: loadTermsAndConditionsResponse.nrn || null,
                    content: loadTermsAndConditionsResponse.content || null,
                    accepted: loadTermsAndConditionsResponse.accepted || null
                  })
                ]
          ),
          catchError((err: HttpErrorResponse) =>
            /*
                There is no consent required for the t&c or access is denied for the t&c
                which means that the t&c feature got disabled
                https://github.com/Newforma/cloud-terms-and-conditions/blob/develop/documentation/designReviews/support_terms_and_conditions/README.md#errors-2
                Here is the refrence the backend code that shows when feature is disabled, a 403 error is thrown:
                https://github.com/Newforma/cloud-terms-and-conditions/blob/999908830283f20ac84d4bab84428d39c47cf71e/assets/lambda-assets/src/controllers/GetTermsAndConditionsContentController.ts#L31
                */

            this.handleLoadTermsAndConditionsDetailsError(err, payload.referenceNrn)
          )
        )
      )
    )
  );

  createTermsAndConditions = createEffect(() =>
    this.actions.pipe(
      ofType(createTermsAndConditions),
      mergeMap(({ payload }) =>
        this.termsAndConditionsService.createTermsAndConditions(payload).pipe(
          tap(() =>
            this.analyticsService.recordEvent(
              AnalyticEventAction.CreateTermsAndConditions,
              AnalyticEventCategory.TermsAndConditions
            )
          ),
          mergeMap((createTermsAndConditionsResponse: CreateTermsAndConditionsResponse) => [
            createTermsAndConditionsSuccess({ payload: createTermsAndConditionsResponse }),
            closeTermsAndConditionsDialog()
          ]),
          catchError(() => {
            this.toastService.displayError('PROJECT.TERMS_AND_CONDITIONS.ERROR.CREATE_FAILURE_ERROR_MESSAGE');
            return of(createTermsAndConditionsFailed());
          })
        )
      )
    )
  );

  acceptTermsAndConditions = createEffect(() =>
    this.actions.pipe(
      ofType(acceptTermsAndConditions),
      withLatestFrom(this.store.select(selectTermsAndConditionsContent)),
      mergeMap(([{ payload }, termsAndConditionsContent]) =>
        this.termsAndConditionsService.acceptTermsAndConditions(payload).pipe(
          tap(() =>
            this.analyticsService.recordEvent(
              AnalyticEventAction.AcceptTermsAndConditions,
              AnalyticEventCategory.TermsAndConditions
            )
          ),
          mergeMap(() => [
            closeTermsAndConditionsDialog(),
            acceptTermsAndConditionsSuccess({
              content: termsAndConditionsContent,
              accepted: payload.accepted
            }),
            payload.actionOnSuccess || selectProject({ projectNrn: payload.projectNrn })
          ]),
          catchError((err: HttpErrorResponse) =>
            /*
                If error status is 403, we treat it as a sccuessful case and open the project
                https://github.com/Newforma/cloud-terms-and-conditions/blob/develop/documentation/designReviews/support_terms_and_conditions/README.md#errors-3
            */
            this.handleAcceptTermsAndConditionsError(err, payload.projectNrn)
          )
        )
      )
    )
  );

  private handleLoadTermsAndConditionsDetailsError(error: HttpErrorResponse, projectNrn: string): Observable<Action> {
    if (error.status === 404 || error.status === 403) {
      return of(selectProject({ projectNrn: projectNrn }));
    }

    this.toastService.displayError('PROJECT.TERMS_AND_CONDITIONS.ERROR.DEFAULT');

    return of(loadTermsAndConditionsDetailsFailed());
  }

  private handleAcceptTermsAndConditionsError(error: HttpErrorResponse, projectNrn: string): Observable<Action> {
    if (error.status === 403) {
      return of(selectProject({ projectNrn: projectNrn }));
    }

    error.status === 409
      ? this.toastService.displayError('PROJECT.TERMS_AND_CONDITIONS.ERROR.ACCEPT_FAILURE_CONFLICT_ERROR_MESSAGE')
      : this.toastService.displayError('PROJECT.TERMS_AND_CONDITIONS.ERROR.DEFAULT');

    return of(closeTermsAndConditionsDialog());
  }
}
