import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ExternalFolder } from '@core/models/external-folder/external-folder.model';
import { Breadcrumbs } from '@shared/models/breadcrumbs/breadcrumbs.model';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent {
  @Input() separator: string;
  @Input() set breadcrumbs(crumbs: Breadcrumbs[]) {
    this.handleBreadcrumbs(crumbs);
  }
  @Output() onExternalFolderSelected = new EventEmitter<ExternalFolder>();
  breadcrumbsWithSeparator: Breadcrumbs[];

  handleBreadcrumbs(crumbs: Breadcrumbs[]) {
    this.breadcrumbsWithSeparator = crumbs.map((crumb: Breadcrumbs, index: number) =>
      index !== 0 ? { ...crumb, separator: this.separator.trim() + ' ' } : crumb
    );
  }

  selectFolder(crumb: Breadcrumbs): void {
    if (crumb.link) {
      this.onExternalFolderSelected.emit({ nrn: crumb.link, name: crumb.name });
    }
  }
}
