import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocalStorageService } from '@core/services/storage/local-storage.service';
import { LocalStorageKey } from '@core/models/storage/local-storage-key.enum';
import { SidebarState } from '@shared/models/sidebarState/sidebar-state.enum';
import { BIG_SCREEN_SIZE, MEDIUM_SCREEN_SIZE, SMALL_SCREEN_SIZE } from '@shared/constants';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnDestroy, AfterViewInit, OnInit {
  @Input() submittalsRoutePath = '';
  @Input() rfisRoutePath = '';
  @Input() isEmailFilingSupported: boolean;
  @Input() isActionItemsSupported: boolean;
  @Input() isSubmittalsSupported: boolean;
  @Input() isRfisSupported: boolean;
  @Input() isEulaSupported: boolean;

  isSidebarOpen = false;
  isUserMenuOpen = false;
  isMobileVersion = false;
  private destroyComponent = new Subject();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private changeDetectorRef: ChangeDetectorRef,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.setSidebarMode();
  }

  ngAfterViewInit(): void {
    this.handleBreakpoints();
  }

  ngOnDestroy(): void {
    this.destroyComponent.next();
    this.destroyComponent.complete();
  }

  toggleSidebar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;

    if (!this.breakpointObserver.isMatched(SMALL_SCREEN_SIZE)) {
      this.localStorageService.setItem(
        LocalStorageKey.SideBarMode,
        this.isSidebarOpen ? SidebarState.Open : SidebarState.Closed
      );
    }

    if (!this.isSidebarOpen) {
      this.isUserMenuOpen = false;
    }
  }

  menuOptionSelected(): void {
    if (this.breakpointObserver.isMatched(SMALL_SCREEN_SIZE)) {
      this.isSidebarOpen = false;
      this.isUserMenuOpen = false;
    }
  }

  toggleUserMenu(isOpen: boolean): void {
    this.isUserMenuOpen = isOpen;
  }

  handleBreakpoints(): void {
    this.breakpointObserver
      .observe([SMALL_SCREEN_SIZE, MEDIUM_SCREEN_SIZE, BIG_SCREEN_SIZE])
      .pipe(takeUntil(this.destroyComponent))
      .subscribe(result => {
        const item = this.localStorageService.getItem(LocalStorageKey.SideBarMode);
        if ((result.breakpoints[BIG_SCREEN_SIZE] || result.breakpoints[MEDIUM_SCREEN_SIZE]) && item) {
          this.isSidebarOpen = item === SidebarState.Open;
          this.isUserMenuOpen = false;
          this.changeDetectorRef.detectChanges();
          return;
        }

        if (result.breakpoints[BIG_SCREEN_SIZE]) {
          this.isSidebarOpen = true;
          this.isUserMenuOpen = false;
        }

        if (result.breakpoints[MEDIUM_SCREEN_SIZE] || result.breakpoints[SMALL_SCREEN_SIZE]) {
          this.isSidebarOpen = false;
          this.isUserMenuOpen = false;
        }

        this.changeDetectorRef.detectChanges();
      });
  }

  private setSidebarMode(): void {
    const item = this.localStorageService.getItem(LocalStorageKey.SideBarMode);
    if (item && !this.breakpointObserver.isMatched(SMALL_SCREEN_SIZE)) {
      this.isSidebarOpen = item === SidebarState.Open;
    }
  }
}
