import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorUtils } from '@shared/utilities/error/error.utils';
import { of } from 'rxjs';
import { ProjectsService } from '@core/services/project/projects.service';
import {
  addRecentProject,
  completeLoadingProjectDetails,
  failLoadingProjectDetails,
  loadProjectDetails,
  loadProjects,
  openTeamProject,
  refreshRecentProjects,
  selectProject,
  updateProjectList,
  updateProjectsError,
  updateRecentProjectNrns
} from '@root/actions/project-list/project-list.actions';
import { Store } from '@ngrx/store';
import { selectProjects, selectRecentProjectNrns } from '@root/selectors/project-list/project-list.selectors';
import { Router } from '@angular/router';
import { RecentProjectsService } from '@projects/services/recent-projects/recent-projects.service';
import { AppRootState } from '@root/reducers';
import { AnalyticsService } from '@core/services/analytics/analytics.service';
import { AnalyticEventAction } from '@shared/models/analytics/analytic-event-action.model';
import { AnalyticEventCategory } from '@shared/models/analytics/analytic-event-category.model';
import { ProjectDetailsResponseModel } from '@shared/models/project/project-details.response.model';

export interface LoadProjectsError {
  isLicensingAccessDeniedError: boolean;
  isLicensingUserNotLicensedError: boolean;
  isTimeoutError: boolean;
  isBadRequestMicrosoftProfile: boolean;
  isError: boolean;
}

@Injectable()
export class ProjectListEffects {
  constructor(
    private store: Store<AppRootState>,
    private actions: Actions,
    private projectsService: ProjectsService,
    private router: Router,
    private recentProjectsService: RecentProjectsService,
    private analyticsService: AnalyticsService
  ) {}

  loadProjects = createEffect(() =>
    this.actions.pipe(
      ofType(loadProjects),
      withLatestFrom(this.store.select(selectProjects)),
      switchMap(([{ actionsToDispatch, forceRefresh }, existingProjects]) =>
        forceRefresh || !existingProjects.length
          ? this.projectsService.getProjects({ disableCache: forceRefresh }).pipe(
              mergeMap(({ projects }) => [
                updateProjectList({ projects }),
                refreshRecentProjects({ projects }),
                ...(actionsToDispatch ? actionsToDispatch : [])
              ]),
              catchError((err: HttpErrorResponse) => {
                const loadProjectsError: LoadProjectsError = {
                  isLicensingAccessDeniedError: ErrorUtils.isLicensingAccessDenied(err),
                  isLicensingUserNotLicensedError: ErrorUtils.isLicensingUserNotLicensed(err),
                  isTimeoutError: ErrorUtils.isTimeout(err),
                  isBadRequestMicrosoftProfile: ErrorUtils.isBadRequestMicrosoftProfile(err),
                  isError: true
                };

                return of(updateProjectsError({ error: loadProjectsError }));
              })
            )
          : [updateProjectList({ projects: existingProjects }), ...(actionsToDispatch ? actionsToDispatch : [])]
      )
    )
  );

  loadProjectDetails = createEffect(() =>
    this.actions.pipe(
      ofType(loadProjectDetails),
      switchMap(({ projectNrn }) =>
        this.projectsService.getProjectDetails(projectNrn).pipe(
          tap(response => this.handleIsViewSupported(response)),
          map(response => completeLoadingProjectDetails({ projectDetails: response })),
          catchError(() => of(failLoadingProjectDetails()))
        )
      )
    )
  );

  refreshRecentProjects = createEffect(() =>
    this.actions.pipe(
      ofType(refreshRecentProjects),
      map(({ projects }) => this.recentProjectsService.loadRecentProjects(projects)),
      map(recentProjectNrns => updateRecentProjectNrns({ recentProjectNrns }))
    )
  );

  addRecentProject = createEffect(() =>
    this.actions.pipe(
      ofType(addRecentProject),
      withLatestFrom(this.store.select(selectRecentProjectNrns), this.store.select(selectProjects)),
      map(([{ recentProjectNrn }, recentProjectNrns, projects]) =>
        !!projects.filter(project => project.nrn === recentProjectNrn).length
          ? this.recentProjectsService.updateRecentProjects(recentProjectNrn, recentProjectNrns)
          : recentProjectNrns
      ),
      map(recentProjectNrns => updateRecentProjectNrns({ recentProjectNrns }))
    )
  );

  selectProject = createEffect(
    () =>
      this.actions.pipe(
        ofType(selectProject),
        tap(({ projectNrn }) => {
          this.router.navigate([`projects/${projectNrn}`]);
        })
      ),
    {
      dispatch: false
    }
  );

  openProjectTeam = createEffect(
    () =>
      this.actions.pipe(
        ofType(openTeamProject),
        tap(({ projectNrn }) => {
          this.analyticsService.recordEvent(AnalyticEventAction.ViewProjectTeam, AnalyticEventCategory.ProjectTeam);
          this.router.navigate([`projects/${projectNrn}/project-team`]);
        })
      ),
    {
      dispatch: false
    }
  );

  private handleIsViewSupported(projectDetails: ProjectDetailsResponseModel): void {
    const isEmailsView = this.router.url.includes('/emails');
    const isSubmittalsView = this.router.url.includes('/submittals');
    const isRfisView = this.router.url.includes('/rfis');

    if (
      (isEmailsView && !this.canAccessEmailsView(projectDetails.capabilities, projectDetails.nrn)) ||
      (isSubmittalsView && !this.canAccessSubmittals(projectDetails.capabilities)) ||
      (isRfisView && !this.canAccessRfis(projectDetails.capabilities))
    ) {
      this.router.navigate([`/projects/${projectDetails.nrn}/files`]);
    }
  }

  private canAccessEmailsView(capabilities: string[], nrn: string): boolean {
    return capabilities.includes('search') && nrn.includes('nrn:ncloud:project');
  }

  private canAccessSubmittals(capabilities: string[]): boolean {
    return capabilities.includes('view_submittals');
  }

  private canAccessRfis(capabilities: string[]): boolean {
    return capabilities.includes('view_rfis');
  }
}
