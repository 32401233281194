import { createSelector } from '@ngrx/store';
import { selectSubmittalsModule } from '@submittals/selectors/submittals-common.selectors';
import { Project } from '@core/models/project/project.model';

const selectSubmittalWorkflowActionFormState = createSelector(
  selectSubmittalsModule,
  state => state.submittalWorkflowActionForm
);

export const selectActiveFormType = createSelector(
  selectSubmittalWorkflowActionFormState,
  state => state.activeActionFormType
);

export const selectIsLoadingWorkflowActionKeywordsList = createSelector(
  selectSubmittalWorkflowActionFormState,
  state => state.isLoadingWorkflowActionKeywords
);

export const selectWorkflowActionKeywordsList = createSelector(
  selectSubmittalWorkflowActionFormState,
  state => state.workflowActionKeywordsList
);

export const selectHasLoadingWorkflowActionKeywordsListError = createSelector(
  selectSubmittalWorkflowActionFormState,
  state => state.hasLoadingWorkflowActionKeywordsListError
);

export const selectReceivedViaKeywordsList = createSelector(
  selectSubmittalWorkflowActionFormState,
  state => state.receivedViaKeywordsList
);

export const selectIsProcessingSubmittalAction = createSelector(
  selectSubmittalWorkflowActionFormState,
  state => state.isProcessingSubmittalAction
);

export const selectIsLoadingSubmittalsProjects = createSelector(
  selectSubmittalWorkflowActionFormState,
  state => state.isLoadingSubmittalsProjects
);

export const selectSubmittalsProjects = createSelector(
  selectSubmittalWorkflowActionFormState,
  state => state.submittalsProjects
);

export const selectHasLoadingSubmittalsProjectsError = createSelector(
  selectSubmittalWorkflowActionFormState,
  state => state.hasLoadingSubmittalsProjectsError
);

export const selectSubmittalProjectListViewModel = createSelector(
  selectSubmittalsProjects,
  selectIsLoadingSubmittalsProjects,
  selectHasLoadingSubmittalsProjectsError,
  (projects, isLoading, hasError) => ({
    items: projects.map((project: Project) => ({
      nrn: project.nrn,
      name: project.name,
      description: project.number,
      type: project.type
    })),
    isLoading,
    hasError
  })
);

export const selectEnablePurposeSelector = createSelector(
  selectSubmittalWorkflowActionFormState,
  state => !state.isLoadingWorkflowActionKeywords && !state.hasLoadingWorkflowActionKeywordsListError
);

export const selectIsLogFormDirty = createSelector(
  selectSubmittalWorkflowActionFormState,
  state => state.isLogFormDirty
);
