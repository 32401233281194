import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiUrlProvider } from '@core/utilities/apiUrl/apiUrl.provider';
import { switchMap } from 'rxjs/operators';
import { Paging } from '@shared/models/paging/paging.model';
import { EventModel } from '@shared/models/events/events-model';
import { Activity } from '@shared/models/activities/activities.model';

export interface GetActivitiesResponseModel {
  events: Activity[];
  paging?: Paging;
}

export interface GetEventsResponseModel {
  events: EventModel[];
  paging?: Paging;
}

@Injectable()
export class ActivityService {
  constructor(private http: HttpClient, private apiUrlProvider: ApiUrlProvider) {}

  private readonly defaultMaxPageSize = 50;

  loadActivities(
    nrn: string,
    delayInMs: number = 0,
    offsetToken: string | null = null
  ): Observable<GetEventsResponseModel | GetActivitiesResponseModel> {
    const params = this.getParams(nrn, offsetToken);
    return timer(delayInMs).pipe(
      switchMap(() =>
        this.http.get<GetEventsResponseModel | GetActivitiesResponseModel>(`${this.getUrl()}/events`, { params })
      )
    );
  }

  private getUrl(): string {
    return this.apiUrlProvider.getUrl();
  }

  private getParams(nrn, offsetToken): HttpParams {
    let params = new HttpParams().set('nrn', nrn);
    params = params.set('maxSize', this.defaultMaxPageSize.toString());
    if (offsetToken) {
      params = params.set('offsetToken', offsetToken);
    }

    return params;
  }
}
