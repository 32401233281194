import { Injectable } from '@angular/core';
import { LicenseType } from '@core/models/project/licenseType.enum';
import { Nrns } from '@newforma/npm-nrn';
import { basicLicenseNrn, constructionAdministrationNrn } from '@user-administration/services/license-tiers-helper';

@Injectable()
export class NrnService {
  private static specialFolderNrnPrefix = 'nrn:file:folder:special:npc';
  private readonly actionItemsSpecialFolder = 'fieldnotes';
  private readonly nrnResolverCache = new Map<string, any>();

  isAbsoluteFileNrn(nrn: string): boolean {
    return (
      Nrns.isValidNrnWithPrefix(nrn, Nrns.defaultSchemaPrefixes.fileNPC) ||
      Nrns.isValidNrnWithPrefix(nrn, Nrns.defaultSchemaPrefixes.fileCloud) ||
      Nrns.isValidNrnWithPrefix(nrn, Nrns.defaultSchemaPrefixes.fileShared)
    );
  }

  isAbsoluteFolderNrn(nrn: string): boolean {
    return (
      Nrns.isValidNrnWithPrefix(nrn, Nrns.defaultSchemaPrefixes.folderNPC) ||
      Nrns.isValidNrnWithPrefix(nrn, Nrns.defaultSchemaPrefixes.folderCloud) ||
      Nrns.isValidNrnWithPrefix(nrn, Nrns.defaultSchemaPrefixes.folderShared)
    );
  }

  getAbsoluteNrn(nrn: string, relativeToNrn: string): string {
    if (!this.nrnResolverCache.has(relativeToNrn)) {
      this.nrnResolverCache.set(relativeToNrn, Nrns.getNrnResolver(relativeToNrn));
    }

    const resolver = this.nrnResolverCache.get(relativeToNrn);
    return resolver.resolve(nrn);
  }

  getParentNrn(folderNrn: string): string {
    const parsedFolderNrn = Nrns.parseNrn(folderNrn);
    const parentNamePart = parsedFolderNrn.getArrayArgument(Nrns.defaultSchemaArguments.namePart)?.slice(0, -1) ?? [];

    const newNrnValues = new Map(parsedFolderNrn.arguments);
    newNrnValues.set(Nrns.defaultSchemaArguments.namePart, parentNamePart);

    return Nrns.create(folderNrn, newNrnValues);
  }

  isDirectlyWithinRootFolder(parentFolderNrn: string): boolean {
    const nrnParts = Nrns.parseNrn(parentFolderNrn);
    const nameParts = nrnParts.getArrayArgument(Nrns.defaultSchemaArguments.namePart) as string[];
    const isSharedFolder = Nrns.isValidNrnWithPrefix(parentFolderNrn, Nrns.defaultSchemaPrefixes.folderShared);

    return nameParts?.length === 1 && !isSharedFolder;
  }

  getSpecialFolderNrn(actionItemNrn: string): string {
    const nrnParts: Map<string, any> = Nrns.parseNrn(actionItemNrn).arguments;
    nrnParts.delete(Nrns.defaultSchemaArguments.actionItemId);
    nrnParts.set(Nrns.defaultSchemaArguments.specialFolderType, this.actionItemsSpecialFolder);

    return Nrns.create(NrnService.specialFolderNrnPrefix, nrnParts);
  }

  isProjectNrn(nrn: string): boolean {
    return (
      Nrns.isValidNrnWithPrefix(nrn, Nrns.defaultSchemaPrefixes.projectNPC) ||
      Nrns.isValidNrnWithPrefix(nrn, Nrns.defaultSchemaPrefixes.projectCloud) ||
      Nrns.isValidNrnWithPrefix(nrn, Nrns.defaultSchemaPrefixes.projectShared)
    );
  }

  getFolderNameFromNrn(nrn: string): string {
    return this.isProjectNrn(nrn) ? '' : <string>nrn.split(':').pop();
  }

  getLicenseTypeFromNrn(nrn: string): LicenseType {
    switch (nrn) {
      case basicLicenseNrn:
        return LicenseType.Basic;
      case constructionAdministrationNrn:
        return LicenseType.ConstructionAdministration;
      default:
        return LicenseType.NotSupported;
    }
  }

  getNrnForLicenseType(licenseType: LicenseType): string {
    switch (licenseType) {
      case LicenseType.Basic:
        return basicLicenseNrn;
      case LicenseType.ConstructionAdministration:
        return constructionAdministrationNrn;
      default:
        return '';
    }
  }
}
