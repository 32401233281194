import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ApiUrlProvider } from '@core/utilities/apiUrl/apiUrl.provider';
import { SharedItemDetailsModel } from '@core/models/shared-item/shared-item.details.model';
import { map, mergeMap } from 'rxjs/operators';
import { DownloadService } from '@core/services/download/download.service';
import { SharedFolderRequestModel } from '@core/models/shared-item/shared-item.sharefolderrequest.model';
import { SharedItemShareType } from '@core/models/shared-item/shared-item.share.type.enum';
import { SharedFolderResponseModel } from '@core/models/shared-item/shared-item.sharefolderresponse.model';
import { SharedItemResponseModel } from '@core/models/shared-item/shared-item.response.model';
import { SharePermission } from '@core/models/shared-item/share-permission.model';
import { GetFileLocationResponse } from '@core/services/download/download.models';
import { Recipient } from '@core/models/shared-item/recipient.model';
import { UserDetailsWithPermissionsModel } from '@core/models/shared-item/user-details.with-permissions.model';
import { SharedItemMapperService } from '@core/services/shared-item-mapper/shared-item-mapper.service';
import { SharedFileResponseModel } from '@core/models/shared-item/shared-item.sharefileresponse.model';
import { SharedFileRequestModel } from '@core/models/shared-item/shared-item.sharefilerequest.model';

@Injectable()
export class SharedItemService {
  private readonly sharedItemsEndpointPart = 'shareditems';
  private readonly sharedFoldersEndpointPart = 'sharedfolders';
  private readonly detailsEndpointPart = 'details';
  private readonly directEndpointPart = 'direct';

  constructor(
    private http: HttpClient,
    private apiUrlProvider: ApiUrlProvider,
    private downloadService: DownloadService,
    private sharedItemMapperService: SharedItemMapperService
  ) {}

  fetchSharedItemDetails(nrn: string): Observable<SharedItemDetailsModel> {
    const url = `${this.apiUrlProvider.getUrl()}/${this.sharedItemsEndpointPart}/${encodeURIComponent(nrn)}/${
      this.detailsEndpointPart
    }/${this.directEndpointPart}`;
    return this.http.get<SharedItemDetailsModel>(url);
  }

  downloadSharedItem(sharedItemNrn: string, fileSnapshotNrn: string): Observable<any> {
    return this.getSharedItemLocation(sharedItemNrn, fileSnapshotNrn).pipe(
      mergeMap(location => this.downloadService.nativeDownloadFile(location))
    );
  }

  shareFolder(
    folderNrn: string,
    recipients: Recipient[],
    permission: SharePermission,
    note: string
  ): Observable<HttpResponse<SharedFolderResponseModel>> {
    const url = `${this.apiUrlProvider.getUrl()}/${this.sharedItemsEndpointPart}`;

    const body = <SharedFolderRequestModel>{
      nrns: [folderNrn],
      shareType: SharedItemShareType.ExternalFolderShare,
      recipients: recipients,
      permissions: permission,
      note: note
    };

    return this.http.post<SharedFolderResponseModel>(url, body, { observe: 'response' });
  }

  shareFile(fileNrn: string): Observable<SharedFileResponseModel> {
    const url = `${this.apiUrlProvider.getUrl()}/${this.sharedItemsEndpointPart}`;
    const body = <SharedFileRequestModel>{
      nrns: [fileNrn],
      shareType: SharedItemShareType.ExternalShare
    };

    return this.http.post<SharedFileResponseModel>(url, body);
  }

  getSharedItems(subjectNrn: string): Observable<UserDetailsWithPermissionsModel[]> {
    const options = {
      params: {
        subjectNrn
      }
    };

    const url = `${this.apiUrlProvider.getUrl()}/${this.sharedItemsEndpointPart}`;

    return this.http
      .get<SharedItemResponseModel>(url, options)
      .pipe(map(this.sharedItemMapperService.mapShareRecipients.bind(this.sharedItemMapperService)));
  }

  changeSharedFolderPermission(sharedItemNrn: string, permission: SharePermission): Observable<Object> {
    const body = {
      permissions: permission
    };

    const url = `${this.apiUrlProvider.getUrl()}/${this.sharedItemsEndpointPart}/${encodeURIComponent(sharedItemNrn)}`;

    return this.http.patch(url, body);
  }

  revokeFolderShare(sharedItemNrn: string): Observable<Object> {
    const url = `${this.apiUrlProvider.getUrl()}/${this.sharedFoldersEndpointPart}/${encodeURIComponent(
      sharedItemNrn
    )}`;

    return this.http.delete(url);
  }

  revokeFileShare(sharedItemNrn: string): Observable<Object> {
    const params = {
      params: {
        subjectNrn: sharedItemNrn
      }
    };

    const url = `${this.apiUrlProvider.getUrl()}/${this.sharedItemsEndpointPart}`;

    return this.http.delete(url, params);
  }

  private getSharedItemLocation(sharedItemNrn: string, fileSnapshotNrn: string): Observable<GetFileLocationResponse> {
    const url = `${this.apiUrlProvider.getUrl()}/${this.sharedItemsEndpointPart}/${encodeURIComponent(sharedItemNrn)}/${
      this.detailsEndpointPart
    }/${encodeURIComponent(fileSnapshotNrn)}/${this.directEndpointPart}`;

    return this.http.get<GetFileLocationResponse>(url);
  }
}
