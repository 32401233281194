export class DateUtils {
  private static readonly dayInMS = 1000 * 60 * 60 * 24;

  static get Now(): Date {
    return new Date(Date.now());
  }

  static get Today(): Date {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return now;
  }

  static diffInDaysUTC(firstDate: Date, secondDate: Date): number {
    const firstDateUtc = Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate());
    const secondDateUtc = Date.UTC(secondDate.getFullYear(), secondDate.getMonth(), secondDate.getDate());

    return Math.floor((secondDateUtc - firstDateUtc) / this.dayInMS);
  }

  static diffInDays(firstDate: Date, secondDate: string): number {
    const parsedSecondDate = new Date(secondDate);
    parsedSecondDate.setDate(parsedSecondDate.getDate() + 1);

    const diffTime = parsedSecondDate.getTime() - firstDate.getTime();

    return Math.floor(diffTime / this.dayInMS);
  }

  static addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
  }

  static isSame(firstDate: Date, secondDate: Date): boolean {
    return (
      firstDate.getFullYear() === secondDate.getFullYear() &&
      firstDate.getMonth() === secondDate.getMonth() &&
      firstDate.getDate() === secondDate.getDate()
    );
  }

  static getDayEndUtc(date?: Date | null): Date {
    const now = date ? new Date(date) : new Date();
    now.setUTCHours(23, 59, 59, 999);
    return now;
  }

  static getDayStartUtc(date?: Date | null): Date {
    const now = date ? new Date(date) : new Date();
    now.setUTCHours(0, 0, 0, 0);
    return now;
  }
}
