<div
  class="display-flex"
>
  <span
    class="center-counter"
  >
    {{ rowCounterTitle }}
  </span>
  <span
    class="center-counter counter-value"
  >
    {{ rowCounter || '-' }}
  </span>
</div>
