export const eventTypesTranslations = {
  /* eslint-disable @typescript-eslint/naming-convention */
  SHARE_CREATED: 'EVENTS.SHARE_CREATED',
  SHARE_REVOKE_ALL: 'EVENTS.SHARE_REVOKE_ALL',
  SHARE_FILE_DOWNLOADED: 'EVENTS.SHARE_FILE_DOWNLOADED',
  FOLDER_SHARE_CREATED: 'EVENTS.FOLDER_SHARE_CREATED',
  PERMISSIONS_CHANGE: 'EVENTS.PERMISSIONS_CHANGE',
  FILE_DELETED: 'EVENTS.FILE_DELETED',
  FILE_DOWNLOADED: 'EVENTS.FILE_DOWNLOADED',
  FILE_UPLOADED: 'EVENTS.FILE_UPLOADED',
  FOLDER_CREATED: 'EVENTS.FOLDER_CREATED',
  FOLDER_SHARE_REVOKED: 'EVENTS.FOLDER_SHARE_REVOKED',
  FOLDER_DELETED: 'EVENTS.FOLDER_DELETED',
  FILE_RENAMED: 'EVENTS.FILE_RENAMED',
  FOLDER_RENAMED: 'EVENTS.FOLDER_RENAMED',
  FILE_BULK_DOWNLOADED: 'EVENTS.FILE_BULK_DOWNLOADED',
  FOLDER_BULK_DOWNLOADED: 'EVENTS.FOLDER_BULK_DOWNLOADED',
  SHARED_FILE_BULK_DOWNLOADED: 'EVENTS.SHARED_FILE_BULK_DOWNLOADED',
  SHARED_FOLDER_BULK_DOWNLOADED: 'EVENTS.SHARED_FOLDER_BULK_DOWNLOADED',
  SUBMITTAL_RECEIVED: 'EVENTS.SUBMITTAL_RECEIVED',
  SUBMITTAL_FORWARDED: 'EVENTS.SUBMITTAL_FORWARDED',
  SUBMITTAL_REVIEW_RESPONSE: 'EVENTS.SUBMITTAL_REVIEW_RESPONSE',
  SUBMITTAL_RESPONDED: 'EVENTS.SUBMITTAL_RESPONDED',
  SUBMITTAL_CLOSED: 'EVENTS.SUBMITTAL_CLOSED',
  SUBMITTAL_DELETED: 'EVENTS.SUBMITTAL_DELETED',
  SUBMITTAL_MODIFIED: 'EVENTS.SUBMITTAL_MODIFIED',
  SUBMITTAL_FILE_TRANSFER_FAILED: 'EVENTS.SUBMITTAL_FILE_TRANSFER_FAILED',
  SUBMITTAL_FILE_DOWNLOADED: 'EVENTS.SUBMITTAL_FILE_DOWNLOADED',
  RFI_RECEIVED: 'EVENTS.RFI_RECEIVED',
  RFI_FORWARDED: 'EVENTS.RFI_FORWARDED',
  RFI_REVIEW_RESPONSE: 'EVENTS.RFI_REVIEW_RESPONSE',
  RFI_RESPONDED: 'EVENTS.RFI_RESPONDED',
  RFI_CLOSED: 'EVENTS.RFI_CLOSED',
  RFI_DELETED: 'EVENTS.RFI_DELETED',
  RFI_MODIFIED: 'EVENTS.RFI_MODIFIED',
  RFI_FILE_TRANSFER_FAILED: 'EVENTS.RFI_FILE_TRANSFER_FAILED',
  RFI_FILE_DOWNLOADED: 'EVENTS.RFI_FILE_DOWNLOADED'
  /* eslint-enable @typescript-eslint/naming-convention */
};

export const fieldTypeNameTranslations = {
  name: 'EVENTS.NAME_CHANGED',
  number: 'EVENTS.NUMBER_CHANGED',
  dueDate: 'EVENTS.DUE_DATE_CHANGED',
  senderNumber: 'EVENTS.SENDER_NUMBER_CHANGED',
  disciplines: 'EVENTS.DISCIPLINES_CHANGED',
  keywords: 'EVENTS.KEYWORDS_CHANGED',
  specSection: 'EVENTS.SPEC_SECTION_CHANGED',
  receivedDate: 'EVENTS.RECEIVED_DATE_CHANGED',
  description: 'EVENTS.DESCRIPTION_CHANGED',
  internalNotes: 'EVENTS.INTERNAL_NOTES_CHANGED',
  impactSchedule: 'EVENTS.IMPACT_SCHEDULE_CHANGED',
  impactCost: 'EVENTS.IMPACT_COST_CHANGED',
  reasons: 'EVENTS.REASONS_CHANGED',
  types: 'EVENTS.TYPES_CHANGED',
  question: 'EVENTS.QUESTION_CHANGED',
  suggestion: 'EVENTS.SUGGESTION_CHANGED',
  subject: 'EVENTS.SUBJECT_CHANGED',
  urgent: 'EVENTS.URGENT_CHANGED'
};
