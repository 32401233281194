import { Injectable } from '@angular/core';
import { LoginResponse } from '@core/models/authentication/loginResponse.model';
import { LoginRequest } from '@core/models/authentication/loginRequest.model';
import { IdentityProvider } from '@core/models/authentication/identityProvider.model';
import { WindowService } from '@core/services/window/window.service';
import { PartialUserInfo } from '@core/models/authentication/partialUserInfo';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthenticationCredentialProvider {
  private readonly accessKeyId: string = 'accessKeyId';
  private readonly secretAccessKey: string = 'secretAccessKey';
  private readonly sessionToken: string = 'sessionToken';
  private readonly sessionTokenRequest: string = 'sessionTokenRequest';
  private readonly expiration: string = 'expiration';
  private readonly signatureScope: string = 'signatureScope';
  private readonly identityProvider: string = 'identityProvider';
  private readonly tenantId: string = 'tenantId';
  private readonly isRegionRequired: string = 'isRegionRequired';
  private readonly isEulaRequired: string = 'isEulaRequired';
  private readonly email: string = 'email';
  private readonly unavailableTokenErrorMessage = 'Cannot find session token';

  private loggedInUserEmailSubject = new BehaviorSubject<string | null>(this.getEmail());
  loggedInUserEmail = this.loggedInUserEmailSubject.asObservable();

  constructor(private windowService: WindowService) {}

  storeCredential(credential: LoginResponse, loginRequest: LoginRequest): void {
    localStorage.setItem(this.accessKeyId, credential.accessKeyId);
    localStorage.setItem(this.secretAccessKey, credential.secretAccessKey);
    localStorage.setItem(this.sessionToken, credential.sessionToken);
    localStorage.setItem(this.expiration, credential.expiration);
    localStorage.setItem(this.sessionTokenRequest, loginRequest.idToken);
    localStorage.setItem(this.signatureScope, credential.signatureScope);
  }

  releaseStoredCredentials(): void {
    localStorage.removeItem(this.accessKeyId);
    localStorage.removeItem(this.secretAccessKey);
    localStorage.removeItem(this.sessionToken);
    localStorage.removeItem(this.expiration);
    localStorage.removeItem(this.sessionTokenRequest);
    localStorage.removeItem(this.signatureScope);
    localStorage.removeItem(this.identityProvider);
    localStorage.removeItem(this.tenantId);
    localStorage.removeItem(this.isRegionRequired);
    localStorage.removeItem(this.email);
    localStorage.removeItem(this.isEulaRequired);
  }

  getAccessKeyId(): string | null {
    return localStorage.getItem(this.accessKeyId);
  }

  getSecretAccessKey(): string | null {
    return localStorage.getItem(this.secretAccessKey);
  }

  getSessionToken(): string | null {
    return localStorage.getItem(this.sessionToken);
  }

  getExpiration(): Date | null {
    const expirationTime = localStorage.getItem(this.expiration);
    return expirationTime ? new Date(expirationTime) : null;
  }

  getSignatureScope(): string | null {
    return localStorage.getItem(this.signatureScope);
  }

  getSessionTokenRequest(): string | null {
    return localStorage.getItem(this.sessionTokenRequest);
  }

  setSessionTokenRequest(sessionTokenRequest: string): void {
    localStorage.setItem(this.sessionTokenRequest, sessionTokenRequest);
  }

  getIdentityProvider(): IdentityProvider | null {
    return JSON.parse(localStorage.getItem(this.identityProvider) as string);
  }

  setIdentityProvider(identityProvider: IdentityProvider): void {
    localStorage.setItem(this.identityProvider, JSON.stringify(identityProvider));
  }

  getTenantId(): string | null {
    return localStorage.getItem(this.tenantId);
  }

  setTenantId(tenantId: string): void {
    localStorage.setItem(this.tenantId, tenantId);
  }

  getIsRegionRequired(): boolean {
    return localStorage.getItem(this.isRegionRequired) === 'true';
  }

  setIsRegionRequired(isRegionRequired: boolean): void {
    localStorage.setItem(this.isRegionRequired, isRegionRequired.toString());
  }

  getActiveUserEmail(): string | undefined {
    const sessionToken = this.getSessionTokenRequest();
    if (!sessionToken) {
      return;
    }
    const userInfo = this.windowService.getUserInfo(sessionToken);

    return userInfo.unique_name ? userInfo.unique_name : userInfo.preferred_username;
  }

  getEmail(): string | null {
    return localStorage.getItem(this.email);
  }

  setEmail(email: string): void {
    this.loggedInUserEmailSubject.next(email);
    localStorage.setItem(this.email, email);
  }

  getLoggedInUserInfo(): PartialUserInfo {
    const token = this.getSessionTokenRequest();
    if (!token) {
      throw new Error(this.unavailableTokenErrorMessage);
    }
    return this.windowService.getUserInfo(token);
  }
}
