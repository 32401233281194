import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { UserService } from '@core/services/user/user.service';
import {
  loadUserLicenseCompleted,
  loadUserLicenseFailed,
  refreshUser,
  updateUser,
  updateUserComplete,
  updateUserFailed
} from '@root/actions/user/user.actions';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { ConfigurationService } from '@core/services/configuration/configuration.service';
import { UserDetailsLicense, UserLicenseResponseModel } from '@core/models/user/user-license-response.model';
import { UpdateUserModel, UpdateUserRequestData } from '@core/models/user/user.model';
import { AnalyticEventAction } from '@shared/models/analytics/analytic-event-action.model';
import { AnalyticEventCategory } from '@shared/models/analytics/analytic-event-category.model';
import { AnalyticsService } from '@core/services/analytics/analytics.service';
import { ToastService } from '@core/services/toast/toast.service';

@Injectable()
export class UserEffects {
  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private configurationService: ConfigurationService,
    private analyticsService: AnalyticsService,
    private toastService: ToastService,
    private actions: Actions
  ) {}

  loadUserLicense = createEffect(() =>
    combineLatest([
      this.authenticationService.isLogged.pipe(filter(isLogged => isLogged)),
      this.configurationService.configurationLoaded.pipe(filter(isLoaded => isLoaded))
    ]).pipe(map(() => refreshUser()))
  );

  refreshUser = createEffect(() =>
    this.actions.pipe(
      ofType(refreshUser),
      mergeMap(() =>
        this.userService.getUser().pipe(
          map(userLicenseResponse =>
            loadUserLicenseCompleted(this.mapUserLicenseModelToUserDetailsLicense(userLicenseResponse))
          ),
          catchError(() => of(loadUserLicenseFailed()))
        )
      )
    )
  );

  updateUserDetails = createEffect(() =>
    this.actions.pipe(
      ofType(updateUser),
      mergeMap(userDetails =>
        this.userService
          .updateUserProfile(
            this.mapUpdateUserModelToUpdateUserRequestData(userDetails.updateUserModel),
            userDetails.updateUserModel.nrn
          )
          .pipe(
            tap(() => {
              this.analyticsService.recordEvent(
                AnalyticEventAction.UpdateUserProfile,
                AnalyticEventCategory.ViewProfile
              );
            }),
            mergeMap(() => [refreshUser(), updateUserComplete()]),
            catchError(() => {
              this.toastService.displayError('VIEW_PROFILE_DIALOG.ERROR');
              return of(updateUserFailed());
            })
          )
      )
    )
  );

  private mapUpdateUserModelToUpdateUserRequestData = (updateUserModel: UpdateUserModel): UpdateUserRequestData => ({
    discipline: updateUserModel.discipline,
    firstName: updateUserModel.firstName,
    lastName: updateUserModel.lastName,
    phoneNumber: updateUserModel.phoneNumber
  });

  private mapUserLicenseModelToUserDetailsLicense = (
    userLicenseResponse: UserLicenseResponseModel
  ): UserDetailsLicense => ({
    user: {
      nrn: userLicenseResponse.nrn,
      email: userLicenseResponse.email,
      firstName: userLicenseResponse.firstName,
      lastName: userLicenseResponse.lastName,
      phoneNumber: userLicenseResponse.phoneNumber,
      discipline: userLicenseResponse.discipline
    },
    license: {
      customer: userLicenseResponse.customer,
      licenseTier: userLicenseResponse.licenseTier
    }
  });
}
