<div
  class="app-wrapper"
  [class.loggedIn]="isLogged && isAuthenticatedConfigurationLoaded && !isDashboardRoute"
  [class.dashboard-wrapper]="isDashboardRoute"
>
  <app-sidebar
    id="sidebar-menu"
    *ngIf="isLogged && isApplicationLoaded"
    [isSubmittalsSupported]="isSubmittalsSupported$ | async"
    [isRfisSupported]="isRfisSupported$ | async"
    [isActionItemsSupported]="isActionItemsSupported$ | async"
    [isEmailFilingSupported]="isEmailFilingSupported$ | async"
    [isEulaSupported]="isEulaSupported$ | async"
    [submittalsRoutePath]="submittalsRoutePath"
    [rfisRoutePath]="rfisRoutePath"
  ></app-sidebar>
  <div class="main-content-wrapper" #mainContent>
    <div
      *ngIf="isApplicationLoaded"
      class="content"
      [class.dashboard-content]="isDashboardRoute"
    >
      <router-outlet></router-outlet>
    </div>
    <app-user-voice-snippet></app-user-voice-snippet>
    <app-walk-me-snippet></app-walk-me-snippet>
    <div
      *ngIf="!isApplicationLoaded"
      fxLayout
      fxLayoutAlign="center center"
      class="scrolling-spinner"
    >
      <app-spinner
        color="white"
        diameter="75"
      ></app-spinner>
    </div>
  </div>
</div>
