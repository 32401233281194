import { Project } from '@core/models/project/project.model';
import { createSelector } from '@ngrx/store';
import { selectRfisModule } from '@rfis/selectors/rfis-common.selectors';

const selectRfiWorkflowActionFormState = createSelector(selectRfisModule, state => state.rfiWorkflowActionForm);

export const selectIsProcessingLogRfi = createSelector(
  selectRfiWorkflowActionFormState,
  state => state.isProcessingRfiAction
);

export const selectIsLogFormDirty = createSelector(selectRfiWorkflowActionFormState, state => state.isLogFormDirty);

export const selectIsUploadInProgress = createSelector(
  selectRfiWorkflowActionFormState,
  state => state.isUploadInProgress
);

export const selectActiveFormType = createSelector(
  selectRfiWorkflowActionFormState,
  state => state.activeActionFormType
);

export const selectIsProcessingRfiAction = createSelector(
  selectRfiWorkflowActionFormState,
  state => state.isProcessingRfiAction
);

export const selectActiveFormTabType = createSelector(
  selectRfiWorkflowActionFormState,
  state => state.activeFormTabType
);

export const selectWorkflowActionKeywordsList = createSelector(
  selectRfiWorkflowActionFormState,
  state => state.workflowActionKeywordsList
);

export const selectEnableActionSelector = createSelector(
  selectRfiWorkflowActionFormState,
  state => !state.isLoadingWorkflowActionKeywords && !state.hasLoadingWorkflowActionKeywordsListError
);

export const selectIsLoadingWorkflowActionKeywordsList = createSelector(
  selectRfiWorkflowActionFormState,
  state => state.isLoadingWorkflowActionKeywords
);

export const selectHasLoadingWorkflowActionKeywordsListError = createSelector(
  selectRfiWorkflowActionFormState,
  state => state.hasLoadingWorkflowActionKeywordsListError
);

export const selectIsLoadingRfisProjects = createSelector(
  selectRfiWorkflowActionFormState,
  state => state.isLoadingRfisProjects
);

export const selectRfisProjects = createSelector(selectRfiWorkflowActionFormState, state => state.rfisProjects);

export const selectHasLoadingRfisProjectsError = createSelector(
  selectRfiWorkflowActionFormState,
  state => state.hasLoadingRfisProjectsError
);

export const selectRfiProjectListViewModel = createSelector(
  selectRfisProjects,
  selectIsLoadingRfisProjects,
  selectHasLoadingRfisProjectsError,
  (projects, isLoading, hasError) => ({
    items: projects.map((project: Project) => ({
      nrn: project.nrn,
      name: project.name,
      description: project.number
    })),
    isLoading,
    hasError
  })
);
