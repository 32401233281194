import { KeywordModel } from '@core/models/project/keyword.model';
import { createAction, props } from '@ngrx/store';

const prefix = '[KEYWORDS]';

export const loadDisciplines = createAction(`${prefix} LOAD_DISCIPLINES`);

export const loadDisciplinesCompleted = createAction(
  `${prefix} LOAD_DISCIPLINES_COMPLETED`,
  props<{ disciplines: KeywordModel[] }>()
);

export const loadDisciplinesFailed = createAction(`${prefix} LOAD_DISCIPLINES_FAILED`);
