import { updateObject } from '@app/architecture';
import { Action, createReducer, on } from '@ngrx/store';
import {
  closeProjectFoldersDialog,
  completeLoadingExternalFolders,
  completeLoadingExternalFoldersNextPage,
  createProjectFolder,
  createProjectFolderFailed,
  createProjectFolderSuccess,
  initProjectFolders,
  loadCurrentPageIndex,
  loadExternalFolders,
  loadExternalFoldersNextPage,
  loadNavigation,
  openProjectFoldersDialog,
  selectExternalFolder,
  setProjectFoldersCapabilities,
  updateLoadExternalFoldersError
} from '@root/actions/project-folders/project-folders.actions';
import { DialogError } from '@shared/models/project-folders/dialog-error.type';
import { ExternalFoldersResponse } from '@core/models/external-folder/external-folders.response.model';
import { ExternalFolder } from '@core/models/external-folder/external-folder.model';

export interface ProjectFoldersState {
  projectCapabilities: string[];
  loadingError: DialogError | null;
  searchQuery: string | null;
  externalFolders: ExternalFolder[];
  offsetToken: string | null;
  isLoading: boolean;
  isLoadingNextPage: boolean;
  selectedExternalFolder: string | null;
  navigation: ExternalFolder[];
  pageIndex: number | null;
  isConnecting: boolean;
  isEditMode: boolean;
  hasFolderConnected: boolean;
}

export const defaultProjectFoldersState: ProjectFoldersState = {
  projectCapabilities: [],
  loadingError: null,
  searchQuery: null,
  externalFolders: [],
  offsetToken: null,
  isLoading: false,
  isLoadingNextPage: false,
  selectedExternalFolder: null,
  navigation: [],
  pageIndex: null,
  isConnecting: false,
  isEditMode: false,
  hasFolderConnected: false
};

const reducer = createReducer(
  defaultProjectFoldersState,
  on(setProjectFoldersCapabilities, (state, { capabilities }) =>
    updateObject(state, {
      projectCapabilities: capabilities.filter(el => el.includes('connect_root_folder'))
    })
  ),
  on(openProjectFoldersDialog, state =>
    updateObject(state, {
      selectedExternalFolder: null,
      loadingError: null
    })
  ),
  on(closeProjectFoldersDialog, state =>
    updateObject(state, {
      ...defaultProjectFoldersState
    })
  ),
  on(loadExternalFolders, (state, { query }) =>
    updateObject(state, {
      searchQuery: query,
      selectedExternalFolder: null,
      isLoading: true,
      loadingError: null
    })
  ),
  on(loadExternalFoldersNextPage, state =>
    updateObject(state, {
      isLoadingNextPage: true,
      loadingError: null
    })
  ),
  on(completeLoadingExternalFolders, handleCompleteLoadingUserItems),
  on(completeLoadingExternalFoldersNextPage, handleCompleteLoadingUserNextPageItems),
  on(loadNavigation, (state, { navigation }) => updateObject(state, { navigation: navigation, loadingError: null })),
  on(loadCurrentPageIndex, (state, { pageIndex }) => updateObject(state, { pageIndex: pageIndex, loadingError: null })),
  on(selectExternalFolder, (state, { nrn }) =>
    updateObject(state, { selectedExternalFolder: nrn, loadingError: null })
  ),
  on(updateLoadExternalFoldersError, (state, { error }) =>
    updateObject(state, {
      loadingError: error,
      isLoading: false,
      isLoadingNextPage: false
    })
  ),
  on(createProjectFolder, state => updateObject(state, { isConnecting: true })),
  on(createProjectFolderSuccess, createProjectFolderFailed, state => updateObject(state, { isConnecting: false })),
  on(initProjectFolders, (state, { isEditMode, hasFolderConnected }) =>
    updateObject(state, {
      isEditMode,
      hasFolderConnected
    })
  )
);

function handleCompleteLoadingUserItems(
  state: ProjectFoldersState,
  action: ExternalFoldersResponse
): ProjectFoldersState {
  return updateObject(state, {
    externalFolders: action.items,
    ...handleCompleteLoading(action)
  });
}

function handleCompleteLoadingUserNextPageItems(
  state: ProjectFoldersState,
  action: ExternalFoldersResponse
): ProjectFoldersState {
  return updateObject(state, {
    externalFolders: [...state.externalFolders, ...action.items],
    ...handleCompleteLoading(action)
  });
}

function handleCompleteLoading(action: ExternalFoldersResponse): Partial<ProjectFoldersState> {
  return {
    offsetToken: action.paging ? action.paging.offsetToken : null,
    isLoading: false,
    isLoadingNextPage: false,
    loadingError: null
  };
}

export function projectFoldersReducer(
  state: ProjectFoldersState = defaultProjectFoldersState,
  action: Action
): ProjectFoldersState {
  return reducer(state, action);
}
