import { Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { FeatureService } from '@core/services/feature/feature.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AnalyticsService } from '@core/services/analytics/analytics.service';
import { LocalStorageService } from '@core/services/storage/local-storage.service';
import { LocalStorageKey } from '@core/models/storage/local-storage-key.enum';
import { selectIsHybridUser } from '@root/selectors/user/user.selectors';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLogged: boolean;
  isAuthenticatedConfigurationLoaded: boolean;
  isEmailFilingSupported$ = this.featureService.isEmailFilingSupported;
  isActionItemsSupported$ = this.featureService.isActionItemsSupported;
  isEulaSupported$ = this.featureService.isEulaSupported;
  isSubmittalsSupported$ = combineLatest([
    this.store.select(selectIsHybridUser),
    this.featureService.isHybridSubmittalsSupported,
    this.featureService.isCloudSubmittalsSupported
  ]).pipe(
    map(
      ([isHybridUser, isHybridSubmittalsSupported, isCloudSubmittalsSupported]) =>
        (isHybridUser && (isHybridSubmittalsSupported || isCloudSubmittalsSupported)) || !isHybridUser
    )
  );
  isRfisSupported$ = combineLatest([this.store.select(selectIsHybridUser), this.featureService.isRfisSupported]).pipe(
    map(([isHybridUser, isRfisSupported]) => (isHybridUser && isRfisSupported) || !isHybridUser)
  );
  @ViewChild('mainContent', { static: false }) mainContent: ElementRef;

  isApplicationLoaded = false;
  isDashboardRoute = false;
  submittalsRoutePath = '';
  rfisRoutePath = '';

  private readonly unsupportedBrowserUrl = 'unsupported-browser';
  private isUnsupportedBrowserPageOpen = false;
  private isGlobalConfigurationSupported: boolean;

  constructor(
    @Inject(LOCALE_ID) private localeId: string,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private featureService: FeatureService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private localStorageService: LocalStorageService,
    private store: Store
  ) {
    this.updateApplicationLoadedFlag();
    this.subscribeToRootChange();
  }

  ngOnInit() {
    this.setApplicationLangs();

    this.authenticationService.isLogged.subscribe(isLogged => {
      this.isLogged = isLogged;

      this.updateApplicationLoadedFlag();
    });

    this.featureService.isAuthenticatedConfigurationLoaded.subscribe(isConfLoaded => {
      this.isAuthenticatedConfigurationLoaded = isConfLoaded;
      this.updateApplicationLoadedFlag();
    });

    this.featureService.isGlobalConfigurationLoaded.subscribe(value => {
      this.isGlobalConfigurationSupported = value;
      this.updateApplicationLoadedFlag();
    });

    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(({ url }: NavigationStart) => {
      this.isDashboardRoute = url.includes('dashboard');
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.mainContent.nativeElement.scrollTo(0, 0);
    });
  }

  private updateApplicationLoadedFlag() {
    const isGlobalConfigLoaded = this.isGlobalConfigurationSupported;

    this.isApplicationLoaded =
      (this.isLogged && this.isAuthenticatedConfigurationLoaded) ||
      (!this.isLogged && isGlobalConfigLoaded) ||
      this.isUnsupportedBrowserPageOpen;
  }

  private setApplicationLangs() {
    this.translateService.setDefaultLang('en');
    this.translateService.use(this.localeId).subscribe();
  }

  private subscribeToRootChange() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.submittalsRoutePath = this.localStorageService.getItem(LocalStorageKey.SubmittalsView) ?? 'board';
      this.rfisRoutePath = this.localStorageService.getItem(LocalStorageKey.RfisView) ?? 'board';

      this.analyticsService.recordPageViewEvent(event);

      this.isUnsupportedBrowserPageOpen = event.url === `/${this.unsupportedBrowserUrl}`;

      this.updateApplicationLoadedFlag();
    });
  }
}
