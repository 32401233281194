<form
  class="project-folders-dialog-container"
  *ngIf="{
    isConnecting: isConnecting$ | async,
    isLoading: isLoading$ | async,
    isLoadingNextPage: isLoadingNextPage$ | async,
    loadingError: loadingError$ | async,
    hasFolderConnected: hasFolderConnected$ | async,
    externalFolders: externalFolders$ | async,
    searchQuery: searchQuery$ | async,
    currentExternalFolder: currentExternalFolder$ | async,
    navigation: navigation$ | async 
   } as folderData"
>
  <div
    class="connect-project-folder-header"
  >
  {{
    isDefaultView ?
      ('PROJECT.CREATE.PROJECT_FOLDER.DIALOG_HEADER' | translate) :
      ('PROJECT.CREATE.PROJECT_FOLDER.DIALOG_HEADER_FOLDERS' | translate)
  }}
  </div>
  <div
    class="search-hint"
  >
  {{
    isDefaultView ?
      ('PROJECT.CREATE.PROJECT_FOLDER.SEARCH_HINT' | translate) :
      ('PROJECT.CREATE.PROJECT_FOLDER.SEARCH_HINT_SUB_FOLDERS' | translate)
  }}
  </div>
  <mat-dialog-content
    class="project-dialog-content"
  >
    <div
      class="sharepoint-site-container"
    >
      <app-search
        *ngIf="isDefaultView"
        id="search-sharepoint-sites-input"
        size="full-width"
        placeholder="PROJECT.CREATE.PROJECT_FOLDER.SEARCH"
        (searchTermChanged)="searchSharePointSites($event)"
        [searchTerm]="folderData.searchQuery"
        [autoFocus]="true"
      ></app-search>
      <div
        *ngIf="!isDefaultView"
        class="breadcrumb-wrapper"
      >
        <app-breadcrumbs
          separator="/"
          [breadcrumbs]="breadcrumbs"
          (onExternalFolderSelected)="selectExternalFolderFromBreadcrumbs($event, folderData.navigation)"
        ></app-breadcrumbs>
      </div>
      <app-sites-search-results
        class="full-width"
        [class.sharepoint-site-results]="isDefaultView"
        [isDefaultView]="isDefaultView"
        [isLoading]="folderData.isLoading"
        [isLoadingNextPage]="folderData.isLoadingNextPage"
        [externalFolders]="folderData.externalFolders"
        [selectedExternalFolder]="folderData.currentExternalFolder"
        [error]="folderData.loadingError"
        (onEndOfListReached)="loadNextPage(selectedItem)"
        (onExternalFolderSelected)="selectExternalFolder($event, folderData.navigation)"
      ></app-sites-search-results>
      <span 
        class="font-regular selected"
        *ngIf="!isDefaultView && selectedItem"
      >
        Selected: <span class="selected-item"> {{ selectedItem.name }} </span>
      </span>
    </div>
  </mat-dialog-content>
  <div
    class="project-folder-dialog-actions"
    mat-dialog-actions
  >
    <button
      *ngIf="!isDefaultView"
      mat-button
      (click)="goToDefaultView()"
      class="skip-connect-btn back-btn"
    >
      <span>
        {{'PROJECT.UPDATE.BACK_BUTTON' | translate}}
      </span>
    </button>
    <div
      *ngIf="!!folderData.navigation"
      class="spacer"
    >
    </div>
    <button
      mat-button
      (click)="closeDialog()"
      class="skip-connect-btn"
    >
    <span>
      {{
        isEditMode || isFilesView ?
          ('PROJECT.CREATE.PROJECT_FOLDER.DECLINE' | translate) :
          ('PROJECT.CREATE.PROJECT_FOLDER.SKIP' | translate)
      }}
    </span>
    </button>
    <app-raised-button
      *ngIf="!isEditMode || isFilesView"
      id="create-project-folder-button"
      (clicked)="isDefaultView ? nextProjectFolder() : createProjectFolder()"
      class="connect-btn"
      [disabled]="folderData.isLoading || folderData.isConnecting || !selectedItem"
    >
      <mat-spinner
        *ngIf="folderData.isConnecting"
        class="connect-project-folder-spinner"
        [diameter]="28"
      ></mat-spinner>
      <ng-content
        *ngIf="isDefaultView; then templateContinue else templateConnect"
      ></ng-content>
    </app-raised-button>
    <app-raised-button
      *ngIf="isEditMode"
      id="update-project-folder-button"
      (clicked)="isDefaultView ? nextProjectFolder() : updateProjectFolder()"
      class="connect-btn"
      [disabled]="!selectedItem"
    >
      <ng-content
      *ngIf="isDefaultView; then templateContinue else templateConnect"
      ></ng-content>
    </app-raised-button>
    <ng-template #templateContinue>
      <span>
        {{ 'PROJECT.UPDATE.CONTINUE_BUTTON' | translate }}
      </span>
    </ng-template>
    <ng-template #templateConnect>
      <span [class.hidden]="folderData.isConnecting">
        {{ 'PROJECT.UPDATE.CONNECT_BUTTON' | translate }}
      </span>
    </ng-template>
  </div>
</form>
