import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocalStorageService } from '@core/services/storage/local-storage.service';
import { LocalStorageKey } from '@core/models/storage/local-storage-key.enum';
import { BIG_SCREEN_SIZE, MEDIUM_SCREEN_SIZE, SMALL_SCREEN_SIZE } from '@shared/constants';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchbarComponent implements OnDestroy, OnInit {
  @Input() allowToggle = true;
  @Input() displaySorting: boolean;
  @Output() onClearSearchFilters = new EventEmitter();
  @Output() closeSearchBarChange = new EventEmitter<boolean>();

  @Input() set closeSearchBar(val: boolean) {
    if (!val) {
      return;
    }

    this.isSearchBarOpen = false;
    this.localStorageService.setItem(LocalStorageKey.SearchBarVisibility, this.isSearchBarOpen.toString());
  }

  private destroyComponent = new Subject();
  isSearchBarOpen = false;
  showCollapsedButtons = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private changeDetectorRef: ChangeDetectorRef,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    if (this.openNotAllowed()) {
      return;
    }
    const item = this.localStorageService.getItem(LocalStorageKey.SearchBarVisibility);
    if (item) {
      this.isSearchBarOpen = item === 'true';
    }
    this.handleBreakpoints();
  }

  ngOnDestroy(): void {
    this.destroyComponent.next();
    this.destroyComponent.complete();
  }

  clearFilters(): void {
    this.onClearSearchFilters.emit();
  }

  toggleSidebar(): void {
    this.closeSearchBarChange.emit(false);
    this.isSearchBarOpen = !this.isSearchBarOpen;
    this.localStorageService.setItem(LocalStorageKey.SearchBarVisibility, this.isSearchBarOpen.toString());
  }

  private handleBreakpoints(): void {
    this.breakpointObserver
      .observe([SMALL_SCREEN_SIZE, MEDIUM_SCREEN_SIZE, BIG_SCREEN_SIZE])
      .pipe(takeUntil(this.destroyComponent))
      .subscribe(result => {
        this.showCollapsedButtons = !result.breakpoints[SMALL_SCREEN_SIZE];
        if (this.localStorageService.getItem(LocalStorageKey.SearchBarVisibility)) {
          return;
        }

        this.isSearchBarOpen = result.breakpoints[BIG_SCREEN_SIZE];
        this.localStorageService.setItem(LocalStorageKey.SearchBarVisibility, this.isSearchBarOpen.toString());
        this.changeDetectorRef.detectChanges();
      });
  }

  private openNotAllowed(): boolean {
    if (!this.allowToggle) {
      this.isSearchBarOpen = false;
      return true;
    }
    return false;
  }
}
