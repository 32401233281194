import { Action, createAction, props } from '@ngrx/store';
import { Project } from '@core/models/project/project.model';
import { LoadProjectsError } from '@root/effects/project-list/project-list.effects';
import { ProjectDetailsResponseModel } from '@shared/models/project/project-details.response.model';

const prefix = '[PROJECT_LIST]';

export const loadProjects = createAction(
  `${prefix} LOAD_PROJECTS`,
  props<{ actionsToDispatch?: Action[]; forceRefresh?: boolean }>()
);

export const loadProjectDetails = createAction(`${prefix} LOAD_PROJECT_DETAILS`, props<{ projectNrn: string }>());

export const completeLoadingProjectDetails = createAction(
  `${prefix} COMPLETE_LOADING_PROJECT_DETAILS`,
  props<{ projectDetails: ProjectDetailsResponseModel }>()
);

export const failLoadingProjectDetails = createAction(`${prefix} FAIL_LOADING_PROJECT_DETAILS`);

export const destroyProjectViewContainer = createAction(`${prefix} DESTROY_PROJECT_VIEW_CONTAINER`);

export const updateProjectList = createAction(`${prefix} UPDATE_PROJECT_LIST`, props<{ projects: Project[] }>());

export const updateProjectsError = createAction(
  `${prefix} UPDATE_PROJECTS_ERROR`,
  props<{ error: LoadProjectsError }>()
);

export const addRecentProject = createAction(`${prefix} ADD_RECENT_PROJECT`, props<{ recentProjectNrn: string }>());

export const refreshRecentProjects = createAction(
  `${prefix} REFRESH_RECENT_PROJECTS`,
  props<{ projects: Project[] }>()
);

export const updateRecentProjectNrns = createAction(
  `${prefix} UPDATE_RECENT_PROJECT_NRNS`,
  props<{ recentProjectNrns: string[] }>()
);

export const selectProject = createAction(`${prefix} SELECT_PROJECT`, props<{ projectNrn: string }>());

export const openTeamProject = createAction(`${prefix} OPEN_PROJECT_TEAM`, props<{ projectNrn: string }>());

export const updateCurrentProjectNrn = createAction(
  `${prefix} UPDATE_CURRENT_PROJECT_NRN`,
  props<{ projectNrn: string }>()
);

export const clearProject = createAction(`${prefix} CLEAR_PROJECT`);

export const updateCurrentProjectConnectStatus = createAction(`${prefix} UPDATE_CURRENT_PROJECT_CONNECT_STATUS`);
