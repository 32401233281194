import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { InputErrorStateMatcher } from '@shared/validators/input-error-state-matcher/input-error-state-matcher';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatePickerComponent {
  @Input() parentForm: FormGroup;
  @Input() placeholder: string;
  @Input() controlName: string;
  @Input() errorMessage: string;
  @Input() isRequired: boolean;
  @Input() displayEditIndicatorButtons: boolean;
  @Input() maxDate: Date | null;
  @Input() minDate: Date | null;
  @Input() filter: (d: Date | null) => boolean = () => true;
  @Output() onPickerOpened = new EventEmitter();
  @Output() onPickerClosed = new EventEmitter();
  @Output() onDiscardChanges = new EventEmitter();
  @Output() onAcceptChanges = new EventEmitter();
  @Output() onDateChanged = new EventEmitter<Date | null>();

  inputErrorMatcher = new InputErrorStateMatcher();

  onPickerClosedHandler(): void {
    this.onPickerClosed.emit();
  }

  onPickerOpenedHandler(): void {
    this.onPickerOpened.emit();
  }

  onDiscardChangesHandler(): void {
    this.onDiscardChanges.emit();
  }

  onAcceptChangesHandler(): void {
    this.onAcceptChanges.emit();
  }

  onDateChangedHandler($event: MatDatepickerInputEvent<any>): void {
    this.onDateChanged.emit($event.value);
  }
}
