import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { WindowService } from '@core/services/window/window.service';
import { ComponentCanDeactivateDirective } from '@core/guards/can-deactivate/can-deactivate';
import { SubmittalsState } from '@submittals/reducers';
import { selectIsLogFormDirty } from '@submittals/selectors/submittal-workflow-action-form/submittal-workflow-action-form.selectors';

@Injectable()
export class CanDeactivateLogFormGuard implements CanDeactivate<ComponentCanDeactivateDirective> {
  constructor(
    private store: Store<SubmittalsState>,
    private translateService: TranslateService,
    private windowService: WindowService
  ) {}

  canDeactivate(): Observable<boolean> {
    return combineLatest([
      this.store.select(selectIsLogFormDirty),
      this.translateService.get('SUBMITTALS.ACTION_FORM.ERROR.LOG_CONFIRMATION_ERROR')
    ]).pipe(
      map(([isDirty, confirmationMessage]) => (isDirty ? this.windowService.confirm(confirmationMessage) : true))
    );
  }
}
