import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WindowService } from '@core/services/window/window.service';
import { SEARCH_TYPE_QUERY_PARAM_NAME, SEARCH_VALUE_QUERY_PARAM_NAME } from '@shared/constants';

@Component({
  selector: 'app-dashboard-search',
  templateUrl: './dashboard-search.component.html',
  styleUrls: ['./dashboard-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardSearchComponent implements OnInit {
  private projectNrn: string;
  searchValue = '';

  constructor(private activatedRoute: ActivatedRoute, private windowService: WindowService) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => (this.projectNrn = params.projectNrn));
  }

  openSearchPage(): void {
    const baseUrl = this.windowService.getCurrentBaseUrl();
    const searchUrl = `${baseUrl}/projects/${this.projectNrn}/files?${SEARCH_TYPE_QUERY_PARAM_NAME}=file&${SEARCH_VALUE_QUERY_PARAM_NAME}=${this.searchValue}`;

    this.windowService.open(searchUrl);
  }
}
