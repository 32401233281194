<div
  *ngIf="{
    isCreateAccessKeySupported: isCreateAccessKeySupported$ | async,
    isCloudTermsAndConditionsAdminSupported: isCloudTermsAndConditionsAdminSupported$ | async,
    isUserAdministrationSupported: isUserAdministrationSupported$ | async
  } as templateData"
  class="user-menu-button"
>
  <button
    *ngIf="!isMobile"
    mat-button
    [matMenuTriggerFor]="signOutSelectorMenu"
    (click)="menuIsDisplay = true"
  >
  <div class="display-user-name-wrapper">
    <mat-icon
      svgIcon="user-menu"
      class="user-menu-icon"
    ></mat-icon>
    <div class="display-user-name overflow-ellipsis">{{ displayUserName }}</div>
  </div>
  </button>
  <mat-menu
    id="signOutSelectorMenu"
    #signOutSelectorMenu="matMenu"
    class="popup-menu-sign-out actions-menu"
    (close)="menuIsDisplay = false"
    xPosition="before"
  >
    <button
      id="sign-out"
      class="sign-out-btn"
      mat-menu-item
      (click)="manualLogout()"
    >
      {{ 'PROJECT.SIGN_OUT' | translate }}
    </button>
    <button
      id="view-profile"
      class="view-profile-btn"
      mat-menu-item
      (click)="openViewProfileDialog()"
    >
      {{ 'APP.USER_MENU.VIEW_PROFILE_BTN' | translate }}
    </button>
    <button
      *ngIf="templateData.isCreateAccessKeySupported"
      id="generate-service-access-key-button"
      mat-menu-item
      class="navigation-link"
      (click)="navigateToGenerateServerAccess()"
    >
      {{ 'PROJECT.GENERATE_SERVICE_ACCESS_KEY' | translate }}
    </button>
    <button
      *ngIf="templateData.isCloudTermsAndConditionsAdminSupported"
      id="terms-and-conditions-admin-button"
      mat-menu-item
      (click)="openTermsAndConditionsDialog()"
    >
      {{ 'PROJECT.COMPANY_TERMS_AND_CONDITIONS' | translate }}
    </button>
    <button
      *ngIf="templateData.isUserAdministrationSupported"
      id="user-administration-button"
      mat-menu-item
      (click)="navigateToUserAdministration()"
    >
      {{ 'APP.USER_MENU.USER_ADMINISTRATION_BTN' | translate }}
    </button>
  </mat-menu>
  <div *ngIf="isMobile && isSidebarOpen">
    <div class="mobile-user-profile-wrapper">
      <div class="mobile-user-profile-menu" [class.mobile-user-menu-displayed]="menuIsDisplay">
        <span
          class="mobile-menu-item mobile-sign-out-button"
          (click)="manualLogout()"
        >
          {{ 'PROJECT.SIGN_OUT' | translate }}
        </span>
        <span
          class="mobile-menu-item mobile-view-profile-button"
          (click)="openViewProfileDialog()"
        >
          {{ 'APP.USER_MENU.VIEW_PROFILE_BTN' | translate }}
        </span>
        <span
          class="mobile-menu-item mobile-user-administration-button"
          *ngIf="templateData.isUserAdministrationSupported"
          (click)="navigateToUserAdministration()"
        >
          {{ 'APP.USER_MENU.USER_ADMINISTRATION_BTN' | translate }}
        </span>
        <span
          class="mobile-menu-item mobile-generate-key-button"
          *ngIf="templateData.isCreateAccessKeySupported"
          (click)="navigateToGenerateServerAccess()"
        >
          {{ 'PROJECT.GENERATE_SERVICE_ACCESS_KEY' | translate }}
        </span>
        <span
          class="mobile-menu-item mobile-terms-and-conditions-button"
          *ngIf="templateData.isCloudTermsAndConditionsAdminSupported"
          (click)="openTermsAndConditionsDialog()"
        >
          {{ 'PROJECT.COMPANY_TERMS_AND_CONDITIONS' | translate }}
        </span>
      </div>
    </div>
    <div
      [class.active-user-menu]="menuIsDisplay"
      class="mobile-user-profile"
      (click)="toggleUserMobileMenu()"
    >
      <mat-icon class="mobile-menu-item-icon" svgIcon="menu-user-profile"></mat-icon>
      <span class="mobile-menu-item-text">
      {{ displayUserName }}
      </span>
    </div>
  </div>
</div>
