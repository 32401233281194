import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiUrlProvider } from '@core/utilities/apiUrl/apiUrl.provider';
import { Observable } from 'rxjs';
import { CreateProjectFolderRequest } from '@shared/models/project-folders/create-project-folder.request.model';
import { CreateProjectFolderResponse } from '@shared/models/project-folders/create-project-folder.response.model';
import { ProjectFoldersModel } from '@shared/models/project-folders/project-folders.model';
import { ExternalFoldersResponse } from '@core/models/external-folder/external-folders.response.model';
import { ExternalFolderProviderTypes } from '@core/models/external-folder/external-folder-provider-types.model';
import { ProjectFoldersResponseModel } from '@shared/models/project-folders/project-folders.response.model';

@Injectable({ providedIn: 'root' })
export class ProjectFolderService {
  private readonly pageSize = 15;

  constructor(private http: HttpClient, private apiUrlProvider: ApiUrlProvider) {}

  getExternalFolders(
    query: string | null = null,
    parentNrn: string | null = null,
    offsetToken: string | null = null
  ): Observable<ExternalFoldersResponse> {
    const baseUrl = this.getUrl();
    const params = this.getExternalFoldersParams(query, parentNrn, offsetToken);

    return this.http.get<ExternalFoldersResponse>(
      `${baseUrl}/integrations/${ExternalFolderProviderTypes.SharepointSite}/external-folders`,
      { params }
    );
  }

  createProjectFolder(
    projectNrn: string,
    createRootProjectFolderRequest: CreateProjectFolderRequest
  ): Observable<CreateProjectFolderResponse> {
    const baseUrl = this.getUrl();

    const createRootFolderUrl = `${baseUrl}/projects/${encodeURIComponent(projectNrn)}/projectfolders`;
    return this.http.post<CreateProjectFolderResponse>(createRootFolderUrl, createRootProjectFolderRequest);
  }

  updateRootFolder(
    projectNrn: string,
    projectFolderToUpdate: ProjectFoldersModel,
    currentProjectFolderNrn: string
  ): Observable<{}> {
    const baseUrl = this.getUrl();

    const updateRootFolderUrl = `${baseUrl}/projects/${encodeURIComponent(
      projectNrn
    )}/projectfolders/${encodeURIComponent(currentProjectFolderNrn)}`;
    return this.http.put(updateRootFolderUrl, {
      projectFolder: { nrn: projectFolderToUpdate.nrn, version: projectFolderToUpdate.version }
    });
  }

  getProjectFolders(projectNrn: string): Observable<ProjectFoldersResponseModel> {
    return this.http.get<ProjectFoldersResponseModel>(
      `${this.getUrl()}/projects/${encodeURIComponent(projectNrn)}/projectfolders`
    );
  }

  private getUrl(): string {
    return this.apiUrlProvider.getUrl();
  }

  private getExternalFoldersParams(
    query: string | null = null,
    parentNrn: string | null = null,
    offsetToken: string | null = null
  ): HttpParams {
    let params = new HttpParams();

    params = params.set('maxSize', this.pageSize.toString());

    if (!!query) {
      params = params.set('query', this.getQuery(query));
    }

    if (!!offsetToken) {
      params = params.set('offsetToken', offsetToken);
    }

    if (!!parentNrn) {
      params = params.set('parentNrn', parentNrn);
    }

    return params;
  }

  private getQuery(query: string): string {
    return query.includes('https://') ? encodeURIComponent(query) : query;
  }
}
