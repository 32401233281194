import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IconMap } from '@shared/utilities/icons/icon.map';

@Injectable()
export class IconRegistry {
  static readonly defaultSvgIcons: IconMap[] = [
    new IconMap('file_file', '/assets/images/general-file.svg'),
    new IconMap('file_group', '/assets/images/general-file-group.svg'),
    new IconMap('file_email', '/assets/images/general-email.svg'),
    new IconMap('image_file', '/assets/images/image-file.svg'),
    new IconMap('image_zip', '/assets/images/image-zip.svg'),
    new IconMap('image_email', '/assets/images/image-email.svg'),
    new IconMap('text_file', '/assets/images/txt-file.svg'),
    new IconMap('pdf_file', '/assets/images/pdf-file.svg'),
    new IconMap('pdf_zip', '/assets/images/pdf-zip.svg'),
    new IconMap('pdf_email', '/assets/images/pdf-email.svg'),
    new IconMap('excel_file', '/assets/images/excel-file.svg'),
    new IconMap('powerpoint_file', '/assets/images/powerpoint-file.svg'),
    new IconMap('word_file', '/assets/images/word-file.svg'),
    new IconMap('dgn_file', '/assets/images/dgn-file.svg'),
    new IconMap('dwf_file', '/assets/images/dwf-file.svg'),
    new IconMap('dwg_file', '/assets/images/dwg-file.svg'),
    new IconMap('zip_file', '/assets/images/zip-file.svg'),
    new IconMap('email_file', '/assets/images/email-file.svg'),
    new IconMap('download', '/assets/images/download.svg'),
    new IconMap('folder-miniature', '/assets/images/miniatures/folder.svg'),
    new IconMap('folder-shared-miniature', '/assets/images/miniatures/folder-shared.svg'),
    new IconMap('folder-filled-miniature', '/assets/images/miniatures/folder-copy.svg'),
    new IconMap('file-miniature', '/assets/images/miniatures/file.svg'),
    new IconMap('pdf-miniature', '/assets/images/miniatures/pdf.svg'),
    new IconMap('excel-miniature', '/assets/images/miniatures/excel.svg'),
    new IconMap('powerpoint-miniature', '/assets/images/miniatures/powerpoint.svg'),
    new IconMap('word-miniature', '/assets/images/miniatures/word.svg'),
    new IconMap('dgn-miniature', '/assets/images/miniatures/dgn.svg'),
    new IconMap('dwf-miniature', '/assets/images/miniatures/dwf.svg'),
    new IconMap('dwg-miniature', '/assets/images/miniatures/dwg.svg'),
    new IconMap('icon-miniature', '/assets/images/miniatures/image.svg'),
    new IconMap('zip-miniature', '/assets/images/miniatures/zip.svg'),
    new IconMap('image-miniature', '/assets/images/miniatures/image.svg'),
    new IconMap('text-miniature', '/assets/images/miniatures/text.svg'),
    new IconMap('email-miniature', '/assets/images/miniatures/email.svg'),
    new IconMap('folder-empty', '/assets/images/folder-empty.svg'),
    new IconMap('items-people', '/assets/images/items-people.svg'),
    new IconMap('projectill', '/assets/images/project-ill.svg'),
    new IconMap('newforma', '/assets/images/newforma-logo-white.svg'),
    new IconMap('search', '/assets/images/search.svg'),
    new IconMap('share', '/assets/images/share.svg'),
    new IconMap('cancel-search', '/assets/images/cancel-search.svg'),
    new IconMap('search-no-result', '/assets/images/search-no-result.svg'),
    new IconMap('cancel-upload', '/assets/images/close-upload.svg'),
    new IconMap('newforma-logo', '/assets/images/newforma-logo-white.svg'),
    new IconMap('newforma-logo-dark', '/assets/images/newforma-logo-dark.svg'),
    new IconMap('newforma-text', '/assets/images/newforma-logo-type-white.svg'),
    new IconMap('share-processing', '/assets/images/shared-file-processing.svg'),
    new IconMap('share-error', '/assets/images/general-error-file.svg'),
    new IconMap('share-expired', '/assets/images/shared-file-expired.svg'),
    new IconMap('general-error', '/assets/images/general-error.svg'),
    new IconMap('general-error-orange', '/assets/images/general-error-orange.svg'),
    new IconMap('locked-folder', '/assets/images/locked-folder.svg'),
    new IconMap('no-projects', '/assets/images/no-projects.svg'),
    new IconMap('login-background', '/assets/images/login-background.svg'),
    new IconMap('error-cross', '/assets/images/error-cross.svg'),
    new IconMap('edit', '/assets/images/edit.svg'),
    new IconMap('done', '/assets/images/done.svg'),
    new IconMap('done-white', '/assets/images/done-white.svg'),
    new IconMap('plus-symbol', '/assets/images/plus-symbol.svg'),
    new IconMap('select-file', '/assets/images/select-file.svg'),
    new IconMap('open-in', '/assets/images/open-in.svg'),
    new IconMap('item-not-selected', '/assets/images/item-not-selected.svg'),
    new IconMap('delete', '/assets/images/delete.svg'),
    new IconMap('folder', '/assets/images/folder.svg'),
    new IconMap('folder-add', '/assets/images/folder-add.svg'),
    new IconMap('shared-folder', '/assets/images/shared-folder.svg'),
    new IconMap('file-expired', '/assets/images/file-expired.svg'),
    new IconMap('project-card-building-1', '/assets/images/project-card-building-1.svg'),
    new IconMap('project-card-building-2', '/assets/images/project-card-building-2.svg'),
    new IconMap('project-card-building-3', '/assets/images/project-card-building-3.svg'),
    new IconMap('project-card-building-4', '/assets/images/project-card-building-4.svg'),
    new IconMap('project-card-building-5', '/assets/images/project-card-building-5.svg'),
    new IconMap('project-card-building-6', '/assets/images/project-card-building-6.svg'),
    new IconMap('project-card-building-7', '/assets/images/project-card-building-7.svg'),
    new IconMap('project-card-building-8', '/assets/images/project-card-building-8.svg'),
    new IconMap('project-card-building-9', '/assets/images/project-card-building-9.svg'),
    new IconMap('project-card-building-10', '/assets/images/project-card-building-10.svg'),
    new IconMap('project-card-building-11', '/assets/images/project-card-building-11.svg'),
    new IconMap('project-card-building-12', '/assets/images/project-card-building-12.svg'),
    new IconMap('project-card-building-13', '/assets/images/project-card-building-13.svg'),
    new IconMap('project-card-building-14', '/assets/images/project-card-building-14.svg'),
    new IconMap('project-card-building-15', '/assets/images/project-card-building-15.svg'),
    new IconMap('project-card-building-16', '/assets/images/project-card-building-16.svg'),
    new IconMap('project-card-cloud', '/assets/images/project-card-cloud.svg'),
    new IconMap('project-card-email', '/assets/images/project-card-email.svg'),
    new IconMap('project-card-file', '/assets/images/project-card-file.svg'),
    new IconMap('project-card-npc', '/assets/images/project-card-npc.svg'),
    new IconMap('project-card-shared', '/assets/images/project-card-shared.svg'),
    new IconMap('project-card-shared_folder', '/assets/images/project-card-shared.svg'),
    new IconMap('project-card-external', '/assets/images/project-card-shared.svg'),
    new IconMap('project-no-root-folder', '/assets/images/project-no-root-folder.svg'),
    new IconMap('empty-projects', '/assets/images/empty-projects.svg'),
    new IconMap('no-folder-connected', '/assets/images/no-folder-connected.svg'),
    new IconMap('no-folders-available', '/assets/images/no-folders-available.svg'),
    new IconMap('no-project-found', '/assets/images/no-project-found.svg'),
    new IconMap('close-window', '/assets/images/close-win.svg'),
    new IconMap('log-error', '/assets/images/log-error.svg'),
    new IconMap('login-logo', '/assets/images/login-logo.svg'),
    new IconMap('login-newforma-text', '/assets/images/login-newforma-text.svg'),
    new IconMap('empty-inbox', '/assets/images/empty-inbox.svg'),
    new IconMap('folder-select', '/assets/images/folder-select.svg'),
    new IconMap('calendar', '/assets/images/calendar.svg'),
    new IconMap('general-error-white', '/assets/images/general-error-white.svg'),
    new IconMap('multiple-files-selected', 'assets/images/multiple-files-selected.svg'),
    new IconMap('remove-item', 'assets/images/remove-item.svg'),
    new IconMap('group', '/assets/images/group.svg'),
    new IconMap('group-chip', '/assets/images/group-chip.svg'),
    new IconMap('upload-dnd', '/assets/images/upload-dnd.svg'),
    new IconMap('down-arrow', '/assets/images/down-arrow.svg'),
    new IconMap('drop-down', '/assets/images/drop-down.svg'),
    new IconMap('dashboard-project-search', './assets/images/dashboard-project-search.svg'),
    new IconMap('clear-filter', './assets/images/clear-filter.svg'),
    new IconMap('filter-arrow', './assets/images/filter-arrow.svg'),
    new IconMap('remove-chip-item', './assets/images/close-white.svg'),
    new IconMap('attachment', './assets/images/attachment.svg'),
    new IconMap('comment', './assets/images/comment.svg'),
    new IconMap('sharepoint-site', './assets/images/site-select.svg'),
    new IconMap('all-reviews-recorded', './assets/images/all-reviews-recorded.svg'),
    new IconMap('forwarded-arrow', './assets/images/forwarded-arrow.svg'),
    new IconMap('show-less', './assets/images/show-less.svg'),
    new IconMap('icons-error', '/assets/images/icons-error.svg'),
    new IconMap('empty-list', '/assets/images/empty-list.svg'),
    new IconMap('request-for-information', '/assets/images/request-for-information.svg'),
    new IconMap('empty-state-team-members', '/assets/images/empty-state-team-members.svg'),
    new IconMap('priority-high-white', '/assets/images/priority-high-white.svg'),
    new IconMap('workflow-items-no-license', '/assets/images/workflow-items-no-license.svg'),
    new IconMap('kanban-board-view-active', '/assets/images/board-view-active.svg'),
    new IconMap('kanban-board-view-not-active', '/assets/images/board-view-not-active.svg'),
    new IconMap('list-view-active', '/assets/images/list-view-active.svg'),
    new IconMap('list-view-not-active', '/assets/images/list-view-not-active.svg'),
    new IconMap('no-workflow-items-available', '/assets/images/no-workflow-items-available.svg'),
    new IconMap('active-user-avatar', '/assets/images/active-user-avatar.svg'),
    new IconMap('inactive-user-avatar', '/assets/images/inactive-user-avatar.svg'),
    new IconMap('multiple-users-avatar', '/assets/images/multiple-users-avatar.svg'),
    new IconMap('cancel-action', '/assets/images/cancel-action.svg'),
    new IconMap('revoke-access', '/assets/images/revoke-access.svg'),
    new IconMap('back-link-left-arrow', '/assets/images/back-arrow.svg'),
    new IconMap('user-menu', '/assets/images/person-circle-outline.svg'),
    new IconMap('information', '/assets/images/icon-i.svg'),
    new IconMap('submittals', '/assets/images/submittals.svg'),
    new IconMap('plus', '/assets/images/icon-plus.svg'),
    new IconMap('menu-projects', '/assets/images/sidebar/projects.svg'),
    new IconMap('menu-action-items', '/assets/images/sidebar/action-items.svg'),
    new IconMap('menu-submittals', '/assets/images/sidebar/submittals.svg'),
    new IconMap('menu-rfis', '/assets/images/sidebar/rfis.svg'),
    new IconMap('menu-file-email', '/assets/images/sidebar/file-email.svg'),
    new IconMap('menu-hide-sidebar', '/assets/images/sidebar/hide-sidebar.svg'),
    new IconMap('menu-user-profile', '/assets/images/sidebar/user-profile.svg'),
    new IconMap('menu-external-link', '/assets/images/sidebar/external-link.svg'),
    new IconMap('search-filters', '/assets/images/searchbar/icon-filters.svg'),
    new IconMap('search-sorting', '/assets/images/searchbar/icon-sorting.svg'),
    new IconMap('menu-hide-searchbar', '/assets/images/searchbar/icon-hide.svg'),
    new IconMap('close-flyout', '/assets/images/close-flyout.svg'),
    new IconMap('icon-download', '/assets/images/icon-download.svg'),
    new IconMap('icon-download-attachment', '/assets/images/icon-download-attachment.svg'),
    new IconMap('loading-details-error', '/assets/images/loading-details-error.svg'),
    new IconMap('straight-attachment', '/assets/images/straight_attachment.svg'),
    new IconMap('icon-empty-state', '/assets/images/icon-empty-state.svg'),
    new IconMap('icon-error-state', '/assets/images/icon-error-state.svg'),
    new IconMap('briefcase', '/assets/images/briefcase.svg'),
    new IconMap('folder-with-logo', '/assets/images/folder-with-logo.svg')
  ];

  constructor(private matIconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}

  registerDefaultSvgIcons(): void {
    IconRegistry.defaultSvgIcons.forEach(icon => this.registerSvgIcon(icon.name, icon.path));
  }

  registerSvgIcon(name: string, path: string): void {
    this.matIconRegistry.addSvgIcon(name, this.sanitizer.bypassSecurityTrustResourceUrl(path));
  }
}
