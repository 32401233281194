<div
  class="eula-container full-height"
  fxFlex
  fxLayout="column"
>
  <div class="eula-header"
  >
    <div
      fxLayoutAlign="center"
      class="header"
    >
      {{ isEulaPreviouslyAccepted && !isEulaVersionAccepted ? ('AUTHENTICATION.EULA_UPDATED' | translate) : ('AUTHENTICATION.EULA' | translate) }}
    </div>
  </div>
  <mat-dialog-content
    class="content"
    fxLayoutAlign="center center"
  >
    <app-spinner
      *ngIf="isLoading"
      id="eula-loading-spinner"
    ></app-spinner>
    <app-empty-state
      *ngIf="isErrorLoadingEula && !isLoading"
      class="eula-error"
      primaryLabel="AUTHENTICATION.EULA_DIALOG.ERROR"
      icon="general-error"
    ></app-empty-state>
    <div
      *ngIf="!isLoading && !isErrorLoadingEula"
      class="eula-body"
    >
      <div class="version">
        {{ 'AUTHENTICATION.EULA_DIALOG.VERSION' | translate: {version: eulaVersion} }}
      </div>
      <div class="eula-text" [innerHTML]="eulaContents"></div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions
    *ngIf="!isLoading"
    class="actions"
  >
    <mat-checkbox
      *ngIf="!isEulaVersionAccepted && !isErrorLoadingEula"
      class="checkbox"
      [checked]="isEulaRead"
      (change)="markedAsReadChanged()"
      [disabled]="isLoading"
    >
      {{ 'AUTHENTICATION.EULA_DIALOG.READ_ACKNOWLEDGEMENT' | translate}}
    </mat-checkbox>
    <div
      fxFlex
      fxLayout
      fxLayoutAlign="end center"
    >
      <button
        *ngIf="!isEulaVersionAccepted && !isErrorLoadingEula"
        mat-button
        (click)="onDecline()"
        class="cancel-button"
        type="button"
        tabindex="-1"
      >
        {{'AUTHENTICATION.EULA_DIALOG.DECLINE' | translate}}
      </button>
      <app-raised-button
        *ngIf="!isEulaVersionAccepted && !isErrorLoadingEula"
        [disabled]="!isEulaRead"
        (clicked)="onConfirm()"
        id="eula-accept-button"
        class="accept-button"
      >
        {{'AUTHENTICATION.EULA_DIALOG.CONFIRM' | translate}}
      </app-raised-button>
      <app-raised-button
        *ngIf="isEulaVersionAccepted || isErrorLoadingEula"
        (clicked)="onClose()"
        id="eula-close-button"
        class="accept-button"
      >
        {{'AUTHENTICATION.EULA_DIALOG.CLOSE' | translate}}
      </app-raised-button>
    </div>
  </mat-dialog-actions>
</div>
