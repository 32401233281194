import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EulaContainer } from '@shared/containers/eula/eula.container';
import { Observable } from 'rxjs';
import { Eula } from '@core/models/authentication/loginRequest.model';
import { EulaDialogData } from '@shared/models/eula/eula-dialog-data.model';

@Injectable({
  providedIn: 'root'
})
export class EulaDialogService {
  constructor(private dialog: MatDialog) {}

  open(data: EulaDialogData): Observable<Eula | undefined> {
    return this.dialog
      .open(EulaContainer, {
        data,
        disableClose: true,
        width: '600px',
        maxWidth: '600px',
        maxHeight: '675px',
        panelClass: 'eula-container-dialog'
      })
      .afterClosed();
  }
}
