import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'emptyLabel'
})
export class EmptyLabelPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(text: string | null): string {
    return text?.length ? text : this.translateService.instant('APP.KANBAN.NOT_SPECIFIED_FIELD');
  }
}
