import { ApplicationRef, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogData } from '@shared/services/confirmation-dialog/confirmation.dialog.model';
import { ConfirmationDialogLocalStorageService } from '@shared/components/confirmation-dialog/local-storage-service/confirmation-dialog.local-storage.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnDestroy {
  hideDialogCheckboxValue = false;
  additionalCheckboxValue = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
    private localStorageService: ConfirmationDialogLocalStorageService,
    private applicationRef: ApplicationRef,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {}

  ngOnDestroy() {
    if (this.hideDialogCheckboxValue && this.data.localStorageKey) {
      this.localStorageService.setLocalStorageForHidingDialog(this.data.localStorageKey);
    }
  }

  onConfirmClick(): void {
    const { confirmCallback } = this.data;

    if (confirmCallback) {
      confirmCallback();
    }

    this.applicationRef.tick();
    this.dialogRef.close({ isConfirmed: true, checkboxValue: this.additionalCheckboxValue });
  }

  onDeclineClick(): void {
    const { declineCallback } = this.data;

    if (declineCallback) {
      declineCallback();
    }

    this.applicationRef.tick();
    this.dialogRef.close({ isConfirmed: false });
  }
}
