import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';

const AUTO_HEIGHT = 'none';
const ANIMATION_DELAY = 750;
const DETECT_CHANGES_DELAY = 100;

@Component({
  selector: 'app-detail-accordion',
  templateUrl: './detail-accordion.component.html',
  styleUrls: ['./detail-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailAccordionComponent implements AfterViewInit {
  @ViewChild('accordionContainer', { static: true })
  accordionContainer: ElementRef;
  @Input() header: string;
  @Input() value: string;
  @Input() collapsedHeight = '17px';
  @Input() expandedHeight: string;
  @Input() isExpandPossible: boolean;
  @Input() isEditPossible: boolean;
  @Input() isExpandedByDefault: boolean;
  @Input() toggleIconId: string;
  @Input() headerStyle: 'flyout';
  @Input() set panelCollapsed(collapsed: boolean) {
    if (collapsed) {
      this.collapse();
    }
  }
  @Output() onToggle = new EventEmitter();

  isExpanded = true;
  isToggling = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    if (!this.isExpandedByDefault) {
      this.isExpanded = false;
      const container = this.accordionContainer.nativeElement;
      container.style.maxHeight = this.collapsedHeight;
      this.changeDetectorRef.detectChanges();
    }
  }

  toggleHeight(event: MouseEvent): void {
    event.stopPropagation();

    if (this.isToggling) {
      return;
    }

    if (this.isExpanded) {
      this.collapse();
    } else {
      this.expand();
    }
  }

  expand(): void {
    const container = this.accordionContainer.nativeElement;

    container.style.maxHeight = `${container.scrollHeight}px`;
    this.isExpanded = true;
    this.changeDetectorRef.detectChanges();
    this.onToggle.emit();

    if (this.expandedHeight !== AUTO_HEIGHT) {
      return;
    }

    this.isToggling = true;

    setTimeout(() => {
      container.style.maxHeight = AUTO_HEIGHT;
      this.isToggling = false;
      this.changeDetectorRef.detectChanges();
    }, ANIMATION_DELAY);
  }

  collapse(): void {
    const container = this.accordionContainer.nativeElement;
    const isAutoHeight = this.expandedHeight === AUTO_HEIGHT;

    container.style.maxHeight = isAutoHeight ? `${container.scrollHeight}px` : this.collapsedHeight;
    this.isExpanded = false;
    this.changeDetectorRef.detectChanges();

    if (!isAutoHeight) {
      return;
    }

    this.isToggling = true;

    setTimeout(() => {
      container.style.maxHeight = this.collapsedHeight;
      this.isToggling = false;
      this.changeDetectorRef.detectChanges();
    }, DETECT_CHANGES_DELAY);
  }
}
