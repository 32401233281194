import { ProjectListEffects } from '@root/effects/project-list/project-list.effects';
import { EventsEffects } from '@root/effects/events/events.effects';
import { ProjectFoldersEffects } from '@root/effects/project-folders/project-folders.effects';
import { DownloadEffects } from '@root/effects/download/download.effects';
import { UserEffects } from '@root/effects/user/user.effects';
import { KeywordsEffects } from '@root/effects/keywords/keywords.effects';

export const appRootEffects = [
  ProjectListEffects,
  EventsEffects,
  ProjectFoldersEffects,
  DownloadEffects,
  UserEffects,
  KeywordsEffects
];
