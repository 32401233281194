<div
  [ngSwitch]="avatarManagerState"
  class="avatar-container"
>
  <mat-icon
    *ngSwitchCase="avatarState.SingleActiveUser"
    class="user-avatar-icon"
    svgIcon="active-user-avatar"
  ></mat-icon>
  <mat-icon
    *ngSwitchCase="avatarState.SingleInactiveUser"
    class="user-avatar-icon"
    svgIcon="inactive-user-avatar"
  ></mat-icon>
  <mat-icon
    *ngSwitchCase="avatarState.MultipleUsers"
    class="user-avatar-icon"
    svgIcon="multiple-users-avatar"
  ></mat-icon>
</div>
