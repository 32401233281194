<form
  id="terms-and-conditions-dialog"
  class="full-height"
  fxLayout="column"
  [formGroup]="termsAndConditionsForm"
>
  <div
    class="dialog-header"
    fxFlex="1 1 5"
  >
    <div
      fxLayoutAlign="center"
      class="terms-and-conditions-dialog-header"
    >
      {{ 'PROJECT.TERMS_AND_CONDITIONS.CREATE.DIALOG_HEADER' | translate }}
    </div>
  </div>
  <div
    class="dialog-paragraph"
    fxFlex="1 1 5"
  >
    <div
      fxLayoutAlign="center"
      *ngIf="!isViewAcceptedTermsAndConditions &&
        !isRequestedForSharedFolder &&
        !(isGettingTermsAndConditionsContent$ | async)"
      class="terms-and-conditions-dialog-paragraph"
    >
      {{ 'PROJECT.TERMS_AND_CONDITIONS.CREATE.DIALOG_PARAGRAPH' | translate }}
    </div>
  </div>
  <mat-dialog-content
    fxFlex="1 1 85"
    fxLayoutAlign="center center"
    *ngIf="showSpinner$ | async"
    id="terms-and-conditions-dialog-spinner"
  >
    <app-spinner></app-spinner>
  </mat-dialog-content>
  <app-empty-state
    fxFlex="1 1 85"
    fxLayoutAlign="center center"
    fxLayout="column"
    *ngIf="showLoadingError$ | async"
    class="terms-and-conditions-dialog-loading-error"
    [primaryLabel]="'PROJECT.TERMS_AND_CONDITIONS.ERROR.DEFAULT'"
    [icon]="'general-error'"
  ></app-empty-state>
  <mat-dialog-content
    fxFlex="1 1 85"
    *ngIf="!isViewAcceptedTermsAndConditions &&
     !isRequestedForSharedFolder &&
     !(isGettingTermsAndConditionsContent$ | async)"
  >
    <div class="admin-terms-and-conditions-dialog-container">
      <div class="terms-and-conditions-data">
        <mat-form-field fxFill>
          <textarea
            id="terms-and-conditions-content"
            autosize
            matInput
            minRows="3"
            [formControl]="contentControl"
            [placeholder]="'PROJECT.TERMS_AND_CONDITIONS.TEXT_PLACEHOLDER' | translate"
            [required]="true"
          ></textarea>
          <mat-error
            id="terms-and-conditions-error-messages-container"
            *ngIf="contentControl?.invalid && contentControl?.touched"
          >
            {{'PROJECT.TERMS_AND_CONDITIONS.ERROR.CREATE_EMPTY_ERROR_MESSAGE' | translate}}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-content
    fxFlex="1 1 85"
    *ngIf="isViewAcceptedTermsAndConditions ||
      isRequestedForSharedFolder &&
      !(isLoadingTermsAndConditions$ | async)"
  >
    <div class="user-terms-and-conditions-dialog-container">
      <div class="terms-and-conditions-data">
        <mat-form-field fxFill>
          <textarea
            id="terms-and-conditions-read-only-content"
            readonly
            tabindex="-1"
            autosize
            matInput
            minRows="3"
            [formControl]="contentControl"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions
    class="create-dialog-actions"
    fxFlex="1 1 10"
    fxLayout
    fxLayoutAlign="end center"
  >
    <button
      mat-button
      (click)="closeDialog()"
      id="terms-and-conditions-cancel-btn"
      class="cancel-button"
      type="button"
      *ngIf = "!isViewAcceptedTermsAndConditions"
      [mat-dialog-close]="false"
      [disabled]="isCreatingTermsAndConditions$ | async"
      tabindex="-1"
    >
      {{'PROJECT.TERMS_AND_CONDITIONS.CREATE.DIALOG_CLOSE' | translate}}
    </button>
    <app-raised-button
      [disabled]="((contentControl && !contentControl.value) ||
        (loadedContent && loadedContent === contentControl.value) ||
        (showDisabledButton$ | async)) &&
        !isRequestedForSharedFolder"
      (clicked)="saveChanges()"
      *ngIf = "!isViewAcceptedTermsAndConditions"
      id="terms-and-conditions-accept-btn"
      class="terms-and-conditions-btn"
      type="submit"
    >
      <mat-spinner
        class="terms-and-conditions-button-spinner"
        *ngIf="showButtonSpinner$ | async"
        [diameter]="progressSpinnerDiameter"
      ></mat-spinner>
      <span [class.hidden]="showButtonSpinner$ | async">
        {{ isRequestedForSharedFolder ? ('PROJECT.TERMS_AND_CONDITIONS.ACCEPT.AGREE_BUTTON' | translate ) : ('PROJECT.TERMS_AND_CONDITIONS.CREATE.PUBLISH_BUTTON' | translate) }}
      </span>
    </app-raised-button>
    <app-raised-button
      *ngIf = "isViewAcceptedTermsAndConditions"
      id="terms-and-conditions-close-btn"
      [mat-dialog-close]="false"
      type="button"
    >
    {{'PROJECT.TERMS_AND_CONDITIONS.VIEW.DIALOG_CLOSE' | translate}}
    </app-raised-button>
  </mat-dialog-actions>
</form>
