import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-content-header-back-link',
  templateUrl: './content-header-back-link.component.html',
  styleUrls: ['./content-header-back-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentHeaderBackLinkComponent {
  @Input() link: string;
  @Input() linkName: string;
  constructor(private router: Router) {}

  handleBackLink(link: string): void {
    this.router.navigate([link]);
  }
}
