import { Component, ChangeDetectionStrategy } from '@angular/core';
import { DateUtils } from '@shared/utilities/date/date.utils';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  readonly currentYear = DateUtils.Today.getFullYear();
}
