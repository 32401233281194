interface ParsedBytes {
  value: string;
  unit: string;
}

export class BytesParser {
  private static readonly units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
  private static readonly unitDivisor = 1024;

  static parse(bytes: number): ParsedBytes {
    if (bytes <= 0) {
      return { value: '0', unit: 'B' };
    }

    let i = 0;
    let bytesToParse = bytes;

    while (bytesToParse >= this.unitDivisor && i < this.units.length) {
      bytesToParse = bytesToParse / this.unitDivisor;
      i++;
    }

    const returnBytes = {
      value: bytesToParse.toFixed(bytesToParse >= 10 || i < 1 ? 0 : 1).toString(),
      unit: this.units[i]
    };

    return bytesToParse < this.unitDivisor ? returnBytes : { value: bytes.toString(), unit: 'B' };
  }
}
