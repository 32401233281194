import { Component, Input } from '@angular/core';
import { InitialsTheme } from '@shared/models/initials/initialsTheme.enum';

@Component({
  selector: 'app-initials',
  templateUrl: './initials.component.html',
  styleUrls: ['./initials.component.scss']
})
export class InitialsComponent {
  @Input() initials;
  @Input() theme: InitialsTheme;

  initialsTheme = InitialsTheme;
}
