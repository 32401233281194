import { createSelector } from '@ngrx/store';
import { appRootSelector } from '@root/selectors/app-root.selectors';
import { basicLicenseNrn, constructionAdministrationNrn } from '@user-administration/services/license-tiers-helper';

const userSelector = createSelector(appRootSelector, state => state.user);

export const selectUserLicense = createSelector(userSelector, state => state.userLicense);

export const selectUserDetails = createSelector(userSelector, state => state.userDetails);

export const selectIsCloudOnlyConstructionAdministrator = createSelector(
  selectUserLicense,
  userLicense => userLicense?.licenseTier?.nrn === constructionAdministrationNrn
);

export const selectIsCloudOnlyBasicUser = createSelector(
  selectUserLicense,
  userLicense => userLicense?.licenseTier?.nrn === basicLicenseNrn
);

export const selectIsCloudOnlyUnlicensedUser = createSelector(
  selectUserLicense,
  userLicense =>
    userLicense?.customer !== null && userLicense?.customer?.license === 'cloud' && userLicense?.licenseTier === null
);

const hybridLicenses = ['npc', 'cloud-npc'];

export const selectIsHybridUser = createSelector(selectUserLicense, userLicense =>
  hybridLicenses.includes(userLicense?.customer?.license ?? '')
);

export const selectIsExternalUser = createSelector(selectUserLicense, userLicense => userLicense?.customer === null);
