import { Injectable } from '@angular/core';
import { LocalStorageKey } from '@core/models/storage/local-storage-key.enum';

@Injectable()
export class LocalStorageService {
  setItem(key: LocalStorageKey, value: string): void {
    localStorage.setItem(key, value);
  }

  getItem(key: LocalStorageKey): string | null {
    return localStorage.getItem(key);
  }

  getFileEmailConversation(): boolean {
    return this.getItem(LocalStorageKey.FileEmailConversationSetting) === 'true';
  }

  getIsProtractor(): boolean {
    return this.getItem(LocalStorageKey.Protractor) === 'true';
  }
}
