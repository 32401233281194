import { createSelector } from '@ngrx/store';
import { appRootSelector } from '@root/selectors/app-root.selectors';

export const projectFoldersSelector = createSelector(appRootSelector, state => state.projectRootFolders);

export const selectIsSharePointSiteProviderSupported = createSelector(projectFoldersSelector, state =>
  state.projectCapabilities.some(capability => capability.includes('sharepoint_site'))
);

export const selectSearchQuery = createSelector(projectFoldersSelector, state => state.searchQuery);

export const selectIsLoading = createSelector(projectFoldersSelector, state => state.isLoading);

export const selectIsLoadingNextPage = createSelector(projectFoldersSelector, state => state.isLoadingNextPage);

export const selectExternalFolders = createSelector(projectFoldersSelector, state => state.externalFolders);

export const selectNavigation = createSelector(projectFoldersSelector, state => state.navigation);

export const selectPageIndex = createSelector(projectFoldersSelector, state => state.pageIndex);

export const selectOffsetToken = createSelector(projectFoldersSelector, state => state.offsetToken);

export const selectCurrentExternalFolder = createSelector(
  projectFoldersSelector,
  state => state.selectedExternalFolder
);

export const selectLoadingError = createSelector(projectFoldersSelector, state => state.loadingError);

export const selectIsConnecting = createSelector(projectFoldersSelector, state => state.isConnecting);

export const selectIsEditMode = createSelector(projectFoldersSelector, state => state.isEditMode);

export const selectHasFolderConnected = createSelector(projectFoldersSelector, state => state.hasFolderConnected);
