import { Activity, EventData } from '@shared/models/activities/activities.model';
import { Originator } from '@core/models/shared-item/originator.model';
import { SharePermission } from '@core/models/shared-item/share-permission.model';
import { UserDisplayService } from '@core/services/user-display/user-display.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getSubjectFromPath } from '@shared/utilities/events-mapper/events-mapper.utils';

export interface EventUser {
  email: string;
  firstName: string;
  lastName: string;
  displayName: string;
  initials: string;
}

export interface MappedActivities {
  eventType: string;
  originator: EventUser;
  path: string;
  date: string;
  subject: string | undefined;
  recipient: EventUser | undefined;
  displayValue: string;
}

export interface UntranslatedEvent {
  type: string;
  params?: Object;
}

// TODO: this service should be moved directly to files module
// new name: ActivitiesMapperService
// https://newforma.atlassian.net/browse/PROG-45468
@Injectable()
export class EventMapperService {
  constructor(private userDisplayService: UserDisplayService, private translateService: TranslateService) {}

  private translatedEventTypes = new Map();

  private readonly activityTypes = {
    /* eslint-disable @typescript-eslint/naming-convention */
    SHARE_CREATED: 'FILE.ACTIVITY_SHARED',
    SHARE_REVOKE_ALL: 'FILE.ACTIVITY_REVOKED',
    SHARE_FILE_DOWNLOADED: 'FILE.ACTIVITY_SHARED_FILE_DOWNLOADED',
    FOLDER_SHARE_CREATED: 'FILE.ACTIVITY_FOLDER_SHARE',
    PERMISSIONS_CHANGE: 'FILE.ACTIVITY_CHANGED_PERMISSION',
    FILE_DELETED: 'FILE.ACTIVITY_DELETED_FILE',
    FILE_DOWNLOADED: 'FILE.ACTIVITY_FILE_DOWNLOADED',
    FILE_UPLOADED: 'FILE.ACTIVITY_UPLOADED',
    FOLDER_CREATED: 'FILE.ACTIVITY_FOLDER_CREATED',
    FOLDER_SHARE_REVOKED: 'FILE.ACTIVITY_FOLDER_SHARE_REVOKED',
    FILE_BULK_DOWNLOADED: 'FILE.ACTIVITY_DOWNLOADED',
    FOLDER_BULK_DOWNLOADED: 'FILE.ACTIVITY_DOWNLOADED',
    SHARED_FILE_BULK_DOWNLOADED: 'FILE.ACTIVITY_DOWNLOADED',
    SHARED_FOLDER_BULK_DOWNLOADED: 'FILE.ACTIVITY_DOWNLOADED',
    FOLDER_DELETED: 'FILE.ACTIVITY_FOLDER_DELETED',
    FILE_RENAMED: 'FILE.ACTIVITY_FILE_RENAMED',
    FOLDER_RENAMED: 'FILE.ACTIVITY_FOLDER_RENAMED'
    /* eslint-enable @typescript-eslint/naming-convention */
  };

  mapActivities(events: Activity[]): MappedActivities[] {
    return events.map(event => {
      const eventType = this.getTranslatedEvent(this.getEventType(event));
      const eventSubject = this.getEventSubject(event);
      const eventRecipient = this.getRecipient(event.eventData);
      const displaySubject = eventSubject ? eventSubject : eventRecipient ? eventRecipient.displayName : '';

      return {
        eventType,
        originator: this.getEventUser(event.originator),
        path: this.getEventPath(event.eventData),
        subject: eventSubject,
        recipient: eventRecipient,
        date: event.createdOn,
        displayValue: `${eventType} ${displaySubject}`.trim()
      };
    });
  }

  private getEventUser(originator: Originator = {}): EventUser {
    return {
      email: originator.email || '',
      firstName: originator.firstName || '',
      lastName: originator.lastName || '',
      displayName: this.userDisplayService.getDisplayName(originator),
      initials: this.userDisplayService.getInitials(originator)
    };
  }

  private getEventType(activity: Activity): UntranslatedEvent {
    switch (activity.type) {
      case 'FOLDER_SHARE_CREATED':
        return { type: this.getFolderShareString(activity.eventData) };

      case 'PERMISSIONS_CHANGE':
        return { type: this.getPermissionString(activity.eventData) };

      case 'FILE_UPLOADED':
        return { type: this.getUploadString(activity.eventData) };

      case 'FILE_RENAMED':
      case 'FOLDER_RENAMED':
        return this.getRenameEvent(activity.type, activity.eventData);

      default: {
        return { type: this.activityTypes[activity.type] || 'FILE.ACTIVITY_UNKNOWN' };
      }
    }
  }

  private getFolderShareString(eventData?: EventData): string {
    if (!eventData || !eventData.permissions) {
      return 'FILE.ACTIVITY_FOLDER_SHARE';
    }

    switch (eventData.permissions) {
      case SharePermission.ReadOnly:
        return 'FILE.ACTIVITY_FOLDER_SHARE_READ_ONLY';
      case SharePermission.ReadAndUpload:
        return 'FILE.ACTIVITY_FOLDER_SHARE_READ_AND_UPLOAD';
      case SharePermission.FullAccess:
        return 'FILE.ACTIVITY_FOLDER_SHARE_FULL_ACCESS';
    }

    return 'FILE.ACTIVITY_FOLDER_SHARE';
  }

  private getPermissionString(eventData?: EventData): string {
    if (!eventData || !eventData.accessPermission) {
      return 'FILE.ACTIVITY_CHANGED_PERMISSION';
    }

    switch (eventData.accessPermission) {
      case SharePermission.ReadOnly:
        return 'FILE.ACTIVITY_CHANGED_PERMISSION_TO_READ_ONLY';
      case SharePermission.ReadAndUpload:
        return 'FILE.ACTIVITY_CHANGED_PERMISSION_TO_READ_AND_UPLOAD';
      case SharePermission.FullAccess:
        return 'FILE.ACTIVITY_CHANGED_PERMISSION_TO_FULL_ACCESS';
    }

    return 'FILE.ACTIVITY_CHANGED_PERMISSION';
  }

  private getUploadString(eventData?: EventData): string {
    if (!eventData) {
      return 'FILE.ACTIVITY_UPLOADED';
    }

    return eventData.fileOverwritten ? 'FILE.ACTIVITY_UPLOAD_OVERWRITE' : 'FILE.ACTIVITY_UPLOADED';
  }

  private getRenameEvent(type: string, eventData?: EventData): UntranslatedEvent {
    if (!eventData || !eventData.oldName) {
      return { type: 'FILE.ACTIVITY_RENAMED_GENERIC' };
    }

    return { type: this.activityTypes[type], params: { oldName: eventData.oldName } };
  }

  private getRecipient(eventData?: EventData): EventUser | undefined {
    return eventData && eventData.recipient ? this.getEventUser(eventData.recipient) : undefined;
  }

  private getEventSubject(activity: Activity): string | undefined {
    const eventData = activity.eventData;

    if (!eventData) {
      return;
    }

    if (
      [
        'FILE_BULK_DOWNLOADED',
        'FOLDER_BULK_DOWNLOADED',
        'SHARED_FILE_BULK_DOWNLOADED',
        'SHARED_FOLDER_BULK_DOWNLOADED'
      ].includes(activity.type)
    ) {
      return getSubjectFromPath(eventData.path);
    }

    switch (true) {
      case !!eventData.fileName:
        return eventData.fileName;

      case !!eventData.createdFolderName:
        return eventData.createdFolderName;

      case !!eventData.deletedFolderName:
        return eventData.deletedFolderName;

      case !!eventData.newName:
        return eventData.newName;
    }

    return;
  }

  private getEventPath(eventData?: EventData): string {
    if (!eventData) {
      return '';
    }

    return eventData.path || '';
  }

  private getTranslatedEvent(event: UntranslatedEvent): string {
    if (event.params) {
      return this.translateService.instant(event.type, event.params);
    }

    if (this.translatedEventTypes.has(event.type)) {
      return this.translatedEventTypes.get(event.type);
    }

    const translatedType = this.translateService.instant(event.type);

    this.translatedEventTypes.set(event.type, translatedType);

    return translatedType;
  }
}
