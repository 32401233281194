import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-chip-item',
  templateUrl: './chip-item.component.html',
  styleUrls: ['./chip-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipItemComponent {
  constructor(private changeDetectorRef: ChangeDetectorRef) {}
  @Input() removable = true;
  @Input() selectable = false;
  @Input() keyword: string;
  @Input() subtitle: string;
  @Output() onRemove = new EventEmitter();
  @Output() onClick = new EventEmitter();
  showHoverCard: boolean;

  remove(): void {
    this.onRemove.emit();
  }
  click(): void {
    this.onClick.emit();
  }
  mouseOver(): void {
    this.showHoverCard = true;
    this.changeDetectorRef.detectChanges();
  }
  mouseOut(): void {
    this.showHoverCard = false;
    this.changeDetectorRef.detectChanges();
  }
}
