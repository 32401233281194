import { KeywordModel } from '@core/models/project/keyword.model';

export function compareKeywords(firstKeyword: KeywordModel, secondKeyword: KeywordModel): number {
  if (firstKeyword.displayOrder !== undefined && secondKeyword.displayOrder === undefined) {
    return -1;
  }

  if (firstKeyword.displayOrder === undefined && secondKeyword.displayOrder !== undefined) {
    return 1;
  }

  if (firstKeyword.displayOrder === undefined && secondKeyword.displayOrder === undefined) {
    return 0;
  }

  return (firstKeyword.displayOrder as number) - (secondKeyword.displayOrder as number);
}
