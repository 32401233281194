<mat-toolbar class="navigation-toolbar">
  <mat-icon
    svgIcon="newforma"
    class="navigation-toolbar-logo"
    fxFlexAlign="center"
    routerLink="/projects"
    (click)="menuOptionSelected()"
  ></mat-icon>
  <div
    class="hamburger-menu"
    [class.is-active]="isSidebarOpen"
    (click)="toggleSidebar()"
  >
    <span class="hamburger-menu-line"></span>
    <span class="hamburger-menu-line"></span>
    <span class="hamburger-menu-line"></span>
  </div>
</mat-toolbar>
<div
  [class.opened]="isSidebarOpen"
  [class.closed]="!isSidebarOpen"
  class="sidebar-container"
>
  <mat-icon
    svgIcon="newforma"
    class="sidebar-newforma-logo"
    fxFlexAlign="center"
    routerLink="/projects"
    (click)="menuOptionSelected()"
  ></mat-icon>
  <div
    class="sidebar-content"
    cdkScrollable
  >
    <div class="sidebar-body">
      <a
        id="sidebar-project-navigation-link"
        class="sidebar-menu-item"
        routerLink="/projects"
        routerLinkActive="active"
        (click)="menuOptionSelected()"
      >
        <mat-icon
          class="sidebar-menu-item-icon"
          svgIcon="menu-projects"
        ></mat-icon>
        <span class="sidebar-menu-item-text">
          {{ 'APP.PROJECTS' | translate }}
        </span>
      </a>
      <a
        id="sidebar-action-items-navigation-link"
        *ngIf="isActionItemsSupported"
        class="sidebar-menu-item"
        routerLink="/actionitems"
        routerLinkActive="active"
        (click)="menuOptionSelected()"
      >
        <mat-icon
          class="sidebar-menu-item-icon"
          svgIcon="menu-action-items"
        ></mat-icon>
        <span class="sidebar-menu-item-text">
          {{ 'APP.ACTION_ITEMS' | translate }}
        </span>
      </a>
      <a
        id="sidebar-submittals-navigation-link"
        *ngIf="isSubmittalsSupported"
        class="sidebar-menu-item"
        [routerLink]="['/submittals', submittalsRoutePath]"
        [routerLinkActiveOptions]="{ exact: false }"
        routerLinkActive="active"
        (click)="menuOptionSelected()"
      >
        <mat-icon
          class="sidebar-menu-item-icon"
          svgIcon="menu-submittals"
        ></mat-icon>
        <span class="sidebar-menu-item-text">
          {{ 'APP.SUBMITTALS' | translate }}
        </span>
      </a>
      <a
        id="sidebar-rfis-navigation-link"
        *ngIf="isRfisSupported"
        class="sidebar-menu-item"
        [routerLink]="['/rfis', rfisRoutePath]"
        [routerLinkActiveOptions]="{ exact: false }"
        routerLinkActive="active"
        (click)="menuOptionSelected()"
      >
        <mat-icon
          class="sidebar-menu-item-icon"
          svgIcon="menu-rfis"
        ></mat-icon>
        <span class="sidebar-menu-item-text">
          {{ 'APP.RFIS' | translate }}
        </span>
      </a>
      <a
        id="sidebar-email-navigation-link"
        *ngIf="isEmailFilingSupported"
        class="sidebar-menu-item"
        routerLink="/emails"
        routerLinkActive="active"
        (click)="menuOptionSelected()"
      >
        <mat-icon
          class="sidebar-menu-item-icon"
          svgIcon="menu-file-email"
        ></mat-icon>
        <span class="sidebar-menu-item-text">
          {{ 'APP.FILE_EMAIL' | translate }}
        </span>
      </a>
    </div>
    <div class="sidebar-footer">
      <a
        class="sidebar-menu-item-close-wrapper sidebar-menu-item"
        (click)="toggleSidebar()"
      >
        <mat-icon
          class="sidebar-menu-item-icon sidebar-menu-item-close"
          svgIcon="menu-hide-sidebar"
        ></mat-icon>
        <span class="sidebar-hide-text">
          {{ 'APP.HIDE_SIDEBAR' | translate }}
        </span>
      </a>
      <app-sidebar-submenu
        [isSidebarOpen]="isSidebarOpen"
        [isEulaSupported]="isEulaSupported"
        (optionSelected)="menuOptionSelected()"
      ></app-sidebar-submenu>
      <app-user-menu
        [isMobile]="true"
        [isSidebarOpen]="isSidebarOpen"
        [isUserMobileMenuOpen]="isUserMenuOpen"
        (optionSelected)="toggleSidebar()"
        (toggleUserMenu)="toggleUserMenu($event)"
      ></app-user-menu>
    </div>
  </div>
</div>
