import { createAction, props } from '@ngrx/store';
import { DialogError } from '@shared/models/project-folders/dialog-error.type';
import { ProjectFoldersModel } from '@shared/models/project-folders/project-folders.model';
import { Omit } from '@architecture/type-helpers';
import { ExternalFoldersResponse } from '@core/models/external-folder/external-folders.response.model';
import { ProjectsDialogMode } from '@projects/models/projects-dialog/projects-dialog-mode.model';
import { ExternalFolder } from '@core/models/external-folder/external-folder.model';

const prefix = '[PROJECT_FOLDERS]';

interface InitProjectFoldersPayload {
  isEditMode?: boolean;
  hasFolderConnected?: boolean;
  projectCapabilities?: string[];
}
export const initProjectFolders = createAction(
  `${prefix} INIT_PROJECT_FOLDERS`,
  ({ isEditMode = false, hasFolderConnected = false, projectCapabilities = [] }: InitProjectFoldersPayload = {}) => ({
    isEditMode,
    hasFolderConnected,
    projectCapabilities
  })
);

export const openProjectFoldersDialog = createAction(`${prefix} OPEN_PROJECT_FOLDERS_DIALOG`);

export const closeProjectFoldersDialog = createAction(`${prefix} CLOSE_PROJECT_FOLDERS_DIALOG`);

export const setProjectFoldersCapabilities = createAction(
  `${prefix} SET_PROJECT_FOLDERS_CAPABILITIES`,
  props<{ capabilities: string[] }>()
);

export const loadExternalFolders = createAction(
  `${prefix} LOAD_EXTERNAL_FOLDERS`,
  props<{ query?: string; parentNrn?: string }>()
);

export const loadExternalFoldersNextPage = createAction(
  `${prefix} LOAD_EXTERNAL_FOLDERS_NEXT_PAGE`,
  props<{ parentNrn?: string }>()
);

export const completeLoadingExternalFolders = createAction(
  `${prefix} COMPLETE_LOADING_EXTERNAL_FOLDERS`,
  props<ExternalFoldersResponse>()
);

export const completeLoadingExternalFoldersNextPage = createAction(
  `${prefix} COMPLETE_LOADING_EXTERNAL_FOLDERS_NEXT_PAGE`,
  props<ExternalFoldersResponse>()
);

export const selectExternalFolder = createAction(`${prefix} SELECT_EXTERNAL_FOLDER`, props<{ nrn: string }>());
export const loadNavigation = createAction(`${prefix} LOAD_NAVIGATION`, props<{ navigation: ExternalFolder[] }>());

export const loadCurrentPageIndex = createAction(`${prefix} LOAD_CURRENT_PAGE_INDEX`, props<{ pageIndex: number }>());

export const updateLoadExternalFoldersError = createAction(
  `${prefix} UPDATE_LOAD_EXTERNAL_FOLDERS_ERROR`,
  props<{ error: DialogError | null }>()
);

export const createProjectFolder = createAction(
  `${prefix} CREATE_PROJECT_FOLDER`,
  (projectsDialogMode: ProjectsDialogMode | null = null) => ({
    projectsDialogMode
  })
);

export const updateProjectFolder = createAction(
  `${prefix} UPDATE_PROJECT_FOLDER`,
  props<{ projectFolder: Omit<ProjectFoldersModel, 'version'> }>()
);

export const createProjectFolderSuccess = createAction(`${prefix} CREATE_PROJECT_FOLDER_SUCCESS`);

export const reloadFiles = createAction(`${prefix} RELOAD_FILES`, props<{ nrn: string }>());

export const createProjectFolderFailed = createAction(`${prefix} CREATE_PROJECT_FOLDER_FAILED`);
