<div class="upload-progress-container" [ngClass]="{'blue-bg' : uploadProgress === 0, 'green-bg' : uploadProgress === 100, 'error-bg': uploadError}">
  <div class="upload-progress">
    <div class="progress-text">
      <span
        *ngIf="uploadProgress < 100 && !uploadError"
        id="upload-progress-file-label"
      >
        {{'UPLOAD.UPLOADING' | translate }} {{fileName}}
        <span
          *ngIf="filesRemaining?.count > 0"
          id="upload-progress-remaining"
        >
          - {{'UPLOAD.UPLOADS_REMAINING' | translate: filesRemaining }}
        </span>
        <span
          *ngIf="destinationResourceName"
          id="destination-resource"
        >
          for {{destinationResourceName}}
        </span>
      </span>
      <span
        *ngIf="uploadProgress === 100 && !uploadError"
        id="upload-completed"
      >
        {{'UPLOAD.COMPLETED' | translate }} {{fileName}}
        <span
          *ngIf="filesRemaining?.count > 0"
          id="upload-completed-files-remaining"
        >
          - {{'UPLOAD.UPLOADS_REMAINING' | translate: filesRemaining }}
        </span>
      </span>
      <span
        *ngIf="uploadError"
        id="upload-error"
      >
        <b>{{'UPLOAD.ERROR' | translate }}</b>
        {{'UPLOAD.CANT_UPLOAD' | translate }} {{fileName}}
      </span>
    </div>
    <div *ngIf="!uploadError">
      <mat-progress-bar
        mode="determinate"
        [value]="uploadProgress"
        color="accent"
        id="progress-bar"
      ></mat-progress-bar>
    </div>
  </div>
  <mat-icon
    *ngIf="uploadProgress === 100"
    class="upload-icon"
    id="upload-success"
  >
    check
  </mat-icon>
</div>
