import { Pipe, PipeTransform } from '@angular/core';
import { KeywordModel } from '@core/models/project/keyword.model';
import { compareKeywords } from '@shared/utilities/compare-keywords/compare-keywords';

@Pipe({
  name: 'keywordsLabel'
})
export class KeywordsLabelPipe implements PipeTransform {
  transform(keywords: KeywordModel[] | null): string {
    if (!keywords) {
      return '';
    }

    return [...keywords]
      .sort(compareKeywords)
      .map(keyword => keyword.name)
      .join(', ');
  }
}
