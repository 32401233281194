import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';
import { BaseSearchableItemModel, SearchableItemViewModel } from '@core/models/project/keyword.model';
import { debounce, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { EMPTY, Subject, timer } from 'rxjs';
import { BaseSearchableDetails } from '@shared/models/keywords/keywords-details';

@Component({
  selector: 'app-single-item-search-selector',
  templateUrl: './single-item-search-selector.component.html',
  styleUrls: ['./single-item-search-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleItemSearchSelectorComponent implements OnInit, OnDestroy, OnChanges {
  constructor(private changeDetectorRef: ChangeDetectorRef) {}
  @ViewChild('searchInput', { read: MatAutocompleteTrigger, static: false })
  autoCompleteTrigger: MatAutocompleteTrigger;

  @ViewChild('searchInput', { static: false })
  searchInput: ElementRef<HTMLInputElement>;

  @Input() set itemList(value: BaseSearchableDetails) {
    this.hasError = value.hasError;
    this.items = value.items || [];
    this.isLoading = value.isLoading;
    this.hasMoreItemsToLoad = !!value.paging;

    if (this.customValidator) {
      const matchingKeyword = this.items.find(keyword => keyword.name === this.searchControl.value);
      this.isCustomValidatorValid = this.customValidator(this.searchControl.value);
      this.tooltipValue = matchingKeyword?.description;
      this.matchingKeyword = !!matchingKeyword;
    }
  }

  @Input() set selectedItem(item: BaseSearchableItemModel | null) {
    this.item = item;
    if (item) {
      this.searchControl.patchValue(item.name);
    }
  }
  @Input() loadingErrorMessage: string;
  @Input() label: string;
  @Input() elementId: string;
  @Input() shouldShowDescription: boolean;
  @Input() set displayTooltip(shouldDisplayTooltip: boolean) {
    this.shouldDisplayTooltip = shouldDisplayTooltip;
    this.toggleSearchControlEnabled(shouldDisplayTooltip);
  }
  @Input() tooltipErrorMessage: string;
  @Input() isRequired: boolean;
  @Input() validationErrorMessage: string;
  @Input() isDisabled: boolean;
  @Input() isClientSideFiltering: boolean;
  @Input() noResultErrorMessage: string;
  @Input() customMessage: string;
  @Input() customValidator?: (value: string) => boolean;
  @Input() projectNrn: string | null;

  @Output() onPanelOpened = new EventEmitter();
  @Output() onPanelClosed = new EventEmitter();
  @Output() onItemChanged = new EventEmitter<BaseSearchableItemModel | null>();
  @Output() onItemListQuery = new EventEmitter<string>();
  @Output() onError = new EventEmitter();

  hasError: boolean;
  isLoading: boolean;
  isFocused: boolean;
  shouldDisplayTooltip: boolean;
  query: string;
  item: BaseSearchableItemModel | null;
  items: BaseSearchableItemModel[] = [];
  filteredItems: BaseSearchableItemModel[] = [];
  hasMoreItemsToLoad: boolean;
  showHoverCard: boolean;
  tooltipValue?: string;
  shouldDisplaySuffixCustomMessage: boolean;
  shouldDisplayCustomMessage: boolean;

  private matchingKeyword: boolean;
  private displayInitHint: boolean;
  private isCustomValidatorValid: boolean;
  private readonly searchControlName = 'searchControl';
  private readonly inputDebounceTime = 200;
  private destroyComponent = new Subject();

  form = new FormGroup({
    [this.searchControlName]: new FormControl('')
  });

  ngOnInit(): void {
    if (this.customValidator) {
      this.displayInitHint = true;
    }

    this.subscribeToFormChanges();

    const inputValue = this.searchControl.value;
    if (inputValue) {
      this.isClientSideFiltering ? this.filter(inputValue) : this.onItemListQuery.emit(inputValue);
    }

    this.setDisplayInputMessages();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.projectNrn?.previousValue !== changes.projectNrn?.currentValue && !changes.projectNrn?.firstChange) {
      this.item = null;
      this.searchControl.patchValue('');
      this.onItemChanged.emit(null);
    }

    this.setDisplayInputMessages();
  }

  ngOnDestroy(): void {
    this.destroyComponent.next();
    this.destroyComponent.complete();
  }

  private setDisplayInputMessages(): void {
    if (this.customValidator) {
      this.shouldDisplaySuffixCustomMessage =
        this.isCustomValidatorValid && !!this.customMessage && !this.matchingKeyword;
      this.shouldDisplayCustomMessage = this.displayInitHint || this.isCustomValidatorValid;
    }
  }

  get searchControl(): AbstractControl {
    return <AbstractControl>this.form.get(this.searchControlName);
  }

  selectItem(item: BaseSearchableItemModel): void {
    this.autoCompleteTrigger.closePanel();
    this.matchingKeyword = true;

    if (item.name !== this.searchControl.value) {
      this.searchControl.patchValue(item.name);
      this.updateFormValidity();
    }
  }

  panelOpened(): void {
    this.onPanelOpened.emit();
  }

  panelClosed(): void {
    this.onPanelClosed.emit();
  }

  setIsInputFocused(isFocused: boolean): void {
    this.isFocused = isFocused;
    if (this.isFocused) {
      return;
    }
    this.updateFormValidity();
  }

  private updateFormValidity(): void {
    if (this.customValidator) {
      this.checkCustomValidator();
    } else {
      this.checkDefaultValidator();
    }
  }

  focusOnClick(): void {
    this.isFocused = true;
    this.searchInput.nativeElement.focus();
  }

  openAutoCompletePanel(): void {
    this.autoCompleteTrigger._onChange(this.searchControl.value || '');
    this.autoCompleteTrigger.openPanel();
  }

  mouseOver(): void {
    if (this.tooltipValue) {
      this.showHoverCard = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  mouseOut(): void {
    if (this.tooltipValue) {
      this.showHoverCard = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  private filter(query: string): void {
    const lowerCaseQuery = query.toLowerCase();
    this.filteredItems = query.length
      ? this.items.filter(
          item =>
            item.name.toLowerCase().includes(lowerCaseQuery) || item.description?.toLowerCase().includes(lowerCaseQuery)
        )
      : [];
  }

  private toggleSearchControlEnabled(enableControl: boolean): void {
    if (!enableControl) {
      this.searchControl.enable({ emitEvent: false });
    } else {
      this.searchControl.disable({ emitEvent: false });
    }
  }

  private subscribeToFormChanges(): void {
    this.searchControl.valueChanges
      .pipe(
        debounce(() => (this.isClientSideFiltering ? EMPTY : timer(this.inputDebounceTime))),
        distinctUntilChanged(),
        takeUntil(this.destroyComponent)
      )
      .subscribe(value => {
        const formValue = value.name ?? value;
        this.isClientSideFiltering ? this.filter(formValue) : this.onItemListQuery.emit(formValue);
      });
  }

  private checkCustomValidator(): void {
    if (!this.customValidator) {
      return;
    }

    const isEmpty = !this.searchControl.value.length;
    const isInvalid =
      (this.searchControl.value.length > 0 && !this.customValidator(this.searchControl.value)) ||
      (isEmpty && this.isRequired);

    if (isInvalid) {
      this.setFormError();
      this.isCustomValidatorValid = false;
    } else if (isEmpty) {
      this.onItemChanged.emit(null);
    } else {
      this.onItemChanged.emit({ name: this.searchControl.value, type: 'generic' } as SearchableItemViewModel);
      this.isCustomValidatorValid = true;
      this.onPanelClosed.emit();
    }
  }

  private checkDefaultValidator(): void {
    const matchingKeyword = this.items.find(keyword => keyword.name === this.searchControl.value);
    const isEmpty = !this.searchControl.value.length;
    const isInvalid = (!matchingKeyword && this.searchControl.value.length) || (isEmpty && this.isRequired);
    if (isInvalid) {
      this.setFormError();
    } else if (isEmpty) {
      this.onItemChanged.emit(null);
    } else if (matchingKeyword && matchingKeyword.name !== this.item?.name) {
      this.onItemChanged.emit(matchingKeyword);
    }
  }

  private setFormError(): void {
    this.searchControl.setErrors({ incorrect: true });
    this.onItemChanged.emit(null);
    this.onError.emit();
  }
}
