import { registerLocaleData, DatePipe } from '@angular/common';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from '@shared/components/app/app.component';
import { IconRegistry } from '@shared/utilities/icons/icons.registry';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { BrowserCompatibilityWarningComponent } from '@shared/components/browser-compatibility-warning/browser-compatibility-warning.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { WalkMeSnippetComponent } from '@shared/components/walk-me-snippet/walk-me-snippet.component';
import { UserVoiceSnippetComponent } from '@shared/components/user-voice-snippet/user-voice-snippet.component';
import localeDe from '@angular/common/locales/de';
import localeEnGb from '@angular/common/locales/en-GB';
import { ConfigurationService } from '@core/services/configuration/configuration.service';
import { CoreModule } from '@core/core.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { appRootReducer, appRootReducerProvider } from '@app/root/reducers';
import { appRootEffects } from '@app/root/effects';
import { environment } from '../environments/environment';
import { TermsAndConditionsModule } from '@app/terms-and-conditions/terms-and-conditions.module';
import { InternationalizationService } from '@core/services/internationalization/internationalization.service';

registerLocaleData(localeDe, 'de');
registerLocaleData(localeEnGb, 'en-GB');

export function appInitializeFactory(configurationService: ConfigurationService, iconRegistry: IconRegistry): Function {
  return async () => {
    iconRegistry.registerDefaultSvgIcons();
    await configurationService.load();
  };
}

export function createTranslateServiceGlobalLocale(translateService: TranslateService): string {
  return translateService.currentLang;
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
const storeConfiguration = {
  runtimeChecks: {
    strictStateImmutability: true,
    strictActionImmutability: true,
    strictStateSerializability: false,
    strictActionSerializability: false,
    strictActionWithinNgZone: true
  }
};

@NgModule({
  declarations: [AppComponent, BrowserCompatibilityWarningComponent, WalkMeSnippetComponent, UserVoiceSnippetComponent],
  imports: [
    TermsAndConditionsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    StoreModule.forRoot(appRootReducer, storeConfiguration),
    EffectsModule.forRoot(appRootEffects),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializeFactory,
      deps: [ConfigurationService, IconRegistry],
      multi: true
    },
    {
      provide: LOCALE_ID,
      deps: [InternationalizationService],
      useFactory: (internationalizationService: InternationalizationService) =>
        internationalizationService.getApplicationLanguage()
    },
    DatePipe,
    IconRegistry,
    {
      provide: MAT_DATE_LOCALE,
      deps: [TranslateService],
      useFactory: createTranslateServiceGlobalLocale
    },
    { provide: 'ENVIRONMENT', useValue: environment },
    appRootReducerProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
