import { Directive, ElementRef, EventEmitter, Output, Input } from '@angular/core';
import { DomService } from '@core/services/dom/dom.service';

@Directive({
  selector: '[appDynamicScrollbarPadding]'
})
export class DynamicScrollbarPaddingDirective {
  @Input() set isLoading(loading: boolean) {
    if (!loading) {
      this.handleScrollBar();
    }
  }
  @Output() onPaddingChanged = new EventEmitter<string>();

  constructor(private elementRef: ElementRef, private domService: DomService) {}

  private handleScrollBar(): void {
    setTimeout(() => {
      if (!this.elementRef) {
        return;
      }

      const hasScroll = this.domService.hasElementScroll(this.elementRef);
      const headerRightPadding = hasScroll ? `${this.domService.getScrollbarWidth()}px` : `0px`;
      this.onPaddingChanged.emit(headerRightPadding);
    }, 0);
  }
}
