import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EmailOnlyViewModel, TeamResourceViewModel } from '@shared/models/team-resource/team-resource.view.model';
import {
  areEqual,
  hasEmail,
  hasNrn,
  isEmailOnly,
  isTeamGroup,
  isTeamMember,
  isTeamMemberWithoutEmail
} from '@shared/utilities/team-resource/team-resource.utils';
import { TeamResourceViewModelType } from '@shared/models/team-resource/team-resource-view-model.type';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { ENTER, SPACE, TAB } from '@angular/cdk/keycodes';
import { StringUtils } from '@shared/utilities/BaseTypes/string.utils';
import { debounceTime, distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';
import { InitialsTheme } from '@shared/models/initials/initialsTheme.enum';
import { TeamResourceService } from '@shared/services/team-resource/team-resource.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticationCredentialProvider } from '@core/services/authentication/authentication.credential.provider';

interface TeamMembersSelectorValidationResult {
  allEmailsValid: boolean;
  allDomainsValid: boolean;
  empty: boolean;
  validEmails: EmailOnlyViewModel[];
  invalidEmails: string[];
  invalidDomains: string[];
}

export type SelectorMode = 'default' | 'inline-edit' | 'create-panel';

@Component({
  selector: 'app-team-members-selector',
  templateUrl: './team-members-selector.component.html',
  styleUrls: ['./team-members-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamMembersSelectorComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('chipList', { static: true })
  chipList;
  @ViewChild('auto', { static: true })
  matAutocomplete: MatAutocomplete;
  @ViewChild('emailInput', { static: true })
  emailInput: ElementRef<HTMLInputElement>;
  @ViewChild('emailInput', { read: MatAutocompleteTrigger, static: true })
  autoCompleteTrigger: MatAutocompleteTrigger;
  private readonly separator = ';';
  private readonly emailStartingDelimiter = '<';
  private readonly emailClosingDelimiter = '>';
  private isFilterSet = false;
  private filterSubscription: Subscription;
  private destroyComponent = new Subject();

  @Input() currentMode: SelectorMode = 'default';
  @Input() parentFormGroup: FormGroup = new FormGroup({
    emailAddressControl: new FormControl('')
  });
  @Input() fieldErrorMessageId: string;
  @Input() customFieldErrorMessageId: string;
  @Input() fieldLabelId?: string;
  @Input() inputId = 'email-address-selector';
  @Input() projectNrn: string | null;
  @Input() inputPlaceholder?: string;
  @Input() panelWidth = '340px';
  @Input() isRequired: boolean;
  @Input() limitSelectedMembers: boolean;

  @Input() set teamMembers(teamMembers: TeamResourceViewModel[]) {
    this.defaultTeamResources = teamMembers;
    this.setSelectedResources(this.defaultTeamResources);
  }
  @Input() shouldShowSearchIcon = true;
  @Input() domainNameForValidation = '';
  @Input() shouldHideInputPlaceholder = false;
  @Input() disableEmailsControl: boolean;
  @Input() tooltipMessage: string;
  @Output() teamResourcesChange = new EventEmitter<TeamResourceViewModel[]>();
  @Output() teamResourcesError = new EventEmitter<HttpErrorResponse>();

  defaultTeamResources?: TeamResourceViewModel[];
  selectedTeamResources: TeamResourceViewModel[] = [];
  separatorKeysCodes: number[] = [ENTER, SPACE];
  filteredTeamResources: Observable<TeamResourceViewModel[]> = of([]);
  isLoading$ = this.teamResourcesService.isLoading$;
  isCurrentUserAlreadyAssignedTo: boolean;
  isInputFocused: boolean;
  isDisabled = false;
  private currentUserEmail?: string;

  initialsTheme = InitialsTheme;
  isEmailErrorVisible: boolean;
  isDomainErrorVisible: boolean;

  constructor(
    private teamResourcesService: TeamResourceService,
    private changeDetectorRef: ChangeDetectorRef,
    private authenticationCredentialProvider: AuthenticationCredentialProvider
  ) {}

  ngOnInit(): void {
    this.subscribeToFormChanges();
    this.currentUserEmail = this.authenticationCredentialProvider.getActiveUserEmail();
    this.checkIfCurrentUserAlreadyAssigned();

    this.filteredTeamResources = this.teamResourcesService.teamResources.pipe(
      map(resources => resources.filter(resource => !isTeamMemberWithoutEmail(resource)))
    );

    this.resetFilteredTeamResources();

    if (this.defaultTeamResources) {
      this.setSelectedResources(this.defaultTeamResources);
    }

    this.configureCurrentMode();

    this.setShouldHideInputPlaceholder();
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.projectNrn && simpleChanges.projectNrn.previousValue !== simpleChanges.projectNrn.currentValue) {
      if (simpleChanges.projectNrn.firstChange) {
        this.filterTeamResources('');
      }

      this.setIsValid(true);
      this.clearEmailInput();

      if (this.currentMode === 'create-panel' && !simpleChanges.projectNrn.firstChange) {
        this.clearSelectedResources();
      }
    }

    this.setShouldHideInputPlaceholder();

    if (this.disableEmailsControl) {
      this.parentFormGroup.controls.emailAddressControl.disable();
    } else if (!this.isDisabled && !this.disableEmailsControl) {
      this.parentFormGroup.controls.emailAddressControl.enable();
    }
  }

  ngOnDestroy(): void {
    this.destroyComponent.next();
    this.destroyComponent.complete();
  }

  private setSelectedResources(resources: TeamResourceViewModel[]): void {
    this.selectedTeamResources = resources;
    this.isDisabled = this.limitSelectedMembers && this.selectedTeamResources.length > 0;
    if (this.isDisabled) {
      this.emailInput.nativeElement.blur();
      this.parentFormGroup.controls.emailAddressControl.disable();
    }

    this.changeDetectorRef.detectChanges();
  }

  removeTeamResource(teamResource: TeamResourceViewModel): void {
    this.setSelectedResources(this.selectedTeamResources.filter(resource => !areEqual(teamResource, resource)));
    this.teamResourcesChange.emit(this.selectedTeamResources);

    if (this.isDisabled) {
      this.parentFormGroup.controls.emailAddressControl.enable();
    }

    this.checkIfCurrentUserAlreadyAssigned();
    this.autoCompleteTrigger.closePanel();

    this.setIsValid(this.selectedTeamResources.length > 0);
    this.setShouldHideInputPlaceholder();
  }

  closeAutocompletePanel(): void {
    this.autoCompleteTrigger.closePanel();
  }

  clearSelectedResources(): void {
    this.selectedTeamResources = [];
    this.clearEmailInput();
    this.teamResourcesChange.emit(this.selectedTeamResources);
    this.checkIfCurrentUserAlreadyAssigned();
  }

  tryAddEmails(event: any): void {
    this.setIsEmailInputFocused(false);

    if (this.wasTeamResourceSelected(event)) {
      this.clearEmailInput();
      event.stopPropagation();
      return;
    }

    this.addEmails(event.target.value);
  }

  addEmails(emails: string): void {
    if (!this.validateEmailsInput(emails)) {
      return;
    }

    const {
      allEmailsValid,
      allDomainsValid,
      empty,
      validEmails,
      invalidEmails,
      invalidDomains
    } = this.parseEmailsString(emails);

    this.setIsValid(allEmailsValid, allDomainsValid);

    if (!allEmailsValid || !allDomainsValid) {
      const emailsError = invalidEmails.join(this.separator);
      const domainError = invalidDomains.join(this.separator);
      this.emailInput.nativeElement.value = emailsError.concat(domainError);
    } else {
      this.clearEmailInput();
    }

    if (!empty) {
      this.setSelectedResources([...this.selectedTeamResources, ...validEmails]);
    }

    this.teamResourcesChange.emit(this.selectedTeamResources);
    this.checkIfCurrentUserAlreadyAssigned();
  }

  parseMatChipInput(emails: string): void {
    this.autoCompleteTrigger.closePanel();
    this.addEmails(emails);
  }

  private validateEmailsInput(emails: string): boolean {
    if (!emails) {
      const isValid = !(this.isRequired && this.selectedTeamResources.length === 0);
      this.setIsValid(isValid);
      return false;
    }

    if (!this.isEmailBaseValidation(emails)) {
      this.setIsValid(false);
      return false;
    }

    return true;
  }

  private parseEmailsString(emails: string): TeamMembersSelectorValidationResult {
    const uniqueEmails = this.splitAndFilterEmails(emails);

    const validEmails: EmailOnlyViewModel[] = [];
    const invalidEmails: string[] = [];
    const invalidDomains: string[] = [];

    uniqueEmails.forEach(email => {
      if (!StringUtils.validateEmailString(email)) {
        invalidEmails.push(email);
      } else if (this.domainNameForValidation.length && !email.endsWith(this.domainNameForValidation)) {
        invalidDomains.push(email);
      } else {
        validEmails.push({ email, type: TeamResourceViewModelType.Email });
      }
    });

    return {
      allEmailsValid: !invalidEmails.length,
      allDomainsValid: !invalidDomains.length,
      empty: !validEmails.length,
      validEmails,
      invalidEmails,
      invalidDomains
    };
  }

  selectTeamResource(selectedTeamResource: TeamResourceViewModel): void {
    const isUnique = !this.selectedTeamResources.find(resource =>
      isTeamMember(selectedTeamResource) || isEmailOnly(selectedTeamResource)
        ? hasEmail(resource, selectedTeamResource.email)
        : isTeamGroup(selectedTeamResource)
        ? hasNrn(resource, selectedTeamResource.nrn)
        : false
    );

    this.autoCompleteTrigger.closePanel();
    this.setIsValid(true);
    this.clearEmailInput();
    this.focusEmailInput();

    if (isUnique) {
      this.setSelectedResources([...this.selectedTeamResources, selectedTeamResource]);
      this.checkIfCurrentUserAlreadyAssigned();
    }

    this.resetFilteredTeamResources();

    this.teamResourcesChange.emit(this.selectedTeamResources);
  }

  filterTeamResources(filter: string): void {
    if (this.filterSubscription && !this.filterSubscription.closed) {
      this.filterSubscription.unsubscribe();
    }

    if (!this.projectNrn) {
      this.resetFilteredTeamResources();
      return;
    }

    this.filterSubscription = this.teamResourcesService
      .filterTeamResources(filter, this.projectNrn)
      .pipe(takeUntil(this.destroyComponent))
      .subscribe(
        () => {},
        (error: HttpErrorResponse) => {
          this.teamResourcesError.emit(error);
        }
      );
  }

  updateIsFilterSet(isFilterSet: boolean): void {
    this.isFilterSet = isFilterSet;
    this.teamResourcesChange.emit(this.selectedTeamResources);
  }

  private resetFilteredTeamResources(): void {
    this.teamResourcesService.resetTeamResources();
  }

  private setIsValid(isValid: boolean, isCustomValid: boolean = true): void {
    this.chipList.errorState = !isValid || !isCustomValid;
    this.isEmailErrorVisible = !isValid;
    this.isDomainErrorVisible = isValid && !isCustomValid;

    if (!this.parentFormGroup.controls.emailAddressControl) {
      return;
    }

    const errors: { incorrectEmail?: boolean; incorrectDomainName?: boolean } = {};
    if (!isValid) {
      errors.incorrectEmail = !isValid;
    }
    if (!isCustomValid) {
      errors.incorrectDomainName = !isCustomValid;
    }

    this.parentFormGroup.controls.emailAddressControl.setErrors(isValid && isCustomValid ? null : errors);
  }

  private isEmailBaseValidation(emails: string): boolean {
    return emails.includes('@') && emails.includes('.');
  }

  private clearEmailInput(): void {
    this.updateIsFilterSet(false);
    this.emailInput.nativeElement.value = '';
    this.parentFormGroup.patchValue({ emailAddressControl: '' });
  }

  private subscribeToFormChanges(): Subscription {
    return this.parentFormGroup.valueChanges
      .pipe(
        tap(() => this.processFilterChange(this.emailInput.nativeElement.value)),
        debounceTime(450),
        distinctUntilChanged(),
        takeUntil(this.destroyComponent)
      )
      .subscribe(() => {
        this.filterTeamResources(this.emailInput.nativeElement.value);
      });
  }

  private processFilterChange(value: string): void {
    if (this.isFilterSet && value.length === 0) {
      this.updateIsFilterSet(false);
      this.autoCompleteTrigger.closePanel();
      this.setIsValid(true);
    } else if (!this.isFilterSet && value.length >= 1) {
      this.updateIsFilterSet(true);
    }
  }

  private splitAndFilterEmails(emails: string): string[] {
    return emails
      .split(/[;,\n]/)
      .map(email => this.parseOutlookEmail(email) || email)
      .map(email => email.trim())
      .filter(
        (email, index, array) =>
          index === array.indexOf(email) &&
          email &&
          this.selectedTeamResources.findIndex(e => hasEmail(e, email)) === -1
      );
  }

  private parseOutlookEmail(email: string): string {
    return email.substring(
      email.lastIndexOf(this.emailStartingDelimiter) + 1,
      email.lastIndexOf(this.emailClosingDelimiter)
    );
  }

  private wasTeamResourceSelected(event: any): boolean {
    return (
      event.relatedTarget &&
      event.relatedTarget.tagName === 'MAT-OPTION' &&
      !event.relatedTarget?.className.includes('disabled')
    );
  }

  assignToCurrentUser(): void {
    if (!this.currentUserEmail || this.isCurrentUserAlreadyAssignedTo) {
      return;
    }

    this.teamResourcesChange.emit([
      ...this.selectedTeamResources,
      {
        email: this.currentUserEmail || '',
        type: TeamResourceViewModelType.Email
      }
    ]);

    this.isCurrentUserAlreadyAssignedTo = true;
  }

  private checkIfCurrentUserAlreadyAssigned(): void {
    this.isCurrentUserAlreadyAssignedTo = !!this.selectedTeamResources.find(
      (a: EmailOnlyViewModel) => a.email === this.currentUserEmail
    );
  }

  private focusEmailInput(): void {
    setTimeout(() => this.emailInput.nativeElement.focus(), 1);
  }

  private configureCurrentMode(): void {
    if (this.currentMode === 'inline-edit') {
      this.separatorKeysCodes.push(TAB);
    }
  }

  setIsEmailInputFocused(isFocused: boolean): void {
    this.isInputFocused = isFocused;
    this.setShouldHideInputPlaceholder();
  }

  setShouldHideInputPlaceholder(): void {
    this.shouldHideInputPlaceholder =
      (!this.inputPlaceholder || !this.selectedTeamResources.length) &&
      !this.isInputFocused &&
      !this.emailInput.nativeElement.value.length;
  }
}
