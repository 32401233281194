import { createAction, props } from '@ngrx/store';
import { ProjectType } from '@core/services/project/projects.service';

const prefix = '[PROJECTS_GRID]';

export const initActiveProjectType = createAction(`${prefix} INIT_ACTIVE_PROJECT_TYPE`);

export const updateActiveProjectType = createAction(
  `${prefix} UPDATE_ACTIVE_PROJECT_TYPE`,
  props<{ projectType: ProjectType }>()
);

export const updateSearchQuery = createAction(`${prefix} UPDATE_SEARCH_QUERY`, props<{ searchQuery: string | null }>());

export const deleteProject = createAction(`${prefix} DELETE_PROJECT`, props<{ projectNrn: string }>());

export const scrollToProject = createAction(`${prefix} SCROLL_TO_PROJECT`, props<{ projectNrn: string }>());

export const openProjectLog = createAction(`${prefix} OPEN_PROJECT_LOG`, props<{ projectNrn: string }>());

export const deleteProjectFailed = createAction(`${prefix} DELETE_PROJECT_FAILED`);
