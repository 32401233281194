import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { FeatureService } from '@core/services/feature/feature.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class UserAdministrationGuard implements CanActivate {
  constructor(private featureService: FeatureService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.featureService.isUserAdministrationSupported.pipe(
      tap(isSupported => {
        if (!isSupported) {
          this.router.navigate(['/projects']);
        }
      })
    );
  }
}
