import {
  termsAndConditionsDialogReducer,
  TermsAndConditionsDialogState,
  initialState as dialogInitialState
} from '@terms-and-conditions/reducers/terms-and-conditions-dialog/terms-and-conditions-dialog.reducer';
import { ActionReducerMap } from '@ngrx/store';

export interface TermsAndConditionsState {
  dialog: TermsAndConditionsDialogState;
}

export const termsAndConditionsReducer: ActionReducerMap<TermsAndConditionsState> = {
  dialog: termsAndConditionsDialogReducer
};

export const initialTermsState: TermsAndConditionsState = {
  dialog: dialogInitialState
};
