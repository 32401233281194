import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { KeywordModel } from '@core/models/project/keyword.model';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-single-keyword-selector',
  templateUrl: './single-keyword-selector.component.html',
  styleUrls: ['./single-keyword-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleKeywordSelectorComponent {
  @ViewChild('keywordSelect', { static: false }) keywordSelect;

  keywords: KeywordModel[] = [];
  private _selectedKeyword: KeywordModel | null = null;
  private isSelectedKeywordInitialized = false;

  @Input() isLoadingKeywords: boolean;
  @Input() hasLoadingKeywordsError: boolean;
  @Input() isDisabled: boolean;
  @Input() isRequired: boolean;
  @Input() isClearable: boolean;
  @Input() setFirstAsDefault: boolean;
  get selectedKeyword() {
    return this._selectedKeyword;
  }
  @Input() set selectedKeyword(keyword: KeywordModel | null) {
    this._selectedKeyword = keyword;
    this.emitSelectionChangeIfNeeded();
  }
  @Input() set keywordsList(keywords: KeywordModel[]) {
    this.keywords = keywords;
    if (this.setFirstAsDefault) {
      this.setDefaultKeyword();
    } else {
      this.emitSelectionChangeIfNeeded();
    }
  }

  @Input() tooltipMessage: string;
  @Input() loadErrorMessage: string;
  @Input() placeholder: string;
  @Input() emptyFieldErrorMessage: string;

  @Output() selectionChange = new EventEmitter<KeywordModel | null>();
  @Output() selectionFocus = new EventEmitter<void>();
  @Output() openedChange = new EventEmitter<boolean>();

  keywordSelected(event: MatSelectChange): void {
    this.keywordSelect.errorState = this.isRequired && !!this.emptyFieldErrorMessage && !event.value?.name;
    this.selectionChange.emit(event.value);
  }

  emitSelectionChangeIfNeeded(): void {
    if (!this.isSelectedKeywordInitialized && !this.setFirstAsDefault && this.selectedKeyword) {
      this.keywords
        .filter(k => k.name === this.selectedKeyword?.name)
        .forEach(k => {
          this.isSelectedKeywordInitialized = true;
          this.selectionChange.emit({ name: k.name, type: k.type });
        });
    }
  }

  selectionFocused(): void {
    this.selectionFocus.emit();
  }

  panelOpened(value: boolean): void {
    this.openedChange.emit(value);
  }

  compareKeywords(matSelectOption: KeywordModel, selectedOption: KeywordModel): boolean {
    return matSelectOption && selectedOption
      ? matSelectOption.type === selectedOption.type && matSelectOption.name === selectedOption.name
      : matSelectOption === selectedOption;
  }

  updateFieldValidity(): void {
    this.keywordSelect.errorState = this.isRequired && !this.selectedKeyword && !!this.emptyFieldErrorMessage;
  }

  private setDefaultKeyword(): void {
    if (this.keywords.length > 0) {
      const [defaultKeyword] = this.keywords;
      this.selectionChange.emit({ name: defaultKeyword.name, type: defaultKeyword.type });
    } else {
      this.selectionChange.emit(null);
    }
  }
}
