import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TeamResourceViewModel } from '@shared/models/team-resource/team-resource.view.model';
import { InitialsTheme } from '@shared/models/initials/initialsTheme.enum';
import { TeamResourceViewModelType } from '@shared/models/team-resource/team-resource-view-model.type';
import { isTeamMember } from '@shared/utilities/team-resource/team-resource.utils';

@Component({
  selector: 'app-team-members-search-result',
  templateUrl: './team-members-search-result.component.html',
  styleUrls: ['./team-members-search-result.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamMembersSearchResultComponent {
  @Input() set teamResource(resource: TeamResourceViewModel) {
    this.resource = resource;
    this.discipline = isTeamMember(resource) ? resource.boldDiscipline : '';
  }

  resourceType = TeamResourceViewModelType;
  initialsTheme = InitialsTheme;
  discipline = '';
  resource;
}
