import { Injectable } from '@angular/core';
import {
  ActivityService,
  GetActivitiesResponseModel,
  GetEventsResponseModel
} from '@core/services/activity/activity.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  destroyEventsContainer,
  loadEvents,
  loadEventsError,
  loadMoreEvents,
  loadMoreProjectEvents,
  loadProjectEvents,
  updateEvents,
  updateProjectEvents,
  updateSearchQuery
} from '@app/root/actions/events/events.actions';
import { catchError, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { EventMapperService } from '@shared/services/event-mapper/event-mapper.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { selectEvents, selectOffsetToken, selectProjectLogEvents } from '@root/selectors/events/events.selectors';
import { EventsState } from '@root/reducers/events/events.reducers';
import { Store } from '@ngrx/store';
import { selectCurrentProjectNrn } from '@root/selectors/project-list/project-list.selectors';
import { AnalyticsService } from '@core/services/analytics/analytics.service';
import { AnalyticEventAction } from '@shared/models/analytics/analytic-event-action.model';
import { AnalyticEventCategory } from '@shared/models/analytics/analytic-event-category.model';
import { ComposedEventsMapperService } from '@shared/services/events/composed-events-mapper/composed-events-mapper.service';

interface UpdateEventParams {
  nrn: string;
  type: string;
  delayInMs?: number;
}

@Injectable()
export class EventsEffects {
  constructor(
    private actions: Actions,
    private activityService: ActivityService,
    private eventMapper: EventMapperService,
    private composedEventsMapperService: ComposedEventsMapperService,
    private router: Router,
    private store: Store<EventsState>,
    private analyticsService: AnalyticsService
  ) {}

  private updateActivities = (source: Observable<UpdateEventParams>) =>
    source.pipe(
      withLatestFrom(this.store.select(selectOffsetToken), this.store.select(selectEvents)),
      switchMap(([{ nrn, type, delayInMs }, offsetToken, currentlyLoadedEvents]) =>
        this.activityService.loadActivities(nrn, delayInMs, offsetToken).pipe(
          map((response: GetActivitiesResponseModel) => {
            const newOffsetToken = response.paging ? response.paging.offsetToken : null;
            return updateEvents({
              events: [...currentlyLoadedEvents, ...this.eventMapper.mapActivities(response.events)],
              offsetToken: newOffsetToken
            });
          }),
          catchError(() => of(loadEventsError())),
          takeUntil(this.actions.pipe(ofType(destroyEventsContainer)))
        )
      )
    );

  private updateProjectLogEvents = (source: Observable<UpdateEventParams>) =>
    source.pipe(
      withLatestFrom(this.store.select(selectOffsetToken), this.store.select(selectProjectLogEvents)),
      switchMap(([{ nrn, type, delayInMs }, offsetToken, currentlyLoadedProjectEvents]) =>
        this.activityService.loadActivities(nrn, delayInMs, offsetToken).pipe(
          map((response: GetEventsResponseModel) => {
            const newOffsetToken = response.paging ? response.paging.offsetToken : null;
            return updateProjectEvents({
              projectEvents: [
                ...currentlyLoadedProjectEvents,
                ...this.composedEventsMapperService.mapProjectLogEvents(response.events)
              ],
              offsetToken: newOffsetToken
            });
          }),
          catchError((error: HttpErrorResponse) => {
            const redirectStatusCodes = [401, 403, 404];
            if (type === loadProjectEvents.type && redirectStatusCodes.includes(error.status)) {
              this.router.navigate(['/projects']);
            }
            return of(loadEventsError());
          }),
          takeUntil(this.actions.pipe(ofType(destroyEventsContainer)))
        )
      )
    );

  loadEvents = createEffect(() => this.actions.pipe(ofType(loadEvents), this.updateActivities));

  loadMoreEvents = createEffect(() => this.actions.pipe(ofType(loadMoreEvents), this.updateActivities));

  loadProjectEvents = createEffect(() =>
    this.actions.pipe(
      ofType(loadProjectEvents),
      withLatestFrom(this.store.select(selectCurrentProjectNrn)),
      map(([{ type }, nrn]) => ({ nrn, type })),
      this.updateProjectLogEvents
    )
  );

  loadMoreProjectEvents = createEffect(() =>
    this.actions.pipe(
      ofType(loadMoreProjectEvents),
      withLatestFrom(this.store.select(selectCurrentProjectNrn)),
      map(([{ type }, nrn]) => ({ nrn, type })),
      this.updateProjectLogEvents
    )
  );

  filterEvents = createEffect(
    () =>
      this.actions.pipe(
        ofType(updateSearchQuery),
        tap(({ searchTerm }) => {
          if (searchTerm) {
            this.analyticsService.recordEvent(AnalyticEventAction.FilterEvents, AnalyticEventCategory.Projects);
          }
        })
      ),
    { dispatch: false }
  );
}
