import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FeatureService } from '@core/services/feature/feature.service';
import { Observable } from 'rxjs';

@Injectable()
export class FeatureGuard implements CanActivate {
  constructor(private featureService: FeatureService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.featureService.resolveFeatures();
  }
}
