import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { FeatureService } from '@core/services/feature/feature.service';
import { combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectIsHybridUser } from '@root/selectors/user/user.selectors';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class SubmittalsGuard implements CanActivate {
  constructor(private featureService: FeatureService, private router: Router, private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([
      this.store.select(selectIsHybridUser),
      this.featureService.isHybridSubmittalsSupported,
      this.featureService.isCloudSubmittalsSupported
    ]).pipe(
      map(
        ([isHybridUser, isHybridSubmittalsSupported, isCloudSubmittalsSupported]) =>
          (isHybridUser && (isHybridSubmittalsSupported || isCloudSubmittalsSupported)) || !isHybridUser
      ),
      tap(isFeatureSupported => {
        if (isFeatureSupported) {
          return;
        }

        this.router.navigate([route.params?.projectNrn ? `/projects/${route.params.projectNrn}/files` : '/projects']);
      })
    );
  }
}
