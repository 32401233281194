import { ElementRef, Injectable } from '@angular/core';
import { WindowService } from '@core/services/window/window.service';

@Injectable()
export class DomService {
  constructor(private windowService: WindowService) {}

  createAndOpenAnchor(finalUrl: string, url?: string) {
    const anchor = this.windowService.getNativeDocument().createElement('a');
    anchor.href = finalUrl;
    const downloadUrl = url || finalUrl;
    anchor.download = downloadUrl.substr(downloadUrl.lastIndexOf('/') + 1);

    this.clickLink(anchor);
  }

  hasElementScroll(elementRef: ElementRef): boolean {
    return elementRef.nativeElement.scrollHeight > elementRef.nativeElement.clientHeight;
  }

  getScrollbarWidth(): number {
    const nativeDocument = this.windowService.getNativeDocument();
    // Creating invisible container
    const outer = nativeDocument.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    nativeDocument.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = nativeDocument.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Removing temporary elements from the DOM
    (<Node & ParentNode>outer.parentNode).removeChild(outer);

    return scrollbarWidth;
  }

  private clickLink(anchor: HTMLAnchorElement): void {
    const nativeDocument = this.windowService.getNativeDocument();

    nativeDocument.body.appendChild(anchor);
    anchor.click();
    nativeDocument.body.removeChild(anchor);
  }

  addExternalScript(scriptUrl: string): void {
    const externalScriptNode = document.createElement('script');
    const scriptElement = document.getElementsByTagName('script')[0];
    externalScriptNode.type = 'text/javascript';
    externalScriptNode.async = true;
    externalScriptNode.src = scriptUrl;
    (<Node & ParentNode>scriptElement.parentNode).insertBefore(externalScriptNode, scriptElement);
  }
}
