import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUrlProvider } from '@core/utilities/apiUrl/apiUrl.provider';
import { GetTermsAndConditionsResponse } from '@terms-and-conditions/models/get-terms-and-conditions.response.model';
import { CreateTermsAndConditionsResponse } from '@terms-and-conditions/models/create-terms-and-conditions.response.model';
import { CreateTermsAndConditionsModel } from '@terms-and-conditions/models/create-terms-and-conditions.model';
import { LoadTermsAndConditionsResponse } from '@terms-and-conditions/models/load-terms-and-conditions.response.model';
import { LoadTermsAndConditionsModel } from '@terms-and-conditions/models/load-terms-and-conditions.model';
import { AcceptTermsAndConditionsRequest } from '@terms-and-conditions/models/accept-terms-and-conditions.request';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsService {
  private readonly termsAndConditionsEndpointName = 'terms';

  constructor(private http: HttpClient, private apiUrlProvider: ApiUrlProvider) {}

  private getTermsAndConditions(): Observable<GetTermsAndConditionsResponse> {
    return this.http.get<GetTermsAndConditionsResponse>(`${this.getUrl()}/${this.termsAndConditionsEndpointName}`);
  }

  createTermsAndConditions(
    createTermsAndConditionsRequest: CreateTermsAndConditionsModel
  ): Observable<CreateTermsAndConditionsResponse> {
    return this.http.post<CreateTermsAndConditionsResponse>(
      `${this.getUrl()}/${this.termsAndConditionsEndpointName}`,
      createTermsAndConditionsRequest
    );
  }

  loadTermsAndConditionsDetails(
    loadTermsAndConditionsRequest: LoadTermsAndConditionsModel
  ): Observable<LoadTermsAndConditionsResponse> {
    return this.http.get<LoadTermsAndConditionsResponse>(
      `${this.getUrl()}/${this.termsAndConditionsEndpointName}/${encodeURIComponent(
        loadTermsAndConditionsRequest.referenceNrn
      )}`
    );
  }

  acceptTermsAndConditions(acceptTermsAndConditionsRequest: AcceptTermsAndConditionsRequest): Observable<void> {
    return this.http.post<void>(
      `${this.getUrl()}/${this.termsAndConditionsEndpointName}/${encodeURIComponent(
        acceptTermsAndConditionsRequest.termsNrn
      )}/accept`,
      { accepted: acceptTermsAndConditionsRequest.accepted }
    );
  }

  getTermsAndConditionsContent(): Observable<LoadTermsAndConditionsResponse | null> {
    return this.getTermsAndConditions().pipe(
      mergeMap(response =>
        response.terms.length ? this.loadTermsAndConditionsDetails({ referenceNrn: response.terms[0].nrn }) : of(null)
      )
    );
  }

  private getUrl(): string {
    return `${this.apiUrlProvider.getUrl()}`;
  }
}
