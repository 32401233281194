<div
  id="confirmation-dialog"
  class="confirmation-dialog"
>
  <div
    mat-dialog-title
    class="dialog-header-text"
  >
    {{data.headerText | translate}}
  </div>
  <div class="dialog-content">
    {{data.dialogText | translate: data.resourceValue }}
  </div>
  <div
    class="resource-text"
  >
    {{data.resourceName}}
  </div>
  <div
    *ngIf="data.additionalText"
    class="additional-text"
    id="additional-text"
  >
    {{data.additionalText | translate: data.additionalTextValue }}
  </div>
  <div
    *ngIf="data.showAdditionalCheckbox"
    class="additional-checkbox-container"
  >
    <mat-checkbox
      id="confirmation-dialog-additional-checkbox"
      class="hide-dialog-checkbox"
      *ngIf="data.additionalCheckboxText"
      [(ngModel)]="additionalCheckboxValue"
    >
      {{data.additionalCheckboxText | translate}}
    </mat-checkbox>
  </div>
  <mat-dialog-actions
    class="actions-container"
    fxLayout
    fxLayoutAlign="space-between center"
  >
    <div>
      <mat-checkbox
        id="confirmation-dialog-hide-checkbox"
        class="hide-dialog-checkbox"
        *ngIf="data.hideDialogText && data.localStorageKey"
        [(ngModel)]="hideDialogCheckboxValue"
      >
        {{data.hideDialogText | translate}}
      </mat-checkbox>
    </div>
    <div>
      <button
        ngxClipboard
        id="confirmation-dialog-no-button"
        class="decline-button"
        mat-button
        (click)="onDeclineClick()"
        tabindex="-1"
      >
        {{data.declineText | translate}}
      </button>
      <app-raised-button
        id="confirmation-dialog-yes-button"
        class="confirmation-button"
        (click)="onConfirmClick()"
      >
        {{data.confirmationText | translate}}
      </app-raised-button>
    </div>
  </mat-dialog-actions>
</div>
