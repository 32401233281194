<button
  mat-button
  id="project-selector-button"
  [matMenuTriggerFor]="projectSelectorMenu"
  class="project-selector"
>
  <div
    class="full-width"
    fxLayout
    fxLayoutAlign="start center"
  >
    <span
      id="project-selector-header-text"
      class="font-size-large project-selector-header-text"
    >
      {{selectedProject?.name || (defaultLabel | translate)}}
    </span>
    <mat-icon
      class="white-arrow project-selector-icon"
    >
      keyboard_arrow_down
    </mat-icon>
  </div>
</button>
<mat-menu
  id="project-selector-menu"
  #projectSelectorMenu="matMenu"
  class="project-selector-list"
>
  <div
    class="full-size project-list-style"
    fxLayout
    fxLayoutAlign="center center"
  >
    <div
      class="full-size project-list-container"
      (click)="stopMenuClose($event)"
    >
      <ul
        class="full-size without-margin without-padding project-list"
        id="projectList"
      >
        <li
          *ngIf="displayAllProjectsOption"
          class="project-list-row"
          (click)="selectProject(null)"
          fxLayout
        >
          <span
            class="project-name font-size-medium text-left"
          >
            {{'ACTION_ITEM.ALL_PROJECTS' | translate}}
          </span>
        </li>
        <li
          class="project-list-row"
          *ngFor="let project of projects"
          (click)="selectProject(project)"
          fxLayout
        >
        <span
          class="project-name font-size-medium text-left"
          [attr.data-nrn]="project.nrn"
        >
          {{project.name}}
        </span>
          <span
            class="project-number font-size-medium text-right"
          >
          {{project.number}}
        </span>
        </li>
      </ul>
    </div>
  </div>
</mat-menu>
