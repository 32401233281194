import { createSelector } from '@ngrx/store';
import { selectProjectsModule } from '@projects/selectors/projects-common/projects-common.selector';

export const selectProjectsDialogState = createSelector(selectProjectsModule, state => state.dialog);
export const selectIsProcessing = createSelector(selectProjectsDialogState, state => state.isProcessing);
export const selectIsLoadingProjectDetails = createSelector(
  selectProjectsDialogState,
  state => state.isLoadingProjectDetails
);
export const selectProjectDialogMode = createSelector(selectProjectsDialogState, state => state.mode);

export const selectShowLoadingError = createSelector(selectProjectsDialogState, state => state.showLoadingError);

export const selectRootFolderToUpdate = createSelector(selectProjectsDialogState, state => state.projectFolderToUpdate);

export const selectIsLoadingCustomerDetails = createSelector(
  selectProjectsDialogState,
  state => state.isLoadingCustomerDetails
);

export const selectProjectDetails = createSelector(selectProjectsDialogState, state => state.projectDetails);

export const selectCustomerDetails = createSelector(selectProjectsDialogState, state => state.customerDetails);

export const selectEmailFilingAddressError = createSelector(
  selectProjectsDialogState,
  state => state.emailFilingAddressError
);

export const selectIsSaveButtonDisabled = createSelector(
  selectIsProcessing,
  selectIsLoadingProjectDetails,
  selectShowLoadingError,
  selectIsLoadingCustomerDetails,
  (isProcessing, isLoadingProjectDetails, showLoadingError, isLoadingCustomerDetails) =>
    isProcessing || isLoadingProjectDetails || showLoadingError || isLoadingCustomerDetails
);

export const selectIsLoadingData = createSelector(
  selectIsLoadingCustomerDetails,
  selectIsLoadingProjectDetails,
  (isLoadingCustomerDetails, isLoadingProjectDetails) => isLoadingCustomerDetails || isLoadingProjectDetails
);

export const selectShowProjectDetailsContent = createSelector(
  selectIsLoadingData,
  selectShowLoadingError,
  (isLoadingData, showLoadingError) => !isLoadingData && !showLoadingError
);
