import { createAction, props } from '@ngrx/store';
import { MappedActivities } from '@shared/services/event-mapper/event-mapper.service';
import { MappedProjectEvent } from '@project-log/models/mapped-project-events';

const prefix = '[EVENTS]';

export const loadEvents = createAction(`${prefix} LOAD_EVENTS`, props<{ nrn: string; delayInMs?: number }>());

export const loadMoreEvents = createAction(`${prefix} LOAD_MORE_EVENTS`, props<{ nrn: string }>());

export const loadProjectEvents = createAction(`${prefix} LOAD_PROJECT_EVENTS`);

export const loadMoreProjectEvents = createAction(`${prefix} LOAD_MORE_PROJECT_EVENTS`);

export const updateEvents = createAction(
  `${prefix} UPDATE_EVENTS`,
  props<{ events: MappedActivities[]; offsetToken: string | null }>()
);

export const updateProjectEvents = createAction(
  `${prefix} UPDATE_PROJECT_EVENTS`,
  props<{ projectEvents: MappedProjectEvent[]; offsetToken: string | null }>()
);

export const loadEventsError = createAction(`${prefix} LOAD_EVENTS_ERROR`);

export const updateSearchQuery = createAction(`${prefix} UPDATE_SEARCH_QUERY`, props<{ searchTerm: string | null }>());

export const destroyEventsContainer = createAction(`${prefix} DESTROY_CONTAINER`);
