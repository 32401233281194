import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements OnInit {
  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    // NOTE: timeout is needed to keep smooth material input animation
    setTimeout(() => this.elementRef.nativeElement.focus(), 50);
  }
}
