<div
  class="progress-container"
  [class.progress-success]="processSuccess"
  [class.progress-error]="processError"
>
  <div class="progress-wrapper">
    <div class="progress-label">
      <span *ngIf="isProcessing">{{progressLabel}}</span>
      <span *ngIf="!isProcessing && !processError">{{successLabel}}</span>
      <span *ngIf="!isProcessing && processError">{{errorLabel}}</span>
    </div>
    <div class="progress-bar">
      <mat-progress-bar
        id="progress-bar"
        *ngIf="!processError"
        [mode]="isProcessing ? 'indeterminate' : 'determinate'"
        [value]="isProcessing ? undefined : 100"
        color="accent"
      ></mat-progress-bar>
    </div>
  </div>
  <mat-icon 
    *ngIf="processSuccess" 
    id="success-icon" 
    class="progress-icon"
  >
    check
  </mat-icon>
  <mat-icon 
    *ngIf="processError" 
    id="error-icon" 
    class="progress-icon" 
    svgIcon="error-cross"
  ></mat-icon>
</div>
