<div class="browser-compatibility-container">
  <div class="browser-compatibility-warning">
    <div class="content">
      <div class="primary-text">
        {{'BROWSER_SUPPORT.TITLE' | translate}}
      </div>
      <div class="secondary-text">
        {{'BROWSER_SUPPORT.WARNING' | translate}}
      </div>
      <div class="browsers-showcase">
        <div class="browser-icon no-margin browser-chrome"></div>
        <div class="browser-icon browser-firefox"></div>
        <div class="browser-icon browser-safari"></div>
        <div class="browser-icon browser-edge"></div>
      </div>
      <div class="secondary-text">
        {{'BROWSER_SUPPORT.IE_WARNING' | translate}}
      </div>
      <button
        mat-raised-button
        (click)="dismissWarning()"
        class="dismiss-button"
      >
        {{'BROWSER_SUPPORT.DISMISS_SUPPORT_WARNING' | translate}}
      </button>
    </div>
  </div>
</div>
