import { Injectable } from '@angular/core';
import { ToastService } from '@core/services/toast/toast.service';
import { DialogErrorIconType } from '@shared/models/project-folders/dialog-error-icon-type';
import { DialogError } from '@shared/models/project-folders/dialog-error.type';
import { HttpErrorResponse } from '@angular/common/http';
import { ExternalFoldersResponse } from '@core/models/external-folder/external-folders.response.model';

@Injectable({ providedIn: 'root' })
export class ProjectFoldersErrorMessagesService {
  constructor(private toastService: ToastService) {}

  displayToastError(resourceText: string): void {
    this.toastService.displayError(resourceText);
  }

  getDialogErrorMessage(response: HttpErrorResponse | ExternalFoldersResponse): DialogError | null {
    let message;
    let type;

    if (response instanceof HttpErrorResponse) {
      const isNotFoundError = response.status === 404;
      message = isNotFoundError
        ? 'PROJECT.CREATE.PROJECT_FOLDER.NO_SEARCH_RESULTS'
        : 'PROJECT.CREATE.PROJECT_FOLDER.UNABLE_TO_LOAD_SHAREPOINT';
      type = isNotFoundError ? 'no-search-results' : 'general-error';

      return this.getIcon(message, type);
    }

    if (response.items.length) {
      return null;
    }

    if (!response.items.length) {
      message = 'PROJECT.CREATE.PROJECT_FOLDER.NO_SEARCH_RESULTS';
      type = 'no-search-results';
    }

    return this.getIcon(message, type);
  }

  private getIcon(message: string, type: DialogErrorIconType): DialogError {
    let errorIcon: string;

    switch (type) {
      case 'no-search-results':
        errorIcon = 'search-no-result';
        break;
      default:
        errorIcon = 'general-error-orange';
    }

    return {
      type,
      message,
      icon: errorIcon
    };
  }
}
