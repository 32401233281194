import { Component, Input } from '@angular/core';
import { SnackbarType } from '@core/models/snackbar/snackbar.type.enum';

@Component({
  selector: 'app-file-list-toast',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackBarComponent {
  @Input() message: String;
  @Input() type: SnackbarType;
}
