import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { ContentHeaderBackLinkComponent } from '@shared/components/header/content-header-back-link/content-header-back-link.component';
import { ContentHeaderButtonComponent } from '@shared/components/header/content-header-button/content-header-button.component';
import { BIG_SCREEN_SIZE, MEDIUM_SCREEN_SIZE, SMALL_SCREEN_SIZE } from '@shared/constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', './header-utilities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnDestroy, AfterContentInit {
  @ContentChild(ContentHeaderBackLinkComponent) ngContentHeaderBackLink?: ContentHeaderBackLinkComponent;
  @ContentChild(ContentHeaderButtonComponent) ngContentHeaderButton?: ContentHeaderButtonComponent;
  @Input() contentHeaderButtonInlineWithHeader: boolean;
  @Input() header: string;
  @Input() buttonId: string;
  @Input() buttonText: string;
  @Input() svgIcon: string;
  @Input() disabled: boolean;
  @Output() buttonClick = new EventEmitter<void>();
  searchBoxFullScreen: boolean;
  displayBackLinkOrButtonWrapper: boolean;
  private destroyComponent = new Subject();
  private readonly smallScreenSize = SMALL_SCREEN_SIZE;
  private readonly mediumScreenSize = MEDIUM_SCREEN_SIZE;
  private readonly bigScreenSize = BIG_SCREEN_SIZE;
  constructor(private breakpointObserver: BreakpointObserver, private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterContentInit(): void {
    this.handleBreakpoints();
  }
  ngOnDestroy(): void {
    this.destroyComponent.next();
    this.destroyComponent.complete();
  }

  handleBreakpoints(): void {
    this.breakpointObserver
      .observe([this.smallScreenSize, this.mediumScreenSize, this.bigScreenSize])
      .pipe(takeUntil(this.destroyComponent))
      .subscribe(result => {
        if (result.breakpoints[this.bigScreenSize] || result.breakpoints[this.mediumScreenSize]) {
          this.contentHeaderButtonInlineWithHeader = true;
          this.displayBackLinkOrButtonWrapper = !!this.ngContentHeaderBackLink;
          this.searchBoxFullScreen = false;
        }
        if (result.breakpoints[this.smallScreenSize]) {
          this.contentHeaderButtonInlineWithHeader = false;
          this.displayBackLinkOrButtonWrapper = !!(this.ngContentHeaderBackLink || this.ngContentHeaderButton);
          this.searchBoxFullScreen = true;
        }

        this.changeDetectorRef.detectChanges();
      });
  }
}
