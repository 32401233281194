<div
  class="show-more-container"
  [style.max-height]="minimalHeight"
  #showMoreContainer
>{{text}}</div>
<div
  *ngIf="showMore"
  (click)="toggleHeight()"
  class="toggle-text"
>
  {{ (isOpened ? "SHOW_MORE_CONTAINER.SHOW_LESS" :  "SHOW_MORE_CONTAINER.SHOW_MORE") | translate }}
</div>
