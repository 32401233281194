/**
 * NOTE This file blacklists certain events from triggering Angular 5+ digest loop (not
 *      the same as rerender).
 *      Blacklisting mouse events fixes CPU issues with dragging over dense DOM areas (lists etc.).
 *
 *      Explanation and Github issues can be found under https://stackoverflow.com/a/43294113
 */
/// <reference types='zone.js/dist/zone.js' />

const BLACKLISTED_ZONE_EVENTS: string[] = [
  'addEventListener:mouseenter',
  'addEventListener:mouseleave',
  'addEventListener:mousemove',
  'addEventListener:mouseout',
  'addEventListener:mouseover',
  'addEventListener:mousewheel',
  'addEventListener:scroll',
  'addEventListener:drag',
  'addEventListener:dragover',
  'addEventListener:dragout',
  'requestAnimationFrame'
];

export const blacklistZone = Zone.current.fork({
  name: 'blacklist',
  onScheduleTask: (delegate: ZoneDelegate, current: Zone, target: Zone, task: Task): Task => {
    if (task.type === 'eventTask' && BLACKLISTED_ZONE_EVENTS.some(name => task.source.indexOf(name) > -1)) {
      task.cancelScheduleRequest();

      return Zone.root.scheduleTask(task);
    } else {
      return delegate.scheduleTask(target, task);
    }
  }
});
