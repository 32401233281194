import { Project } from '@core/models/project/project.model';
import {
  clearProject,
  completeLoadingProjectDetails,
  destroyProjectViewContainer,
  failLoadingProjectDetails,
  loadProjectDetails,
  loadProjects,
  updateCurrentProjectConnectStatus,
  updateCurrentProjectNrn,
  updateProjectList,
  updateProjectsError,
  updateRecentProjectNrns
} from '@root/actions/project-list/project-list.actions';
import { updateObject } from '@app/architecture';
import { LoadProjectsError } from '@root/effects/project-list/project-list.effects';
import { Action, createReducer, on } from '@ngrx/store';
import { ProjectDetailsResponseModel } from '@shared/models/project/project-details.response.model';

export interface ProjectListState {
  projects: { [nrn: string]: Project };
  recentProjectNrns: string[];
  isLoadingProjects: boolean;
  isLoadingProjectDetails: boolean;
  loadingProjectsError: LoadProjectsError | null;
  currentProjectNrn: string | null;
  projectDetails: ProjectDetailsResponseModel | null;
}

export const initialProjectListState: ProjectListState = {
  projects: {},
  recentProjectNrns: [],
  isLoadingProjects: false,
  isLoadingProjectDetails: false,
  loadingProjectsError: null,
  currentProjectNrn: null,
  projectDetails: null
};

function updateProjectFolderStatus(
  projects: { [nrn: string]: Project },
  currentProject: Project
): { [nrn: string]: Project } {
  return {
    ...projects,
    [currentProject.nrn]: { ...currentProject, hasProjectFolder: true }
  };
}

const reducer = createReducer(
  initialProjectListState,
  on(loadProjects, state => updateObject(state, { isLoadingProjects: true })),
  on(updateProjectList, (state, { projects }) =>
    updateObject(state, {
      isLoadingProjects: false,
      projects: projects.reduce((obj, project) => ({ ...obj, [project.nrn]: project }), {}),
      loadingProjectsError: null
    })
  ),
  on(updateProjectsError, (state, { error }) =>
    updateObject(state, { ...initialProjectListState, loadingProjectsError: error })
  ),
  on(updateCurrentProjectNrn, (state, { projectNrn }) => updateObject(state, { currentProjectNrn: projectNrn })),
  on(updateRecentProjectNrns, (state, { recentProjectNrns }) => updateObject(state, { recentProjectNrns })),
  on(clearProject, state => updateObject(state, { currentProjectNrn: null })),
  on(updateCurrentProjectConnectStatus, state =>
    updateObject(state, {
      projects: updateProjectFolderStatus(state.projects, state.projects[<string>state.currentProjectNrn])
    })
  ),
  on(loadProjectDetails, state => updateObject(state, { isLoadingProjectDetails: true })),
  on(completeLoadingProjectDetails, (state, { projectDetails }) =>
    updateObject(state, { isLoadingProjectDetails: false, projectDetails })
  ),
  on(destroyProjectViewContainer, failLoadingProjectDetails, state =>
    updateObject(state, { projectDetails: null, isLoadingProjectDetails: false })
  )
);

export function projectListReducer(
  state: ProjectListState = initialProjectListState,
  action: Action
): ProjectListState {
  return reducer(state, action);
}
