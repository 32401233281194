// for list of in use actions: https://confluence.newforma.com/x/lZMmB
// any new actions not already in the list should be added to confluence for parity with mobile apps
export enum AnalyticEventAction {
  AcceptTermsAndConditions = 'AcceptTermsAndConditions',
  AddActionItemAttachment = 'ActionItemAttachmentsAddedExistingFiles',
  AddDiscussionItem = 'AddDiscussionItem',
  AddLicenseForUser = 'AddLicenseForUser',
  AddMembersToProjectTeam = 'AddMembersToProjectTeam',
  ChangeFolderPermission = 'ChangePermission',
  CloseActionItem = 'CloseActionItem',
  CloseRfi = 'CloseRfi',
  CloseSubmittal = 'CloseSubmittal',
  ConnectProjectFolder = 'ConnectProjectFolder',
  CopyEmailFilingAddress = 'CopyEmailFilingAddress',
  CopyFileUrl = 'CopyFileUrl',
  CopyProjectUrl = 'CopyProjectUrl',
  CreateTermsAndConditions = 'CreateTermsAndConditions',
  CreateActionItem = 'CreateActionItem',
  CreateFolder = 'CreateFolder',
  CreateProject = 'CreateProject',
  DeleteDiscussionItem = 'DeleteDiscussionItem',
  DeleteFile = 'DeleteFile',
  DeleteFolder = 'DeleteFolder',
  DeleteProject = 'DeleteProject',
  DeleteSubmittal = 'DeleteSubmittal',
  DownloadContactCard = 'DownloadContactCard',
  DownloadEmail = 'Export',
  DownloadEmailAttachment = 'DownloadEmailAttachment',
  DownloadFile = 'DownloadFile',
  DownloadMultipleItems = 'DownloadMultipleItems',
  EmailFileConversationSettingChanged = 'EmailFileWithConversationSettingChanged',
  EulaAccepted = 'EulaAccepted',
  EulaRejected = 'EulaRejected',
  FileEmail = 'FileEmail',
  FileEmailWithConversationEnabled = 'EmailFiledWithFileConversationEnabled',
  FilterActionItems = 'ActionItemsFiltered',
  FilterEvents = 'FilterEvents',
  FilterProjects = 'FilterProjects',
  ForwardSubmittal = 'ForwardSubmittal',
  ReceiveSubmittal = 'ReceiveSubmittal',
  ForwardRfi = 'ForwardRfi',
  GetServerAccessKey = 'GetServerAccessKey',
  Login = 'Login',
  Logout = 'Logout',
  LogSubmittal = 'LogSubmittal',
  ModifyActionItem = 'ModifyActionItem',
  ModifyDiscussionItem = 'ModifyDiscussionItem',
  ModifyProject = 'ModifyProject',
  ModifySubmittalDetails = 'ModifySubmittalDetails',
  ModifyRfiDetails = 'ModifyRfiDetails',
  ProjectMenuCopyFilingAddress = 'ProjectMenu_CopyEmailFilingAddress',
  RemovedActionItemAttachments = 'ActionItemAttachmentsRemoved',
  RemoveUserLicenseTierAssignment = 'RemoveUserLicense',
  RemoveProjectTeamMembers = 'RemoveProjectTeamMembers',
  RenameFile = 'RenameFile',
  RenameFolder = 'RenameFolder',
  RespondToSubmittal = 'RespondToSubmittal',
  RespondToRfi = 'RespondToRfi',
  ReviewResponse = 'ReviewResponse',
  RevokeFileShares = 'RevokeLink',
  RevokeFolder = 'RevokeFolder',
  RfisKanbanBoard = 'RfisKanbanBoard',
  RfisListView = 'RfisListView',
  Search = 'Search',
  SearchEmailFilterAttachments = 'SearchEmailFilterAttachments',
  SearchEmailFilterDate = 'SearchEmailFilterDate',
  SearchEmailFilterNone = 'SearchEmailNoFilters',
  SearchEmailFilterSender = 'SearchEmailFilterSender',
  SearchEmailFilterRecipients = 'SearchEmailFilterRecipients',
  ShareFile = 'ShareLink',
  ShareFolder = 'ShareFolder',
  SubmittalsKanbanBoard = 'SubmittalsKanbanBoard',
  SubmittalsListView = 'SubmittalsListView',
  UpdateProjectFolder = 'UpdateProjectFolder',
  UpdateUserLicenseTierAssignment = 'UpdateUserLicense',
  UploadActionItemAttachment = 'ActionItemAttachmentsAdded',
  UploadFiles = 'Upload',
  UploadOverwriteFiles = 'UploadOverwrite',
  UpdateUserProfile = 'UpdateUserProfile',
  ViewFile = 'ViewFile',
  LogRfi = 'LogRfi',
  DeleteRfi = 'DeleteRfi',
  ViewEula = 'ViewEula',
  ViewPrivacyPolicy = 'ViewPrivacyPolicy',
  ViewTermsAndConditions = 'ViewTermsAndConditions',
  ViewTermsOfUse = 'ViewTermsOfUse',
  ViewUserAdmin = 'ViewUserAdmin',
  ViewSeverAccessKey = 'ViewSeverAccessKey',
  ViewProjectTeam = 'ViewProjectTeam'
}
