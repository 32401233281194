<div id="content-header-button" class="raised-button">
  <app-raised-button
    [id]="buttonId"
    (clicked)="handleClick()"
    [disabled]="disabled"
    [svgIcon]="svgIcon"
  >
    <span>
      {{ buttonText | translate}}
    </span>
  </app-raised-button>
</div>
