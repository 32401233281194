import { SnackbarType } from '@core/models/snackbar/snackbar.type.enum';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'snackbaricon'
})
export class SnackbarIconPipe implements PipeTransform {
  transform(val: SnackbarType): string | null {
    switch (val) {
      case SnackbarType.Success:
        return 'check';
      case SnackbarType.Error:
        return 'warning';
    }
    return null;
  }
}
