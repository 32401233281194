<div class="team-members-container">
  <div
    class="emails-container"
    [class.hide-input-placeholder]="shouldHideInputPlaceholder"
    [class.show-search-icon]="shouldShowSearchIcon && shouldHideInputPlaceholder"
    [formGroup]="parentFormGroup"
  >
    <mat-form-field
      class="full-width email-input-field"
      [floatLabel]="inputPlaceholder ? (selectedTeamResources.length > 0 ? 'always' : 'auto') : 'never'"
      [class.disabled]="disableEmailsControl"
    >
      <mat-chip-list #chipList [required]="isRequired">
        <mat-icon
          *ngIf="shouldShowSearchIcon && (!selectedTeamResources || !selectedTeamResources.length)"
          svgIcon="search"
          [ngClass]="currentMode + '-search-icon'"
          class="search-icon"
          matPrefix
        >
        </mat-icon>
        <app-team-members-chip
          *ngFor="let teamResource of selectedTeamResources"
          [removable]="!disableEmailsControl"
          [name]="teamResource.name"
          [type]="teamResource.type"
          [email]="teamResource.email"
          [discipline]="teamResource.discipline || ''"
          [initials]="teamResource.initials"
          (onRemove)="removeTeamResource(teamResource)"
          (onClick)="closeAutocompletePanel()"
        ></app-team-members-chip>
        <input
          matInput
          [ngClass]="currentMode + '-input'"
          [id]="inputId"
          placeholder="{{inputPlaceholder ? (inputPlaceholder | translate) : (!selectedTeamResources.length && fieldLabelId ? (fieldLabelId | translate) : '')}}"
          autocomplete="do-not-autocomplete"
          matAutocompletePosition="below"
          #emailInput
          formControlName="emailAddressControl"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="parseMatChipInput($event.value)"
          [matAutocomplete]="auto"
          (focus)="setIsEmailInputFocused(true)"
          (focusout)="tryAddEmails($event)"
        >
      </mat-chip-list>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selectTeamResource($event.option.value)"
        [panelWidth]="panelWidth"
      >
        <div
          *ngIf="isLoading$ | async"
          class="autocomplete-loading-spinner"
        >
          <mat-option disabled class="team-resource-option">
            <app-spinner
              id="team-members-autocomplete-loading-spinner"
              diameter="32"
              strokeWidth="1.5"
            ></app-spinner>
          </mat-option>
        </div>
        <div
          *ngIf="!(isLoading$ | async)"
        >
          <mat-option
            *ngFor="let teamResource of filteredTeamResources | async"
            [value]="teamResource"
            (mousedown)="selectTeamResource(teamResource)"
            class="team-resource-option"
          >
            <app-team-members-search-result [teamResource]="teamResource"></app-team-members-search-result>
          </mat-option>
        </div>
      </mat-autocomplete>
      <mat-error
        *ngIf="chipList.errorState"
        id="subject-error-messages-container"
      >
        <span *ngIf="isEmailErrorVisible">
          {{ fieldErrorMessageId | translate}}
        </span>
        <span *ngIf="isDomainErrorVisible">
          {{ customFieldErrorMessageId | translate}}
        </span>
      </mat-error>
      <span class="tooltip" *ngIf="tooltipMessage">
        {{ tooltipMessage | translate }}
      </span>
    </mat-form-field>
  </div>
  <span
    *ngIf="currentMode === 'inline-edit' || currentMode === 'create-panel'"
    fxLayout="row-reverse"
    class="assign-to-me"
    (click)="assignToCurrentUser()"
    [class.disabled-assign]="isCurrentUserAlreadyAssignedTo"
  >
    {{'ACTION_ITEM_CREATE.ASSIGN_TO_ME' | translate}}
  </span>
</div>
