import { Component, ChangeDetectionStrategy, Input, Inject, Output, EventEmitter } from '@angular/core';
import { AnalyticEventAction } from '@shared/models/analytics/analytic-event-action.model';
import { AnalyticEventCategory } from '@shared/models/analytics/analytic-event-category.model';
import { catchError, mergeMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { WindowService } from '@core/services/window/window.service';
import { AnalyticsService } from '@core/services/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { EulaDialogService } from '@shared/services/eula-dialog/eula-dialog.service';
import { EulaService } from '@core/services/eula/eula.service';
import { ToastService } from '@core/services/toast/toast.service';
import { AuthenticationService } from '@core/services/authentication/authentication.service';

@Component({
  selector: 'app-sidebar-submenu',
  templateUrl: './sidebar-submenu.component.html',
  styleUrls: ['./sidebar-submenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarSubmenuComponent {
  @Input() isSidebarOpen: boolean;
  @Input() isEulaSupported: boolean;
  @Output() optionSelected = new EventEmitter();

  analyticEventAction = AnalyticEventAction;
  version: string;

  constructor(
    private windowService: WindowService,
    private analyticsService: AnalyticsService,
    private translateService: TranslateService,
    private eulaDialogService: EulaDialogService,
    private eulaService: EulaService,
    private toastService: ToastService,
    private authenticationService: AuthenticationService,
    @Inject('ENVIRONMENT') environment
  ) {
    this.version = environment.version;
  }

  navigate(navigationLink: string, analyticEvent: AnalyticEventAction): Window | null {
    const link = this.translateService.instant(navigationLink);
    this.analyticsService.recordEvent(analyticEvent, AnalyticEventCategory.UserMenu);
    return this.windowService.open(link);
  }

  displayEula(): void {
    this.optionSelected.emit();
    this.analyticsService.recordEvent(AnalyticEventAction.ViewEula, AnalyticEventCategory.UserMenu);

    this.eulaDialogService
      .open({ isUserAuthenticated: true })
      .pipe(
        mergeMap(eula => {
          if (!eula) {
            return of(undefined);
          }

          return this.eulaService.respondToEula(eula.isAccepted, eula.nrn).pipe(
            catchError(error => {
              if (error.status === 403) {
                this.logOut();
                return of(undefined);
              }
              return throwError(error);
            })
          );
        })
      )
      .subscribe(
        () => {},
        error => {
          console.log(`There was an error responding to the eula: ${JSON.stringify(error)}`);
          this.toastService.displayError('AUTHENTICATION.EULA_ERROR_RESPONDING');
        }
      );
  }

  logOut(): void {
    this.authenticationService.logOut();
    this.toastService.closeAllSnackbars();
  }
}
