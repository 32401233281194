import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AuthenticationService } from '@core/services/authentication/authentication.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorComponent {
  @Input() title: string;
  @Input() description: string;

  constructor(private authenticationService: AuthenticationService) {}

  backToLogin() {
    this.authenticationService.logOut();
  }
}
