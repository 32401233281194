<div class="header-component-wrapper">
  <div
    class="header-component-container"
  >
    <div
      [class.header-content-wrapper-with-back-link]="ngContentHeaderBackLink"
      [class.header-content-wrapper-without-back-link]="!ngContentHeaderBackLink"
    >
      <div
        [class.header-content-section-with-back-link-or-button]="displayBackLinkOrButtonWrapper"
        [class.header-content-section-without-back-link-and-button]="!displayBackLinkOrButtonWrapper"
      >
        <div>
          <ng-content select="app-content-header-back-link"></ng-content>
        </div>
        <div class="raised-button-mobile" *ngIf="!contentHeaderButtonInlineWithHeader">
          <ng-container *ngTemplateOutlet="raisedButton"></ng-container>
        </div>
      </div>
      <div class="header-content-section">
        <div class="header-title-wrapper">
          <h1 class="header">
            {{ header | translate }}
          </h1>
          <div class="raised-button-inline-with-button" *ngIf="contentHeaderButtonInlineWithHeader">
            <ng-container *ngTemplateOutlet="raisedButton"></ng-container>
          </div>
        </div>
        <div *ngIf="!searchBoxFullScreen" class="search-box-container">
          <ng-container *ngTemplateOutlet="search"></ng-container>
        </div>
        <div class="app-user-menu">
          <app-user-menu [isMobile]="false"></app-user-menu>
        </div>
      </div>
      <ng-content select="app-content-header-sub-header"></ng-content>
      <ng-content select="app-content-header-row-counter"></ng-content>
      <ng-content select="app-content-header-terms-and-condition"></ng-content>
      <div *ngIf="searchBoxFullScreen" class="search-box-container-mobile">
        <ng-container *ngTemplateOutlet="search"></ng-container>
      </div>
    </div>
    <div class="header-custom-tabs-wrapper">
      <ng-content select="app-content-header-user-license-tabs"></ng-content>
      <ng-content select="[custom-tabs-with-routes]"></ng-content>
    </div>
  </div>
  <ng-template #raisedButton>
      <ng-content select="app-content-header-button"></ng-content>
  </ng-template>
  <ng-template #search>
    <ng-content select="app-search"></ng-content>
  </ng-template>
</div>