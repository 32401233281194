import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { UserModel, UserModelData } from '@core/models/user/user.model';
import { take } from 'rxjs/operators';
import { LicenseUserViewModel } from '@user-administration/models/user-administration/license-user';

@Injectable()
export class UserDisplayService {
  defaultName: string;
  defaultInitial: string;

  constructor(private translateService: TranslateService) {
    this.translateService.onLangChange.pipe(take(1)).subscribe(() => {
      this.defaultName = this.translateService.instant('FILE.ACTIVITY_UNKNOWN_USER');
      this.defaultInitial = this.translateService.instant('FILE.ACTIVITY_QUESTION_MARK');
    });
  }

  getInitials(user?: UserModel): string {
    if (!user) {
      return this.defaultInitial;
    } else if (user.firstName || user.lastName) {
      const userInitials: string[] = [];
      if (user.firstName) {
        userInitials.push(user.firstName.charAt(0));
      }
      if (user.lastName) {
        userInitials.push(user.lastName.charAt(0));
      }
      return userInitials
        .join('')
        .trim()
        .toUpperCase();
    } else if (user.email) {
      return user.email.charAt(0).toUpperCase();
    }

    return this.defaultInitial;
  }

  getDisplayName(user?: UserModel): string {
    if (!user) {
      return this.defaultName;
    } else if (user.firstName || user.lastName) {
      const nameArr = [user.firstName, user.lastName];
      return nameArr.join(' ').trim();
    } else if (user.email) {
      return user.email;
    }

    return this.defaultName;
  }

  getUserModelData(user: LicenseUserViewModel): UserModelData {
    if (!user.fullName?.trim()) {
      return {
        email: user.email,
        firstName: '',
        lastName: ''
      };
    } else {
      const userFullNameSplit = user.fullName.split(' ');
      if (userFullNameSplit.length === 2) {
        return {
          email: user.email,
          firstName: userFullNameSplit[0],
          lastName: userFullNameSplit[1]
        };
      } else {
        return {
          email: user.email,
          firstName: user.fullName,
          lastName: ''
        };
      }
    }
  }
}
