import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'projectTitle'
})
export class ProjectTitlePipe implements PipeTransform {
  transform(project: { name: string; number: string | null }): string {
    return project.number ? `${project.name} - ${project.number}` : project.name;
  }
}
