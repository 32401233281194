import { Component, Input } from '@angular/core';
import { EventUser } from '@shared/services/event-mapper/event-mapper.service';
import { InitialsTheme } from '@shared/models/initials/initialsTheme.enum';

@Component({
  selector: 'app-user-display',
  templateUrl: './user-display.component.html',
  styleUrls: ['./user-display.component.scss']
})
export class UserDisplayComponent {
  @Input() userDetails: EventUser;
  initialsTheme = InitialsTheme;
}
