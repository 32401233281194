import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, concatMap, map, mergeMap, skip, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiUrlProvider } from '@core/utilities/apiUrl/apiUrl.provider';
import { UploadFileModel } from '@files/models/file/uploadFile.model';
import { v4 } from 'uuid';
import { ToastService } from '@core/services/toast/toast.service';
import {
  ConflictBehavior,
  CreateUploadSessionRequest,
  CreateUploadSessionResponse,
  FileUploadResponse,
  UploadCompleteModel
} from '@core/models/upload/upload.model';
import { ApiErrorService } from '@core/services/api/api-error.service';
import { ApiErrorType } from '@core/models/api/error-type.model';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { getType } from 'mime';

@Injectable()
export class UploadService {
  constructor(
    private http: HttpClient,
    private apiUrlProvider: ApiUrlProvider,
    private toastService: ToastService,
    private apiErrorService: ApiErrorService
  ) {}

  private uploadProgressSubject: BehaviorSubject<number> = new BehaviorSubject(0);
  private uploadInProgressSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private nameOfFileBeingUploadedSubject: BehaviorSubject<string> = new BehaviorSubject('');
  private filesRemainingToUploadSubject: BehaviorSubject<number> = new BehaviorSubject(0);
  private uploadTargetNrnSubject: BehaviorSubject<string> = new BehaviorSubject('');

  uploadProgress = this.uploadProgressSubject.asObservable();
  uploadInProgress = this.uploadInProgressSubject.asObservable();
  nameOfFileBeingUploaded = this.nameOfFileBeingUploadedSubject.asObservable();
  uploadTargetNrn = this.uploadTargetNrnSubject.asObservable();
  filesRemainingToUpload = this.filesRemainingToUploadSubject
    .asObservable()
    .pipe(map(filesRemaining => ({ count: filesRemaining })));

  private cancelUploadOnError(): void {
    this.uploadProgressSubject.next(0);
    this.uploadInProgressSubject.next(false);
    this.nameOfFileBeingUploadedSubject.next('');
    this.uploadTargetNrnSubject.next('');
    this.filesRemainingToUploadSubject.next(0);
  }

  upload(
    files: File[],
    folderNrn: string,
    conflictBehavior: ConflictBehavior = ConflictBehavior.Rename,
    throwErrorOnFailure: boolean = false
  ): Observable<UploadCompleteModel> {
    const filesToRetry: File[] = [];
    const uploadedFileNrns: string[] = [];

    const validQueueElements = files.filter(file => {
      if (file && file.size > 0) {
        return true;
      }

      this.toastService.displayError('UPLOAD.EMPTY_FILE_ERROR');
    });

    const batchId = v4();

    let filesRemaining = validQueueElements.length;
    this.filesRemainingToUploadSubject.next(filesRemaining - 1);

    if (validQueueElements.length > 0) {
      this.uploadTargetNrnSubject.next(folderNrn);
    } else {
      return of({ filesToRetry, uploadedFileNrns });
    }

    return from(validQueueElements)
      .pipe(
        concatMap(upload =>
          this.uploadFileAndComplete(upload, folderNrn, batchId, conflictBehavior).pipe(
            tap(({ success, code, errorType, fileNeedingRetry, uploadedFileNrn }) => {
              if (!success && throwErrorOnFailure) {
                this.handleUploadError(code, errorType || '', conflictBehavior);
                this.cancelUploadOnError();

                throw new Error('Unable to upload file');
              }

              if (!success) {
                code === 409 && conflictBehavior === ConflictBehavior.Fail && fileNeedingRetry
                  ? filesToRetry.push(fileNeedingRetry)
                  : this.handleUploadError(code, errorType || '', conflictBehavior);
              }

              if (success && !!uploadedFileNrn) {
                uploadedFileNrns.push(uploadedFileNrn);
              }

              filesRemaining--;
              this.endUpload(filesRemaining);
            })
          )
        )
      )
      .pipe(skip(validQueueElements.length - 1))
      .pipe(map(() => ({ filesToRetry, uploadedFileNrns })));
  }

  private endUpload(filesRemaining: number): void {
    this.filesRemainingToUploadSubject.next(filesRemaining - 1);
    if (filesRemaining !== 0) {
      return;
    }

    this.uploadInProgressSubject.next(false);
    this.uploadProgressSubject.next(0);
    this.nameOfFileBeingUploadedSubject.next('');
    this.filesRemainingToUploadSubject.next(0);
  }

  private uploadFileAndComplete(
    fileToUpload: File,
    folderNrn: string,
    batchId: string,
    conflictBehavior: ConflictBehavior
  ): Observable<FileUploadResponse> {
    let uploadedFileNrn;
    const { name, size } = fileToUpload;
    return of({}).pipe(
      tap(() => {
        this.uploadInProgressSubject.next(true);
        this.nameOfFileBeingUploadedSubject.next(name);
        this.uploadProgressSubject.next(0);
      }),
      mergeMap(() =>
        this.createUploadSession({
          folderNrn,
          requestedFileName: name,
          fileSizeInBytes: size,
          mimeType: getType(name) || 'application/octet-stream',
          conflictBehavior,
          batchId
        })
      ),
      mergeMap((createUploadSessionResponse: CreateUploadSessionResponse) => {
        uploadedFileNrn = createUploadSessionResponse.fileNrn;

        return this.uploadFile({
          file: fileToUpload,
          uploadSessionId: createUploadSessionResponse.uploadSessionId,
          signedS3Url: createUploadSessionResponse.uploadUrl
        });
      }),
      mergeMap(uploadSessionId => this.completeUploadSession(uploadSessionId)),
      map(() => ({
        success: true,
        code: 200,
        fileNeedingRetry: null,
        uploadedFileNrn,
        errorType: null
      })),
      catchError((error: HttpErrorResponse) => {
        const errorType = error.error && error.error.type ? error.error.type : null;
        return of({
          success: false,
          code: error.status,
          errorType: errorType,
          fileNeedingRetry: fileToUpload,
          uploadedFileNrn: null
        });
      })
    );
  }

  private createUploadSession(requestBody: CreateUploadSessionRequest): Observable<CreateUploadSessionResponse> {
    return this.http.post<CreateUploadSessionResponse>(`${this.getUrl()}/files/uploadsessions`, requestBody);
  }

  private uploadFile(uploadModel: UploadFileModel): Observable<string> {
    return this.uploadFileToBucket(uploadModel.file, uploadModel.signedS3Url, this.uploadProgressSubject).pipe(
      map(() => uploadModel.uploadSessionId)
    );
  }

  private completeUploadSession(sessionId: string): Observable<CreateUploadSessionResponse> {
    return this.http.patch<CreateUploadSessionResponse>(`${this.getUrl()}/files/uploadsessions/${sessionId}`, {
      status: 'UploadComplete'
    });
  }

  uploadFileToBucket(file: File, url: string, progressObservable: Subject<Number>): Observable<any> {
    progressObservable.next(0);
    const options = {
      url: url,
      method: 'PUT',
      headers: [
        {
          name: 'Content-Type',
          value: file.type
        }
      ],
      disableMultipart: true,
      reportProgress: true
    };

    const uploader = this.createFileUploader(options);
    uploader.onAfterAddingFile = addedFile => {
      addedFile.withCredentials = false;
    };

    uploader.addToQueue([file]);
    uploader.uploadAll();

    return new Observable(observer => {
      uploader.onProgressAll = function(progress: any): any {
        progressObservable.next(progress);
        return { progress };
      };
      uploader.onCompleteAll = function(): any {
        observer.next();
        observer.complete();
        return void 0;
      };
      uploader.onErrorItem = function(item: FileItem, response: string, status: number): any {
        observer.error({ status: status });
        return void 0;
      };
    });
  }

  createFileUploader(options): FileUploader {
    return new FileUploader(options);
  }

  private getUrl(): string {
    return this.apiUrlProvider.getUrl();
  }

  private handleUploadError(statusCode: number, errorType: string, conflictBehavior: ConflictBehavior): void {
    const errorMessage =
      conflictBehavior === ConflictBehavior.Rename
        ? this.getErrorMessageForAttachmentUpload(statusCode)
        : this.getErrorMessageForFileUpload(statusCode, errorType, conflictBehavior);

    this.toastService.displayError(errorMessage);
  }

  private getErrorMessageForFileUpload(
    statusCode: number,
    errorType: string,
    conflictBehavior: ConflictBehavior
  ): string {
    const errorReason = this.apiErrorService.getApiErrorType(errorType);
    const errorMessages = {
      409: conflictBehavior === ConflictBehavior.Overwrite ? 'UPLOAD.REPLACE_FAILED' : 'UPLOAD.FILE_EXISTS',
      403: errorReason === ApiErrorType.OverwriteNotAllowed ? 'UPLOAD.FILE_EXISTS' : 'UPLOAD.ACCESS_DENIED',
      501: 'UPLOAD.FILE_EXISTS'
    };

    return errorMessages[statusCode] || 'UPLOAD.UPLOAD_FAILED';
  }

  private getErrorMessageForAttachmentUpload(statusCode: number): string {
    const errorMessages = {
      403: 'ACTION_ITEM.UPLOAD_ATTACHMENT_ERROR.ACCESS_DENIED',
      409: 'ACTION_ITEM.UPLOAD_ATTACHMENT_ERROR.FILE_EXISTS',
      501: 'ACTION_ITEM.UPLOAD_ATTACHMENT_ERROR.NOT_SUPPORTED'
    };

    return errorMessages[statusCode] || 'ACTION_ITEM.UPLOAD_ATTACHMENT_ERROR.DEFAULT';
  }
}
