import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from '@shared/models/contact/contact.model';
import { UserDisplayService } from '@core/services/user-display/user-display.service';
import { UserModel } from '@core/models/user/user.model';

@Pipe({
  name: 'displayContact'
})
export class DisplayContactPipe implements PipeTransform {
  constructor(private userDisplayService: UserDisplayService) {}

  transform(contact: Contact): string {
    return this.userDisplayService.getDisplayName(this.mapContactToUserModel(contact));
  }

  mapContactToUserModel(contact: Contact): UserModel {
    return {
      firstName: contact.firstName || undefined,
      lastName: contact.lastName || undefined,
      email: contact.email || undefined
    };
  }
}
