import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-drag-and-drop-label',
  templateUrl: './drag-and-drop-label.component.html',
  styleUrls: ['./drag-and-drop-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DragAndDropLabelComponent {
  @Input() highlightDropArea: boolean;
  @Input() labelFor: string;
  @Input() size: 'small' | 'large';
}
