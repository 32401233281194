<app-tooltip
  [title]="keyword"
  [subtitle]="subtitle"
  [showHoverCard]="showHoverCard">
</app-tooltip>
<div class="chip-item">
  <mat-chip
    [removable]="removable"
    [class.disabled-chip]="!removable"
    [selectable]="selectable"
    (removed)="remove()"
    (click)="click()"
    (mouseenter)="mouseOver()"
    (mouseleave)="mouseOut()"
  >
    <ng-content></ng-content>
    <mat-icon
      *ngIf="removable"
      matChipRemove
      class="remove-chip-item"
      svgIcon="remove-chip-item"
    >
    </mat-icon>
  </mat-chip>
</div>

