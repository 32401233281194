import { Pipe, PipeTransform } from '@angular/core';
import { EmailContact } from '@emails/models/search/emailContact.model';

@Pipe({
  name: 'emailContactListDisplay'
})
export class EmailContactListDisplayPipe implements PipeTransform {
  transform(params: EmailContact[]) {
    return params
      .map((contact: EmailContact) => {
        const name = contact.name !== contact.email ? contact.nameHighlighted || contact.name : '';
        const email = contact.emailHighlighted || contact.email;
        return `${name}${name ? ' ' : ''}${name ? '&lt;' : ''}${email || ''}${name ? '&gt;' : ''}`;
      })
      .join('; ');
  }
}
