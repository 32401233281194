export interface CreateUploadSessionRequest {
  folderNrn?: string;
  requestedFileName: string;
  fileSizeInBytes: number;
  mimeType: string;
  allowOverwrite?: boolean;
  batchId: string;
  conflictBehavior?: string;
}

export interface CreateUploadSessionResponse {
  fileNrn: string;
  status: string;
  uploadSessionId: string;
  uploadUrl: string;
}

export interface FileUploadResponse {
  success: boolean;
  code: number;
  fileNeedingRetry: File | null;
  uploadedFileNrn: string | null;
  errorType: string | null;
}

export enum ConflictBehavior {
  Fail = 'fail',
  Overwrite = 'overwrite',
  Rename = 'rename'
}

export interface UploadCompleteModel {
  filesToRetry: File[];
  uploadedFileNrns: string[];
}
