import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-content-header-row-counter',
  templateUrl: './content-header-row-counter.component.html',
  styleUrls: ['./content-header-row-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentHeaderRowCounterComponent {
  @Input() rowCounter: string;
  @Input() rowCounterTitle: string;
}
