<form
  class="search-container {{ size }}"
  [class.focused]="isFocused"
  fxLayout="row"
  fxLayoutAlign="start center"
  [formGroup]="searchForm"
>
  <mat-icon
    svgIcon="search"
    class="search-icon"
    (click)="focusInput()"
  >
  </mat-icon>
  <input
    #searchInput
    matInput
    type="text"
    (blur)="onBlur()"
    (focus)="onFocus()"
    class="search-input"
    [formControl]="searchQueryControl"
    placeholder="{{placeholder | translate}}"
    autocomplete="off"
  />
  <mat-icon
    [class.hidden]="!searchForm.value.searchQuery"
    svgIcon="cancel-search"
    class="cancel-search-icon"
    (click)="clearSearch()"
  >
  </mat-icon>
</form>
