import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TypedAction } from '@ngrx/store/src/models';
import { TermsAndConditionsDialogContainer } from '@terms-and-conditions/containers/terms-and-conditions-dialog/terms-and-conditions-dialog.container';

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsDialogService {
  constructor(private dialog: MatDialog) {}

  private dialogRef: MatDialogRef<TermsAndConditionsDialogContainer>;

  open(
    projectNrn?: string,
    viewTermsAndconditions?: boolean,
    action?: TypedAction<any>
  ): MatDialogRef<TermsAndConditionsDialogContainer> {
    this.dialogRef = this.dialog.open(TermsAndConditionsDialogContainer, {
      data: { projectNrn, viewTermsAndconditions, actionOnSuccess: action },
      disableClose: true,
      panelClass: 'user-terms-and-conditions-dialog-container',
      height: '626px',
      width: '470px'
    });
    return this.dialogRef;
  }

  close(): void {
    this.dialogRef.close();
  }
}
