import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserLicenseResponseModel } from '@core/models/user/user-license-response.model';
import { ApiUrlProvider } from '@core/utilities/apiUrl/apiUrl.provider';
import { Observable } from 'rxjs';
import { UpdateUserRequestData } from '@core/models/user/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient, private apiUrlProvider: ApiUrlProvider) {}

  getUser(): Observable<UserLicenseResponseModel> {
    return this.http.get<UserLicenseResponseModel>(`${this.getUrl()}/me`);
  }

  updateUserProfile(userData: UpdateUserRequestData, userNrn: string): Observable<void> {
    return this.http.patch<void>(`${this.getUrl()}/users/${encodeURIComponent(userNrn)}`, userData);
  }

  private getUrl(): string {
    return `${this.apiUrlProvider.getUrl()}`;
  }
}
