import * as Bowser from 'bowser';
import { Injectable } from '@angular/core';
import { WindowService } from '@core/services/window/window.service';

@Injectable()
export class BrowserCompatibilityService {
  private supportedBrowsers = {
    chrome: '>=84',
    firefox: '>=78',
    edge: '>=84',
    safari: '>=13',
    ios: '10'
  };
  private dismissUnsupportedCookieId = 'dismiss-unsupported-alert';

  constructor(private windowService: WindowService) {}

  isBrowserSupported(): boolean {
    const userAgent = this.windowService.getUserAgent();
    const browser = Bowser.getParser(userAgent);
    const isSupportedBrowser = browser.satisfies(this.supportedBrowsers);

    return !!isSupportedBrowser;
  }

  setUserAcknowledgedSupport(): void {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(this.dismissUnsupportedCookieId, 'true');
    }
  }

  unsetUserAcknowledgedSupport(): void {
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem(this.dismissUnsupportedCookieId);
    }
  }

  hasUserAcknowledgedSupport(): boolean {
    if (typeof localStorage !== 'undefined') {
      const acknowledgementCookie = localStorage.getItem(this.dismissUnsupportedCookieId);

      return acknowledgementCookie === 'true';
    } else {
      return false;
    }
  }
}
