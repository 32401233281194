<div class="list-table-wrapper">
  <div class="list-table full-height">
    <div [style.margin-right]="rightMarginInPx">
      <ng-content select="div.list-table-header"></ng-content>
    </div>
    <div
      *ngIf="isLoading"
      fxLayout
      fxLayoutAlign="center center"
      class="loading-spinner-container"
    >
      <app-spinner></app-spinner>
    </div>
    <ng-container *ngIf="!isLoading">
      <div
        *ngIf="!hasLoadingError"
        class="list-table-container"
      >
        <app-empty-state
          *ngIf="isEmpty"
          [secondaryLabel]="emptyMessage"
          icon="empty-list"
          fxLayout="column"
          fxLayoutAlign="0 center"
          class="list-empty-state"
        ></app-empty-state>
        <div
          *ngIf="!isEmpty"
          #listTableBody
          class="list-table-body full-height"
          infiniteScroll
          (scrolled)="reachEndOfTable()"
          [scrollWindow]="false"
          [alwaysCallback]="true"
        >
          <ng-content select="div.list-table-iterable-content"></ng-content>
          <div
            *ngIf="isLoadingMore"
            fxLayout
            fxLayoutAlign="center center"
            class="load-more-users-spinner"
          >
            <app-spinner></app-spinner>
          </div>
          <div
            *ngIf="hasLoadingMoreError"
            fxLayout
            fxLayoutAlign="center center"
            class="more-license-error-container"
          >
            <app-empty-state
              primaryLabel="LIST_WRAPPER.DEFAULT_CANNOT_LOAD_MORE_USERS_ERROR_PRIMARY_LABEL"
              secondaryLabel="LIST_WRAPPER.DEFAULT_CANNOT_LOAD_MORE_USERS_ERROR_SECONDARY_LABEL"
            ></app-empty-state>
          </div>
        </div>
      </div>
      <div
        *ngIf="hasLoadingError"
        fxLayout
        fxLayoutAlign="center"
        class="list-error-container"
      >
        <app-empty-state
          primaryLabel="LIST_WRAPPER.DEFAULT_CANNOT_LOAD_USERS_ERROR_PRIMARY_LABEL"
          secondaryLabel="LIST_WRAPPER.DEFAULT_CANNOT_LOAD_USERS_ERROR_SECONDARY_LABEL"
          icon="general-error"
        ></app-empty-state>
      </div>
    </ng-container>
  </div>
</div>
