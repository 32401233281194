<div
  class="team-members-search-result"
  *ngIf="resource.type === resourceType.Member"
>
  <app-initials [initials]="resource.initials" [theme]="initialsTheme.Light"></app-initials>
  <div class="member-info full-width">
    <div class="team-member-name" [innerHtml]="resource.boldName"></div>
    <div class="team-member-details full-width">
      <div class="team-member-email overflow-ellipsis" [innerHtml]="resource.boldEmail"></div>
      <div class="team-member-discipline" [innerHtml]="discipline"></div>
    </div>
  </div>
</div>
<div
  *ngIf="resource.type === resourceType.Group"
  class="team-members-search-result"
>
  <mat-icon class="group-icon" svgIcon="group"></mat-icon>
  <span class="team-member-name full-width" [innerHtml]="resource.boldName"></span>
</div>
