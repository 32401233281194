import { Input, Output, EventEmitter, Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-editable-due-date',
  templateUrl: './editable-due-date.component.html',
  styleUrls: ['./editable-due-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditableDueDateComponent implements OnInit, OnDestroy {
  @Input() isReadonly: boolean;
  @Input() isRequired: boolean;
  @Input() requiredErrorMessage: string;
  @Input() set dueDate(value: string) {
    this.initialDueDate = value;
    this.handleDueDateValueChange(value);
  }
  @Input() set hasRequestError(hasError: boolean) {
    this.handleError(hasError);
  }

  @Output() onChangeMode = new EventEmitter<boolean>();
  @Output() onSubmit = new EventEmitter<string>();

  isEditMode = false;
  isOutsideDirectiveLocked = false;

  private initialDueDate: string;
  private destroyComponent = new Subject();

  readonly dueDateControlName = 'dueDate';
  form = new FormGroup({
    [this.dueDateControlName]: new FormControl('')
  });

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.subscribeToRouter();
  }

  ngOnDestroy(): void {
    this.destroyComponent.next();
    this.destroyComponent.complete();
  }

  get dueDateControl(): AbstractControl {
    return <AbstractControl>this.form.get(this.dueDateControlName);
  }

  openEditMode(): void {
    if (this.isEditMode || this.isReadonly) {
      return;
    }

    this.updateEditMode(true);
  }

  tryToSubmit(): void {
    const currentDueDate = this.dueDateControl.value;

    if (!this.isEditMode || this.isOutsideDirectiveLocked || (this.isRequired && currentDueDate === null)) {
      return;
    }

    currentDueDate !== this.initialDueDate ? this.submit() : this.discardChanges();
  }

  enableClickOutside(): void {
    this.isOutsideDirectiveLocked = false;
  }

  disableClickOutside(): void {
    this.isOutsideDirectiveLocked = true;
  }

  discardChanges(): void {
    this.updateEditMode(false);
    this.form.patchValue({
      [this.dueDateControlName]: this.initialDueDate
    });
  }

  private submit(): void {
    this.onSubmit.emit(this.dueDateControl.value);
    this.updateEditMode(false);
  }

  private updateEditMode(isEditMode: boolean): void {
    this.isEditMode = isEditMode;
    this.onChangeMode.emit(isEditMode);
  }

  private handleDueDateValueChange(value: string): void {
    this.dueDateControl.patchValue(value);
  }

  private handleError(hasError: boolean): void {
    if (!hasError) {
      return;
    }

    this.isEditMode = this.dueDateControl.value !== this.initialDueDate;

    if (this.isEditMode) {
      this.onChangeMode.emit(true);
    }
  }

  private subscribeToRouter(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        takeUntil(this.destroyComponent)
      )
      .subscribe(() => {
        this.tryToSubmit();
      });
  }
}
