import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { SharePermission } from '@core/models/shared-item/share-permission.model';
import { ConfirmationDialogService } from '@shared/services/confirmation-dialog/confirmation.dialog.service';

@Component({
  selector: 'app-permission-selector',
  templateUrl: './permission-selector.component.html',
  styleUrls: ['./permission-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermissionSelectorComponent implements OnInit {
  @Input() defaultValue: SharePermission;
  @Output() permissionChanged = new EventEmitter<SharePermission>();

  previousPermission: SharePermission;

  constructor(
    private confirmationDialogService: ConfirmationDialogService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.previousPermission = this.defaultValue;
  }

  get sharePermissions(): typeof SharePermission {
    return SharePermission;
  }

  newPermissionSelected(permission: SharePermission): void {
    if (permission !== SharePermission.FullAccess) {
      this.permissionChanged.emit(permission);
      this.previousPermission = permission;
      return;
    }
    this.confirmationDialogService
      .openConfirmationDialog({
        headerText: 'FOLDER_SHARE.PERMISSION_CHANGE_DIALOG.HEADER',
        declineText: 'FOLDER_SHARE.PERMISSION_CHANGE_DIALOG.DECLINE',
        confirmationText: 'FOLDER_SHARE.PERMISSION_CHANGE_DIALOG.ACCEPT',
        dialogText: 'FOLDER_SHARE.PERMISSION_CHANGE_DIALOG.MESSAGE',
        hideDialogText: 'FOLDER_SHARE.PERMISSION_CHANGE_DIALOG.DONT_SHOW_AGAIN',
        localStorageKey: 'permission-change-confirmation-dialog'
      })
      .subscribe(result => {
        if (result.isConfirmed) {
          this.permissionChanged.emit(permission);
        } else {
          this.defaultValue = this.previousPermission;
          this.changeDetectorRef.markForCheck();
        }
      });
  }
}
