export class FileTypeParser {
  private static readonly extensionSeparator = '.';

  static getExtensionFromName(name: string): string {
    const fileParts = name.split(this.extensionSeparator);
    return fileParts.length > 1 ? <string>fileParts.pop() : '';
  }

  static isArchiveFile(id: string): boolean {
    const archiveExtensions = ['zip', 'zipx', 'rar', '7z', 'tar', 'gz', 'cab'];
    const extension = this.getExtensionFromName(id);

    return archiveExtensions.includes(extension);
  }
}
