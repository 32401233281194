import { Injectable } from '@angular/core';
import { EventModel, EventPerson, EventSharedProperties } from '@shared/models/events/events-model';
import { eventTypesTranslations } from '@shared/services/events/event-types-translations';
import { TranslateCacheService } from '@shared/services/events/translate-cache/translate-cache-service';
import { MappedProjectEvent, ProjectEventDetail } from '@project-log/models/mapped-project-events';
import { DatePipe } from '@angular/common';
import { KeywordModel } from '@core/models/project/keyword.model';
import { SubmittalFileDownloadedEvent, SubmittalFileTransferFailedEvent } from '@shared/models/events/submittal-events';
import { RfiFileDownloadedEvent, RfiFileTransferFailedEvent } from '@shared/models/events/rfi-events';

@Injectable({ providedIn: 'root' })
export class SharedEventMapperService {
  constructor(private translateCacheService: TranslateCacheService, private datePipe: DatePipe) {}
  readonly dash = '-';

  mapSharedEventProperties(event: EventModel): EventSharedProperties {
    const action = eventTypesTranslations[event.type] || 'EVENTS.UNKNOWN';
    return {
      date: event.createdOn,
      originator: this.getOriginatorData(event.originator),
      action: this.translateCacheService.getCachedTranslation(action)
    };
  }

  mapUnknownEvent(event: EventModel): MappedProjectEvent {
    return {
      ...this.mapSharedEventProperties(event),
      item: this.dash,
      details: [{ key: '', value: this.dash }]
    };
  }

  mapWorkflowItemFileTransferFailedEventDetails(
    event: SubmittalFileTransferFailedEvent | RfiFileTransferFailedEvent
  ): ProjectEventDetail[] {
    return [
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.FILE_NAME'),
        value: event.eventData.fileName
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.FAILED_WORKFLOW_ACTION'),
        value: event.eventData.workflowAction.name
      }
    ];
  }

  mapWorkflowFileDownloadedEventDetails(
    event: SubmittalFileDownloadedEvent | RfiFileDownloadedEvent
  ): ProjectEventDetail[] {
    return [
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.FILE_NAME'),
        value: event.eventData.fileName
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.PATH'),
        value: event.eventData.path
      }
    ];
  }

  mapModifiedEventField(value: any, fieldName: string): string {
    const dateFieldNames = ['dueDate', 'receivedDate'];

    if (typeof value === 'boolean') {
      return this.translateCacheService.getCachedTranslation(!!value ? 'EVENTS.TRUE' : 'EVENTS.FALSE');
    }

    if (!value || value?.length === 0) {
      return this.translateCacheService.getCachedTranslation('EVENTS.EMPTY');
    }

    if (dateFieldNames.includes(fieldName)) {
      return <string>this.mapDate(value);
    }

    if (typeof value === 'string') {
      return value;
    }

    if ('name' in value) {
      return value.name;
    }

    if (Array.isArray(value)) {
      return value.map(part => this.mapModifiedEventField(part, fieldName)).join(', ');
    }

    return '';
  }

  mapDate(date?: string | null): string | null {
    return this.datePipe.transform(date, 'shortDate');
  }

  mapKeywords(keywords: KeywordModel[]): string {
    return keywords.map(keyword => keyword.name).join(', ');
  }

  mapParticipant(person: EventPerson): string {
    const isFirstNameAndLastNameProvided = !!person.firstName && !!person.lastName;

    return isFirstNameAndLastNameProvided ? `${person.firstName} ${person.lastName}` : `${person.email}`;
  }

  mapParticipants(persons: EventPerson[]): string {
    return persons.map(this.mapParticipant).join(', ');
  }

  private getOriginatorData(person?: EventPerson): EventPerson {
    if (!person) {
      return {
        email: this.dash
      };
    }

    const isEventPersonDataProvided = !!person.email || (!!person.firstName && !!person.lastName);
    return isEventPersonDataProvided
      ? {
          email: person.email ?? '',
          firstName: person.firstName ?? '',
          lastName: person.lastName ?? ''
        }
      : {
          email: this.dash
        };
  }
}
