import { createSelector } from '@ngrx/store';
import { appRootSelector } from '@root/selectors/app-root.selectors';

export const projectListSelector = createSelector(appRootSelector, state => state.projectList);

export const selectIsLoadingProjects = createSelector(projectListSelector, state => state.isLoadingProjects);

export const selectProjects = createSelector(projectListSelector, state =>
  Object.keys(state.projects).map(key => state.projects[key])
);

export const selectRecentProjectNrns = createSelector(projectListSelector, state => state.recentProjectNrns);

export const selectRecentProjects = createSelector(projectListSelector, state =>
  state.recentProjectNrns.map(recentProjectNrn => state.projects[recentProjectNrn])
);

export const selectLoadingProjectsError = createSelector(projectListSelector, state => state.loadingProjectsError);

export const selectCurrentProject = createSelector(projectListSelector, state =>
  state.currentProjectNrn ? state.projects[state.currentProjectNrn] : null
);

export const selectCurrentProjectNrn = createSelector(projectListSelector, state => <string>state.currentProjectNrn);

export const selectIsCloudProject = createSelector(selectCurrentProject, project =>
  !project ? false : project.type === 'cloud'
);

export const selectIsSearchSupported = createSelector(selectCurrentProject, project =>
  project ? project.capabilities.includes('search') : false
);

export const selectProjectsWhereViewChangeLogPermitted = createSelector(selectProjects, projects =>
  projects.filter(project => project.capabilities.includes('view_change_log'))
);

export const selectIsLoadingProjectDetails = createSelector(
  projectListSelector,
  state => state.isLoadingProjectDetails
);

export const selectProjectDetails = createSelector(projectListSelector, state => state.projectDetails);

export const selectCanDisplayEmailsContainer = createSelector(
  selectIsLoadingProjectDetails,
  selectProjectDetails,
  (isLoadingDetails, projectDetails) =>
    !isLoadingDetails &&
    !!projectDetails?.capabilities.includes('search') &&
    !!projectDetails?.nrn.includes('nrn:ncloud:project')
);

export const selectShowProjectTabs = createSelector(
  selectProjectDetails,
  selectIsLoadingProjectDetails,
  (projectDetails, isLoadingDetails) => !!projectDetails && !isLoadingDetails
);

export const selectShowEmailsTab = createSelector(
  selectProjectDetails,
  projectDetails =>
    !!(projectDetails?.capabilities.includes('search') && projectDetails?.nrn.includes('nrn:ncloud:project'))
);

export const selectIsViewSubmittalsCapabilitySupported = createSelector(
  selectProjectDetails,
  projectDetails => !!projectDetails?.capabilities.includes('view_submittals')
);

export const selectIsViewRfisCapabilitySupported = createSelector(
  selectProjectDetails,
  projectDetails => !!projectDetails?.capabilities.includes('view_rfis')
);

export const selectShowSearch = createSelector(
  selectProjectDetails,
  projectDetails => !!projectDetails?.capabilities.includes('search')
);

export const selectIsLogSubmittalCapabilitySupported = createSelector(
  selectProjectDetails,
  projectDetails => !!projectDetails?.capabilities.includes('log_submittal')
);

export const selectIsLogRfiCapabilitySupported = createSelector(
  selectProjectDetails,
  projectDetails => !!projectDetails?.capabilities.includes('log_rfi')
);
