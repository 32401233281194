import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectFoldersContainer } from '@shared/containers/project-folders/project-folders.container';

@Injectable({ providedIn: 'root' })
export class ProjectFoldersDialogService {
  constructor(private dialog: MatDialog) {}

  private dialogRef: MatDialogRef<ProjectFoldersContainer>;

  open(): void {
    this.dialogRef = this.dialog.open(ProjectFoldersContainer, {
      disableClose: true,
      width: '600px',
      height: '675px',
      panelClass: 'project-root-folders-dialog-container'
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
