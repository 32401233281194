import { BaseEvent, EventModel, EventPerson, SimpleChange } from '@shared/models/events/events-model';
import { KeywordModel } from '@core/models/project/keyword.model';

export type SubmittalReceivedEvent = BaseEvent<
  'SUBMITTAL_RECEIVED',
  {
    nrn: string;
    projectNrn: string;
    subject: string;
    number: string;
    specSection: KeywordModel;
    receivedDate: string;
    dueDate: string;
    purpose: KeywordModel;
    from: EventPerson;
    to: EventPerson[];
    senderNumber?: string | null;
    description?: string | null;
    internalNotes?: string | null;
    disciplines: KeywordModel[];
    keywords: KeywordModel[];
    attachments: string[];
    path: string;
  }
>;
export function isSubmittalReceivedEvent(event: EventModel): event is SubmittalReceivedEvent {
  return event.type === 'SUBMITTAL_RECEIVED';
}

export type SubmittalForwardedEvent = BaseEvent<
  'SUBMITTAL_FORWARDED',
  {
    nrn: string;
    projectNrn: string;
    subject: string;
    number: string;
    purpose: KeywordModel;
    reviewDueDate?: string | null;
    to: EventPerson[];
    cc: EventPerson[];
    remarks?: string | null;
    attachments: string[];
    path: string;
  }
>;
export function isSubmittalForwardedEvent(event: EventModel): event is SubmittalForwardedEvent {
  return event.type === 'SUBMITTAL_FORWARDED';
}

export type SubmittalReviewResponseEvent = BaseEvent<
  'SUBMITTAL_REVIEW_RESPONSE',
  {
    nrn: string;
    projectNrn: string;
    subject: string;
    number: string;
    response: KeywordModel;
    from: EventPerson;
    to: EventPerson[];
    cc: EventPerson[];
    remarks?: string | null;
    attachments: string[];
    path: string;
  }
>;
export function isSubmittalReviewResponseEvent(event: EventModel): event is SubmittalReviewResponseEvent {
  return event.type === 'SUBMITTAL_REVIEW_RESPONSE';
}

export type SubmittalRespondedEvent = BaseEvent<
  'SUBMITTAL_RESPONDED',
  {
    nrn: string;
    projectNrn: string;
    subject: string;
    number: string;
    response: KeywordModel;
    from: EventPerson;
    to: EventPerson[];
    cc: EventPerson[];
    remarks?: string | null;
    attachments: string[];
    path: string;
  }
>;
export function isSubmittalRespondedEvent(event: EventModel): event is SubmittalRespondedEvent {
  return event.type === 'SUBMITTAL_RESPONDED';
}

export type SubmittalClosedEvent = BaseEvent<
  'SUBMITTAL_CLOSED',
  {
    nrn: string;
    projectNrn: string;
    subject: string;
    number: string;
    purpose: KeywordModel;
    from: EventPerson;
    to: EventPerson[];
    cc: EventPerson[];
    remarks?: string | null;
    attachments: string[];
    path: string;
  }
>;
export function isSubmittalClosedEvent(event: EventModel): event is SubmittalClosedEvent {
  return event.type === 'SUBMITTAL_CLOSED';
}

export type SubmittalDeletedEvent = BaseEvent<
  'SUBMITTAL_DELETED',
  {
    nrn: string;
    projectNrn: string;
    subject: string;
    number: string;
    path: string | null;
    deletedFiles: string[];
  }
>;
export function isSubmittalDeletedEvent(event: EventModel): event is SubmittalDeletedEvent {
  return event.type === 'SUBMITTAL_DELETED';
}

export type SubmittalModifiedEvent = BaseEvent<
  'SUBMITTAL_MODIFIED',
  {
    nrn: string;
    projectNrn: string;
    subject: string;
    number: string;
    changes: SimpleChange[];
  }
>;
export function isSubmittalModifiedEvent(event: EventModel): event is SubmittalModifiedEvent {
  return event.type === 'SUBMITTAL_MODIFIED';
}

export type SubmittalFileTransferFailedEvent = BaseEvent<
  'SUBMITTAL_FILE_TRANSFER_FAILED',
  {
    nrn: string;
    projectNrn: string;
    subject: string;
    number: string;
    fileName: string;
    workflowAction: KeywordModel;
  }
>;
export function isSubmittalFileTransferFailedEvent(event: EventModel): event is SubmittalFileTransferFailedEvent {
  return event.type === 'SUBMITTAL_FILE_TRANSFER_FAILED';
}

export type SubmittalFileDownloadedEvent = BaseEvent<
  'SUBMITTAL_FILE_DOWNLOADED',
  {
    nrn: string;
    projectNrn: string;
    subject: string;
    number: string;
    fileName: string;
    path: string;
  }
>;
export function isSubmittalFileDownloadedEvent(event: EventModel): event is SubmittalFileDownloadedEvent {
  return event.type === 'SUBMITTAL_FILE_DOWNLOADED';
}
