import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Configuration } from '@core/models/configuration/configuration.model';
import { WindowService } from '@core/services/window/window.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ConfigurationService {
  private readonly defaultConfig = {
    apiBaseUrl: 'https://dev.api.newforma.cloud/v1',
    urlPrefix: 'dev.',
    production: environment.production,
    origin: '*',
    awsRegion: 'us-east-1',
    environmentShortCode: 'dev'
  };
  private configurationValue: Configuration;

  private configurationSubject = new BehaviorSubject<Configuration>(this.defaultConfig);
  private configurationLoadedSubject = new BehaviorSubject<boolean>(false);

  configuration = this.configurationSubject.asObservable();
  configurationLoaded = this.configurationLoadedSubject.asObservable();

  readonly prodShortCode = 'prod';

  constructor(private http: HttpClient, private windowService: WindowService) {
    this.configuration.subscribe(value => (this.configurationValue = value));
  }

  async load(): Promise<void> {
    await this.http
      .get('assets/config/default.json')
      .toPromise()
      .then((config: Configuration) => {
        const oldConfiguration = this.configurationValue;
        const newConfiguration = { ...oldConfiguration, ...this.defaultConfig, ...config };
        newConfiguration.origin = this.windowService.getNativeWindow().location.origin;

        this.configurationSubject.next(newConfiguration);
      })
      .catch(() => {
        console.warn('Configurations options are not set. Default values will be used.');
      })
      .finally(() => this.configurationLoadedSubject.next(true));
  }
}
