<div
  class="due-date"
  [class.error-due-date]="diffInDays < 0"
>
  <span class="label">
    {{ dueLabel | translate }}
  </span>
  <span class="diff-in-days">
    {{diffInDays | dueInLabel | translate: {value: diffInDays} }}
  </span>
</div>
