import { Pipe, PipeTransform } from '@angular/core';
import { FileTypeParser } from '@shared/utilities/fileTypes/fileType.parser';

@Pipe({
  name: 'fileTypeParser'
})
export class FileTypeParserPipe implements PipeTransform {
  transform(name: string): string {
    return FileTypeParser.getExtensionFromName(name);
  }
}
