import { Injectable } from '@angular/core';
import { TranslateCacheService } from '@shared/services/events/translate-cache/translate-cache-service';
import { SharedEventMapperService } from '@shared/services/events/shared-event-mapper/shared-event-mapper.service';
import { createEventMapper, EventMapperFunc, on } from '@shared/services/events/createEventMapper';
import {
  isRfiClosedEvent,
  isRfiDeletedEvent,
  isRfiFileDownloadedEvent,
  isRfiFileTransferFailedEvent,
  isRfiForwardedEvent,
  isRfiModifiedEvent,
  isRfiReceivedEvent,
  isRfiRespondedEvent,
  isRfiReviewResponseEvent,
  RfiClosedEvent,
  RfiDeletedEvent,
  RfiFileDownloadedEvent,
  RfiFileTransferFailedEvent,
  RfiForwardedEvent,
  RfiModifiedEvent,
  RfiReceivedEvent,
  RfiRespondedEvent,
  RfiReviewResponseEvent
} from '@shared/models/events/rfi-events';
import { MappedProjectEvent } from '@project-log/models/mapped-project-events';
import { fieldTypeNameTranslations } from '@shared/services/events/event-types-translations';

@Injectable({ providedIn: 'root' })
export class RfiEventsMapperService {
  constructor(
    private translateCacheService: TranslateCacheService,
    private sharedEventMapperService: SharedEventMapperService
  ) {}

  createEventMapper(): EventMapperFunc {
    return createEventMapper(
      on(isRfiReceivedEvent, this.mapRfiReceivedEvent.bind(this)),
      on(isRfiForwardedEvent, this.mapRfiForwardedEvent.bind(this)),
      on(isRfiReviewResponseEvent, this.mapRfiReviewResponseEvent.bind(this)),
      on(isRfiRespondedEvent, this.mapRfiRespondedEvent.bind(this)),
      on(isRfiClosedEvent, this.mapRfiClosedEvent.bind(this)),
      on(isRfiDeletedEvent, this.mapRfiDeletedEvent.bind(this)),
      on(isRfiModifiedEvent, this.mapRfiModifiedEvent.bind(this)),
      on(isRfiFileDownloadedEvent, this.mapRfiFileDownloadedEvent.bind(this)),
      on(isRfiFileTransferFailedEvent, this.mapRfiFileTransferFailedEvent.bind(this))
    );
  }

  private mapRfiReceivedEvent(event: RfiReceivedEvent): MappedProjectEvent {
    const details = [
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.SPEC_SECTION'),
        value: event.eventData.specSection?.name
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.RECEIVED_DATE'),
        value: this.sharedEventMapperService.mapDate(event.eventData.receivedDate)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.DUE_DATE'),
        value: this.sharedEventMapperService.mapDate(event.eventData.dueDate)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.URGENT'),
        value: this.translateCacheService.getCachedTranslation(
          !!event.eventData.urgent ? 'EVENTS.TRUE' : 'EVENTS.FALSE'
        )
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.TYPE'),
        value: event.eventData.type?.name
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.SENDER_NUMBER'),
        value: event.eventData.senderNumber
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.FROM'),
        value: this.sharedEventMapperService.mapParticipant(event.eventData.from)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.TO'),
        value: this.sharedEventMapperService.mapParticipants(event.eventData.to)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.QUESTION'),
        value: event.eventData.question
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.SUGGESTIONS'),
        value: event.eventData.suggestion
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.INTERNAL_NOTES'),
        value: event.eventData.internalNotes
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.IMPACT_SCHEDULE'),
        value: event.eventData.impactSchedule?.name
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.IMPACT_COST'),
        value: event.eventData.impactCost?.name
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.REASONS'),
        value: this.sharedEventMapperService.mapKeywords(event.eventData.reasons ?? [])
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.DISCIPLINES'),
        value: this.sharedEventMapperService.mapKeywords(event.eventData.disciplines)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.KEYWORDS'),
        value: this.sharedEventMapperService.mapKeywords(event.eventData.keywords)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.ATTACHMENTS'),
        value: event.eventData.attachments.join(', ')
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.PATH'),
        value: event.eventData.path
      }
    ];
    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: [event.eventData.subject, event.eventData.number],
      link: this.createLink(),
      linkQueryParams: this.createLinkQueryParams(event.eventData.projectNrn, event.eventData.nrn),
      details: details.filter(({ value }) => !!value)
    };
  }

  private mapRfiForwardedEvent(event: RfiForwardedEvent): MappedProjectEvent {
    const details = [
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.REVIEW_DUE_DATE'),
        value: this.sharedEventMapperService.mapDate(event.eventData.reviewDueDate)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.TO'),
        value: this.sharedEventMapperService.mapParticipants(event.eventData.to)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.CC'),
        value: this.sharedEventMapperService.mapParticipants(event.eventData.cc)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.REMARKS'),
        value: event.eventData.remarks
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.ATTACHMENTS'),
        value: event.eventData.attachments.join(', ')
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.PATH'),
        value: event.eventData.path
      }
    ];

    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: [event.eventData.subject, event.eventData.number],
      link: this.createLink(),
      linkQueryParams: this.createLinkQueryParams(event.eventData.projectNrn, event.eventData.nrn),
      details: details.filter(({ value }) => !!value)
    };
  }

  private mapRfiReviewResponseEvent(event: RfiReviewResponseEvent): MappedProjectEvent {
    const details = [
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.ACTION'),
        value: event.eventData.action.name
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.FROM'),
        value: this.sharedEventMapperService.mapParticipant(event.eventData.from)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.TO'),
        value: this.sharedEventMapperService.mapParticipants(event.eventData.to)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.CC'),
        value: this.sharedEventMapperService.mapParticipants(event.eventData.cc)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.RESPONSE'),
        value: event.eventData.answer
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.ATTACHMENTS'),
        value: event.eventData.attachments.join(', ')
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.PATH'),
        value: event.eventData.path
      }
    ];

    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: [event.eventData.subject, event.eventData.number],
      link: this.createLink(),
      linkQueryParams: this.createLinkQueryParams(event.eventData.projectNrn, event.eventData.nrn),
      details: details.filter(({ value }) => !!value)
    };
  }

  private mapRfiRespondedEvent(event: RfiRespondedEvent): MappedProjectEvent {
    const details = [
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.ACTION'),
        value: event.eventData.action.name
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.FROM'),
        value: this.sharedEventMapperService.mapParticipant(event.eventData.from)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.TO'),
        value: this.sharedEventMapperService.mapParticipants(event.eventData.to)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.CC'),
        value: this.sharedEventMapperService.mapParticipants(event.eventData.cc)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.ANSWER'),
        value: event.eventData.answer
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.ATTACHMENTS'),
        value: event.eventData.attachments.join(', ')
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.PATH'),
        value: event.eventData.path
      }
    ];

    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: [event.eventData.subject, event.eventData.number],
      link: this.createLink(),
      linkQueryParams: this.createLinkQueryParams(event.eventData.projectNrn, event.eventData.nrn),
      details: details.filter(({ value }) => !!value)
    };
  }

  private mapRfiClosedEvent(event: RfiClosedEvent): MappedProjectEvent {
    const details = [
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.ACTION'),
        value: event.eventData.action.name
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.REMARKS'),
        value: event.eventData.remarks
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.ATTACHMENTS'),
        value: event.eventData.attachments.join(', ')
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.PATH'),
        value: event.eventData.path
      }
    ];

    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: [event.eventData.subject, event.eventData.number],
      link: this.createLink(),
      linkQueryParams: this.createLinkQueryParams(event.eventData.projectNrn, event.eventData.nrn),
      details: details.filter(({ value }) => !!value)
    };
  }

  private mapRfiDeletedEvent(event: RfiDeletedEvent): MappedProjectEvent {
    const details = [
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.FILES_PRESERVED'),
        value: event.eventData.path
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.RFI_FILES_DELETED'),
        value: event.eventData.path ? '' : event.eventData.deletedFiles.join(', ')
      }
    ];

    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: [event.eventData.subject, event.eventData.number],
      link: this.createLink(),
      linkQueryParams: this.createLinkQueryParams(event.eventData.projectNrn, event.eventData.nrn),
      details: details.filter(({ value }) => !!value)
    };
  }

  private mapRfiModifiedEvent(event: RfiModifiedEvent): MappedProjectEvent {
    const details = event.eventData.changes.map(change => {
      const fieldTypeTranslation = fieldTypeNameTranslations[change.fieldName] || 'EVENTS.UNKNOWN';
      return [
        { key: this.translateCacheService.getCachedTranslation(fieldTypeTranslation) },
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.FROM'),
          value: this.sharedEventMapperService.mapModifiedEventField(change.previousValue, change.fieldName)
        },
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.TO'),
          value: this.sharedEventMapperService.mapModifiedEventField(change.currentValue, change.fieldName)
        }
      ];
    });

    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: [event.eventData.subject, event.eventData.number],
      link: this.createLink(),
      linkQueryParams: this.createLinkQueryParams(event.eventData.projectNrn, event.eventData.nrn),
      details: details.reduce((a, b) => a.concat(b), [])
    };
  }

  private mapRfiFileTransferFailedEvent(event: RfiFileTransferFailedEvent): MappedProjectEvent {
    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: [event.eventData.subject, event.eventData.number],
      link: this.createLink(),
      linkQueryParams: this.createLinkQueryParams(event.eventData.projectNrn, event.eventData.nrn),
      details: [
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.FILE_NAME'),
          value: event.eventData.fileName
        },
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.FAILED_WORKFLOW_ACTION'),
          value: event.eventData.workflowAction.name
        }
      ]
    };
  }

  private mapRfiFileDownloadedEvent(event: RfiFileDownloadedEvent): MappedProjectEvent {
    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: [event.eventData.subject, event.eventData.number],
      link: this.createLink(),
      linkQueryParams: this.createLinkQueryParams(event.eventData.projectNrn, event.eventData.nrn),
      details: [
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.FILE_NAME'),
          value: event.eventData.fileName
        },
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.PATH'),
          value: event.eventData.path
        }
      ]
    };
  }

  private createLink(): string {
    return `/rfis/list`;
  }

  private createLinkQueryParams(projectNrn: string, nrn: string): { [key: string]: string } {
    return {
      projectNrn,
      rfiNrn: nrn,
      rfiProjectNrn: projectNrn
    };
  }
}
