  <div class="back-link">
    <div class="arrow-back-icon">
      <a
        class="display-block"
        (click)="handleBackLink(link)"
      >
        <mat-icon svgIcon="back-link-left-arrow"></mat-icon>
      </a>
    </div>
    <a
      class="back-link-anchor"
      (click)="handleBackLink(link)"
    >
      {{ linkName | translate }}
    </a>
  </div>
