import * as Bowser from 'bowser';
import { Injectable } from '@angular/core';
import { PartialUserInfo } from '@core/models/authentication/partialUserInfo';
import { Base64 } from 'js-base64';

@Injectable()
export class WindowService {
  readonly refreshFrameName = 'refreshFrame';
  readonly emailValidationExp =
    "^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+\\/0-9=?A-Z^_`a-z{|}~]+(\\.[-!#$%&'*+\\/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*\\.[A-Za-z0-9]{2,}$";

  getNativeWindow(): Window {
    return window;
  }

  getNativeDocument(): Document {
    return document;
  }

  getNativeScreen(): Screen {
    return screen;
  }

  getNativeNavigator(): Navigator {
    return navigator;
  }

  getRefreshFrame(): Window {
    return window.frames[this.refreshFrameName];
  }

  open(url: string, location?: string): Window | null {
    return location ? window.open(url, location) : window.open(url);
  }

  setLocation(location: string): void {
    window.location.href = location;
  }

  atob(param: string): any {
    return JSON.parse(Base64.decode(param));
  }

  isIE11(): boolean {
    return !!this.getNativeNavigator().userAgent.match(/Trident.*rv\:11\./);
  }

  isEdge(): boolean {
    const bowser = Bowser.parse(this.getUserAgent());
    return bowser.browser.name === 'Microsoft Edge';
  }

  getUserInfo(token: string): PartialUserInfo {
    return this.atob(token.split('.')[1]);
  }

  getUserAgent(): string {
    return window.navigator.userAgent;
  }

  confirm(message: string): boolean {
    return window.confirm(message);
  }

  getCurrentBaseUrl(): string {
    const separator = '/';
    const window = this.getNativeWindow();
    const urlParts = window.location.href.split(separator);
    return urlParts.slice(0, 3).join(separator);
  }
}
