<div
  class="flyout"
  [class.open]="isOpen"
>
  <div
    class="flyout-header"
    [class.no-border]="error"
  >
    <ng-content select="[header]"></ng-content>
    <mat-icon
      class="close-icon"
      svgIcon="close-flyout"
      (click)="closeFlyout()"
    ></mat-icon>
  </div>
  <div class="flyout-content">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
