export enum AnalyticEventCategory {
  ActionItems = 'Action Items',
  Authentication = 'Authentication',
  Email = 'Email',
  Files = 'Files',
  Projects = 'Projects',
  TermsAndConditions = 'Terms and Conditions',
  Submittals = 'Submittals',
  RFI = 'Rfi',
  UserMenu = 'UserMenu',
  UserAdministration = 'UserAdministration',
  ProjectTeam = 'ProjectTeam',
  ServerAccess = 'ServerAccess',
  ViewProfile = 'ViewProfile'
}
