import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError as observableThrowError } from 'rxjs';
import {
  TeamGroupModel,
  TeamMemberModel,
  TeamViewModel,
  TeamViewResponseModel
} from '@shared/models/team-resource/team-view-response.model';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ApiUrlProvider } from '@core/utilities/apiUrl/apiUrl.provider';
import { catchError, switchMap } from 'rxjs/operators';
import {
  TeamGroupViewModel,
  TeamMemberViewModel,
  TeamResourceViewModel
} from '@shared/models/team-resource/team-resource.view.model';
import { TeamResourceViewModelType } from '@shared/models/team-resource/team-resource-view-model.type';
import { UserDisplayService } from '@core/services/user-display/user-display.service';

@Injectable()
export class TeamResourceService {
  private teamResourcesSubject: BehaviorSubject<TeamResourceViewModel[]> = new BehaviorSubject([]);
  teamResources = this.teamResourcesSubject.asObservable();
  private isLoadingBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoading$ = this.isLoadingBehaviorSubject.asObservable();

  constructor(
    private http: HttpClient,
    private apiUrlProvider: ApiUrlProvider,
    private userDisplayService: UserDisplayService
  ) {}

  filterTeamResources(query: string, projectNrn: string): Observable<{}> {
    const nrn = encodeURIComponent(projectNrn);

    return this.getTeamResources(nrn, query).pipe(
      catchError((error: HttpErrorResponse) => {
        this.isLoadingBehaviorSubject.next(false);
        return observableThrowError(error);
      }),
      switchMap((teamResourcesResponse: TeamViewResponseModel) => {
        const mappedMembers = this.mapTeamResourcesResponse(teamResourcesResponse, query);
        this.teamResourcesSubject.next(mappedMembers);
        this.isLoadingBehaviorSubject.next(false);

        return of({});
      })
    );
  }

  resetTeamResources(): void {
    this.teamResourcesSubject.next([]);
  }

  private getTeamResources(projectNrn: string, query: string): Observable<TeamViewResponseModel> {
    const requestParams = new HttpParams().set('q', query);
    this.isLoadingBehaviorSubject.next(true);

    return this.http.get<TeamViewResponseModel>(`${this.getUrl()}/projects/${projectNrn}/team/view`, {
      params: requestParams
    });
  }

  private mapTeamResourcesResponse(teamResources: TeamViewResponseModel, query: string): TeamResourceViewModel[] {
    return teamResources.items.map((resource: TeamViewModel) => {
      if (this.isTeamMember(resource)) {
        return this.mapMember(resource, query);
      } else {
        return this.mapGroup(resource, query);
      }
    });
  }

  private isTeamMember(resource: TeamViewModel): resource is TeamMemberModel {
    return resource.type === TeamResourceViewModelType.Member;
  }

  private mapMember(member: TeamMemberModel, query: string): TeamMemberViewModel {
    const fullName = [member.details.firstName, member.details.lastName].filter(Boolean).join(' ');
    const discipline = member.details.discipline?.name || '';
    return {
      name: fullName,
      boldName: this.boldQuery(fullName, query),
      nrn: member.nrn,
      email: member.details.email,
      boldEmail: this.boldQuery(member.details.email, query),
      initials:
        this.userDisplayService.getInitials({
          firstName: member.details.firstName,
          lastName: member.details.lastName
        }) || member.details.email.charAt(0),
      type: TeamResourceViewModelType.Member,
      discipline: discipline,
      boldDiscipline: this.boldQuery(discipline, query)
    };
  }

  private mapGroup(group: TeamGroupModel, query: string): TeamGroupViewModel {
    return {
      name: group.details.name,
      boldName: this.boldQuery(group.details.name, query),
      nrn: group.nrn,
      type: TeamResourceViewModelType.Group
    };
  }

  private boldQuery(property: string, query: string): string {
    const escapedQuery = query.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    const pattern = new RegExp(escapedQuery, 'gi');
    return property.replace(pattern, '<b>$&</b>');
  }

  private getUrl(): string {
    return this.apiUrlProvider.getUrl();
  }
}
