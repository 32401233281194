import { Injectable } from '@angular/core';
import { EventModel } from '@shared/models/events/events-model';
import { MappedProjectEvent } from '@project-log/models/mapped-project-events';
import { FileEventsMapperService } from '@shared/services/events/file-events-mapper/file-events-mapper.service';
import { SubmittalEventsMapperService } from '@shared/services/events/submittal-events-mapper/submittal-events-mapper.service';
import { SharedEventMapperService } from '@shared/services/events/shared-event-mapper/shared-event-mapper.service';
import { EventMapperFunc } from '@shared/services/events/createEventMapper';
import { RfiEventsMapperService } from '@shared/services/events/rfi-events-mapper/rfi-events-mapper.service';

// TODO: this service should be moved directly to project log module
// https://newforma.atlassian.net/browse/PROG-45468
@Injectable()
export class ComposedEventsMapperService {
  constructor(
    private shared: SharedEventMapperService,
    private fileEventsMapperService: FileEventsMapperService,
    private submittalEventsMapperService: SubmittalEventsMapperService,
    private rfiEventsMapperService: RfiEventsMapperService
  ) {}

  private readonly mappers = [
    this.fileEventsMapperService,
    this.submittalEventsMapperService,
    this.rfiEventsMapperService
  ];

  mapProjectLogEvents(events: EventModel[]): MappedProjectEvent[] {
    const eventMapperFunctions = this.mappers.map(m => m.createEventMapper());

    return events.map(event => this.mapEvent(event, eventMapperFunctions));
  }

  private mapEvent(eventModel: EventModel, eventMapperFunctions: EventMapperFunc[]): MappedProjectEvent {
    let result: MappedProjectEvent | null = null;

    for (const eventMapper of eventMapperFunctions) {
      result = result ?? eventMapper(eventModel);
    }

    return result ?? this.shared.mapUnknownEvent(eventModel);
  }
}
