import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { OpenIdConfiguration } from '@core/models/authentication/openIdConfiguration.model';
import { LoginRequest } from '@core/models/authentication/loginRequest.model';
import { LoginResponse } from '@core/models/authentication/loginResponse.model';
import { RegionsResponse } from '@core/models/authentication/regionsResponse.model';
import { Router } from '@angular/router';
import { AuthenticationCredentialProvider } from '@core/services/authentication/authentication.credential.provider';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiUrlProvider } from '@core/utilities/apiUrl/apiUrl.provider';
import { catchError, map } from 'rxjs/operators';
import { Region } from '@core/models/authentication/region.model';
import { AnalyticEventAction } from '@shared/models/analytics/analytic-event-action.model';
import { AnalyticEventCategory } from '@shared/models/analytics/analytic-event-category.model';
import { AnalyticsService } from '@core/services/analytics/analytics.service';

@Injectable()
export class AuthenticationService {
  static readonly rememberMailLocalStorageKey = 'rememberEmail';
  static azureProvider = '3e2fa735-9bb4-4c47-b8e8-ecdfa986ad64';

  isLogged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isUserLogged());
  nextUrl: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private credentialProvider: AuthenticationCredentialProvider,
    private translate: TranslateService,
    private apiUrlProvider: ApiUrlProvider,
    private analyticsService: AnalyticsService
  ) {}

  getDomainConfiguration(domain: string): Observable<OpenIdConfiguration> {
    return this.http.get<OpenIdConfiguration>(`${this.getUrl()}/domains/${domain}/openid-configuration`);
  }

  getIdpConfiguration(email: string): Observable<OpenIdConfiguration> {
    return this.http.get<OpenIdConfiguration>(`${this.getUrl()}/idp-configuration`, { params: { email: email } });
  }

  getRegions(): Observable<Region[]> {
    return this.getRegionsObservable();
  }

  refresh(refreshRequest: LoginRequest): Promise<any> {
    return this.http
      .post(`${this.getUrl()}/login`, refreshRequest)
      .toPromise()
      .then((loginResponse: LoginResponse) => this.credentialProvider.storeCredential(loginResponse, refreshRequest));
  }

  login(loginRequest: LoginRequest): Observable<boolean> {
    return this.http.post(`${this.getUrl()}/login`, loginRequest).pipe(
      map((loginResponse: LoginResponse) => {
        this.analyticsService.recordEvent(AnalyticEventAction.Login, AnalyticEventCategory.Authentication);
        this.credentialProvider.storeCredential(loginResponse, loginRequest);
        this.isLogged.next(true);
        return true;
      })
    );
  }

  logOut(preserveUrl: boolean = false): Promise<boolean> {
    this.analyticsService.recordEvent(AnalyticEventAction.Logout, AnalyticEventCategory.Authentication);
    this.credentialProvider.releaseStoredCredentials();
    this.isLogged.next(false);

    if (preserveUrl) {
      return this.router.navigate(['login'], { queryParams: { originalUrl: this.nextUrl } });
    }

    return this.router.navigate(['login']);
  }

  isUserLogged(): boolean {
    if ((window as any).Cypress) {
      return true;
    }

    return (
      !!this.credentialProvider.getAccessKeyId() &&
      !!this.credentialProvider.getSecretAccessKey() &&
      !!this.credentialProvider.getSessionToken() &&
      !!this.credentialProvider.getExpiration() &&
      !!this.credentialProvider.getSessionTokenRequest()
    );
  }

  isDeployedWithoutDns(url: string): boolean {
    return /\.newforma\.cloud\.s3-website-us-east-1\.amazonaws\.com/.test(url || '');
  }

  private getUrl(): string {
    return this.apiUrlProvider.getUrl();
  }

  private getRegionsObservable(): Observable<Region[]> {
    let acceptedLanguage = this.translate.getBrowserLang();
    acceptedLanguage = acceptedLanguage.substr(0, 2);
    const headers = new HttpHeaders().set('Accept-Language', acceptedLanguage);

    return this.http
      .get(`${this.getUrl()}/properties/regions`, {
        headers: headers
      })
      .pipe(
        catchError(err => {
          if (err.status !== 404) {
            throw err;
          }
          const headersEn = new HttpHeaders().set('Accept-Language', 'en');

          return this.http.get(`${this.getUrl()}/properties/regions`, {
            headers: headersEn
          });
        }),
        map((response: RegionsResponse) => {
          const regions = response.region_list;
          if (!response.region_list) {
            throw new Error('Regions were not returned.');
          }
          return regions;
        })
      );
  }
}
