<div
  class="view-profile-container"
  fxFlex
  fxLayout="column"
>
  <div class="view-profile-header">
    {{ 'VIEW_PROFILE_DIALOG.HEADER' | translate }}
  </div>

  <mat-dialog-content class="content">
    <div>
      <form
        class="view-profile-form-container full-size background-white"
        [formGroup]="viewProfileForm"
        fxLayout="column"
      >
        <div class="view-profile-form-item">
          <mat-form-field>
            <input
              id="view-profile-email-input"
              matInput
              [formControlName]="emailControlName"
              placeholder="{{'VIEW_PROFILE_DIALOG.FORM.EMAIL' | translate}}"
            />
          </mat-form-field>
        </div>
        <div class="view-profile-form-item">
          <div
            class="section-container"
            fxLayout="row wrap"
            fxLayoutAlign="space-between center"
          >
            <mat-form-field
              class="form-field"
              fxFlex="49"
            >
              <input
                matInput
                id="first-name-input"
                [formControlName]="firstNameControlName"
                placeholder="{{'VIEW_PROFILE_DIALOG.FORM.FIRST_NAME' | translate}}"
              />
            </mat-form-field>
            <mat-form-field
              class="form-field"
              fxFlex="49"
            >
              <input
                matInput
                id="last-name-input"
                [formControlName]="lastNameControlName"
                placeholder="{{'VIEW_PROFILE_DIALOG.FORM.LAST_NAME' | translate}}"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="view-profile-form-item">
          <app-single-item-search-selector
            class="form-field"
            elementId="discipline-selector"
            [isRequired]="false"
            [isClientSideFiltering]="true"
            [itemList]="disciplines$ | async"
            [selectedItem]="disciplineControl.value"
            [shouldShowDescription]="true"
            (onItemChanged)="changeDiscipline($event)"
            (onError)="setDisciplineError()"
            label="VIEW_PROFILE_DIALOG.FORM.DISCIPLINE"
          ></app-single-item-search-selector>
        </div>
        <div class="view-profile-form-item">
          <mat-form-field class="form-field">
            <input
              matInput
              id="view-profile-phone-input"
              [formControlName]="phoneControlName"
              placeholder="{{'VIEW_PROFILE_DIALOG.FORM.PHONE' | translate}}"
            />
          </mat-form-field>
        </div>
      </form>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="actions">
    <div fxFlex fxLayout fxLayoutAlign="end center">
      <button class="cancel-button" mat-button type="button" tabindex="-1" (click)="closeForm()">
        {{ 'VIEW_PROFILE_DIALOG.DECLINE' | translate }}
      </button>

      <app-raised-button (clicked)="save()">
        <span>
          {{ 'VIEW_PROFILE_DIALOG.CONFIRM' | translate }}
        </span>
      </app-raised-button>
    </div>
  </mat-dialog-actions>
</div>
