import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpParams, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class QueryParamsInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(
      request.clone({
        params: new HttpParams({
          fromString: request.params.toString(),
          encoder: {
            decodeKey: key => decodeURIComponent(key),
            decodeValue: value => decodeURIComponent(value),
            encodeKey: key => encodeURIComponent(key),
            encodeValue: value => encodeURIComponent(value)
          }
        })
      })
    );
  }
}
