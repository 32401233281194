import {
  destroyEventsContainer,
  loadEvents,
  loadEventsError,
  loadMoreEvents,
  loadMoreProjectEvents,
  loadProjectEvents,
  updateEvents,
  updateProjectEvents,
  updateSearchQuery
} from '@app/root/actions/events/events.actions';
import { updateObject } from '@app/architecture';
import { MappedActivities } from '@shared/services/event-mapper/event-mapper.service';
import { Action, createReducer, on } from '@ngrx/store';
import { MappedProjectEvent } from '@project-log/models/mapped-project-events';

// TODO: this reducer / effects / selectors should be splitted into 2 separate things
// activities reducer - in files module (activities selectors doesn't need filtering)
// project log events reducer - in project log module
// https://newforma.atlassian.net/browse/PROG-45468
export interface EventsState {
  isLoadingEvents: boolean;
  events: MappedActivities[];
  projectEvents: MappedProjectEvent[];
  isErrorLoadingEvents: boolean;
  searchQuery: string | null;
  isLoadingMoreEvents: boolean;
  offsetToken: string | null;
}

export const defaultEventsState: EventsState = {
  isLoadingEvents: false,
  events: [],
  projectEvents: [],
  isErrorLoadingEvents: false,
  searchQuery: null,
  isLoadingMoreEvents: false,
  offsetToken: null
};

const reducer = createReducer(
  defaultEventsState,
  on(loadEvents, state => updateObject(state, { ...defaultEventsState, isLoadingEvents: true })),
  on(loadMoreEvents, loadMoreProjectEvents, state => updateObject(state, { ...state, isLoadingMoreEvents: true })),
  on(loadProjectEvents, state => updateObject(state, { ...defaultEventsState, isLoadingEvents: true })),
  on(updateEvents, (state, { events, offsetToken }) =>
    updateObject(state, {
      isLoadingEvents: false,
      events,
      isLoadingMoreEvents: false,
      offsetToken
    })
  ),
  on(updateProjectEvents, (state, { projectEvents, offsetToken }) =>
    updateObject(state, {
      isLoadingEvents: false,
      projectEvents,
      isLoadingMoreEvents: false,
      offsetToken
    })
  ),
  on(loadEventsError, state =>
    updateObject(state, {
      isLoadingEvents: false,
      projectEvents: [],
      events: [],
      isErrorLoadingEvents: true,
      isLoadingMoreEvents: false
    })
  ),
  on(updateSearchQuery, (state, { searchTerm }) => updateObject(state, { searchQuery: searchTerm })),
  on(destroyEventsContainer, () => defaultEventsState)
);

export function eventsReducer(state: EventsState = defaultEventsState, action: Action): EventsState {
  return reducer(state, action);
}
