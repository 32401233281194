import { Directive, Input, OnChanges, ElementRef } from '@angular/core';

@Directive({
  selector: '[appNotClickable]'
})
export class NotClickableDirective implements OnChanges {
  @Input() currentSection: string;
  @Input() sectionInEditMode: string | null;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges(): void {
    const shouldDisableClick = this.sectionInEditMode !== this.currentSection && this.sectionInEditMode !== null;
    this.elementRef.nativeElement.style.pointerEvents = shouldDisableClick ? 'none' : 'all';
  }
}
