import { IconTypeParams } from '@core/models/files/icon-type.params';
import { FileTypes } from '../../fileTypes';

export class IconTypeParser {
  private static readonly defaultFileType: string = 'file';
  private static readonly folderType: string = 'folder';
  private static readonly fileTypes = FileTypes.getFileTypes();

  static chooseIcon(params: IconTypeParams, folderIcon?: string): string {
    if (params.type === this.folderType) {
      return folderIcon || this.folderType;
    }

    if (!params.contentType) {
      return this.defaultFileType;
    }

    const fileType = this.fileTypes.find(type => type.mimeTypes.includes(params.contentType || ''));

    return fileType ? fileType.iconType : this.defaultFileType;
  }

  static chooseIconByMimeType(mimeType: string): string {
    const fileType = this.fileTypes.find(type => type.mimeTypes.includes(mimeType));

    return fileType ? fileType.iconType : this.defaultFileType;
  }
}
