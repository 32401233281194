import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { FeatureService } from './feature.service';
import { mergeMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

@Injectable()
export class FeatureResolver implements Resolve<any> {
  constructor(private featureService: FeatureService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.featureService.resolveFeatures().pipe(
      mergeMap(() => {
        this.featureService.isResolverLoaded.next(true);
        return EMPTY;
      })
    );
  }
}
