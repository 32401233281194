import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';

@Component({
  selector: 'app-show-more',
  templateUrl: './show-more.component.html',
  styleUrls: ['./show-more.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowMoreComponent implements AfterViewInit {
  @Input() text: string;
  @Input() minimalHeight: string;
  @ViewChild('showMoreContainer', { static: true })
  showMoreContainer: ElementRef;

  showMore = false;
  isOpened = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.showMore = this.showMoreContainer.nativeElement.scrollHeight > parseInt(this.minimalHeight, 10);
    this.changeDetectorRef.detectChanges();
  }

  toggleHeight(): void {
    const container = this.showMoreContainer.nativeElement;

    container.style.maxHeight = this.isOpened ? this.minimalHeight : `${container.scrollHeight}px`;

    this.isOpened = !this.isOpened;
    this.changeDetectorRef.detectChanges();
  }
}
