<div
  id="inline-edit-indicator-buttons-container"
  *ngIf="isEditMode"
>
  <mat-icon
    class="action-icon"
    (mousedown)="onDeclineButtonMouseDownHandler()"
    (click)="onDeclineButtonClickHandler()"
  >
    clear
  </mat-icon>
  <mat-icon
    *ngIf="isEditMode"
    id="inline-edit-confirm-button"
    class="action-icon"
    (mousedown)="onAcceptButtonMouseDownHandler()"
    (click)="onAcceptButtonClickHandler()"
  >
    done
  </mat-icon>
</div>
