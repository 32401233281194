import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarType } from '@core/models/snackbar/snackbar.type.enum';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarComponent } from '@shared/components/snackbar/snackbar.component';

@Injectable()
export class ToastService {
  private readonly snackBarDuration: number = 5000;
  private lastQueuedMessage: string | null;
  queue: MatSnackBarRef<any>[] = [];

  constructor(private snackBar: MatSnackBar, private translateService: TranslateService) {}

  displayError(resourceText: string, duration?: number): MatSnackBarRef<SnackBarComponent> {
    return this.displaySnackBar(resourceText, SnackbarType.Error, duration);
  }

  displaySuccess(resourceText: string, duration?: number): MatSnackBarRef<SnackBarComponent> {
    return this.displaySnackBar(resourceText, SnackbarType.Success, duration);
  }

  displayInfo(resourceText: string, duration?: number): MatSnackBarRef<SnackBarComponent> {
    return this.displaySnackBar(resourceText, SnackbarType.Info, duration);
  }

  displayErrorWithArgs(resourceText: string, args: any, duration?: number): MatSnackBarRef<SnackBarComponent> {
    return this.displaySnackBarWithArgs(resourceText, args, SnackbarType.Error, duration);
  }

  displaySuccessWithArgs(resourceText: string, args: any, duration?: number): MatSnackBarRef<SnackBarComponent> {
    return this.displaySnackBarWithArgs(resourceText, args, SnackbarType.Success, duration);
  }

  displayInfoWithArgs(resourceText: string, args: any, duration?: number): MatSnackBarRef<SnackBarComponent> {
    return this.displaySnackBarWithArgs(resourceText, args, SnackbarType.Info, duration);
  }

  displaySnackBar(name: string, type: SnackbarType, duration?: number): MatSnackBarRef<SnackBarComponent> {
    return this.displaySnackBarWithArgs(name, undefined, type, duration);
  }

  displaySnackBarWithArgs(
    name: string,
    args: any,
    type: SnackbarType,
    duration?: number
  ): MatSnackBarRef<SnackBarComponent> {
    const isDuplicate = this.lastQueuedMessage === name;

    if (!isDuplicate) {
      const snackBarRef = this.snackBar.openFromComponent(SnackBarComponent, {
        duration: duration || this.snackBarDuration
      });
      snackBarRef.instance.type = type;

      this.translateService.get(name, args).subscribe(translation => (snackBarRef.instance.message = translation));

      this.lastQueuedMessage = name;
      this.addToQueue(snackBarRef);

      return snackBarRef;
    } else {
      return this.queue[this.queue.length - 1];
    }
  }

  closeAllSnackbars() {
    this.queue.forEach(el => el.dismiss());
  }

  private addToQueue(snackbar: MatSnackBarRef<any>): void {
    snackbar.afterDismissed().subscribe(() => {
      this.queue.splice(this.queue.indexOf(snackbar), 1);

      if (this.queue.length === 0) {
        this.lastQueuedMessage = null;
      }
    });
    this.queue.push(snackbar);
  }
}
