import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { FeatureService } from '@core/services/feature/feature.service';

@Injectable()
export class ServerAccessGuard implements CanActivate {
  private isCreateAccessKeySupported: boolean;

  constructor(private featureService: FeatureService, private router: Router) {
    this.featureService.isCreateAccessKeySupported.subscribe(value => (this.isCreateAccessKeySupported = value));
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.isCreateAccessKeySupported) {
      return true;
    }

    this.router.navigate(['/projects']);
    return false;
  }
}
