<mat-form-field
  class="permissions-dropdown"
>
  <mat-select
    id="permission-select-dropdown"
    (selectionChange)="newPermissionSelected($event.value)"
    [(value)]="defaultValue"
    class="permission-select"
  >
    <mat-option value="{{sharePermissions.FullAccess}}" class="permission-option">{{'FOLDER_SHARE.FULL_ACCESS' | translate}}</mat-option>
    <mat-option value="{{sharePermissions.ReadOnly}}" class="permission-option">{{'FOLDER_SHARE.READ_ONLY' | translate}}</mat-option>
    <mat-option value="{{sharePermissions.ReadAndUpload}}" class="permission-option">{{'FOLDER_SHARE.READ_AND_UPLOAD' | translate}}</mat-option>
  </mat-select>
</mat-form-field>
