import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, combineReducers } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import {
  initialProjectListState,
  projectListReducer,
  ProjectListState
} from '@root/reducers/project-list/project-list.reducer';
import {
  defaultProjectFoldersState,
  projectFoldersReducer,
  ProjectFoldersState
} from '@root/reducers/project-folders/project-folders.reducer';
import { initialUserState, userReducer, UserState } from '@root/reducers/user/user.reducer';
import { defaultEventsState, eventsReducer, EventsState } from '@root/reducers/events/events.reducers';
import { KeywordsState, initialKeywordsState, keywordsReducer } from '@root/reducers/keywords/keywords.reducer';

export interface AppRootState {
  router: RouterReducerState;
  projectList: ProjectListState;
  projectRootFolders: ProjectFoldersState;
  events: EventsState;
  user: UserState;
  keywords: KeywordsState;
}

export const appRootReducer = new InjectionToken<ActionReducerMap<AppRootState>>('Reducers');

export function getReducers() {
  return {
    root: combineReducers({
      router: routerReducer,
      projectList: projectListReducer,
      projectRootFolders: projectFoldersReducer,
      events: eventsReducer,
      user: userReducer,
      keywords: keywordsReducer
    })
  };
}

export const rootInitialState: AppRootState = {
  events: defaultEventsState,
  projectList: initialProjectListState,
  projectRootFolders: defaultProjectFoldersState,
  router: {} as any,
  user: initialUserState,
  keywords: initialKeywordsState
};

// this reducer provider is necessary because of a TS compilation bug where combinedReducers doesn't work in the prod build
// solution provided: https://github.com/ngrx/platform/issues/306#issuecomment-323829336
export const appRootReducerProvider = [{ provide: appRootReducer, useFactory: getReducers }];
