import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUrlProvider } from '@core/utilities/apiUrl/apiUrl.provider';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ConfigurationFeatureResponse } from '@core/models/feature/configuration.feature.response';
import { catchError, filter, mergeMap, skip } from 'rxjs/operators';

@Injectable()
export class FeatureService {
  isAuthenticatedConfigurationLoaded = new BehaviorSubject(false);
  isGlobalConfigurationLoaded = new BehaviorSubject(false);
  isEmailFilingSupported = new BehaviorSubject(false);
  isExternalFolderSharingSupported = new BehaviorSubject(false);
  isActionItemsSupported = new BehaviorSubject(false);
  isResolverLoaded = new BehaviorSubject(false);
  isCloudProjectsSupported = new BehaviorSubject(false);
  isCreateProjectsSupported = new BehaviorSubject(false);
  isFileUploadOverwriteSupported = new BehaviorSubject(false);
  isCreateAccessKeySupported = new BehaviorSubject(false);
  isCloudTermsAndConditionsAdminSupported = new BehaviorSubject(false);
  isActionItemAttachmentSupported = new BehaviorSubject(false);
  isFolderDeleteSupported = new BehaviorSubject(false);
  isGenerateProjectSearchUrlSupported = new BehaviorSubject(false);
  isActionItemDiscussionItemsSupported = new BehaviorSubject(false);
  isActionItemTypeSupported = new BehaviorSubject(false);
  isActionItemCreateTypeSupported = new BehaviorSubject(false);
  isRfisSupported = new BehaviorSubject(false);
  isActionItemInlineSupported = new BehaviorSubject(false);
  isProjectEmailAddressSupported = new BehaviorSubject(false);
  isLogSubmittalSupported = new BehaviorSubject(false);
  isUserAdministrationSupported = new BehaviorSubject(false);
  isEulaSupported = new BehaviorSubject(false);
  isLogRfiSupported = new BehaviorSubject(false);
  isHybridSubmittalsSupported = new BehaviorSubject(false);
  isCloudSubmittalsSupported = new BehaviorSubject(false);

  private featureConfigurations = new Map<string, any>();
  private capabilityConfigurations = new Map<string, any>();
  private licenseParameters = new Map<string, any>();

  constructor(
    private http: HttpClient,
    private apiUrlProvider: ApiUrlProvider,
    private authenticationService: AuthenticationService
  ) {
    this.subscribeToIsLogged();
  }

  resolveFeatures(): Observable<boolean> {
    return this.loadGlobalConfiguration().pipe(
      mergeMap(this.createAuthenticationFunc(this.authenticationService.isUserLogged())),
      mergeMap(isLoaded => {
        this.isResolverLoaded.next(true);
        return of(isLoaded);
      }),
      catchError(() => of(true))
    );
  }

  private subscribeToIsLogged() {
    this.isResolverLoaded
      .pipe(
        filter(isResolverLoaded => isResolverLoaded === true),
        mergeMap(() => this.authenticationService.isLogged.pipe(skip(1))),
        mergeMap(isLogged => {
          const loadConfFunc = isLogged ? this.loadAuthenticatedConfiguration : this.loadGlobalConfiguration;

          return loadConfFunc();
        })
      )
      .subscribe();
  }

  private loadGlobalConfiguration = () =>
    this.getGlobalConfiguration().pipe(
      mergeMap((response: ConfigurationFeatureResponse) => {
        this.featureConfigurations.clear();
        this.licenseParameters.clear();

        response.configurations.forEach(({ key, value }) => this.featureConfigurations.set(key, value));

        this.updateSupportedFlags();
        this.isGlobalConfigurationLoaded.next(true);
        return of({});
      }),
      catchError(() => {
        this.isGlobalConfigurationLoaded.next(true);
        return of({});
      })
    );

  private loadAuthenticatedConfiguration = () =>
    this.getAuthenticatedConfiguration().pipe(
      mergeMap((response: ConfigurationFeatureResponse) => {
        (window as any).newformaWalkMeConfigurations = {};
        (window as any).newformaWalkMeLicenses = {};
        (window as any).newformaWalkMeCapabilities = {};
        response.configurations.forEach(({ key, value }) => {
          this.featureConfigurations.set(key, value);
          (window as any).newformaWalkMeConfigurations[key] = value;
        });
        response.capabilities.forEach(key => {
          this.capabilityConfigurations.set(key, true);
          (window as any).newformaWalkMeCapabilities[key] = true;
        });
        response.license.parameters.forEach(({ key, value }) => {
          this.licenseParameters.set(key, value);
          (window as any).newformaWalkMeLicenses[key] = value;
        });
        this.updateSupportedFlags();

        this.isAuthenticatedConfigurationLoaded.next(true);
        return of(true);
      }),
      catchError(() => {
        this.isAuthenticatedConfigurationLoaded.next(true);
        return of(true);
      })
    );

  private getGlobalConfiguration(): Observable<ConfigurationFeatureResponse> {
    return this.http.get<ConfigurationFeatureResponse>(`${this.apiUrlProvider.getUrl()}/configurations`);
  }

  private getAuthenticatedConfiguration(): Observable<ConfigurationFeatureResponse> {
    return this.http.get<ConfigurationFeatureResponse>(`${this.apiUrlProvider.getUrl()}/configurations/me`);
  }

  private updateSupportedFlags() {
    const isExternalFolderSharingSupported = !!this.featureConfigurations.get('external-folder-share');
    this.isExternalFolderSharingSupported.next(isExternalFolderSharingSupported);

    const isCloudProjectsSupported = !!this.featureConfigurations.get('cloud-projects');
    this.isCloudProjectsSupported.next(isCloudProjectsSupported);

    const isEmailFilingSupported =
      !!this.featureConfigurations.get('email-filing') && !!this.licenseParameters.get('hasEmailSupport');
    this.isEmailFilingSupported.next(isEmailFilingSupported);

    const isActionItemsSupported = !!this.licenseParameters.get('hasActionItemsSupport');
    this.isActionItemsSupported.next(isActionItemsSupported);

    const isCreateProjectsSupported =
      !!this.licenseParameters.get('canCreateProject') && !!this.licenseParameters.get('hasProjectsSupport');
    this.isCreateProjectsSupported.next(isCreateProjectsSupported);

    const isFileUploadOverwriteSupported = !!this.featureConfigurations.get('file-upload-overwrite');
    this.isFileUploadOverwriteSupported.next(isFileUploadOverwriteSupported);

    const isCreateAccessKeySupported = !!this.featureConfigurations.get('create-access-key');
    this.isCreateAccessKeySupported.next(isCreateAccessKeySupported);

    const isCloudTermsAndConditionsAdminSupported = !!this.featureConfigurations.get(
      'cloud-terms-and-conditions-admin'
    );
    this.isCloudTermsAndConditionsAdminSupported.next(isCloudTermsAndConditionsAdminSupported);

    const isActionItemAttachmentSupported = !!this.featureConfigurations.get('cloud-action-items-attachments');
    this.isActionItemAttachmentSupported.next(isActionItemAttachmentSupported);

    const isFolderDeleteSupported = !!this.featureConfigurations.get('folder-delete');
    this.isFolderDeleteSupported.next(isFolderDeleteSupported);

    const isGenerateProjectSearchUrlSupported = !!this.featureConfigurations.get('generate-project-search-url');
    this.isGenerateProjectSearchUrlSupported.next(isGenerateProjectSearchUrlSupported);

    const isActionItemTypeSupported = !!this.featureConfigurations.get('cloud-action-items-type');
    this.isActionItemTypeSupported.next(isActionItemTypeSupported);

    const isActionItemDiscussionItemsSupported = !!this.featureConfigurations.get('cloud-action-items-discussion-item');
    this.isActionItemDiscussionItemsSupported.next(isActionItemDiscussionItemsSupported);

    const isActionItemCreateTypeSupported = !!this.featureConfigurations.get('cloud-action-item-create-type');
    this.isActionItemCreateTypeSupported.next(isActionItemCreateTypeSupported);

    const isHybridSubmittalsSupported = !!this.featureConfigurations.get('hybrid-submittals');
    this.isHybridSubmittalsSupported.next(isHybridSubmittalsSupported);

    const isCloudSubmittalsSupported = !!this.featureConfigurations.get('cloud-submittals');
    this.isCloudSubmittalsSupported.next(isCloudSubmittalsSupported);

    const isRfisSupported = !!this.featureConfigurations.get('cloud-rfis');
    this.isRfisSupported.next(isRfisSupported);

    const isLogSubmittalSupported =
      !!this.featureConfigurations.get('cloud-submittals') && !!this.licenseParameters.get('canLogSubmittal');
    this.isLogSubmittalSupported.next(isLogSubmittalSupported);

    const isLogRfiSupported =
      !!this.featureConfigurations.get('cloud-rfis') && !!this.licenseParameters.get('canLogRfi');
    this.isLogRfiSupported.next(isLogRfiSupported);

    const isActionItemInlineSupported = !!this.featureConfigurations.get('cloud-action-items-inline');
    this.isActionItemInlineSupported.next(isActionItemInlineSupported);

    const isProjectEmailAddressSupported = !!this.featureConfigurations.get('project-email-address');
    this.isProjectEmailAddressSupported.next(isProjectEmailAddressSupported);

    const isUserAdministrationSupported =
      !!this.featureConfigurations.get('cloud-admin-license-manager') &&
      !!this.capabilityConfigurations.get('cloud_licensing_management');
    this.isUserAdministrationSupported.next(isUserAdministrationSupported);

    const isEulaSupported = !!this.featureConfigurations.get('eula-is-enabled');
    this.isEulaSupported.next(isEulaSupported);
  }

  private createAuthenticationFunc = (shouldLoadAuthenticatedConf: boolean): (() => Observable<boolean>) => () =>
    shouldLoadAuthenticatedConf ? this.loadAuthenticatedConfiguration() : of(true);
}
