import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { WindowService } from '@core/services/window/window.service';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { ToastService } from '@core/services/toast/toast.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { FeatureService } from '@core/services/feature/feature.service';
import { Store } from '@ngrx/store';
import { TermsAndConditionsState } from '@terms-and-conditions/reducers';
import { openTermsAndConditionsDialog } from '@terms-and-conditions/actions/terms-and-conditions-dialog/terms-and-conditions-dialog.actions';
import { AnalyticsService } from '@core/services/analytics/analytics.service';
import { AnalyticEventAction } from '@shared/models/analytics/analytic-event-action.model';
import { AnalyticEventCategory } from '@shared/models/analytics/analytic-event-category.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ViewProfileDialogService } from '@shared/services/view-profile-dialog/view-profile-dialog.service';
import { UserDisplayService } from '@core/services/user-display/user-display.service';
import { selectUserDetails } from '@root/selectors/user/user.selectors';
import { UserProfileModel } from '@core/models/user/user.model';
import { AuthenticationCredentialProvider } from '@core/services/authentication/authentication.credential.provider';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMenuComponent implements OnInit, OnDestroy {
  @Input() set isUserMobileMenuOpen(value: boolean) {
    this.menuIsDisplay = value;
  }
  @Input() isMobile = false;
  @Input() isSidebarOpen = false;
  @Output() optionSelected = new EventEmitter();
  @Output() toggleUserMenu = new EventEmitter<boolean>();

  menuIsDisplay = false;
  displayUserName: string;

  isCreateAccessKeySupported$ = this.featureService.isCreateAccessKeySupported;
  isCloudTermsAndConditionsAdminSupported$ = this.featureService.isCloudTermsAndConditionsAdminSupported;
  isUserAdministrationSupported$ = this.featureService.isUserAdministrationSupported;

  @ViewChild(MatMenuTrigger, { static: false }) signOutSelectorMenu: MatMenuTrigger;

  constructor(
    private router: Router,
    private store: Store<TermsAndConditionsState>,
    private windowService: WindowService,
    private authenticationService: AuthenticationService,
    private toastService: ToastService,
    private featureService: FeatureService,
    private analyticsService: AnalyticsService,
    private viewProfileDialogService: ViewProfileDialogService,
    private userDisplayService: UserDisplayService,
    private credentialProvider: AuthenticationCredentialProvider,
    private cdr: ChangeDetectorRef
  ) {}

  private destroyComponent = new Subject();

  ngOnDestroy(): void {
    this.destroyComponent.next();
    this.destroyComponent.complete();
  }

  ngOnInit(): void {
    this.connectToStore();
  }

  openViewProfileDialog(): void {
    this.optionSelected.emit();
    this.viewProfileDialogService.open();
  }

  navigateToGenerateServerAccess(): void {
    this.optionSelected.emit();
    this.analyticsService.recordEvent(AnalyticEventAction.ViewSeverAccessKey, AnalyticEventCategory.UserMenu);

    this.router.navigate([`serveraccess/`]);
  }

  openTermsAndConditionsDialog(): void {
    this.optionSelected.emit();
    this.analyticsService.recordEvent(AnalyticEventAction.ViewTermsAndConditions, AnalyticEventCategory.UserMenu);

    this.store.dispatch(openTermsAndConditionsDialog({}));
  }

  navigateToUserAdministration(): void {
    this.optionSelected.emit();
    this.analyticsService.recordEvent(AnalyticEventAction.ViewUserAdmin, AnalyticEventCategory.UserMenu);
    this.router.navigate([`/user-administration`]);
  }

  logOut(): void {
    this.optionSelected.emit();
    this.authenticationService.logOut();
    this.toastService.closeAllSnackbars();
  }

  async manualLogout(): Promise<void> {
    const canRedirect = await this.router.navigate(['redirect'], { skipLocationChange: true });
    if (!canRedirect) {
      return;
    }

    this.logOut();
  }

  toggleUserMobileMenu(): void {
    this.menuIsDisplay = !this.menuIsDisplay;
    this.toggleUserMenu.emit(this.menuIsDisplay);
  }

  private connectToStore(): void {
    this.store
      .select(selectUserDetails)
      .pipe(takeUntil(this.destroyComponent))
      .subscribe(result => {
        this.displayUserName = this.getFullName(result);
        this.cdr.detectChanges();
      });
  }

  private getFullName(userModel: UserProfileModel | null): string {
    let fullNameFromToken = '';
    if (!(userModel?.firstName || userModel?.lastName)) {
      fullNameFromToken = this.getFullNameFromToken();
    }
    if (fullNameFromToken === '') {
      fullNameFromToken = this.userDisplayService.getDisplayName(userModel || undefined);
    }
    return fullNameFromToken;
  }

  private getFullNameFromToken(): string {
    const token = this.credentialProvider.getSessionTokenRequest();
    if (!token) {
      return '';
    }
    const payload = this.windowService.atob(token.split('.')[1]);
    return payload.given_name || payload.family_name
      ? `${payload.given_name || ''} ${payload.family_name || ''}`
      : payload.name || payload.upn || payload.preferred_username || '';
  }
}
