<div class="user-display-list">
  <div
    *ngFor="let user of eventUserList; trackBy: trackByEntry"
    class="user-display-list-row-container"
  >
    <app-user-display [userDetails]="user.eventUser"></app-user-display>
    <div
      class="delete-clickable-container"
      (click)="unselectUser(user.elementInstance)"
    >
      <mat-icon class="clear-icon">
        clear
      </mat-icon>
    </div>
  </div>
</div>