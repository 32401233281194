import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ComponentCanDeactivateDirective } from './can-deactivate';
import { ActionItemsState } from '@action-items/reducers';
import { Store } from '@ngrx/store';
import { selectIsCreateFormDirty } from '@action-items/selectors/action-items-management/action-items-management.selectors';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { WindowService } from '@core/services/window/window.service';

@Injectable()
export class CanDeactivateCreateActionItemFormGuard implements CanDeactivate<ComponentCanDeactivateDirective> {
  constructor(
    private store: Store<ActionItemsState>,
    private translateService: TranslateService,
    private windowService: WindowService
  ) {}

  canDeactivate(): Observable<boolean> {
    return combineLatest([
      this.store.select(selectIsCreateFormDirty),
      this.translateService.get('ACTION_ITEM_CREATE.CONFIRMATION_MESSAGE')
    ]).pipe(
      map(([isDirty, confirmationMessage]) => (isDirty ? this.windowService.confirm(confirmationMessage) : true))
    );
  }
}
