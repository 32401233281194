import { Injectable } from '@angular/core';
import { SharedItemResponseModel } from '@core/models/shared-item/shared-item.response.model';
import { UserDetailsWithPermissionsModel } from '@core/models/shared-item/user-details.with-permissions.model';
import { UserDisplayService } from '@core/services/user-display/user-display.service';
import { AuthenticationCredentialProvider } from '@core/services/authentication/authentication.credential.provider';

@Injectable({
  providedIn: 'root'
})
export class SharedItemMapperService {
  private readonly activeUserEmail?: string;

  constructor(
    private userDisplayService: UserDisplayService,
    private credentialProvider: AuthenticationCredentialProvider
  ) {
    this.activeUserEmail = this.credentialProvider.getActiveUserEmail();
  }

  mapShareRecipients(sharedItemsResponse: SharedItemResponseModel): UserDetailsWithPermissionsModel[] {
    const mappedRecipients = sharedItemsResponse.sharedItems.reduce(
      (accumulator, sharedItem) => [
        ...accumulator,
        ...sharedItem.recipients.map(recipient => ({
          email: recipient.email,
          permission: sharedItem.permissions,
          sharedItemNrn: sharedItem.nrn,
          initials: this.userDisplayService.getInitials(recipient),
          displayName: this.userDisplayService.getDisplayName(recipient)
        }))
      ],
      []
    );

    const sortedUserDetails = this.sortRecipientsAlphabetically(mappedRecipients);

    return this.activeUserEmail
      ? this.moveActiveUserToStartOfArray(sortedUserDetails, this.activeUserEmail)
      : sortedUserDetails;
  }

  private sortRecipientsAlphabetically(
    recipients: UserDetailsWithPermissionsModel[]
  ): UserDetailsWithPermissionsModel[] {
    return recipients.sort((recipientA, recipientB) =>
      recipientA.displayName.localeCompare(recipientB.displayName, undefined, { sensitivity: 'base' })
    );
  }

  private moveActiveUserToStartOfArray(
    userDetails: UserDetailsWithPermissionsModel[],
    activeUserEmail: string
  ): UserDetailsWithPermissionsModel[] {
    return userDetails.sort((prevUserDetails, nextUserDetails) =>
      prevUserDetails.email === activeUserEmail ? -1 : nextUserDetails.email === activeUserEmail ? 1 : 0
    );
  }
}
