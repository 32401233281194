<form [formGroup]="form" class="inline-edit-textarea-form accordion-container">
  <div
    class="inline-edit-textarea-header"
    fxLayout
    fxLayoutAlign="space-between"
    [class.readonly-view-cursor]="isReadonly"
    (mousedown)="openEditMode($event)"
  >
    <div>
      <span class="field-title">
        {{ headerText | translate }}
      </span>
      <span
        *ngIf="
          (!initialValue && !isEditMode && !isProcessing) ||
          (!initialValue && !isEditMode && isProcessing && !textareaControl.value) ||
          (initialValue && !isEditMode && isProcessing && !textareaControl.value)
        "
        class="empty-value-message"
      >
        {{ emptyValueMessage | translate }}
      </span>
    </div>
    <div
      *ngIf="!isReadonly"
      class="inline-edit-buttons-container"
    >
      <mat-icon
        *ngIf="!isEditMode"
        class="action-icon edit-indicator-icon"
      >
        mode_edit
      </mat-icon>
      <app-inline-edit-indicator-buttons
        [isEditMode]="isEditMode"
        (onAcceptButtonClick)="submit()"
        (onDeclineButtonClick)="discardChanges()"
      >
      </app-inline-edit-indicator-buttons>
    </div>
  </div>
  <mat-form-field fxFill *ngIf="isEditMode">
    <textarea
      [id]="editableFieldId"
      [formControlName]="inlineEditableTextareaControlName"
      matInput
      appAutoFocus
      autosize
      minRows="1"
      [required]="isRequired"
      (keydown.escape)="discardChanges()"
      (blur)="submit()"
    ></textarea>
    <mat-error>
      {{errorMessage | translate}}
    </mat-error>
  </mat-form-field>
  <div
    [id]="readonlyFieldId"
    class="inline-edit-textarea-readonly"
    [class.readonly-view-cursor]="isReadonly"
    [style.max-height]="minimalHeight"
    [class.hidden-textarea]="isEditMode || !textareaControl.value?.length && !initialValue?.length"
    (click)="openEditMode($event)"
    #textareaContainer
  >
    <span class="readonly-text">{{ isProcessing ? textareaControl.value : initialValue }}</span>
  </div>
  <div
    class="toggle-text"
    *ngIf="readMore && !isEditMode"
    (click)="toggleHeight()"
  >
    {{ (isExpanded ? 'ACTION_ITEM_DETAIL.SHOW_LESS' : 'ACTION_ITEM_DETAIL.SHOW_MORE') | translate }}
  </div>
</form>
