import { Injectable } from '@angular/core';
import { EventPerson } from '@shared/models/events/events-model';
import { MappedProjectEvent } from '@project-log/models/mapped-project-events';
import {
  FileBulkDownloadedEvent,
  FileDeletedEvent,
  FileDownloadedEvent,
  FileRenamedEvent,
  FileSharedBulkDownloadedEvent,
  FileSharedEvent,
  FileShareDownloadedEvent,
  FileShareRevokedEvent,
  FileUploadedEvent,
  FolderCreatedEvent,
  FolderDeletedEvent,
  FolderDownloadedEvent,
  FolderRenamedEvent,
  FolderSharedBulkDownloadedEvent,
  FolderSharedEvent,
  FolderShareRevokedEvent,
  isFileBulkDownloadedEvent,
  isFileDeletedEvent,
  isFileDownloadedEvent,
  isFileRenamedEvent,
  isFileSharedBulkDownloadedEvent,
  isFileSharedEvent,
  isFileShareDownloadedEvent,
  isFileShareRevokedEvent,
  isFileUploadedEvent,
  isFolderCreatedEvent,
  isFolderDeletedEvent,
  isFolderDownloadedEvent,
  isFolderRenamedEvent,
  isFolderSharedBulkDownloadedEvent,
  isFolderSharedEvent,
  isFolderShareRevokedEvent,
  isPermissionsChangeEvent,
  PermissionsChangeEvent
} from '@shared/models/events/file-events';
import { createEventMapper, EventMapperFunc, on } from '../createEventMapper';
import { TranslateCacheService } from '@shared/services/events/translate-cache/translate-cache-service';
import { SharedEventMapperService } from '@shared/services/events/shared-event-mapper/shared-event-mapper.service';
import { getSubjectFromPath } from '@shared/utilities/events-mapper/events-mapper.utils';
import { SharePermission } from '@core/models/shared-item/share-permission.model';

@Injectable({ providedIn: 'root' })
export class FileEventsMapperService {
  constructor(
    private translateCacheService: TranslateCacheService,
    private sharedEventMapperService: SharedEventMapperService
  ) {}

  createEventMapper(): EventMapperFunc {
    return createEventMapper(
      on(isFileUploadedEvent, this.mapFileUploadedEvent.bind(this)),
      on(isFileDeletedEvent, this.mapFileDeletedEvent.bind(this)),
      on(isFileRenamedEvent, this.mapFileRenamedEvent.bind(this)),
      on(isFolderCreatedEvent, this.mapFolderCreatedEvent.bind(this)),
      on(isFolderRenamedEvent, this.mapFolderRenamedEvent.bind(this)),
      on(isFolderSharedEvent, this.mapFolderSharedEvent.bind(this)),
      on(isFolderShareRevokedEvent, this.mapFolderShareRevokedEvent.bind(this)),
      on(isPermissionsChangeEvent, this.mapPermissionsChangeEvent.bind(this)),
      on(isFolderDeletedEvent, this.mapFolderDeletedEvent.bind(this)),
      on(isFileBulkDownloadedEvent, this.mapEventWithItemNameAndPath.bind(this)),
      on(isFileSharedEvent, this.mapEventWithItemNameAndPath.bind(this)),
      on(isFileShareDownloadedEvent, this.mapEventWithItemNameAndPath.bind(this)),
      on(isFolderSharedBulkDownloadedEvent, this.mapEventWithItemNameAndPath.bind(this)),
      on(isFileSharedBulkDownloadedEvent, this.mapEventWithItemNameAndPath.bind(this)),
      on(isFolderDownloadedEvent, this.mapEventWithItemNameAndPath.bind(this)),
      on(isFileDownloadedEvent, this.mapEventWithItemNameAndPath.bind(this)),
      on(isFileShareRevokedEvent, this.mapEventWithItemNameAndPath.bind(this))
    );
  }

  private mapFolderRenamedEvent(event: FolderRenamedEvent): MappedProjectEvent {
    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: event.eventData.newName,
      details: [
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.ORIGINAL_FOLDER_NAME'),
          value: event.eventData.oldName
        },
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.PATH'),
          value: event.eventData.path ?? this.sharedEventMapperService.dash
        }
      ]
    };
  }

  private mapFolderCreatedEvent(event: FolderCreatedEvent): MappedProjectEvent {
    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: event.eventData.createdFolderName,
      details: [
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.PATH'),
          value: event.eventData.path ?? this.sharedEventMapperService.dash
        }
      ]
    };
  }

  private mapFileRenamedEvent(event: FileRenamedEvent): MappedProjectEvent {
    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: event.eventData.newName,
      details: [
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.ORIGINAL_FILE_NAME'),
          value: event.eventData.oldName
        },
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.PATH'),
          value: event.eventData.path ?? this.sharedEventMapperService.dash
        }
      ]
    };
  }

  private mapFileDeletedEvent(event: FileDeletedEvent): MappedProjectEvent {
    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: event.eventData.fileName,
      details: [
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.PATH'),
          value: event.eventData.path ?? this.sharedEventMapperService.dash
        }
      ]
    };
  }

  private mapFileUploadedEvent(event: FileUploadedEvent): MappedProjectEvent {
    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: getSubjectFromPath(event.eventData.path) ?? this.sharedEventMapperService.dash,
      details: [
        ...(event.eventData.fileOverwritten
          ? [
              {
                key: this.translateCacheService.getCachedTranslation('EVENTS.ORIGINAL_FILE_OVERWRITTEN'),
                value: ''
              }
            ]
          : []),
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.PATH'),
          value: event.eventData.path ?? this.sharedEventMapperService.dash
        }
      ]
    };
  }

  private mapFolderSharedEvent(event: FolderSharedEvent): MappedProjectEvent {
    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: getSubjectFromPath(event.eventData.path) ?? this.sharedEventMapperService.dash,
      details: [
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.PERMISSION'),
          value: this.translateCacheService.getCachedTranslation(
            this.getSharePermissionsString(event.eventData.permissions)
          )
        },
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.SHARED_WITH'),
          value: this.getEventPersonString(event.eventData.recipient)
        },
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.PATH'),
          value: event.eventData.path ?? this.sharedEventMapperService.dash
        }
      ]
    };
  }

  private mapPermissionsChangeEvent(event: PermissionsChangeEvent): MappedProjectEvent {
    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: getSubjectFromPath(event.eventData.path) ?? this.sharedEventMapperService.dash,
      details: [
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.UPDATED_PERMISSION'),
          value: this.translateCacheService.getCachedTranslation(
            this.getSharePermissionsString(event.eventData.accessPermission)
          )
        },
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.SHARED_WITH'),
          value: this.getEventPersonString(event.eventData.recipient)
        },
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.PATH'),
          value: event.eventData.path
        }
      ]
    };
  }

  private mapFolderShareRevokedEvent(event: FolderShareRevokedEvent): MappedProjectEvent {
    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: getSubjectFromPath(event.eventData.path) ?? this.sharedEventMapperService.dash,
      details: [
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.REVOKED_FROM'),
          value: this.getEventPersonString(event.eventData.recipient)
        },
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.PATH'),
          value: event.eventData.path ?? this.sharedEventMapperService.dash
        }
      ]
    };
  }

  private getSharePermissionsString(permissions: SharePermission): string {
    switch (permissions) {
      case SharePermission.ReadOnly:
        return 'EVENTS.READ_ONLY';
      case SharePermission.ReadAndUpload:
        return 'EVENTS.READ_AND_UPLOAD';
      case SharePermission.FullAccess:
        return 'EVENTS.FULL_ACCESS';
    }

    return 'FILE.ACTIVITY_CHANGED_PERMISSION';
  }

  private mapEventWithItemNameAndPath(
    event:
      | FileShareDownloadedEvent
      | FolderSharedBulkDownloadedEvent
      | FileSharedEvent
      | FileBulkDownloadedEvent
      | FileSharedBulkDownloadedEvent
      | FolderDownloadedEvent
      | FileDownloadedEvent
      | FileShareRevokedEvent
  ): MappedProjectEvent {
    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: getSubjectFromPath(event.eventData.path) ?? this.sharedEventMapperService.dash,
      details: [
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.PATH'),
          value: event.eventData.path ?? this.sharedEventMapperService.dash
        }
      ]
    };
  }

  private mapFolderDeletedEvent(event: FolderDeletedEvent): MappedProjectEvent {
    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: event.eventData.deletedFolderName,
      details: [
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.PATH'),
          value: event.eventData.path ?? this.sharedEventMapperService.dash
        }
      ]
    };
  }

  private getEventPersonString(person: EventPerson): string {
    const isFirstNameAndLastNameProvided = !!person.firstName && !!person.lastName;
    const isEmailProvided = !!person.email;
    const isFullDataProvided = isFirstNameAndLastNameProvided && isEmailProvided;

    return isFullDataProvided
      ? `${person.firstName} ${person.lastName} ${person.email}`
      : isFirstNameAndLastNameProvided
      ? `${person.firstName} ${person.lastName}`
      : isEmailProvided
      ? `${person.email}`
      : this.sharedEventMapperService.dash;
  }
}
