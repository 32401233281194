<div
  *ngFor="let breadcrumb of breadcrumbsWithSeparator; let last = last;"
  class="breadcrumb-container"
>
  <span
    *ngIf="breadcrumb.separator"
    class="breadcrumb-separator"
  >{{breadcrumb.separator}}</span>
  <a
    [class.breadcrumb-link]="!last"
    [class.breadcrumb-last-link]="last"
    (click)="selectFolder(breadcrumb)"
  >{{breadcrumb.name}}</a>
</div>