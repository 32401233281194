import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput', { static: true })
  searchInput;
  private formSubscription: Subscription;
  searchForm: FormGroup;
  isFocused = false;
  isEmptyQuery = true;

  @Output() searchTermChanged = new EventEmitter<string | null>();
  @Input()
  set searchTerm(query: string | null) {
    this.handleQueryChange(query);
  }
  @Input() placeholder: string;
  @Input() size: string;
  @Input() autoFocus = false;

  constructor(private formBuilder: FormBuilder) {
    this.buildForm();
  }

  ngOnInit() {
    this.subscribeToFormChanges();

    if (this.autoFocus) {
      this.focusInput();
    }
  }

  ngOnDestroy() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  get searchQueryControl(): AbstractControl | null {
    return this.searchForm.get('searchQuery');
  }

  clearSearch(): void {
    if (this.searchForm.value.searchQuery) {
      this.searchForm.setValue({
        searchQuery: null
      });
    } else {
      this.focusInput();
    }
  }

  focusInput(): void {
    this.searchInput.nativeElement.focus();
  }

  onFocus(): void {
    this.isFocused = true;
  }

  onBlur(): void {
    this.isFocused = false;
  }

  private handleQueryChange(query: string | null): void {
    this.isEmptyQuery = !query;
    const shouldEmitEvent = !!this.searchQueryControl && this.searchQueryControl.value !== query && query === '';
    this.searchForm.setValue({ searchQuery: query ?? null }, { emitEvent: shouldEmitEvent });
  }

  private subscribeToFormChanges(): void {
    this.formSubscription = this.searchForm.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged((prev, curr) => prev.searchQuery === curr.searchQuery),
        filter(formChanges => !(!formChanges.searchQuery && this.isEmptyQuery))
      )
      .subscribe(value => {
        this.searchTermChanged.emit(value.searchQuery);
      });
  }

  private buildForm(): void {
    this.searchForm = this.formBuilder.group({
      searchQuery: [this.searchTerm]
    });
  }
}
