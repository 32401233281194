import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import {
  acceptTermsAndConditions,
  closeTermsAndConditionsDialog,
  createTermsAndConditions,
  getTermsAndConditionsContent
} from '@terms-and-conditions/actions/terms-and-conditions-dialog/terms-and-conditions-dialog.actions';
import { TermsAndConditionsState } from '@terms-and-conditions/reducers';
import {
  selectIsCreatingTermsAndConditions,
  selectIsGettingTermsAndConditionsContent,
  selectIsLoadingTermsAndConditions,
  selectShowButtonSpinner,
  selectShowDisabledButton,
  selectShowLoadingError,
  selectShowSpinner,
  selectTermsAndConditionsContent,
  selectTermsAndConditionsNrn
} from '@terms-and-conditions/selectors/terms-and-conditions-dialog/terms-and-conditions-dialog.selectors';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-terms-and-conditions-dialog',
  templateUrl: './terms-and-conditions-dialog.container.html',
  styleUrls: ['./terms-and-conditions-dialog.container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsAndConditionsDialogContainer implements OnInit, OnDestroy {
  private contentControlName = 'content';
  termsAndConditionsNrn: string | null;
  progressSpinnerDiameter = 20;
  private destroyComponent = new Subject();
  isRequestedForSharedFolder = false;
  isViewAcceptedTermsAndConditions = false;
  termsAndConditionsForm: FormGroup;
  loadedContent: string | null;
  isGettingTermsAndConditionsContent$ = this.store.select(selectIsGettingTermsAndConditionsContent);
  isCreatingTermsAndConditions$ = this.store.select(selectIsCreatingTermsAndConditions);
  isLoadingTermsAndConditions$ = this.store.select(selectIsLoadingTermsAndConditions);
  showButtonSpinner$ = this.store.select(selectShowButtonSpinner);
  showSpinner$ = this.store.select(selectShowSpinner);
  showLoadingError$ = this.store.select(selectShowLoadingError);
  showDisabledButton$ = this.store.select(selectShowDisabledButton);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      projectNrn: string;
      viewTermsAndconditions: boolean;
      actionOnSuccess: TypedAction<any>;
    },
    private store: Store<TermsAndConditionsState>
  ) {
    this.termsAndConditionsForm = new FormGroup({
      [this.contentControlName]: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
    this.isViewAcceptedTermsAndConditions = !!this.dialogData.viewTermsAndconditions;
    this.isRequestedForSharedFolder = !!this.dialogData.projectNrn;
    if (!this.isRequestedForSharedFolder) {
      this.store.dispatch(getTermsAndConditionsContent());
    }
    this.connectToStore();
  }

  ngOnDestroy(): void {
    this.destroyComponent.next();
    this.destroyComponent.complete();
  }

  saveChanges(): void {
    if (this.isRequestedForSharedFolder) {
      if (this.termsAndConditionsNrn) {
        this.store.dispatch(
          acceptTermsAndConditions({
            payload: {
              termsNrn: this.termsAndConditionsNrn,
              accepted: true,
              projectNrn: this.dialogData.projectNrn,
              actionOnSuccess: this.dialogData.actionOnSuccess
            }
          })
        );
      }
      return;
    }
    if (this.termsAndConditionsForm.invalid) {
      this.termsAndConditionsForm.markAllAsTouched();
      return;
    }
    this.store.dispatch(createTermsAndConditions({ payload: { content: this.contentControl.value } }));
  }

  closeDialog(): void {
    this.store.dispatch(closeTermsAndConditionsDialog());
  }

  get contentControl(): AbstractControl {
    return <AbstractControl>this.termsAndConditionsForm.get(this.contentControlName);
  }

  private connectToStore(): void {
    this.store.pipe(select(selectTermsAndConditionsNrn), takeUntil(this.destroyComponent)).subscribe(nrn => {
      this.termsAndConditionsNrn = nrn;
    });

    this.store
      .select(selectTermsAndConditionsContent)
      .pipe(takeUntil(this.destroyComponent))
      .subscribe(content => {
        this.termsAndConditionsForm.setValue({
          [this.contentControlName]: content
        });
        this.loadedContent = content;
      });
  }
}
