<div id="error-container"
     class="error-page-container"
     fxLayout
     fxLayoutAlign="center center"
>
  <div class="content">
    <div>
      <img src="/assets/images/general-error-white.svg" class="error-image">
      <div class="font-size-large error-title">{{title | translate}}</div>
      <span class="font-size-medium error-description">{{description | translate}}</span>
    </div>
    <app-raised-button
      (clicked)="backToLogin()"
      id="return-button"
    >
      {{'AUTHENTICATION.SIGNIN' | translate}}
    </app-raised-button>
  </div>
</div>

