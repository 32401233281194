import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-item-details-button',
  templateUrl: './item-details-button.component.html',
  styleUrls: ['./item-details-button.component.scss']
})
export class ItemDetailsButtonComponent {
  @Input() isDisabled: boolean;
  @Input() icon: string;
  @Input() label: string;

  @Output() clicked: EventEmitter<undefined> = new EventEmitter();

  handleClick() {
    this.clicked.emit();
  }
}
