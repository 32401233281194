<mat-autocomplete
  #auto="matAutocomplete"
  (optionSelected)="selectItem($event.option.value)"
  (opened)="panelOpened()"
  (closed)="panelClosed()"
>
  <div
    *ngIf="isLoading"
    class="autocomplete-loading-spinner"
  >
    <mat-option disabled>
      <app-spinner
        id="search-autocomplete-loading-spinner"
        diameter="32"
        strokeWidth="1.5"
      ></app-spinner>
    </mat-option>
  </div>
  <div
    *ngIf="!hasError && !isLoading && !items?.length && noResultErrorMessage"
  >
    <mat-option disabled class="no-results-message">
      {{ noResultErrorMessage | translate }}
    </mat-option>
  </div>
  <div
    *ngIf="!hasError && !isLoading && items?.length"
  >
    <mat-option
      *ngFor="let item of items; trackBy: trackByItemName"
      [value]="item"
      (mousedown)="selectItem(item)"
    >
        <span
          class="item-name"
        >
          {{ item.name }}
        </span>
      <span
        *ngIf="item.description && shouldShowDescription"
        class="item-description"
      >
          {{ item.description }}
        </span>
    </mat-option>
    <div
      *ngIf="hasMoreItemsToLoad"
      class="enter-search-term-message-container"
      (click)="clickMessage()"
    >
      <span class="enter-search-term-message-highlight">{{ 'APP.KEYWORDS.ENTER_SEARCH_TERM_CLICKABLE_PART' | translate }}</span>
      <span class="gray-text"> {{ 'APP.KEYWORDS.ENTER_SEARCH_TERM' | translate }}</span>
    </div>
  </div>
  <div
    *ngIf="hasError"
  >
    <mat-option
      [value]="loadingErrorMessage"
      class="loading-error"
      disabled
    >
      {{ loadingErrorMessage | translate }}
    </mat-option>
  </div>
</mat-autocomplete>
