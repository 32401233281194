import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-raised-button',
  templateUrl: './raised-button.component.html',
  styleUrls: ['./raised-button.component.scss']
})
export class RaisedButtonComponent {
  @Input() disabled = false;
  @Input() preventDefault = false;
  @Input() matIcon: string;
  @Input() svgIcon: string;
  @Input() loadingState = false;
  @Input() iconSize: string;

  @Output() clicked: EventEmitter<undefined> = new EventEmitter();

  handleClick(event: MouseEvent) {
    if (this.preventDefault) {
      event.preventDefault();
    }

    this.clicked.emit();
  }
}
