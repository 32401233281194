import { updateObject } from '@app/architecture';
import { Action, createReducer, on } from '@ngrx/store';
import { UserDetailsLicense, UserLicenseModel } from '@core/models/user/user-license-response.model';
import { loadUserLicenseCompleted, loadUserLicenseFailed } from '@root/actions/user/user.actions';
import { UserProfileModel } from '@core/models/user/user.model';

export interface UserState {
  userDetails: UserProfileModel | null;
  userLicense: UserLicenseModel | null;
  isLoadingUsers: boolean;
}

export const initialUserState: UserState = {
  userDetails: null,
  userLicense: null,
  isLoadingUsers: true
};

const reducer = createReducer(
  initialUserState,
  on(loadUserLicenseCompleted, handleLoadUserLicenseCompleted),
  on(loadUserLicenseFailed, handleLoadUserLicenseFailed)
);

function handleLoadUserLicenseCompleted(state: UserState, action: UserDetailsLicense): UserState {
  return updateObject(state, { isLoadingUsers: false, userLicense: action.license, userDetails: action.user });
}

function handleLoadUserLicenseFailed(state: UserState): UserState {
  return updateObject(state, { isLoadingUsers: false });
}

export function userReducer(state: UserState = initialUserState, action: Action): UserState {
  return reducer(state, action);
}
