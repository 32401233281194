<label
  class="drag-and-drop-label"
  [for]="labelFor"
>
  <div
    fxLayout
    fxLayoutAlign="center center"
    class="drop-upload-container"
    [class.drop-target]="highlightDropArea"
  >
    <mat-icon
      svgIcon="upload-dnd"
      class="upload-dnd"
    ></mat-icon>
    <span>
      <span
        class="main-text"
        [ngClass]="size + '-main-text'"
      >
        {{'ACTION_ITEM_DETAIL.ATTACHMENT_UPLOAD.MANUAL_UPLOAD' | translate}}
      </span>
      <span class="display-text">
        {{'ACTION_ITEM_DETAIL.ATTACHMENT_UPLOAD.DROP_UPLOAD' | translate}}
      </span>
    </span>
  </div>
</label>
