<div
  class="sites-search-results-container"
  [class.no-default-view]="!isDefaultView"
>
  <div
    *ngIf="!isLoading && !error"
    class="sites-search-results"
    infiniteScroll
    (scrolled)="endOfListReached()"
    [scrollWindow]="false"
  >
    <mat-list>
      <mat-list-item
        *ngFor="let externalFolder of externalFolders; trackBy: trackBySharePointSiteNrn"
        class="list-item"
        [class.selected]="selectedExternalFolder === externalFolder.nrn"
        (click)="externalFolderSelected(externalFolder)"
      >
        <mat-radio-button
          *ngIf="isDefaultView"
          class="list-item-button"
          [attr.data-nrn]="externalFolder.nrn"
          [checked]="selectedExternalFolder === externalFolder.nrn"
        ></mat-radio-button>
        <mat-icon
          *ngIf="isDefaultView"
          class="sharepoint-site-icon"
          svgIcon="sharepoint-site"
        ></mat-icon>
        <mat-icon
          *ngIf="!isDefaultView"
          class="type-icon"
          svgIcon="folder-filled-miniature"
      ></mat-icon>
        <div class="item-name">
          {{externalFolder.name}}
        </div>
      </mat-list-item>
      <mat-list-item
        *ngIf="isLoadingNextPage"
        id="sites-search-results-load-more-container"
        fxLayout
        fxLayoutAlign="center center"
      >
        <app-spinner></app-spinner>
      </mat-list-item>
    </mat-list>
  </div>
  <app-empty-state
    *ngIf="!isLoading && error && isDefaultView"
    class="sites-search-result-error-container error-container"
    [secondaryLabel]="error.message"
    icon="briefcase"
    fxLayout="column"
    fxLayoutAlign="center center"
  ></app-empty-state>
  <app-empty-state
    *ngIf="!isLoading && error && !isDefaultView"
    class="sites-search-result-error-container error-container"
    secondaryLabel="PROJECT.CREATE.PROJECT_FOLDER.NO_FOLDER_RESULTS"
    fxLayout="column"
    fxLayoutAlign="center center"
  ></app-empty-state>
  <div
    *ngIf="isLoading"
    id="sites-search-results-loading-container"
    class="sites-search-results-loading-container"
  >
    <app-spinner></app-spinner>
  </div>
</div>