import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { TermsAndConditionsDialogContainer } from '@terms-and-conditions/containers/terms-and-conditions-dialog/terms-and-conditions-dialog.container';
import { termsAndconditionsEffects } from '@terms-and-conditions/effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { termsAndConditionsReducer } from '@terms-and-conditions/reducers';

@NgModule({
  imports: [
    SharedModule,
    StoreModule.forFeature('terms', termsAndConditionsReducer),
    EffectsModule.forFeature(termsAndconditionsEffects)
  ],
  exports: [TermsAndConditionsDialogContainer],
  declarations: [TermsAndConditionsDialogContainer]
})
export class TermsAndConditionsModule {}
