import { selectActionItemsModule } from '@action-items/selectors/action-items-common/action-items-common.selectors';
import { createSelector } from '@ngrx/store';

export const selectActionItemsManagement = createSelector(selectActionItemsModule, state => state.management);

export const selectIsProcessing = createSelector(selectActionItemsManagement, state => state.isProcessing);

export const selectActionItemTypes = createSelector(selectActionItemsManagement, state => state.types);

export const selectIsLoadingTypes = createSelector(selectActionItemsManagement, state => state.isLoadingTypes);

export const selectLoadingTypesError = createSelector(selectActionItemsManagement, state => state.loadingTypesError);

export const selectIsPanelOpened = createSelector(selectActionItemsManagement, state => state.isPanelOpened);

export const selectIsCreateFormDirty = createSelector(selectActionItemsManagement, state => state.isCreateFormDirty);
