<div
  *ngIf="project"
  id="content-sub-header-container"
  class="display-flex"
>
  <div
    class="sub-header overflow-ellipsis"
  >
    {{ project.name }}
  </div>
  <div
    class="sub-sub-header overflow-ellipsis"
  >
    {{ project.number }}
  </div>
</div>
