import { NgModule } from '@angular/core';
import { FooterComponent } from '@shared/components/footer/footer.component';
import { AppMaterialModule } from '@app/app-material-module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SnackBarComponent } from '@shared/components/snackbar/snackbar.component';
import { ByteParserPipe } from '@shared/utilities/pipes/byte-parser.pipe';
import { IconTypeParserPipe } from '@shared/utilities/pipes/icon-type.pipe';
import { FilePathParserPipe } from '@shared/utilities/pipes/file-path-parser.pipe';
import { FileTypeParserPipe } from '@shared/utilities/pipes/file-type-parser.pipe';
import { SnackbarIconPipe } from '@shared/utilities/pipes/snackbar-icon-type.pipe';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';
import { PageHeaderComponent } from '@shared/components/page-header/page-header.component';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { ErrorComponent } from '@shared/components/error/error.component';
import { UserDisplayComponent } from './components/user-display/user-display.component';
import { InitialsComponent } from './components/user-display/initials/initials.component';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from '@shared/services/confirmation-dialog/confirmation.dialog.service';
import { ItemDetailsButtonComponent } from './components/item-details-button/item-details-button.component';
import { ConfirmationDialogLocalStorageService } from '@shared/components/confirmation-dialog/local-storage-service/confirmation-dialog.local-storage.service';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { RaisedButtonComponent } from './components/buttons/raised-button/raised-button.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { EmailContactListDisplayPipe } from '@shared/utilities/pipes/email-contact-list.pipe';
import { ProjectsSelectorComponent } from './components/projects-selector/projects-selector.component';
import { UploadProgressComponent } from './components/upload-progress/upload-progress.component';
import { DashboardSearchComponent } from '@shared/components/dashboard-search/dashboard-search.component';
import { EventMapperService } from '@shared/services/event-mapper/event-mapper.service';
import { SearchComponent } from '@shared/components/search/search.component';
import { ErrorCardComponent } from '@shared/components/error-card/error-card.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FilesBrowserComponent } from '@shared/components/files-browser/files-browser.component';
import { CommonModule } from '@angular/common';
import { PermissionSelectorComponent } from '@files/components/permission-selector/permission-selector.component';
import { IndeterminateProgressBarComponent } from '@shared/components/indeterminate-progress-bar/indeterminate-progress-bar.component';
import { ChipItemComponent } from './components/chip-item/chip-item.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ScrollToModule } from '@newforma/ngx-scroll-to';
import { TeamMembersSelectorComponent } from '@shared/components/team-members-selector/team-members-selector.component';
import { TeamResourceService } from '@shared/services/team-resource/team-resource.service';
import { InlineEditableInputComponent } from './components/inline-editable-input/inline-editable-input.component';
import { AutoFocusDirective } from '@shared/directives/auto-focus/auto-focus.directive';
import { OverlayComponent } from './components/overlay/overlay.component';
import { AutosizeModule } from 'ngx-autosize';
import { InlineEditIndicatorButtonsComponent } from './components/inline-edit-indicator-buttons/inline-edit-indicator-buttons.component';
import { ClickOutsideDirective } from '@shared/directives/click-outside/click-outside.directive';
import { ProjectFoldersContainer } from '@shared/containers/project-folders/project-folders.container';
import { ProjectFoldersDialogService } from '@shared/services/project-folders-dialog/project-folders-dialog.service';
import { SitesSearchResultsComponent } from '@shared/components/sites-search-results/sites-search-results.component';
import { ProjectFoldersErrorMessagesService } from '@shared/services/project-folders-error-messages/project-folders-error-messages.service';
import { NotClickableDirective } from '@shared/directives/not-clickable/not-clickable.directive';
import { DatePickerComponent } from '@shared/components/date-picker/date-picker.component';
import { InlineEditableTextareaComponent } from '@shared/components/inline-edit-textarea/inline-editable-textarea.component';
import { DetailAccordionComponent } from '@shared/components/detail-accordion/detail-accordion.component';
import { TimeOrDatePipe } from '@shared/pipes/time-or-date/time-or-date.pipe';
import { ShowMoreComponent } from './components/show-more/show-more.component';
import { MultipleKeywordsSelectorComponent } from '@shared/components/multiple-keywords-selector/multiple-keywords-selector.component';
import { EulaContainer } from '@shared/containers/eula/eula.container';
import { EulaDialogService } from '@shared/services/eula-dialog/eula-dialog.service';
import { SingleKeywordSelectorComponent } from './components/single-keyword-selector/single-keyword-selector.component';
import { DueInLabelPipe } from '@shared/pipes/due-in-label/due-in-label.pipe';
import { KeywordsLabelPipe } from '@shared/pipes/keywords-label/keywords-label.pipe';
import { DueDateComponent } from '@shared/components/due-date/due-date.component';
import { DragAndDropLabelComponent } from '@shared/components/drag-and-drop-label/drag-and-drop-label.component';
import { DraggableAreaDirective } from '@shared/directives/draggable-area/draggable-area.directive';
import { ProjectTitlePipe } from '@shared/pipes/project-title/project-title.pipe';
import { EditableDueDateComponent } from '@shared/components/editable-due-date/editable-due-date.component';
import { EmptyLabelPipe } from '@shared/pipes/empty-label/empty-label.pipe';
import { DisplayContactPipe } from '@shared/pipes/display-contact/display-contact.pipe';
import { TeamMembersSearchResultComponent } from './components/team-members-selector/team-members-search-result/team-members-search-result.component';
import { TeamMembersChipComponent } from './components/team-members-selector/team-members-chip/team-members-chip.component';
import { SingleItemSearchSelectorComponent } from '@shared/components/single-item-search-selector/single-item-search-selector.component';
import { SearchAutocompleteComponent } from '@shared/components/search-autocomplete/search-autocomplete.component';
import { ListWrapperComponent } from './components/list-wrapper/list-wrapper.component';
import { ComposedEventsMapperService } from '@shared/services/events/composed-events-mapper/composed-events-mapper.service';
import { UserDisplayListWithDeleteComponent } from '@shared/components/user-display-list-with-delete/user-display-list-with-delete.component';
import { DynamicScrollbarPaddingDirective } from '@shared/directives/scrollbar-padding/dynamic-scrollbar-padding.directive';
import { TooltipComponent } from '@shared/components/tooltip/tooltip.component';
import { ViewProfileContainer } from './containers/view-profile/view-profile.container';
import { AvatarManagerComponent } from './components/avatar-manager/avatar-manager.component';
import { HeaderComponent } from '@shared/components/header/header.component';
import { ContentHeaderSubHeaderComponent } from '@shared/components/header/content-header-sub-header/content-header-sub-header.component';
import { ContentHeaderRowCounterComponent } from '@shared/components/header/content-header-row-counter/content-header-row-counter.component';
import { ContentHeaderBackLinkComponent } from '@shared/components/header/content-header-back-link/content-header-back-link.component';
import { ContentHeaderButtonComponent } from '@shared/components/header/content-header-button/content-header-button.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { SidebarSubmenuComponent } from './components/sidebar/sidebar-submenu/sidebar-submenu.component';
import { SearchbarComponent } from '@shared/components/searchbar/searchbar.component';
import { FlyoutComponent } from './components/flyout/flyout.component';
import { BreadcrumbsComponent } from '@shared/components/breadcrumbs/breadcrumbs.component';

@NgModule({
  imports: [
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RoundProgressModule,
    TranslateModule.forChild(),
    ClipboardModule,
    CommonModule,
    InfiniteScrollModule,
    AutosizeModule,
    ScrollToModule.forRoot(),
    RouterModule
  ],
  exports: [
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RoundProgressModule,
    CommonModule,
    InfiniteScrollModule,
    FooterComponent,
    SnackBarComponent,
    ByteParserPipe,
    EmailContactListDisplayPipe,
    IconTypeParserPipe,
    FilePathParserPipe,
    FileTypeParserPipe,
    SnackbarIconPipe,
    TranslateModule,
    SpinnerComponent,
    PageHeaderComponent,
    ErrorComponent,
    UserDisplayComponent,
    ConfirmationDialogComponent,
    ErrorComponent,
    ItemDetailsButtonComponent,
    UserMenuComponent,
    RaisedButtonComponent,
    InitialsComponent,
    EmptyStateComponent,
    HeaderComponent,
    ContentHeaderSubHeaderComponent,
    ContentHeaderRowCounterComponent,
    ContentHeaderBackLinkComponent,
    ContentHeaderButtonComponent,
    UploadProgressComponent,
    DashboardSearchComponent,
    ProjectsSelectorComponent,
    SearchComponent,
    ErrorCardComponent,
    PermissionSelectorComponent,
    FilesBrowserComponent,
    IndeterminateProgressBarComponent,
    ChipItemComponent,
    TooltipComponent,
    TeamMembersSelectorComponent,
    InlineEditableInputComponent,
    AutoFocusDirective,
    OverlayComponent,
    AutosizeModule,
    InlineEditIndicatorButtonsComponent,
    ClickOutsideDirective,
    ProjectFoldersContainer,
    NotClickableDirective,
    DatePickerComponent,
    InlineEditableTextareaComponent,
    DetailAccordionComponent,
    TimeOrDatePipe,
    ShowMoreComponent,
    MultipleKeywordsSelectorComponent,
    ShowMoreComponent,
    EulaContainer,
    SingleKeywordSelectorComponent,
    DueInLabelPipe,
    KeywordsLabelPipe,
    DueDateComponent,
    DragAndDropLabelComponent,
    DraggableAreaDirective,
    ProjectTitlePipe,
    EditableDueDateComponent,
    EmptyLabelPipe,
    DisplayContactPipe,
    SingleItemSearchSelectorComponent,
    ListWrapperComponent,
    SearchAutocompleteComponent,
    UserDisplayListWithDeleteComponent,
    DynamicScrollbarPaddingDirective,
    AvatarManagerComponent,
    SidebarComponent,
    BreadcrumbsComponent,
    SearchbarComponent,
    FlyoutComponent
  ],
  declarations: [
    FooterComponent,
    SnackBarComponent,
    ByteParserPipe,
    EmailContactListDisplayPipe,
    IconTypeParserPipe,
    FilePathParserPipe,
    FileTypeParserPipe,
    SnackbarIconPipe,
    SpinnerComponent,
    PageHeaderComponent,
    ErrorComponent,
    ConfirmationDialogComponent,
    UserDisplayComponent,
    InitialsComponent,
    ItemDetailsButtonComponent,
    UserMenuComponent,
    RaisedButtonComponent,
    EmptyStateComponent,
    HeaderComponent,
    ContentHeaderSubHeaderComponent,
    ContentHeaderRowCounterComponent,
    ContentHeaderBackLinkComponent,
    ContentHeaderButtonComponent,
    UploadProgressComponent,
    DashboardSearchComponent,
    ProjectsSelectorComponent,
    SearchComponent,
    ErrorCardComponent,
    FilesBrowserComponent,
    PermissionSelectorComponent,
    IndeterminateProgressBarComponent,
    ChipItemComponent,
    TooltipComponent,
    TeamMembersSelectorComponent,
    InlineEditableInputComponent,
    AutoFocusDirective,
    OverlayComponent,
    InlineEditIndicatorButtonsComponent,
    ClickOutsideDirective,
    ProjectFoldersContainer,
    SitesSearchResultsComponent,
    NotClickableDirective,
    DatePickerComponent,
    InlineEditableTextareaComponent,
    DetailAccordionComponent,
    TimeOrDatePipe,
    ShowMoreComponent,
    MultipleKeywordsSelectorComponent,
    ShowMoreComponent,
    EulaContainer,
    SingleKeywordSelectorComponent,
    DueInLabelPipe,
    KeywordsLabelPipe,
    DueDateComponent,
    DragAndDropLabelComponent,
    DraggableAreaDirective,
    ProjectTitlePipe,
    EmptyLabelPipe,
    EditableDueDateComponent,
    DisplayContactPipe,
    TeamMembersSearchResultComponent,
    TeamMembersChipComponent,
    SingleItemSearchSelectorComponent,
    SearchAutocompleteComponent,
    ListWrapperComponent,
    UserDisplayListWithDeleteComponent,
    DynamicScrollbarPaddingDirective,
    ViewProfileContainer,
    AvatarManagerComponent,
    SidebarComponent,
    SidebarSubmenuComponent,
    SearchbarComponent,
    BreadcrumbsComponent,
    FlyoutComponent
  ],
  providers: [
    ConfirmationDialogService,
    ConfirmationDialogLocalStorageService,
    EventMapperService,
    TeamResourceService,
    ProjectFoldersDialogService,
    ProjectFoldersErrorMessagesService,
    ComposedEventsMapperService,
    EulaDialogService
  ]
})
export class SharedModule {}
