import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-flyout',
  templateUrl: './flyout.component.html',
  styleUrls: ['./flyout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlyoutComponent {
  @Input() isOpen: boolean;
  @Input() error: boolean;
  @Output() onClose = new EventEmitter<void>();

  closeFlyout(): void {
    this.onClose.emit();
  }
}
