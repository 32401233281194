<button
  mat-raised-button
  class="raised-button"
  [class.skeleton]="loadingState"
  [disabled]="disabled || loadingState"
  (click)="handleClick($event)"
  fxLayout
  fxLayoutAlign="center center"
>
<div class="raised-button-content">
  <mat-icon
    *ngIf="matIcon"
    [class.skeleton-icon]="loadingState"
    class="icon"
  >
    {{matIcon}}
  </mat-icon>
  <mat-icon
    *ngIf="svgIcon"
    class="svg-icon"
    [class.skeleton-icon]="loadingState"
    svgIcon="{{svgIcon}}"
  ></mat-icon>
  <div class="display-name">
    <ng-content></ng-content>
  </div>
</div>
</button>
