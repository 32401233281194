import {
  TeamResourceViewModel,
  EmailOnlyViewModel,
  TeamGroupViewModel,
  TeamMemberViewModel
} from '@shared/models/team-resource/team-resource.view.model';
import { Recipient } from '@core/models/shared-item/recipient.model';
import { ActionContact } from '@workflow-items/models/action-contact';

export function areEqual(one: TeamResourceViewModel, another: TeamResourceViewModel): boolean {
  if (isEmailOnly(one) && isEmailOnly(another)) {
    return one.email === another.email;
  } else if ((isTeamMember(one) && isTeamMember(another)) || (isTeamGroup(one) && isTeamGroup(another))) {
    return one.nrn === another.nrn;
  }
  return false;
}

export function hasNrn(resource: TeamResourceViewModel, nrn: string): boolean {
  return (isTeamMember(resource) || isTeamGroup(resource)) && resource.nrn === nrn;
}

export function hasEmail(resource: TeamResourceViewModel, email: string): boolean {
  return (isTeamMember(resource) || isEmailOnly(resource)) && resource.email === email;
}

export function isTeamMemberWithoutEmail(resource: TeamResourceViewModel): boolean {
  return isTeamMember(resource) && !resource.email.length;
}

export function mapToRecipient(resource: TeamResourceViewModel): Recipient {
  if (isTeamMember(resource) || isEmailOnly(resource)) {
    return { email: resource.email };
  } else {
    return { groupNrn: resource.nrn };
  }
}

export function isTeamMember(resource: TeamResourceViewModel): resource is TeamMemberViewModel {
  return 'email' in resource && 'nrn' in resource;
}

export function isTeamGroup(resource: TeamResourceViewModel): resource is TeamGroupViewModel {
  return !('email' in resource) && 'nrn' in resource;
}

export function isEmailOnly(resource: TeamResourceViewModel): resource is EmailOnlyViewModel {
  return 'email' in resource && !('nrn' in resource);
}

export function getTeamResourcesErrorMessage(errorStatus: number): string {
  switch (errorStatus) {
    case 404:
      return 'TEAM_MEMBERS.ERROR.NOT_FOUND';
    case 501:
      return 'APP.ERROR.NOT_IMPLEMENTED';
    default:
      return 'APP.ERROR.DEFAULT';
  }
}

export function mapTeamMembersToWorkflowItemContacts(teamMembers: TeamResourceViewModel[]): ActionContact[] {
  return teamMembers.map((member: TeamResourceViewModel) =>
    isTeamMember(member) || isTeamGroup(member)
      ? {
          nrn: member.nrn
        }
      : {
          email: member.email
        }
  );
}
