import { createAction, props, Action } from '@ngrx/store';
import { CreateTermsAndConditionsResponse } from '@terms-and-conditions/models/create-terms-and-conditions.response.model';
import { CreateTermsAndConditionsModel } from '@terms-and-conditions/models/create-terms-and-conditions.model';
import { LoadTermsAndConditionsModel } from '@terms-and-conditions/models/load-terms-and-conditions.model';
import { AcceptTermsAndConditionsRequest } from '@terms-and-conditions/models/accept-terms-and-conditions.request';

const prefix = '[TERMS_AND_CONDITIONS_DIALOG]';

export const createTermsAndConditions = createAction(
  `${prefix} CREATE_TERMS_AND_CONDITIONS`,
  props<{ payload: CreateTermsAndConditionsModel }>()
);

export const createTermsAndConditionsFailed = createAction(`${prefix} CREATE_TERMS_AND_CONDITIONS_FAILED`);

export const createTermsAndConditionsSuccess = createAction(
  `${prefix} CREATE_TERMS_AND_CONDITIONS_SUCCESS`,
  props<{ payload: CreateTermsAndConditionsResponse }>()
);

export const closeTermsAndConditionsDialog = createAction(`${prefix} CLOSE_TERMS_AND_CONDITIONS`);

export const openTermsAndConditionsDialog = createAction(
  `${prefix} OPEN_TERMS_AND_CONDITIONS`,
  props<{ projectNrn?: string; viewTermsAndconditions?: boolean; actionOnSuccess?: Action }>()
);

export const getTermsAndConditionsContent = createAction(`${prefix} GET_TERMS_AND_CONDITIONS`);

export const getTermsAndConditionsContentFailed = createAction(`${prefix} GET_TERMS_AND_CONDITIONS_FAILED`);

export const getTermsAndConditionsContentSuccess = createAction(
  `${prefix} GET_TERMS_AND_CONDITIONS_SUCCESS`,
  props<{ content: string | null }>()
);

export const loadTermsAndConditionsUponProjectSelect = createAction(
  `${prefix} LOAD_TERMS_AND_CONDITIONS_DETAILS`,
  props<{ payload: LoadTermsAndConditionsModel }>()
);

export const loadTermsAndConditions = createAction(
  `${prefix} LOAD_TERMS_AND_CONDITIONS`,
  props<{ payload: LoadTermsAndConditionsModel }>()
);

export const loadTermsAndConditionsDetailsSuccess = createAction(
  `${prefix} LOAD_TERMS_AND_CONDITIONS_SUCCESS`,
  props<{
    nrn: string | null;
    content: string | null;
    accepted: boolean | null;
  }>()
);

export const loadTermsAndConditionsDetailsFailed = createAction(`${prefix} LOAD_TERMS_AND_CONDITIONS_FAILED`);

export const acceptTermsAndConditions = createAction(
  `${prefix} ACCEPT_TERMS_AND_CONDITIONS`,
  props<{ payload: AcceptTermsAndConditionsRequest }>()
);

export const acceptTermsAndConditionsFailed = createAction(`${prefix} ACCEPT_TERMS_AND_CONDITIONS_FAILED`);

export const acceptTermsAndConditionsSuccess = createAction(
  `${prefix} ACCEPT_TERMS_AND_CONDITIONS_SUCCESS`,
  props<{
    content: string | null;
    accepted: boolean | null;
  }>()
);
