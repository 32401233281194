<mat-form-field
  [formGroup]="parentForm"
  class="date-picker-container"
>
  <input
    #dateInput
    class="due-date-picker-input"
    matInput
    [matDatepicker]="dueDatePicker"
    [formControlName]="controlName"
    [placeholder]="placeholder | translate"
    [matDatepickerFilter]="filter"
    [max]="maxDate"
    [min]="minDate"
    (dateChange)="onDateChangedHandler($event)"
    [required]="isRequired"
    (keydown.escape)="onDiscardChangesHandler()"
    [errorStateMatcher]="inputErrorMatcher"
  />
  <mat-error
    *ngIf="dateInput.value.length > 0"
    class="invalid-date-message"
  >
    {{ 'INVALID_DATE' | translate }}
  </mat-error>
  <mat-error
    *ngIf="isRequired && errorMessage && dateInput.value.length === 0"
    class="required-field-message"
  >
    {{ errorMessage | translate }}
  </mat-error>
  <mat-datepicker-toggle
    matSuffix [for]="dueDatePicker"
  >
    <mat-icon svgIcon="calendar" class="calendar-icon" matDatepickerToggleIcon></mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker
    #dueDatePicker
    (opened)="onPickerOpenedHandler()"
    (closed)="onPickerClosedHandler()"
  ></mat-datepicker>
  <mat-icon
    *ngIf="displayEditIndicatorButtons"
    matSuffix
    class="action-icon discard-changes-icon"
    (mousedown)="onDiscardChangesHandler()"
  >
    clear
  </mat-icon>
  <mat-icon
    *ngIf="displayEditIndicatorButtons"
    matSuffix
    class="action-icon accept-changes-icon"
    (mousedown)="onAcceptChangesHandler()"
  >
    done
  </mat-icon>
</mat-form-field>
