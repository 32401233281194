import { EventModel } from '@shared/models/events/events-model';
import { MappedProjectEvent } from '@project-log/models/mapped-project-events';

export type EventMapperFunc = (event: EventModel) => MappedProjectEvent | null;

export function on<T extends EventModel>(
  guard: (eventModel: EventModel) => eventModel is T,
  mapperFunction: (event: T) => MappedProjectEvent
): {
  guard: (x: EventModel) => x is T;
  mapperFunction: (event: T) => MappedProjectEvent;
} {
  return { guard, mapperFunction };
}

export function createEventMapper<T extends EventModel>(
  ...ons: {
    guard: (eventModel: EventModel) => eventModel is EventModel;
    mapperFunction: (event: EventModel) => MappedProjectEvent;
  }[]
): EventMapperFunc {
  return function(event: T) {
    const foundOn = ons.find(o => o.guard(event));

    if (!foundOn) {
      return null;
    }

    return foundOn.mapperFunction(event);
  };
}
