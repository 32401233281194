import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ViewProfileContainer } from '@shared/containers/view-profile/view-profile.container';

@Injectable({
  providedIn: 'root'
})
export class ViewProfileDialogService {
  constructor(private dialog: MatDialog) {}

  private dialogRef: MatDialogRef<ViewProfileContainer>;

  open(): MatDialogRef<ViewProfileContainer> {
    this.dialogRef = this.dialog.open(ViewProfileContainer, { width: '600px' });
    return this.dialogRef;
  }

  close(): void {
    this.dialogRef.close();
  }
}
