import { FilePathParser } from '@shared/utilities/paths/file-path.parser';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filePathParser'
})
export class FilePathParserPipe implements PipeTransform {
  transform(parentsList: string[]): string {
    return FilePathParser.getPath(parentsList);
  }
}
