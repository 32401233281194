import { ConfigurationService } from 'app/core/services/configuration/configuration.service';
import { Injectable, Inject } from '@angular/core';

@Injectable()
export class ApiUrlProvider {
  private isProduction: boolean;
  private apiUrl: string;

  constructor(configurationService: ConfigurationService, @Inject('ENVIRONMENT') environment) {
    configurationService.configuration.subscribe(configuration => {
      this.apiUrl = configuration.apiBaseUrl;
    });

    this.isProduction = environment.production;
  }

  getUrl(): string {
    return this.isProduction ? this.apiUrl : sessionStorage.getItem('apiUrl') || this.apiUrl;
  }
}
