import { Component, Inject, OnInit } from '@angular/core';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { WindowService } from '@core/services/window/window.service';
import { AuthenticationCredentialProvider } from '@core/services/authentication/authentication.credential.provider';
import { TranslateService } from '@ngx-translate/core';
import { PartialUserInfo } from '@core/models/authentication/partialUserInfo';

@Component({
  selector: 'app-walk-me-snippet',
  template: ''
})
export class WalkMeSnippetComponent implements OnInit {
  private readonly prodUrl =
    'https://cdn.walkme.com/users/15872d6af676454dbce45f8d4f33cfa1/walkme_15872d6af676454dbce45f8d4f33cfa1_https.js';
  private readonly devUrl =
    'https://cdn.walkme.com/users/15872d6af676454dbce45f8d4f33cfa1/test/walkme_15872d6af676454dbce45f8d4f33cfa1_https.js';
  private isProd = false;

  constructor(
    private authenticationService: AuthenticationService,
    private windowService: WindowService,
    private credentialProvider: AuthenticationCredentialProvider,
    private translateService: TranslateService,
    @Inject('ENVIRONMENT') environment
  ) {
    this.isProd = environment.production;
  }

  ngOnInit() {
    const isTest =
      (localStorage &&
        (localStorage.getItem('PROTRACTOR_ENV') === 'true' || localStorage.getItem('UNIT_TESTS') === 'true')) ||
      (window as any).Cypress;
    this.initWalkMe(isTest);
    this.subscribeToAuthService(isTest);
  }

  initWalkMe(isE2E: boolean): void {
    if (isE2E) {
      return;
    }

    const headElement = document.getElementsByTagName('head')[0];
    const walkMeScriptNode = document.createElement('script');
    const browserLanguage = this.translateService.getBrowserLang().toUpperCase();

    walkMeScriptNode.type = 'text/javascript';
    walkMeScriptNode.async = true;
    walkMeScriptNode.src = this.isProd ? this.prodUrl : this.devUrl;

    (window as any)._walkmeConfig = {
      smartLoad: true
    };

    headElement.appendChild(walkMeScriptNode);

    (window as any).walkme_get_language = this.applyWalkMeLanguage(browserLanguage);
  }

  private applyWalkMeLanguage(lang: string): () => string {
    return function() {
      const supportedLanguages = ['DE'];
      return supportedLanguages.includes(lang) ? lang : '';
    };
  }

  private subscribeToAuthService(isE2E: boolean): void {
    if (isE2E) {
      return;
    }
    this.authenticationService.isLogged.subscribe(isLogged => {
      if (!isLogged) {
        return;
      }

      const sessionToken = this.credentialProvider.getSessionTokenRequest();
      if (sessionToken) {
        const userInfo = this.windowService.getUserInfo(sessionToken);
        this.setWalkMeVariable(userInfo);
      }
    });
  }

  private setWalkMeVariable(userInfo: PartialUserInfo): void {
    (window as any).walkmeNewformaUserIdentifier = userInfo.unique_name;
  }
}
