<div
  class="keywords-selector-container"
  [class.label-text-disabled]="displayTooltip"
>
  <form
    [formGroup]="form"
  >
    <mat-form-field
      fxFlex=" 1 1 100"
      class="keywords-input"
      [class.disabled]="displayTooltip"
      [id]="elementId"
      [floatLabel]="label ? (keywordChips.length > 0 ? 'always' : 'auto') : 'never'"
      [class.label-default-position]="isFocused && keywordChips.length > 0"
      [class.label-moved-position]="!isFocused && keywordChips.length === 0 && keywordsControl.value.length === 0"
    >
      <mat-label *ngIf="!isFocused" class="label-text">
        {{ label | translate}}
      </mat-label>
      <mat-chip-list
        #chipList
        class="chip-list-container"
        [disabled]="displayTooltip"
      >
        <mat-icon
          *ngIf="keywordChips.length === 0"
          svgIcon="search"
          class="search-icon"
          matPrefix
        ></mat-icon>
        <app-chip-item
          *ngFor="let keyword of keywordChips;"
          [selectable]="true"
          (onRemove)="removeKeyword(keyword)"
        >
        <span class="keyword-text">{{ keyword?.name }}</span>
        </app-chip-item>
        <div class="empty-space"></div>
        <input
          *ngIf="!hasError"
          #keywordInput
          placeholder="{{ label && isFocused ? (label | translate) : ''}}"
          matInput
          matAutocompletePosition="below"
          class="multiple-keywords-selector-input"
          [formControl]="keywordsControl"
          [matChipInputFor]="chipList"
          [matAutocomplete]="keywordAutocomplete.auto"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (focus)="focus(true)"
          (blur)="focus(false)"
          (click)="openAutoCompletePanel()"
          (matChipInputTokenEnd)="add($event)"
        />
      </mat-chip-list>
      <app-search-autocomplete
        id="keyword-autocomplete"
        #keywordAutocomplete
        [items]="keywords"
        [hasError]="hasError"
        [loadingErrorMessage]="loadingErrorMessage"
        [noResultErrorMessage]="noResultErrorMessage"
        [isLoading]="isLoading"
        [hasMoreItemsToLoad]="hasMoreKeywordsToLoad"
        (onSelectItem)="selectKeyword($event)"
        (onPanelOpened)="panelOpened()"
        (onPanelClosed)="panelClosed()"
      ></app-search-autocomplete>
      <span
        class="tooltip"
        *ngIf="displayTooltip && tooltipErrorMessage"
      >
          {{ tooltipErrorMessage | translate }}
      </span>
    </mat-form-field>
  </form>
</div>
