import { Pipe, PipeTransform } from '@angular/core';
import { IconTypeParser } from '@shared/utilities/icons/icons.type.parser';
import { IconTypeParams } from '@core/models/files/icon-type.params';

@Pipe({
  name: 'iconTypeParser'
})
export class IconTypeParserPipe implements PipeTransform {
  transform(params: IconTypeParams, folderIcon?: string) {
    return IconTypeParser.chooseIcon(params, folderIcon);
  }
}
