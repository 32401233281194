<button
  mat-button
  (click)="handleClick()"
  class="item-details-button"
  [disabled]="isDisabled"
>
  <mat-icon svgIcon="{{icon}}" class="action-icon"></mat-icon>
  <span
    class="action-button-text"
    class.chrome-text="pixelatedTextInChrome"
  >
          {{label | translate}}
    </span>
</button>
