import { throwError as observableThrowError, Observable, from } from 'rxjs';
import { Injectable, Injector, Inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpParams } from '@angular/common/http';
import { AuthenticationCredentialProvider } from '@core/services/authentication/authentication.credential.provider';
import * as AwsSign from 'aws-sign-web';
import { WindowService } from '@core/services/window/window.service';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { LoginRequest } from '@core/models/authentication/loginRequest.model';
import { catchError, mergeMap } from 'rxjs/operators';
import { ApiUrlProvider } from '@core/utilities/apiUrl/apiUrl.provider';
import { EulaDialogService } from '@shared/services/eula-dialog/eula-dialog.service';

@Injectable()
export class AwsInterceptor implements HttpInterceptor {
  authService: AuthenticationService;
  apiUrlProvider: ApiUrlProvider;
  refreshRequestInProgress: Promise<any> | null;
  private readonly service: string = 'execute-api';
  private readonly refreshErrors: string[] = [
    'bad_request',
    'expired_token',
    'missing_authentication_token',
    'invalid_signature'
  ];
  private readonly version: string;
  private readonly isProductionEnvironment: boolean;

  constructor(
    private windowService: WindowService,
    private credentialProvider: AuthenticationCredentialProvider,
    private injector: Injector,
    @Inject('ENVIRONMENT') environment,
    private eulaDialogService: EulaDialogService
  ) {
    this.version = environment.version;
    this.isProductionEnvironment = !!environment.production;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.preventCircularDependency(req.url)) {
      return next.handle(req);
    }

    if (!this.authService || !this.apiUrlProvider) {
      this.initializeInjectedServices();
    }

    const requestWithVersionHeader = this.addVersionHeader(req);

    if (
      this.preventUnnecessarySigning(
        requestWithVersionHeader.url,
        requestWithVersionHeader.method,
        requestWithVersionHeader.params
      )
    ) {
      return next.handle(requestWithVersionHeader);
    }

    const signedRequest: HttpRequest<any> = this.signRequest(requestWithVersionHeader);

    return next.handle(signedRequest).pipe(
      catchError(err => {
        if (/(login|redirect)/.test(this.windowService.getNativeWindow().location.pathname)) {
          return observableThrowError(err);
        }

        if (this.shouldTriggerRefresh(err)) {
          if (!this.refreshRequestInProgress) {
            this.refreshRequestInProgress = this.handleRefreshError();
          }
          return from(this.refreshRequestInProgress).pipe(
            mergeMap(() => {
              this.refreshRequestInProgress = null;

              const signedRefreshedRequest: HttpRequest<any> = this.signRequest(req);
              const signedRefreshWithVersionHeader = this.addVersionHeader(signedRefreshedRequest);
              return next.handle(signedRefreshWithVersionHeader);
            }),
            catchError(refreshRequestError => {
              if (!refreshRequestError || this.shouldTriggerRefresh(refreshRequestError)) {
                this.authService.logOut(true);
              }
              return observableThrowError(refreshRequestError);
            })
          );
        }

        return observableThrowError(err);
      })
    );
  }

  getConfig(): AwsSign.Config | null {
    const accessKeyId = this.credentialProvider.getAccessKeyId();
    const secretAccessKey = this.credentialProvider.getSecretAccessKey();
    const sessionToken = this.credentialProvider.getSessionToken();
    const signatureScope = this.credentialProvider.getSignatureScope();
    if (
      accessKeyId == null ||
      secretAccessKey == null ||
      sessionToken == null ||
      signatureScope == null ||
      this.apiUrlProvider === undefined
    ) {
      return null;
    }

    return {
      region: signatureScope,
      service: this.service,
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
      sessionToken: sessionToken
    };
  }

  private preventCircularDependency(url: string): boolean {
    return /assets\/config\/default.json/.test(url);
  }

  private preventUnnecessarySigning(url: string, reqMethod: string, params: HttpParams): boolean {
    if (!this.authService.isUserLogged()) {
      return true;
    }
    if (!url.includes(this.apiUrlProvider.getUrl())) {
      return true;
    }
    if (url.includes(`${this.apiUrlProvider.getUrl()}/shareditems`) && reqMethod === 'GET') {
      return !params.has('subjectNrn');
    }
    return false;
  }

  initializeInjectedServices(): void {
    this.authService = this.injector.get(AuthenticationService);
    this.apiUrlProvider = this.injector.get(ApiUrlProvider);
  }

  addVersionHeader(request: HttpRequest<any>): HttpRequest<any> {
    let headers = request.headers;

    headers = headers.set('X-Newforma-Agent', `Web/${this.version}`);

    return request.clone({
      headers: headers
    });
  }

  signRequest(request: HttpRequest<any>): HttpRequest<any> {
    let headers = request.headers;
    const config = this.getConfig();

    if (config != null) {
      const queryParams = request.params.keys().reduce(
        (acc, key) => ({
          ...acc,
          [key]: request.params.get(key)
        }),
        {}
      );

      const signedHeaders = new AwsSign.AwsSigner(config).sign({
        method: request.method,
        url: encodeURI(request.url),
        body: request.body ? JSON.stringify(request.body) : request.body,
        params: queryParams
      });

      headers = headers
        .set('Accept', signedHeaders.Accept)
        .set('Authorization', signedHeaders.Authorization)
        .set('x-amz-date', signedHeaders['x-amz-date'])
        .set('x-amz-security-token', signedHeaders['x-amz-security-token']);
    }

    return request.clone({
      headers: headers
    });
  }

  handleRefreshError(): Promise<any> {
    const identityProvider = this.credentialProvider.getIdentityProvider();
    const tenantId = this.credentialProvider.getTenantId();
    const email = this.credentialProvider.getEmail();

    if (!identityProvider) {
      return Promise.reject('Identity Provider is missing');
    }

    const origin = this.windowService.getNativeWindow().location.origin;
    const deployedWithoutDns =
      identityProvider.id !== AuthenticationService.azureProvider && this.authService.isDeployedWithoutDns(origin);

    let redirectUri = '';
    let authenticatorUrl = `${identityProvider.refreshUrl}`;

    for (const param of identityProvider.parameters) {
      if (param.key === 'response_type' && (this.windowService.isIE11() || this.windowService.isEdge())) {
        param.value = 'id_token';
      }
      if (param.key === 'redirect_uri') {
        if (!this.isProductionEnvironment && !deployedWithoutDns) {
          param.value = `${origin}/redirect`;
        }
        redirectUri = param.value;
        if (deployedWithoutDns) {
          param.value = `${param.value}/dev`;
          redirectUri = `${origin}/redirect`;
        }
      }
      if (param.key === 'tenant' && identityProvider.id !== AuthenticationService.azureProvider && tenantId) {
        param.value = tenantId;
      }
      authenticatorUrl = authenticatorUrl.replace(new RegExp(`{${param.key}}`, 'g'), encodeURIComponent(param.value));
    }

    // For feature branches, include the state so we can redirect properly
    if (deployedWithoutDns) {
      const state = {
        originalUrl: origin,
        redirectBackTo: redirectUri
      };
      authenticatorUrl += `&state=${encodeURIComponent(btoa(JSON.stringify(state)))}`;
    }

    if (email && !(this.windowService.isIE11() || this.windowService.isEdge())) {
      authenticatorUrl += `&login_hint=${encodeURIComponent(email)}`;
    }

    this.windowService.open(authenticatorUrl, this.windowService.refreshFrameName);

    return this.checkRefreshFrame().then((refreshRequest: LoginRequest) =>
      this.authService.refresh(refreshRequest).then(
        success => {},
        error => {
          const showEula = this.shouldShowEula(error);
          if (showEula.shouldShowEula) {
            if (identityProvider.id === AuthenticationService.azureProvider) {
              return this.showEula(refreshRequest, showEula.isEulaUpdated);
            }
            this.windowService.open(authenticatorUrl, this.windowService.refreshFrameName);
            return this.checkRefreshFrame().then((newRefreshRequest: LoginRequest) =>
              this.showEula(newRefreshRequest, showEula.isEulaUpdated)
            );
          } else {
            throw error;
          }
        }
      )
    );
  }

  private showEula(refreshRequest: LoginRequest, isEulaUpdated: boolean): Promise<boolean | {} | undefined> {
    return this.eulaDialogService
      .open({ isUserAuthenticated: false, isEulaPreviouslyAccepted: isEulaUpdated })
      .toPromise()
      .then(eula =>
        this.authService.refresh({ ...refreshRequest, eula: eula }).then(
          () => {
            if (!eula && !isEulaUpdated) {
              // log user out if no previous eula accepted and eula failed to load
              return this.authService.logOut();
            }
          },
          () => this.authService.logOut() // log user out on second request failure. also handles the 403 from eula refusal
        )
      );
  }

  private shouldShowEula(error: any): { shouldShowEula: boolean; isEulaUpdated: boolean } {
    if (error.error?.type?.includes('#eula_confirmation_required')) {
      return { shouldShowEula: true, isEulaUpdated: false };
    }

    if (error.error?.type?.includes('#eula_update_confirmation_required')) {
      return { shouldShowEula: true, isEulaUpdated: true };
    }

    return { shouldShowEula: false, isEulaUpdated: false };
  }

  private checkRefreshFrame(): Promise<LoginRequest> {
    return new Promise((resolve, reject) => {
      let count = 0;

      const window = this.windowService.getNativeWindow();
      const refreshFrame = this.windowService.getRefreshFrame();

      const iframeChecker = setInterval(() => {
        count++;
        if (count >= 10) {
          clearInterval(iframeChecker);
          this.windowService.open('about:blank', this.windowService.refreshFrameName);
          reject();
        }

        if (
          refreshFrame &&
          refreshFrame.location.origin === window.location.origin &&
          (refreshFrame.location.search.includes('code') || refreshFrame.location.hash.includes('code'))
        ) {
          clearInterval(iframeChecker);
          this.windowService.open('about:blank', this.windowService.refreshFrameName);

          const rawParams =
            refreshFrame.location.search.length > 0
              ? refreshFrame.location.search
              : decodeURIComponent(refreshFrame.location.hash);
          const params = new URLSearchParams(rawParams.slice(1));

          const idToken = params.get('id_token') as string;
          const code = params.get('code') as string;

          resolve({
            idToken: idToken || <string>this.credentialProvider.getSessionTokenRequest(),
            code: code,
            global: true
          });
        }
      }, 1000);
    });
  }

  private shouldTriggerRefresh(err: any): boolean {
    // ie11 is not auto-parsing the inner JSON string
    const errorType =
      err.error && (err.error.type || (typeof err.error === 'string' && JSON.parse(err.error.replace(/\n/g, '')).type));
    return (
      err.status === 401 ||
      (err.status === 403 && this.refreshErrors.some(refreshError => errorType.indexOf(refreshError) >= 0))
    );
  }
}
