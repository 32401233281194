import { UserDetailsLicense } from '@core/models/user/user-license-response.model';
import { createAction, props } from '@ngrx/store';
import { UpdateUserModel } from '@core/models/user/user.model';

const prefix = '[USER]';

export const loadUserLicenseCompleted = createAction(
  `${prefix} LOAD_USER_LICENSE_COMPLETED`,
  props<UserDetailsLicense>()
);

export const loadUserLicenseFailed = createAction(`${prefix} LOAD_USER_LICENSE_FAILED`);

export const updateUser = createAction(`${prefix} UPDATE_USER`, props<{ updateUserModel: UpdateUserModel }>());

export const updateUserComplete = createAction(`${prefix} UPDATE_USER_COMPLETE`);

export const updateUserFailed = createAction(`${prefix} UPDATE_USER_FAILED`);

export const refreshUser = createAction(`${prefix} REFRESH_USER`);
