import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { FeatureService } from '@core/services/feature/feature.service';

@Injectable()
export class EmailsGuard implements CanActivate {
  private isEmailFilingSupported: boolean;

  constructor(private featureService: FeatureService, private router: Router) {
    this.featureService.isEmailFilingSupported.subscribe(value => (this.isEmailFilingSupported = value));
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.isEmailFilingSupported) {
      return true;
    }

    this.router.navigate(['/projects']);
    return false;
  }
}
