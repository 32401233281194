import { Component } from '@angular/core';
import { BrowserCompatibilityService } from '@core/services/browser-compatibility/browser-compatibility.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-browser-compatibility-warning',
  templateUrl: './browser-compatibility-warning.component.html',
  styleUrls: ['./browser-compatibility-warning.component.scss']
})
export class BrowserCompatibilityWarningComponent {
  constructor(private browserCompatibilityService: BrowserCompatibilityService, private router: Router) {}

  dismissWarning(): void {
    this.browserCompatibilityService.setUserAcknowledgedSupport();
    this.router.navigate(['/']);
  }
}
