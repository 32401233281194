import { Component, Input } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-error-card',
  templateUrl: './error-card.component.html',
  styleUrls: ['./error-card.component.scss'],
  animations: [
    trigger('errorState', [
      state(
        '0',
        style({
          opacity: 0,
          height: 0,
          'padding-top': 0,
          'padding-bottom': 0
        })
      ),
      state(
        '1',
        style({
          opacity: 1,
          height: '*',
          'padding-top': '0.75rem',
          'padding-bottom': '0.75rem'
        })
      ),
      transition('0 => 1', animate('200ms linear')),
      transition('1 => 0', animate('200ms linear'))
    ])
  ]
})
export class ErrorCardComponent {
  @Input() message: String;
}
