import { Injectable } from '@angular/core';

@Injectable()
export class ConfirmationDialogLocalStorageService {
  shouldDisplayDialog(localStorageKey?: string): boolean {
    return localStorageKey ? !localStorage.getItem(localStorageKey) : true;
  }

  setLocalStorageForHidingDialog(localStorageKey: string) {
    localStorage.setItem(localStorageKey, 'hidden');
  }
}
