import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import {
  closeProjectFoldersDialog,
  completeLoadingExternalFolders,
  completeLoadingExternalFoldersNextPage,
  createProjectFolder,
  createProjectFolderFailed,
  createProjectFolderSuccess,
  initProjectFolders,
  loadExternalFolders,
  loadExternalFoldersNextPage,
  openProjectFoldersDialog,
  reloadFiles,
  setProjectFoldersCapabilities,
  updateLoadExternalFoldersError
} from '@root/actions/project-folders/project-folders.actions';
import { ProjectFoldersDialogService } from '@shared/services/project-folders-dialog/project-folders-dialog.service';
import { Action, Store } from '@ngrx/store';
import { AppRootState } from '@root/reducers';
import { selectCurrentProject, selectCurrentProjectNrn } from '@root/selectors/project-list/project-list.selectors';
import { ProjectFolderService } from '@core/services/project-folder/project-folder.service';
import {
  selectCurrentExternalFolder,
  selectOffsetToken,
  selectSearchQuery
} from '@root/selectors/project-folders/project-folders.selectors';
import { Observable, of } from 'rxjs';
import { DialogError } from '@shared/models/project-folders/dialog-error.type';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectFoldersErrorMessagesService } from '@shared/services/project-folders-error-messages/project-folders-error-messages.service';
import { AnalyticsService } from '@core/services/analytics/analytics.service';
import { AnalyticEventCategory } from '@shared/models/analytics/analytic-event-category.model';
import { AnalyticEventAction } from '@shared/models/analytics/analytic-event-action.model';
import { ExternalFoldersResponse } from '@core/models/external-folder/external-folders.response.model';
import { Router } from '@angular/router';
import { ProjectsDialogMode } from '@projects/models/projects-dialog/projects-dialog-mode.model';
import { addRecentProject, clearProject, loadProjects } from '@root/actions/project-list/project-list.actions';
import { scrollToProject } from '@projects/actions/projects-grid/projects-grid.actions';
import { CreateProjectFolderResponse } from '@shared/models/project-folders/create-project-folder.response.model';

export const projectRootFolderAnalyticsLabel = 'SharepointSite';

@Injectable()
export class ProjectFoldersEffects {
  constructor(
    private actions: Actions,
    private projectsRootFolderDialogService: ProjectFoldersDialogService,
    private projectRootFoldersErrorMessagesService: ProjectFoldersErrorMessagesService,
    private projectFolderService: ProjectFolderService,
    private analyticsService: AnalyticsService,
    private store: Store<AppRootState>,
    private router: Router
  ) {}

  takeUntilDialogOpened = <T>(source: Observable<T>) =>
    source.pipe(takeUntil(this.actions.pipe(ofType(closeProjectFoldersDialog))));

  private recordAnalyticsEvent = (source: Observable<CreateProjectFolderResponse>) =>
    source.pipe(
      tap(() =>
        this.analyticsService.recordEvent(
          AnalyticEventAction.ConnectProjectFolder,
          AnalyticEventCategory.Projects,
          projectRootFolderAnalyticsLabel
        )
      )
    );

  private mapToActionsAfterProjectFolderCreation = (
    projectNrn: string,
    projectsDialogMode: ProjectsDialogMode | null
  ) => (source: Observable<CreateProjectFolderResponse>) =>
    source.pipe(
      mergeMap(() => {
        const actionsToDispatch: Action[] = [closeProjectFoldersDialog(), createProjectFolderSuccess()];

        if (this.router.url.includes('/files')) {
          actionsToDispatch.push(reloadFiles({ nrn: projectNrn }));
        }

        if (projectsDialogMode === ProjectsDialogMode.Create) {
          actionsToDispatch.push(
            loadProjects({
              actionsToDispatch: [
                scrollToProject({
                  projectNrn
                }),
                addRecentProject({ recentProjectNrn: projectNrn }),
                clearProject()
              ],
              forceRefresh: true
            })
          );
        }

        return actionsToDispatch;
      })
    );

  private catchProjectFolderCreationError = <T>(source: Observable<T>) =>
    source.pipe(
      catchError(() => {
        this.projectRootFoldersErrorMessagesService.displayToastError(
          'PROJECT.CREATE.PROJECT_FOLDER.CREATE_ERROR_MESSAGE'
        );
        return of(createProjectFolderFailed());
      })
    );

  initProjectFolders = createEffect(() =>
    this.actions.pipe(
      ofType(initProjectFolders),
      withLatestFrom(this.store.select(selectCurrentProject)),
      mergeMap(([{ projectCapabilities }, project]) => [
        setProjectFoldersCapabilities({
          capabilities: project ? project.capabilities : projectCapabilities ? projectCapabilities : []
        }),
        loadExternalFolders({ query: undefined }),
        openProjectFoldersDialog()
      ])
    )
  );

  loadExternalFolders = createEffect(() =>
    this.actions.pipe(
      ofType(loadExternalFolders),
      switchMap(({ query, parentNrn }) =>
        this.projectFolderService.getExternalFolders(query, parentNrn).pipe(
          mergeMap((response: ExternalFoldersResponse) => [
            completeLoadingExternalFolders(response),
            ...(response.items.length === 0
              ? [updateLoadExternalFoldersError({ error: this.getErrorMessage(response) })]
              : [])
          ]),
          catchError((error: HttpErrorResponse) =>
            of(updateLoadExternalFoldersError({ error: this.getErrorMessage(error) }))
          ),
          this.takeUntilDialogOpened
        )
      )
    )
  );

  loadExternalFoldersNextPage = createEffect(() =>
    this.actions.pipe(
      ofType(loadExternalFoldersNextPage),
      withLatestFrom(this.store.select(selectOffsetToken), this.store.select(selectSearchQuery)),
      switchMap(([{ parentNrn }, offsetToken, query]) =>
        this.projectFolderService.getExternalFolders(query, parentNrn, offsetToken).pipe(
          map((response: ExternalFoldersResponse) => completeLoadingExternalFoldersNextPage(response)),
          catchError((error: HttpErrorResponse) =>
            of(updateLoadExternalFoldersError({ error: this.getErrorMessage(error) }))
          ),
          this.takeUntilDialogOpened,
          takeUntil(this.actions.pipe(ofType(loadExternalFolders)))
        )
      )
    )
  );

  openProjectRootFoldersDialog = createEffect(
    () =>
      this.actions.pipe(
        ofType(openProjectFoldersDialog),
        tap(() => this.projectsRootFolderDialogService.open())
      ),
    { dispatch: false }
  );

  closeProjectRootFoldersDialog = createEffect(
    () =>
      this.actions.pipe(
        ofType(closeProjectFoldersDialog),
        tap(() => this.projectsRootFolderDialogService.close())
      ),
    {
      dispatch: false
    }
  );

  createProjectFolder = createEffect(() =>
    this.actions.pipe(
      ofType(createProjectFolder),
      withLatestFrom(this.store.select(selectCurrentProjectNrn), this.store.select(selectCurrentExternalFolder)),
      mergeMap(([{ projectsDialogMode }, projectNrn, currentItem]) =>
        this.projectFolderService
          .createProjectFolder(projectNrn, {
            projectFolders: [{ nrn: currentItem || '' }]
          })
          .pipe(
            this.recordAnalyticsEvent,
            this.mapToActionsAfterProjectFolderCreation(projectNrn, projectsDialogMode),
            this.catchProjectFolderCreationError
          )
      )
    )
  );

  private getErrorMessage(response: ExternalFoldersResponse | HttpErrorResponse): DialogError | null {
    return this.projectRootFoldersErrorMessagesService.getDialogErrorMessage(response);
  }
}
