import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';

type BackdropEventListener = (event: DragEvent) => void;

interface BackdropEventListenersMap {
  drop: BackdropEventListener;
  dragenter: BackdropEventListener;
  dragover: BackdropEventListener;
  dragleave: BackdropEventListener;
  dragstart: BackdropEventListener;
}

@Directive({
  selector: '[appDraggableArea]'
})
export class DraggableAreaDirective implements OnInit, OnDestroy {
  private backdropElements: HTMLElement[] | null;
  private backdropElementEventListeners: BackdropEventListenersMap;
  private readonly dragNoneEffect = 'none';

  @Input() disableDragAndDrop = false;
  @Output() onDrop = new EventEmitter<DataTransfer>();
  @Output() onDrag = new EventEmitter<boolean>();

  @HostListener('drop', ['$event']) onDropEvent(event: DragEvent): void {
    this.handleDropEvent(event);
  }
  @HostListener('dragenter', ['$event']) onDragEnter(event: DragEvent): void {
    this.handleDragEvent(event, true, true);
  }
  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent): void {
    this.handleDragEvent(event, true, false);
  }
  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent): void {
    this.handleDragEvent(event, false, false);
  }
  @HostListener('dragstart', ['$event']) onDragStart(event: DragEvent): void {
    this.handleDragEvent(event, true, true);
  }

  ngOnInit(): void {
    this.setBackdropReference();
  }

  ngOnDestroy(): void {
    this.unsetBackdropElementDragAndDropListeners();
  }

  handleDragEvent(event: DragEvent, highlightDropArea: boolean, cursorNotAllowed: boolean): void {
    event.preventDefault();
    event.stopPropagation();

    if (event.target && event.dataTransfer && (cursorNotAllowed || this.disableDragAndDrop)) {
      event.dataTransfer.effectAllowed = this.dragNoneEffect;
      event.dataTransfer.dropEffect = this.dragNoneEffect;
    }

    if (this.disableDragAndDrop) {
      return;
    }

    event.dataTransfer?.effectAllowed === this.dragNoneEffect
      ? this.onDrag.emit(false)
      : this.onDrag.emit(highlightDropArea);
  }

  handleDropEvent(event: DragEvent): void {
    this.handleDragEvent(event, false, false);

    if (!event.dataTransfer || this.disableDragAndDrop) {
      return;
    }

    this.onDrop.emit(event.dataTransfer);
  }

  private setBackdropReference(): void {
    this.backdropElements = Array.from(document.querySelectorAll('.background'));

    this.setBackdropElementDragAndDropListeners();
  }

  private setBackdropElementDragAndDropListeners(): void {
    const { backdropElements } = this;

    if (!backdropElements) {
      return;
    }

    this.backdropElementEventListeners = {
      drop: event => this.handleDragEvent(event, false, false),
      dragenter: event => this.handleDragEvent(event, false, false),
      dragover: event => this.handleDragEvent(event, false, true),
      dragleave: event => this.handleDragEvent(event, false, true),
      dragstart: event => this.handleDragEvent(event, false, false)
    };

    Object.keys(this.backdropElementEventListeners).forEach(event => {
      backdropElements.forEach(element => element.addEventListener(event, this.backdropElementEventListeners[event]));
    });
  }

  private unsetBackdropElementDragAndDropListeners(): void {
    const { backdropElements, backdropElementEventListeners } = this;

    if (!backdropElements) {
      return;
    }

    Object.keys(backdropElementEventListeners).forEach(event => {
      backdropElements.forEach(element => element.removeEventListener(event, backdropElementEventListeners[event]));
    });
  }
}
