import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Project } from '@core/models/project/project.model';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-new-projects-selector',
  templateUrl: './projects-selector.component.html',
  styleUrls: ['./projects-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectsSelectorComponent {
  @Input() projects: Project[];
  @Input() selectedProject: Project | null;
  @Input() defaultLabel: string;
  @Input() displayAllProjectsOption = false;

  @Output() onProjectSelected = new EventEmitter<Project | null>();

  @ViewChild(MatMenuTrigger, { static: true })
  matMenuTrigger: MatMenuTrigger;

  selectProject(project: Project | null): void {
    this.onProjectSelected.emit(project);
    this.matMenuTrigger.closeMenu();
  }

  stopMenuClose(event: MouseEvent): void {
    event.stopPropagation();
  }
}
