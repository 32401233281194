import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationCredentialProvider } from '@core/services/authentication/authentication.credential.provider';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { DomService } from '@core/services/dom/dom.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export interface IdentifyUserVoiceEventBody {
  email: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  created_at: Date;
}
export interface AddTriggerUserVoiceEventBody {
  /* eslint-disable @typescript-eslint/naming-convention */
  mode: string;
  trigger_style: string;
  local: string;
  accent_color: string;
  trigger_color: string;
  trigger_background_color: string;
  /* eslint-enable @typescript-eslint/naming-convention */
}
export type UserVoiceEvent = (string | UserVoiceEventBody)[];
export type UserVoiceEventBody = IdentifyUserVoiceEventBody | AddTriggerUserVoiceEventBody;
export interface WindowWithUserVoice extends Window {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  UserVoice: { push: Function; events: {} };
}
declare let window: WindowWithUserVoice;

@Component({
  selector: 'app-user-voice-snippet',
  template: ''
})
export class UserVoiceSnippetComponent implements OnInit, OnDestroy {
  constructor(
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private credentialProvider: AuthenticationCredentialProvider,
    private domService: DomService
  ) {}

  private readonly destroyComponent = new Subject<void>();
  private readonly scriptUrl = 'https://newforma.uservoice.com/widget_environment/zyZQ5s3cyyOiMzH4xZYIhQ.js';

  ngOnInit() {
    const isE2E = (localStorage && localStorage.getItem('PROTRACTOR_ENV') === 'true') || (window as any).Cypress;
    if (!isE2E) {
      this.domService.addExternalScript(this.scriptUrl);
      window.UserVoice = window.UserVoice || [];
      this.authenticationService.isLogged
        .pipe(
          tap((isLogged: boolean) => {
            if (!isLogged && window.UserVoice.events) {
              window.UserVoice.push(['removeTrigger']);
              return;
            }
            if (isLogged) {
              this.addUserVoiceIdentify();
              this.addUserVoiceTrigger();
            }
          }),
          takeUntil(this.destroyComponent)
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.destroyComponent.next();
    this.destroyComponent.complete();
  }

  addUserVoiceIdentify(): void {
    const userInfo = this.credentialProvider.getLoggedInUserInfo();
    const userUniqueName = userInfo.unique_name || '';
    window.UserVoice.push([
      'identify',
      {
        email: userUniqueName,
        name: userUniqueName,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        created_at: new Date()
      }
    ]);
  }

  addUserVoiceTrigger(): void {
    /* eslint-disable @typescript-eslint/naming-convention */
    window.UserVoice.push([
      'addTrigger',
      {
        mode: 'feedback',
        trigger_style: 'tab',
        local: this.getBrowserLanguage(),
        accent_color: '#e14504',
        trigger_color: '#ffffff',
        trigger_background_color: '#003c4d'
      }
    ]);
    /* eslint-enable @typescript-eslint/naming-convention */
  }

  private getBrowserLanguage(): string {
    const browserLanguage = this.translateService.getBrowserLang();
    const supportedLanguages = ['de'];
    return supportedLanguages.includes(browserLanguage) ? browserLanguage : '';
  }
}
