import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-inline-edit-indicator-buttons',
  templateUrl: './inline-edit-indicator-buttons.component.html',
  styleUrls: ['./inline-edit-indicator-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineEditIndicatorButtonsComponent {
  @Input() isEditMode: boolean;
  @Output() onAcceptButtonClick = new EventEmitter();
  @Output() onAcceptButtonMouseDown = new EventEmitter();
  @Output() onDeclineButtonMouseDown = new EventEmitter();
  @Output() onDeclineButtonClick = new EventEmitter();

  onAcceptButtonClickHandler(): void {
    this.onAcceptButtonClick.emit();
  }

  onAcceptButtonMouseDownHandler(): void {
    this.onAcceptButtonMouseDown.emit();
  }

  onDeclineButtonClickHandler(): void {
    this.onDeclineButtonClick.emit();
  }

  onDeclineButtonMouseDownHandler(): void {
    this.onDeclineButtonMouseDown.emit();
  }
}
