import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { DomService } from '@core/services/dom/dom.service';

@Component({
  selector: 'app-list-wrapper',
  templateUrl: './list-wrapper.component.html',
  styleUrls: ['./list-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListWrapperComponent implements AfterViewChecked {
  @Input() isLoading: boolean;
  @Input() hasLoadingError: boolean;
  @Input() isEmpty: boolean;
  @Input() emptyMessage: string;
  @Input() isLoadingMore: boolean;
  @Input() hasLoadingMoreError: boolean;

  @Output() onEndOfListReached = new EventEmitter();

  headerRightMargin: number | null = null;
  rightMarginInPx = '8px';
  private readonly baseMarginValue = 0;
  @ViewChild('listTableBody', { static: false }) listTableBodyElementRef: ElementRef | undefined;

  constructor(protected changeDetectorRef: ChangeDetectorRef, private domService: DomService) {}

  ngAfterViewChecked() {
    this.handleListTableBodyChange(this.listTableBodyElementRef);
  }

  reachEndOfTable(): void {
    this.onEndOfListReached.emit();
  }

  private handleListTableBodyChange(listTableBodyElementRef: ElementRef | undefined): void {
    if (!listTableBodyElementRef) {
      return;
    }
    const hasScroll = this.domService.hasElementScroll(listTableBodyElementRef);

    this.headerRightMargin = hasScroll ? this.domService.getScrollbarWidth() : 0;
    this.rightMarginInPx = this.headerRightMargin
      ? `${this.headerRightMargin + this.baseMarginValue}px`
      : `${this.baseMarginValue}px`;
    this.changeDetectorRef.detectChanges();
  }
}
