import { SharePermission } from '@core/models/shared-item/share-permission.model';
import { BaseEvent, EventModel, EventPerson } from '@shared/models/events/events-model';

export type FileDeletedEvent = BaseEvent<'FILE_DELETED', { path: string; fileName: string }>;
export function isFileDeletedEvent(event: EventModel): event is FileDeletedEvent {
  return event.type === 'FILE_DELETED';
}

export type FileUploadedEvent = BaseEvent<'FILE_UPLOADED', { path: string; fileOverwritten: boolean }>;
export function isFileUploadedEvent(event: EventModel): event is FileUploadedEvent {
  return event.type === 'FILE_UPLOADED';
}

export type FileDownloadedEvent = BaseEvent<'FILE_DOWNLOADED', { path: string; fileName: string }>;
export function isFileDownloadedEvent(event: EventModel): event is FileDownloadedEvent {
  return event.type === 'FILE_DOWNLOADED';
}

export type FileRenamedEvent = BaseEvent<'FILE_RENAMED', { newName: string; oldName: string; path: string }>;
export function isFileRenamedEvent(event: EventModel): event is FileRenamedEvent {
  return event.type === 'FILE_RENAMED';
}

export type FolderDownloadedEvent = BaseEvent<'FOLDER_BULK_DOWNLOADED', { path: string }>;
export function isFolderDownloadedEvent(event: EventModel): event is FolderDownloadedEvent {
  return event.type === 'FOLDER_BULK_DOWNLOADED';
}

export type FolderCreatedEvent = BaseEvent<'FOLDER_CREATED', { path: string; createdFolderName: string }>;
export function isFolderCreatedEvent(event: EventModel): event is FolderCreatedEvent {
  return event.type === 'FOLDER_CREATED';
}

export type FolderRenamedEvent = BaseEvent<'FOLDER_RENAMED', { newName: string; oldName: string; path: string }>;
export function isFolderRenamedEvent(event: EventModel): event is FolderRenamedEvent {
  return event.type === 'FOLDER_RENAMED';
}

export type FolderSharedEvent = BaseEvent<
  'FOLDER_SHARE_CREATED',
  { path: string; permissions: SharePermission; recipient: EventPerson }
>;
export function isFolderSharedEvent(event: EventModel): event is FolderSharedEvent {
  return event.type === 'FOLDER_SHARE_CREATED';
}

export type FolderShareRevokedEvent = BaseEvent<'FOLDER_SHARE_REVOKED', { path: string; recipient: EventPerson }>;
export function isFolderShareRevokedEvent(event: EventModel): event is FolderShareRevokedEvent {
  return event.type === 'FOLDER_SHARE_REVOKED';
}

export type PermissionsChangeEvent = BaseEvent<
  'PERMISSIONS_CHANGE',
  {
    accessPermission: SharePermission;
    path: string;
    recipient: EventPerson;
  }
>;
export function isPermissionsChangeEvent(event: EventModel): event is PermissionsChangeEvent {
  return event.type === 'PERMISSIONS_CHANGE';
}

export type FileSharedEvent = BaseEvent<'SHARE_CREATED', { path: string }>;
export function isFileSharedEvent(event: EventModel): event is FileSharedEvent {
  return event.type === 'SHARE_CREATED';
}

export type FileBulkDownloadedEvent = BaseEvent<'FILE_BULK_DOWNLOADED', { path: string }>;
export function isFileBulkDownloadedEvent(event: EventModel): event is FileBulkDownloadedEvent {
  return event.type === 'FILE_BULK_DOWNLOADED';
}

export type FileSharedBulkDownloadedEvent = BaseEvent<'SHARED_FILE_BULK_DOWNLOADED', { path: string }>;
export function isFileSharedBulkDownloadedEvent(event: EventModel): event is FileSharedBulkDownloadedEvent {
  return event.type === 'SHARED_FILE_BULK_DOWNLOADED';
}

export type FolderSharedBulkDownloadedEvent = BaseEvent<'SHARED_FOLDER_BULK_DOWNLOADED', { path: string }>;
export function isFolderSharedBulkDownloadedEvent(event: EventModel): event is FolderSharedBulkDownloadedEvent {
  return event.type === 'SHARED_FOLDER_BULK_DOWNLOADED';
}

export type FileShareDownloadedEvent = BaseEvent<'SHARE_FILE_DOWNLOADED', { path: string }>;
export function isFileShareDownloadedEvent(event: EventModel): event is FileShareDownloadedEvent {
  return event.type === 'SHARE_FILE_DOWNLOADED';
}

export type FolderDeletedEvent = BaseEvent<'FOLDER_DELETED', { path: string; deletedFolderName: string }>;
export function isFolderDeletedEvent(event: EventModel): event is FolderDeletedEvent {
  return event.type === 'FOLDER_DELETED';
}

export type FileShareRevokedEvent = BaseEvent<'SHARE_REVOKE_ALL', { path: string }>;
export function isFileShareRevokedEvent(event: EventModel): event is FileShareRevokedEvent {
  return event.type === 'SHARE_REVOKE_ALL';
}
