import { Injectable } from '@angular/core';
import { TranslateCacheService } from '@shared/services/events/translate-cache/translate-cache-service';
import { SharedEventMapperService } from '@shared/services/events/shared-event-mapper/shared-event-mapper.service';
import { createEventMapper, EventMapperFunc, on } from '@shared/services/events/createEventMapper';
import {
  isSubmittalClosedEvent,
  isSubmittalDeletedEvent,
  isSubmittalFileDownloadedEvent,
  isSubmittalFileTransferFailedEvent,
  isSubmittalForwardedEvent,
  isSubmittalModifiedEvent,
  isSubmittalReceivedEvent,
  isSubmittalRespondedEvent,
  isSubmittalReviewResponseEvent,
  SubmittalClosedEvent,
  SubmittalDeletedEvent,
  SubmittalFileDownloadedEvent,
  SubmittalFileTransferFailedEvent,
  SubmittalForwardedEvent,
  SubmittalModifiedEvent,
  SubmittalReceivedEvent,
  SubmittalRespondedEvent,
  SubmittalReviewResponseEvent
} from '@shared/models/events/submittal-events';
import { MappedProjectEvent } from '@project-log/models/mapped-project-events';
import { fieldTypeNameTranslations } from '@shared/services/events/event-types-translations';

@Injectable({ providedIn: 'root' })
export class SubmittalEventsMapperService {
  constructor(
    private translateCacheService: TranslateCacheService,
    private sharedEventMapperService: SharedEventMapperService
  ) {}

  createEventMapper(): EventMapperFunc {
    return createEventMapper(
      on(isSubmittalReceivedEvent, this.mapSubmittalReceivedEvent.bind(this)),
      on(isSubmittalForwardedEvent, this.mapSubmittalForwardedEvent.bind(this)),
      on(isSubmittalRespondedEvent, this.mapSubmittalRespondedOrReviewResponseEvent.bind(this)),
      on(isSubmittalReviewResponseEvent, this.mapSubmittalRespondedOrReviewResponseEvent.bind(this)),
      on(isSubmittalClosedEvent, this.mapSubmittalClosedEvent.bind(this)),
      on(isSubmittalModifiedEvent, this.mapSubmittalModifiedEvent.bind(this)),
      on(isSubmittalDeletedEvent, this.mapSubmittalDeletedEvent.bind(this)),
      on(isSubmittalFileTransferFailedEvent, this.mapSubmittalFileTransferFailedEvent.bind(this)),
      on(isSubmittalFileDownloadedEvent, this.mapSubmittalFileDownloadedEvent.bind(this))
    );
  }

  private mapSubmittalReceivedEvent(event: SubmittalReceivedEvent): MappedProjectEvent {
    const details = [
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.SPEC_SECTION'),
        value: event.eventData.specSection.name
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.RECEIVED_DATE'),
        value: this.sharedEventMapperService.mapDate(event.eventData.receivedDate)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.DUE_DATE'),
        value: this.sharedEventMapperService.mapDate(event.eventData.dueDate)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.PURPOSE'),
        value: event.eventData.purpose.name
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.SENDER_NUMBER'),
        value: event.eventData.senderNumber
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.FROM'),
        value: this.sharedEventMapperService.mapParticipant(event.eventData.from)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.TO'),
        value: this.sharedEventMapperService.mapParticipants(event.eventData.to)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.DESCRIPTION'),
        value: event.eventData.description
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.INTERNAL_NOTES'),
        value: event.eventData.internalNotes
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.DISCIPLINES'),
        value: this.sharedEventMapperService.mapKeywords(event.eventData.disciplines)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.KEYWORDS'),
        value: this.sharedEventMapperService.mapKeywords(event.eventData.keywords)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.ATTACHMENTS'),
        value: event.eventData.attachments.join(', ')
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.PATH'),
        value: event.eventData.path
      }
    ];

    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: [event.eventData.subject, event.eventData.number],
      link: this.createLink(),
      linkQueryParams: this.createLinkQueryParams(event.eventData.projectNrn, event.eventData.nrn),
      details: details.filter(({ value }) => !!value)
    };
  }

  private mapSubmittalForwardedEvent(event: SubmittalForwardedEvent): MappedProjectEvent {
    const details = [
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.PURPOSE'),
        value: event.eventData.purpose.name
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.REVIEW_DUE_DATE'),
        value: this.sharedEventMapperService.mapDate(event.eventData.reviewDueDate)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.TO'),
        value: this.sharedEventMapperService.mapParticipants(event.eventData.to)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.CC'),
        value: this.sharedEventMapperService.mapParticipants(event.eventData.cc)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.REMARKS'),
        value: event.eventData.remarks
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.ATTACHMENTS'),
        value: event.eventData.attachments.join(', ')
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.PATH'),
        value: event.eventData.path
      }
    ];

    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: [event.eventData.subject, event.eventData.number],
      link: this.createLink(),
      linkQueryParams: this.createLinkQueryParams(event.eventData.projectNrn, event.eventData.nrn),
      details: details.filter(({ value }) => !!value)
    };
  }

  private mapSubmittalRespondedOrReviewResponseEvent(
    event: SubmittalRespondedEvent | SubmittalReviewResponseEvent
  ): MappedProjectEvent {
    const details = [
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.RESPONSE'),
        value: event.eventData.response.name
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.FROM'),
        value: this.sharedEventMapperService.mapParticipant(event.eventData.from)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.TO'),
        value: this.sharedEventMapperService.mapParticipants(event.eventData.to)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.CC'),
        value: this.sharedEventMapperService.mapParticipants(event.eventData.cc)
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.REMARKS'),
        value: event.eventData.remarks
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.ATTACHMENTS'),
        value: event.eventData.attachments.join(', ')
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.PATH'),
        value: event.eventData.path
      }
    ];

    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: [event.eventData.subject, event.eventData.number],
      link: this.createLink(),
      linkQueryParams: this.createLinkQueryParams(event.eventData.projectNrn, event.eventData.nrn),
      details: details.filter(({ value }) => !!value)
    };
  }

  private mapSubmittalClosedEvent(event: SubmittalClosedEvent): MappedProjectEvent {
    const details = [
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.PURPOSE'),
        value: event.eventData.purpose.name
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.REMARKS'),
        value: event.eventData.remarks
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.ATTACHMENTS'),
        value: event.eventData.attachments.join(', ')
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.PATH'),
        value: event.eventData.path
      }
    ];

    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: [event.eventData.subject, event.eventData.number],
      link: this.createLink(),
      linkQueryParams: this.createLinkQueryParams(event.eventData.projectNrn, event.eventData.nrn),
      details: details.filter(({ value }) => !!value)
    };
  }

  private mapSubmittalDeletedEvent(event: SubmittalDeletedEvent): MappedProjectEvent {
    const details = [
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.FILES_PRESERVED'),
        value: event.eventData.path
      },
      {
        key: this.translateCacheService.getCachedTranslation('EVENTS.SUBMITTAL_FILES_DELETED'),
        value: event.eventData.path ? '' : event.eventData.deletedFiles.join(', ')
      }
    ];

    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: [event.eventData.subject, event.eventData.number],
      link: this.createLink(),
      linkQueryParams: this.createLinkQueryParams(event.eventData.projectNrn, event.eventData.nrn),
      details: details.filter(({ value }) => !!value)
    };
  }

  private mapSubmittalModifiedEvent(event: SubmittalModifiedEvent): MappedProjectEvent {
    const details = event.eventData.changes.map(change => {
      const fieldTypeTranslation = fieldTypeNameTranslations[change.fieldName] || 'EVENTS.UNKNOWN';
      return [
        { key: this.translateCacheService.getCachedTranslation(fieldTypeTranslation) },
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.FROM'),
          value: this.sharedEventMapperService.mapModifiedEventField(change.previousValue, change.fieldName)
        },
        {
          key: this.translateCacheService.getCachedTranslation('EVENTS.TO'),
          value: this.sharedEventMapperService.mapModifiedEventField(change.currentValue, change.fieldName)
        }
      ];
    });

    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: [event.eventData.subject, event.eventData.number],
      link: this.createLink(),
      linkQueryParams: this.createLinkQueryParams(event.eventData.projectNrn, event.eventData.nrn),
      details: details.reduce((a, b) => a.concat(b), [])
    };
  }

  private mapSubmittalFileTransferFailedEvent(event: SubmittalFileTransferFailedEvent): MappedProjectEvent {
    const details = this.sharedEventMapperService.mapWorkflowItemFileTransferFailedEventDetails(event);
    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: [event.eventData.subject, event.eventData.number],
      link: this.createLink(),
      linkQueryParams: this.createLinkQueryParams(event.eventData.projectNrn, event.eventData.nrn),
      details
    };
  }

  private mapSubmittalFileDownloadedEvent(event: SubmittalFileDownloadedEvent): MappedProjectEvent {
    const details = this.sharedEventMapperService.mapWorkflowFileDownloadedEventDetails(event);
    return {
      ...this.sharedEventMapperService.mapSharedEventProperties(event),
      item: [event.eventData.subject, event.eventData.number],
      link: this.createLink(),
      linkQueryParams: this.createLinkQueryParams(event.eventData.projectNrn, event.eventData.nrn),
      details
    };
  }

  private createLink(): string {
    return '/submittals/list';
  }

  private createLinkQueryParams(projectNrn: string, nrn: string): { [key: string]: string } {
    return {
      projectNrn,
      submittalNrn: nrn,
      submittalProjectNrn: projectNrn
    };
  }
}
