import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dueInLabel'
})
export class DueInLabelPipe implements PipeTransform {
  transform(diffInDays: number): string {
    return diffInDays === 1 || diffInDays === -1
      ? 'APP.KANBAN.DUE_DATE_ONE_DAY_LABEL'
      : 'APP.KANBAN.DUE_DATE_X_DAYS_LABEL';
  }
}
