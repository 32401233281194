import { createSelector } from '@ngrx/store';
import { appRootSelector } from '@app/root/selectors/app-root.selectors';
import { MappedActivities } from '@shared/services/event-mapper/event-mapper.service';
import { MappedProjectEvent } from '@project-log/models/mapped-project-events';

export const eventsSelector = createSelector(appRootSelector, state => state.events);

export const selectEvents = createSelector(eventsSelector, state => state.events);

export const selectProjectLogEvents = createSelector(eventsSelector, state => state.projectEvents);

export const selectIsLoadingEvents = createSelector(eventsSelector, state => state.isLoadingEvents);

export const selectIsErrorLoadingEvents = createSelector(eventsSelector, state => state.isErrorLoadingEvents);

export const selectEventsSearchQuery = createSelector(eventsSelector, state => state.searchQuery);

export const selectIsLoadingMoreEvents = createSelector(eventsSelector, state => state.isLoadingMoreEvents);

export const selectOffsetToken = createSelector(eventsSelector, state => state.offsetToken);

export const selectVisibleEvents = createSelector(selectEvents, selectEventsSearchQuery, (events, filter) =>
  filterEvents(events, filter)
);

export const selectProjectLogVisibleEvents = createSelector(
  selectProjectLogEvents,
  selectEventsSearchQuery,
  (events, filter) => filterProjectLogEvents(events, filter)
);

export const selectCanLoadMoreEvents = createSelector(selectOffsetToken, offsetToken => !!offsetToken);

export const selectIsEmptyEventListDisplayed = createSelector(
  selectVisibleEvents,
  selectIsLoadingEvents,
  selectIsErrorLoadingEvents,
  selectCanLoadMoreEvents,
  (events, isLoading, isError, canLoadMoreEvents) => events.length === 0 && !isLoading && !isError && !canLoadMoreEvents
);

export const selectIsEmptyProjectLogEventListDisplayed = createSelector(
  selectProjectLogVisibleEvents,
  selectIsLoadingEvents,
  selectIsErrorLoadingEvents,
  selectCanLoadMoreEvents,
  (events, isLoading, isError, canLoadMoreEvents) => events.length === 0 && !isLoading && !isError && !canLoadMoreEvents
);

export const selectIsEventListDisplayed = createSelector(
  selectEvents,
  selectVisibleEvents,
  selectIsLoadingEvents,
  selectIsErrorLoadingEvents,
  selectCanLoadMoreEvents,
  (events, visibleEvents, isLoadingEvents, isErrorLoadingEvents, canLoadMoreEvents) =>
    events.length > 0 &&
    !isLoadingEvents &&
    !isErrorLoadingEvents &&
    !(visibleEvents.length === 0 && !canLoadMoreEvents)
);

export const selectIsProjectLogEventListDisplayed = createSelector(
  selectProjectLogEvents,
  selectProjectLogVisibleEvents,
  selectIsLoadingEvents,
  selectIsErrorLoadingEvents,
  selectCanLoadMoreEvents,
  (events, visibleEvents, isLoadingEvents, isErrorLoadingEvents, canLoadMoreEvents) =>
    events.length > 0 &&
    !isLoadingEvents &&
    !isErrorLoadingEvents &&
    !(visibleEvents.length === 0 && !canLoadMoreEvents)
);

export const selectIsEventErrorViewDisplayed = createSelector(
  selectIsErrorLoadingEvents,
  selectIsLoadingEvents,
  (isErrorLoadingEvents, isLoadingEvents) => isErrorLoadingEvents && !isLoadingEvents
);

function filterEvents(events: MappedActivities[], searchTerm: string | null): MappedActivities[] {
  if (!searchTerm) {
    return events;
  }
  return events.filter(event =>
    JSON.stringify(event)
      .toLocaleLowerCase()
      .includes(searchTerm.toLocaleLowerCase())
  );
}

function filterProjectLogEvents(events: MappedProjectEvent[], searchTerm: string | null): MappedProjectEvent[] {
  if (!searchTerm) {
    return events;
  }
  return events.filter(event =>
    JSON.stringify(event)
      .toLocaleLowerCase()
      .includes(searchTerm.toLocaleLowerCase())
  );
}
