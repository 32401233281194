import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-content-header-button',
  templateUrl: './content-header-button.component.html',
  styleUrls: ['./content-header-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentHeaderButtonComponent {
  @Input() buttonId: string;
  @Input() buttonText: string;
  @Input() svgIcon: string;
  @Input() disabled: boolean;
  @Output() buttonClick = new EventEmitter<void>();
  handleClick(): void {
    this.buttonClick.emit();
  }
}
